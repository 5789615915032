define('widget-portfolio-holdings/../../bower_components/dwx-widget-portfolio-holdings/src/app',["require", "exports", './controllers/portfolioHoldingsCtrl', './directives/portfolioHoldingsDirective'], function (require, exports, portfolioHoldingsController, portfolioHoldingsDirective) {
    exports.configuration = {
        list: {
            'dwxPortfolioHoldingsCtrl': { callback: ['$scope', '$rootScope', "EventService", "portfolioCoreService", "initConfigService", "$filter", "$location", "paginationService", "$sce", "$translatePartialLoader", "$translate", "$timeout", "profileService", portfolioHoldingsController.PortfolioHoldings.PortfolioHoldingsController], type: 'controller' },
            'dwxWidgetPortfolioHoldings': { callback: [portfolioHoldingsDirective.PortfolioHoldingsDirective.Factory()], type: 'directive' },
            'dwxTableAccordionCustom': { callback: [portfolioHoldingsDirective.TableAccordionCustom.Factory()], type: 'directive' }
        },
        ngModules: ['pascalprecht.translate']
    };
});

