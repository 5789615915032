define('../bower_components/dwx-engine/src/services/languageService',["require", "exports"], function (require, exports) {
    var Engine;
    (function (Engine) {
        var LanguageService = (function () {
            function LanguageService($resource, $translate, tmhDynamicLocale, $cookies, eventService, $document) {
                var getLanguage, switchLocale;
                eventService.subscribe("switchLanguage", function (newLanguage) {
                    var host = window.location.hostname;
                    if (host.lastIndexOf('.') > -1) {
                        var lastDotIndex = host.lastIndexOf('.');
                        if (host.substring(0, lastDotIndex).lastIndexOf('.') > -1) {
                            var secondLastDotIndex = host.substring(0, host.lastIndexOf('.')).lastIndexOf('.');
                            host = host.substring(secondLastDotIndex);
                        }
                        else {
                            host = '.' + host;
                        }
                    }
                    var today = new Date("2300-01-30");
                    var UTCstring = today.toUTCString();
                    document.cookie = 'locale=' + newLanguage + ';domain=' + host + ';expires=' + UTCstring + ';path=/';
                    console.log('locale=' + newLanguage + ';domain=' + host + ';expires=2300-01-30T10:10:10.033Z;path=/');
                    language = newLanguage;
                    $translate.use(newLanguage);
                    tmhDynamicLocale.set(newLanguage);
                    if (typeof switchLocale !== 'undefined' && settings && settings.userLoggedIn) {
                        switchLocale.query({
                            language: newLanguage
                        }, {}, function (response) {
                            console.log('Language switched successfully, reinitializing all widget..');
                            eventService.publish("update-portfolios", [], false);
                            eventService.publish("portfolioSelected", [], false);
                            eventService.publish("languageChangeForFaqs", [], false);
                        }, function (error) {
                            console.log('Error switching language!!');
                        });
                    }
                });
                getLanguage = $resource(apiPrefix + "/l10n/languages", {}, {
                    query: {
                        method: "GET",
                        params: {},
                        headers: {
                            'Accept': 'application/json, text/javascript',
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }
                });
                switchLocale = $resource(apiPrefix + "/l10n/switchLocale/:language", {}, {
                    query: {
                        method: "PUT",
                        params: {},
                        isArray: false,
                        headers: {
                            'Accept': 'application/json, text/javascript',
                            'Content-Type': 'application/json; charset=utf-8'
                        }
                    }
                });
                return {
                    language: getLanguage,
                    switchLocale: switchLocale
                };
            }
            return LanguageService;
        })();
        Engine.LanguageService = LanguageService;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

