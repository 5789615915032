define('widget-portfolio-ownership-view/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var PortfolioOwnershipViewConfig = (function () {
        function PortfolioOwnershipViewConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return PortfolioOwnershipViewConfig;
    })();
    exports.PortfolioOwnershipViewConfig = PortfolioOwnershipViewConfig;
});

