define('widget-advisor/../../project_components/dwx-widget-advisor/src/app',["require", "exports", "./controllers/advisorCtrl", "./directives/advisorDirective", "./services/advisorService", "./config"], function (require, exports, advisorController, advisorDirective, advisorService, advisorConfig) {
    exports.configuration = {
        list: {
            "advisorCtrl": { callback: ["$scope", "advisorService", "initConfigService", "EventService", advisorController.AdvisorController], type: "controller" },
            "widgetAdvisorHeader": { callback: ['$window', '$timeout', 'EventService', advisorDirective.AdvisorHeaderDirective], type: "directive" },
            "dwxWidgetAdvisor": { callback: [advisorDirective.AdvisorDirective], type: "directive" },
            "advisorService": { callback: ['$resource', advisorService.AdvisorService], type: "service" },
            "advisorConfig": { callback: ["$translateProvider", advisorConfig.AdvisorConfig], type: "config" }
        },
        ngModules: ['ngResource']
    };
});

