define('layout-base/../../bower_components/dwx-layout-base/src/directives/owuiWidgetDirective',["require", "exports", '../../../../scripts/owui-widget.constant'], function (require, exports, OwuiWidgetConstant) {
    var OwuiWidgetDirective = (function () {
        function OwuiWidgetDirective($q, eventService) {
            var _this = this;
            this.$q = $q;
            this.eventService = eventService;
            this.replace = true;
            this.scope = {
                widgetName: '=',
                widgetManifest: '=',
                widgetProp: '=?'
            };
            this.link = function (scope, el) {
                console.log('WIDGET ASSET MAP', scope['widgetManifest']);
                console.log('WIDGET NAME', scope['widgetName']);
                var widgetAsset = scope['widgetManifest'][OwuiWidgetConstant.OwuiWidgetMap[scope['widgetName']].stealName];
                _this.getAsset(widgetAsset).then(function () {
                    var widget = document.createElement(OwuiWidgetConstant.OwuiWidgetMap[scope['widgetName']].widgetTag);
                    var configSecurity = { apiPath: '/api' };
                    var configI18n = {
                        "autodiscovery": {
                            "url": 'rosetta/i18n/group/' + OwuiWidgetConstant.OwuiWidgetMap[scope['widgetName']].stealName.replace('/*', '') + '/' + "{culture}",
                            "headerKey": "X-Tracking-callerChain",
                            "group": true
                        }
                    };
                    widget.setAttribute('config-i18n', JSON.stringify(configI18n));
                    widget.setAttribute('config-security', JSON.stringify(configSecurity));
                    widget.setAttribute('config-event', JSON.stringify({
                        name: OwuiWidgetConstant.OwuiWidgetMap[scope['widgetName']].widgetTag,
                        target: "self"
                    }));
                    _this.setWidgetAttribute(scope['widgetProp'], widget);
                    $(el)[0].appendChild(widget);
                    _this.eventService.publish('owuiWidgetDirective:widgetLoaded', [OwuiWidgetConstant.OwuiWidgetMap[scope['widgetName']].widgetTag]);
                });
                scope.$watch('widgetProp', function (newValue, oldValue) {
                    var widget = document.getElementsByTagName(OwuiWidgetConstant.OwuiWidgetMap[scope['widgetName']].widgetTag)[0];
                    if (!!widget) {
                        _this.removeWidgetAttribute(oldValue, widget);
                        _this.setWidgetAttribute(newValue, widget);
                    }
                });
            };
        }
        OwuiWidgetDirective.prototype.getAsset = function (widgetAsset) {
            var deferred = this.$q.defer();
            try {
                console.log('BOOTSTRAP OWUI WIDGET');
                console.log('WIDGET ASSET', widgetAsset);
                var widgetPath = "/widget-thule" + widgetAsset;
                if (widgetAsset.indexOf('://localhost:8080') > -1) {
                    widgetPath = widgetAsset;
                }
                if (document.querySelector("head script[src='" + widgetPath + "']")) {
                    deferred.resolve();
                }
                var scriptElement = document.createElement('script');
                scriptElement.src = widgetPath;
                scriptElement.onload = function () { return deferred.resolve(); };
                scriptElement.onerror = function (error) { return deferred.reject(error); };
                document.getElementsByTagName('head')[0].appendChild(scriptElement);
            }
            catch (error) {
                throw Error(error);
            }
            return deferred.promise;
        };
        OwuiWidgetDirective.prototype.setWidgetAttribute = function (widgetProp, widget) {
            if (widgetProp) {
                Object.keys(widgetProp).forEach(function (key) {
                    widget.setAttribute(key, widgetProp[key]);
                });
            }
        };
        OwuiWidgetDirective.prototype.removeWidgetAttribute = function (widgetProp, widget) {
            if (widgetProp) {
                Object.keys(widgetProp).forEach(function (key) {
                    widget.removeAttribute(key);
                });
            }
        };
        OwuiWidgetDirective.Factory = function () {
            var directive = function ($q, EventService) {
                return new OwuiWidgetDirective($q, EventService);
            };
            directive['$inject'] = ['$q', 'EventService'];
            return directive;
        };
        return OwuiWidgetDirective;
    })();
    exports.OwuiWidgetDirective = OwuiWidgetDirective;
});

