define('../bower_components/dwx-widget-my-documents/src/services/myDocumentsService',["require", "exports"], function (require, exports) {
    var MyDocumentsService = (function () {
        function MyDocumentsService($resource, $http, $q) {
            this.$resource = $resource;
            this.$http = $http;
            this.$q = $q;
            this.retrieveDocuments = this.$resource(apiPrefix + "/widget/myDocuments/documents/forSigner", {});
            this.tag = this.$resource("#{apiPrefix}/documents/:documentId/tag/:tagName", {
                documentId: '@documentId',
                tagName: '@tagName'
            });
            this.getMockConfiguration = this.$resource("bower_components/dwx-widget-my-documents/config/configuration-:widgetType.json", { widgetType: "@widgeType" });
            this.getConfiguration = this.$resource("/myazimut/api/widgets/getByName/:widgetType", { widgetType: "@widgeType" });
            this.getDocumentMock = this.$resource("bower_components/dwx-widget-my-documents/mock/documents.json", {}, {
                get: {
                    isArray: true
                }
            });
            this.retrieveBundleUrl = this.$resource(apiPrefix + "/widget/signRoom/getBundleUrl", { bundleCode: '@bundleCode' }, {
                get: {
                    isArray: false
                }
            });
            this.getCommunicationDocumentUnread = this.$resource("/api/icaro/communication/countNewDocument", {}, {
                get: {
                    isArray: false
                }
            });
            this.getRendicontiDocumentUnread = this.$resource("/api/icaro/communication/countNewDocumentStatement", {}, {
                get: {
                    isArray: false
                }
            });
            this.getAdCommunicationDocumentUnread = this.$resource("/api/icaro/communication/countNewDocumentForAgent", {}, {
                get: {
                    isArray: false
                }
            });
            this.getAdReportingDocumentUnread = this.$resource("/api/icaro/communication/countNewDocumentStatementForAgent", {}, {
                get: {
                    isArray: false
                }
            });
            this.getReportingDocumentsIcaro = this.$resource("/api/icaro/communication/search", {}, {
                save: {
                    method: 'POST',
                    data: {
                        bodydata: true
                    },
                }
            });
            this.retrieveWorkstepId = this.$resource(apiPrefix + "/widget/signRoom/startsignature/:id", { id: '@id' }, {
                save: {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    data: {
                        bodydata: true
                    },
                }
            });
            this.saveBundleDocumentsViewed = this.$resource(apiPrefix + '/widget/signRoom/saveBundleDocumentsViewed', {}, {
                save: {
                    method: 'POST',
                    isArray: false
                }
            });
            this.rejectBundle = this.$resource(apiPrefix + '/widget/signRoom/rejectBundle', { bundleCode: '@bundleCode', bundleContext: '@bundleContext' }, {
                save: {
                    method: 'POST',
                    isArray: false
                }
            });
            this.widgetType = "";
        }
        MyDocumentsService.prototype.getGenericDocuments = function (serviceData) {
            var url = apiPrefix + "/documents/getDocumentListByUser";
            return this.getData(url, serviceData);
        };
        MyDocumentsService.prototype.getDocumentSummary = function (docCategories, docTags) {
            var url = apiPrefix + "/documents/getDocumentSummaryByUser";
            var params = { docCategories: docCategories, docTags: docTags };
            return this.getData(url, params);
        };
        MyDocumentsService.prototype.getPdfDocumentSummary = function (docCategory) {
            var url = apiPrefix + "/documents/getPdfSummaryByUser";
            var params = { docCategory: docCategory };
            return this.getData(url, params);
        };
        MyDocumentsService.prototype.getPaginatedGroupedDocuments = function (serviceData) {
            var url = apiPrefix + "/documents/getGroupedDocumentsByUser";
            return this.getData(url, serviceData);
        };
        MyDocumentsService.prototype.getPaginatedGroupedPDOBDocuments = function (serviceData) {
            var url = apiPrefix + "/documents/getGroupedPdobDocumentsByUser";
            return this.getData(url, serviceData);
        };
        MyDocumentsService.prototype.getGroupedDocuments = function (docCategory, groupBy) {
            var url = apiPrefix + "/documents/groupedDocuments";
            var params = { docCategory: docCategory, groupBy: groupBy };
            return this.getData(url, params);
        };
        MyDocumentsService.prototype.getDocumentCategories = function (widgetId) {
            var url = apiPrefix + "/documents/categories";
            var params = { widgetId: widgetId };
            return this.getData(url, params);
        };
        MyDocumentsService.prototype.getDocumentType = function (docCategory) {
            var url = apiPrefix + "/documents/getDocumentTypeByCategory";
            var params = { docCategory: docCategory };
            return this.getData(url, params);
        };
        MyDocumentsService.prototype.getAFBUrl = function () {
            var deferred = this.$q.defer();
            this.$http.get(apiPrefix + "/documents/product/kiid").then(function (success) {
                if (success.data['outcome'] === 'OK') {
                    deferred.resolve(success.data.content);
                }
                else {
                    deferred.reject(success.data);
                }
            }, function (error) {
                deferred.reject({ type: 'function', error: error });
            });
            return deferred.promise;
        };
        MyDocumentsService.prototype.getAzLifeEnabled = function () {
            var deferred = this.$q.defer();
            this.$http.get(apiPrefix + "/azlife/enabled").then(function (success) {
                if (success.data['outcome'] === 'OK') {
                    deferred.resolve(success.data.content);
                }
                else {
                    deferred.reject(success.data);
                }
            }, function (error) {
                deferred.reject({ type: 'function', error: error });
            });
            return deferred.promise;
        };
        MyDocumentsService.prototype.goToAzLifeDocumentation = function () {
            var deferred = this.$q.defer();
            this.$http.get(apiPrefix + "/azlife/url").then(function (success) {
                if (success.data['outcome'] === 'OK') {
                    deferred.resolve(success.data.content);
                }
                else {
                    deferred.reject(success.data);
                }
            }, function (error) {
                deferred.reject({ type: 'function', error: error });
            });
            return deferred.promise;
        };
        MyDocumentsService.prototype.getData = function (url, params) {
            var deferred = this.$q.defer();
            this.$http.get(url, {
                params: params
            }).then(function (success) {
                if (success.data['outcome'] === 'OK') {
                    deferred.resolve(success.data.content);
                }
                else {
                    deferred.reject(success.data);
                }
            }, function (error) {
                deferred.reject({ type: 'function', error: error });
            });
            return deferred.promise;
        };
        MyDocumentsService.prototype.getDownloadDocumentUrl = function (document, hasEncodedId, key, isAdvisor) {
            var id = hasEncodedId ? encodeURI(document.id) : document.id;
            var category = document.topic;
            var processTypeCode = (document.attributes && document.attributes.processTypeCode) ? document.attributes.processTypeCode : undefined;
            if (!!key && key.indexOf('pdob') > -1)
                processTypeCode = 'ONBOARDING_PROSPECT';
            var url = "" + apiPrefix + "/documents/downloadDocument/" + id;
            if (category || processTypeCode) {
                url += "?";
            }
            if (!!category) {
                url = "" + url + "category=" + category;
            }
            if (!!processTypeCode) {
                url = !!category ? url + "&" : url;
                url = "" + url + "processType=" + processTypeCode;
            }
            if (!!isAdvisor) {
                url = "" + url + "&isAgent=" + true;
            }
            url = document.topic === 'summaryPdf' ? "" + apiPrefix + "/documents/downloadPdfSummaryByUser/" + document.fileName + "?docCategory=summaryPdf" : url;
            return url;
        };
        MyDocumentsService.prototype.downloadIcaroDocument = function (params) {
            var deferred = this.$q.defer();
            this.$http.get('/api/icaro/communication/download/' + params, {
                responseType: 'blob'
            }).then(function (success) {
                deferred.resolve(success);
            }, function (error) {
                deferred.reject({ type: 'function', error: error });
            });
            return deferred.promise;
        };
        return MyDocumentsService;
    })();
    exports.MyDocumentsService = MyDocumentsService;
});

