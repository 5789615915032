define('layout-base/../../bower_components/dwx-utility/src/app',["require", "exports", './services/eventService', './services/callToBackService', './services/paginationService', './services/paginationServiceImproved', './filters/base64Filter', './filters/currencyToSymbolFilter', './filters/dateTranslateFilter', './filters/reverseArray', './filters/sliceArray', './filters/trustAs', './directives/customTableDirective', './directives/datePickerDirective', './directives/dynamicNameDirective', './directives/hasTooltipDirective', './directives/dropdownDirective', './directives/responsiveScrollableTableDirective', './controllers/svgCtrl', './controllers/dwxTableController', './directives/domDirective', './directives/modalBoxDirective', './directives/dwxInputValidatorDirective', './directives/paginationDirective', './directives/dropDownClickableDirective', './directives/dwxTableDirective', "angular-cookies"], function (require, exports, eventService, callToBackService, paginationService, paginationServiceImproved, base64Filter, currencyToSymbolFilter, dateTranslateFilter, reverseArrayFilter, sliceArrayFilter, trustAsFilter, customTable, datePicker, dynamicName, hasTooltip, dropdownDirective, responsiveScrollableTable, svgController, dwxTableController, domDirective, modalBoxDirective, dwxInputValidatorDirective, pagination, dropDownClickableDirective, dwxTableDirective) {
    var Utility;
    (function (Utility) {
        Utility.configuration = {
            ngModules: ['ngCookies'],
            list: {
                "EventService": {
                    callback: ["$rootScope", "$location", "$cookieStore", eventService.Utility.EventService],
                    type: "factory"
                },
                "callToBackService": {
                    callback: ["errorsHandlerService", callToBackService.Utility.CallToBackService],
                    type: "factory"
                },
                "paginationService": {
                    callback: [paginationService.Utility.PaginationService],
                    type: "factory"
                },
                "paginationServiceImproved": {
                    callback: [paginationServiceImproved.Utility.PaginationServiceImproved],
                    type: "factory"
                },
                "base64": { callback: [base64Filter.Utility.Base64Filter.Factory], type: "filter" },
                "currencyToSym": {
                    callback: ['$sce', currencyToSymbolFilter.Utility.CurrencyToSymbolFilter.Factory],
                    type: "filter"
                },
                "dateTranslate": {
                    callback: ['$filter', dateTranslateFilter.Utility.DateTranslateFilter.Factory],
                    type: "filter"
                },
                "reverse": { callback: [reverseArrayFilter.Utility.ReverseArrayFilter.Factory], type: "filter" },
                "slice": { callback: [sliceArrayFilter.Utility.SliceArrayFilter.Factory], type: "filter" },
                "trustAsHtml": { callback: ['$sce', trustAsFilter.Utility.TrustAsHtmlFilter.Factory], type: "filter" },
                "trustAsResourceUri": {
                    callback: ['$sce', trustAsFilter.Utility.TrustAsResourceUriFilter.Factory],
                    type: "filter"
                },
                "trustAsDelegate": {
                    callback: ['$sce', '$sceDelegate', trustAsFilter.Utility.TrustAsDelegateFilter.Factory],
                    type: "filter"
                },
                "trustAsResourceUrl": {
                    callback: ['$sce', trustAsFilter.Utility.TrustAsResourceUrlFilter.Factory],
                    type: "filter"
                },
                "dwxCtSmallToggler": {
                    callback: ['$compile', customTable.Utility.CustomTableSmallTogglerDirective.Factory()],
                    type: "directive"
                },
                "dwxDatePicker": {
                    callback: ["$timeout", "$filter", 'EventService', datePicker.Utility.DatePickerDirective.Factory()],
                    type: "directive"
                },
                "dynamicName": {
                    callback: ['$compile', '$parse', dynamicName.Utility.DynamicNameDirective.Factory()],
                    type: "directive"
                },
                "dwxHasTooltip": {
                    callback: ['$timeout', hasTooltip.Utility.HasTooltipDirective.Factory()],
                    type: "directive"
                },
                "dwxDropdown": {
                    callback: [dropdownDirective.Utility.DropdownDirective.Factory()],
                    type: "directive"
                },
                "dwxResponsiveScrollableTable": {
                    callback: ['$timeout', responsiveScrollableTable.Utility.ResponsiveScrollableTableDirective.Factory()],
                    type: "directive"
                },
                "ctActionsTrigger": {
                    callback: ['$timeout', responsiveScrollableTable.Utility.CustomTableActionTriggerDirective.Factory()],
                    type: "directive"
                },
                "ctActionsClose": {
                    callback: [responsiveScrollableTable.Utility.CustomTableActionCloseDirective.Factory()],
                    type: "directive"
                },
                "svgCtrl": {
                    callback: [svgController.SvgController],
                    type: "controller"
                },
                "dwxTableCtrl": {
                    callback: ["$scope", "$filter", dwxTableController.DwxTableController],
                    type: "controller"
                },
                "dwxDomDirective": {
                    callback: ['$timeout', domDirective.Utility.DomDirective.Factory()],
                    type: "directive"
                },
                "dwxInput": {
                    callback: ['$filter', 'EventService', dwxInputValidatorDirective.Utility.DwxInputValidatorDirective.Factory()],
                    type: "directive"
                },
                "a": {
                    callback: [domDirective.Utility.PreventDefault.Factory()],
                    type: "directive"
                },
                "modalBox": {
                    callback: ["$filter", "EventService", modalBoxDirective.Utility.ModalBoxDirective.Factory()],
                    type: "directive"
                },
                "dwxFormat": {
                    callback: ['$filter', domDirective.Utility.FormatOnBlurDirective.Factory()],
                    type: "directive"
                },
                "formatXml": {
                    callback: [domDirective.Utility.FormatXml.Factory()],
                    type: "directive"
                },
                "dwxPagination": {
                    callback: [pagination.PaginationDirective.Factory()],
                    type: "directive"
                },
                "dwxDropdownClickable": {
                    callback: ["EventService", dropDownClickableDirective.Utility.DropdownClickableDirective.Factory()],
                    type: "directive"
                },
                "dwxTableDirective": {
                    callback: [dwxTableDirective.DwxTableDirective.Factory()],
                    type: "directive"
                }
            }
        };
    })(Utility = exports.Utility || (exports.Utility = {}));
});

