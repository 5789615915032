define('../bower_components/dwx-engine/src/services/configurationService',["require", "exports"], function (require, exports) {
    var Engine;
    (function (Engine) {
        var ConfigurationService = (function () {
            function ConfigurationService($resource, EventService) {
                var httpResponseError;
                httpResponseError = function (responseMessageKey) {
                    if (responseMessageKey === 'session_expired' || responseMessageKey === 'user_not_authenticated') {
                        EventService.publish('user-session-changed');
                        EventService.publish('redirect-to-home');
                    }
                    else {
                        responseMessageKey === 'error.user_not_authorized';
                    }
                };
                EventService.subscribe('http-response-error', function (responseMessageKey) {
                    httpResponseError(responseMessageKey);
                });
                return {
                    start: $resource(apiPrefix + "/configuration/webapp", {}),
                    manifestThule: $resource("/manifest-thule/manifest.assets.json", {}),
                    widgetThule: function (widgetPath) { return $resource("/widget-thule" + widgetPath, {}); },
                    manifestJson: null
                };
            }
            return ConfigurationService;
        })();
        Engine.ConfigurationService = ConfigurationService;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

