define('layout-base/../../bower_components/dwx-layout-base/src/services/headerApiService',["require", "exports"], function (require, exports) {
    var HeaderApiService = (function () {
        function HeaderApiService($resource, $http, $q) {
            this.$resource = $resource;
            this.$http = $http;
            this.$q = $q;
        }
        HeaderApiService.prototype.resetTrustCompany = function () {
            var deferred = this.$q.defer();
            var _this = this;
            this.$http.post("/api/jwt/additionalInfo/reset", { "keys": ["BEHALF2_INFO"] }, { headers: { 'Content-Type': 'application/json; charset=utf-8' } }).then(function (success) {
                window.location.reload();
            }, function (error) {
                deferred.reject({ type: 'function', error: error });
            });
            return deferred.promise;
        };
        return HeaderApiService;
    })();
    exports.HeaderApiService = HeaderApiService;
});

