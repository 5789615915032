define('widget-focus/../../project_components/dwx-widget-focus/src/directives/focusDirective',["require", "exports"], function (require, exports) {
    var FocusDirective = (function () {
        function FocusDirective() {
            this.scope = { identifier: '=' };
            this.replace = true;
            this.controller = 'focusCtrl as focus';
            this.templateUrl = 'bower_components/dwx-widget-focus/dist/src/views/partials/main.html';
            var _this = this;
        }
        FocusDirective.Factory = function () {
            var directive = function () {
                return new FocusDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return FocusDirective;
    })();
    exports.FocusDirective = FocusDirective;
});

