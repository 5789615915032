define('layout-base/../../bower_components/dwx-utility/src/directives/dwxTableDirective',["require", "exports"], function (require, exports) {
    exports.basePath = 'views/';
    var DwxTableDirective = (function () {
        function DwxTableDirective() {
            this.restrict = 'A';
            this.scope = {
                tableConfiguration: '=tableConfiguration',
                widgetName: '@widgetName',
                itemList: '=itemList'
            };
            this.templateUrl = exports.basePath + 'dwx-table.html';
            this.controller = 'dwxTableCtrl';
            this.controllerAs = 'dwxTableCtrl';
        }
        DwxTableDirective.Factory = function () {
            var directive = function () {
                return new DwxTableDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return DwxTableDirective;
    })();
    exports.DwxTableDirective = DwxTableDirective;
});

