define('../bower_components/dwx-widget-my-documents/src/services/myDocumentsConfigurationService',["require", "exports", '../domain/myDocumentsConfiguration'], function (require, exports, configuration) {
    var MyDocumentsConfigurationService = (function () {
        function MyDocumentsConfigurationService() {
            var _this = this;
            this.maxTableConfiguration = [
                {
                    "columnName": "titleLink",
                    "sortColumnName": "documentTitle",
                    "hasModalLink": true,
                    "hasTagNew": true,
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "title",
                            }
                        ],
                        "2": [
                            {
                                "fieldName": "expirationDate",
                                "attribute": true,
                                "fieldType": "date"
                            }
                        ]
                    }
                },
                {
                    "columnName": "processType",
                    "sortColumnName": "processType",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "processTypeDesc",
                                "attribute": true
                            }
                        ],
                        "2": [
                            {
                                "fieldName": "practiceId",
                                "attribute": true
                            }
                        ]
                    }
                },
                {
                    "columnName": "acknowledgment",
                    "checkboxLabel": "Dichiaro di aver preso visione dei contenuti della proposta",
                    "sortColumnName": "acknowledgment",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "acknowledgment",
                            }
                        ]
                    }
                },
                {
                    "columnName": "rejectProposal",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "rejectProposal",
                            }
                        ]
                    }
                },
                {
                    "columnName": "acceptProposal",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "acceptProposal"
                            }
                        ]
                    }
                }
            ];
            this.simulaTableConfiguration = [
                {
                    "hasModalLink": true,
                    "columnName": "titleLink",
                    "sortColumnName": "documentTitle",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "title",
                            }
                        ]
                    }
                },
                {
                    "columnName": "processType",
                    "sortColumnName": "processType",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "processTypeDesc",
                                "attribute": true
                            }
                        ],
                        "2": [
                            {
                                "fieldName": "practiceId",
                                "attribute": true
                            }
                        ]
                    }
                },
                {
                    "columnName": "totalAmount",
                    "sortColumnName": "totalAmount",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "totalAmount",
                                "attribute": true,
                                "fieldType": "currency"
                            }
                        ]
                    }
                },
                {
                    "columnName": "acknowledgment",
                    "sortColumnName": "acknowledgment",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "acknowledgment"
                            }
                        ]
                    }
                }
            ];
            this.rtoTableConfiguration = [
                {
                    "columnName": "titleLink",
                    "sortColumnName": "documentTitle",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "title",
                            }
                        ]
                    }
                },
                {
                    "columnName": "processType",
                    "sortColumnName": "processType",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "processTypeDesc",
                                "attribute": true
                            }
                        ],
                        "2": [
                            {
                                "fieldName": "practiceId",
                                "attribute": true
                            }
                        ]
                    }
                },
                {
                    "columnName": "totalAmount",
                    "sortColumnName": "totalAmount",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "totalAmount",
                                "attribute": true,
                                "fieldType": "currency"
                            }
                        ]
                    }
                },
                {
                    "columnName": "acknowledgment",
                    "sortColumnName": "acknowledgment",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "acknowledgment"
                            }
                        ]
                    }
                },
                {
                    "columnName": "acceptProposal",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "acceptProposal"
                            }
                        ]
                    }
                }
            ];
            this.rtoSecondStepTableConfiguration = [
                {
                    "columnName": "nameTitle",
                    "sortColumnName": "nameTitle",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "productName"
                            }
                        ]
                    }
                },
                {
                    "columnName": "ISIN",
                    "sortColumnName": "ISIN",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "isinCode"
                            }
                        ]
                    }
                },
                {
                    "columnName": "instrument",
                    "sortColumnName": "instrument",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "productType"
                            }
                        ]
                    }
                },
                {
                    "columnName": "owner",
                    "sortColumnName": "owner",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "orderingName"
                            }
                        ]
                    }
                },
                {
                    "columnName": "quantity",
                    "sortColumnName": "quantity",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "quantity"
                            }
                        ]
                    }
                },
                {
                    "columnName": "price",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "price"
                            }
                        ]
                    }
                },
                {
                    "columnName": "priceMode",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "priceParameter"
                            }
                        ]
                    }
                },
                {
                    "columnName": "validity",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "timeParameter"
                            }
                        ]
                    }
                }
            ];
            this.advisorTableConfiguration = [
                {
                    "hasModalLink": true,
                    "columnName": "titleLink",
                    "sortColumnName": "documentTitle",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "title",
                            }
                        ]
                    }
                },
                {
                    "columnName": "processType",
                    "sortColumnName": "processType",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "processTypeDesc",
                                "attribute": true
                            }
                        ],
                        "2": [
                            {
                                "fieldName": "practiceId",
                                "attribute": true
                            }
                        ]
                    }
                },
                {
                    "columnName": "totalAmount",
                    "sortColumnName": "totalAmount",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "totalAmount",
                                "attribute": true,
                                "fieldType": "currency"
                            }
                        ]
                    }
                },
                {
                    "columnName": "acceptProposal",
                    "goToSign": true,
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "acceptProposal"
                            }
                        ]
                    }
                }
            ];
            this.advisorDocumentsTableConfiguration = [
                {
                    "columnName": "title",
                    "sortColumnName": "documentTitle",
                    "fieldsList": {
                        "1": [
                            {
                                "fieldName": "title",
                            }
                        ]
                    }
                }
            ];
            this.initConfiguration = function (widgetConfiguration) {
                _this.myDocConfig = new configuration.MyDocumentsConfiguration();
                _this.myDocConfig.setRemotePagination(widgetConfiguration.remotePagination).setDefaultGrouping(widgetConfiguration.defaultGrouping).setCategories(Object.keys(widgetConfiguration.documentCategories)).setDocumentCategories(widgetConfiguration.documentCategories).setDefaultView(widgetConfiguration.defaultView).setViewType(widgetConfiguration.defaultView).setNumberItemsInPageGridView(widgetConfiguration.numberItemsInPageGridView).setNumberItemsInPageListView(widgetConfiguration.numberItemsInPageListView).setShowFilters(widgetConfiguration.showFilters).setShowFilterBar(widgetConfiguration.showFilterBar).setShowListViewButton(widgetConfiguration.showListViewButton).setShowGridViewButton(widgetConfiguration.showGridViewButton).setSummaryTagsList(widgetConfiguration.summaryTagsList).setEnablePolling(widgetConfiguration.enablePolling).setShowSignView(widgetConfiguration.showSignView);
            };
            this.getDocumentCategoryIndex = function () {
                return _this.myDocConfig && _this.myDocConfig.documentCategoryIndex;
            };
        }
        return MyDocumentsConfigurationService;
    })();
    exports.MyDocumentsConfigurationService = MyDocumentsConfigurationService;
});

