define('widget-news-list/../../project_components/dwx-widget-news-list/src/app',["require", "exports", "./controllers/newsListCtrl", "./directives/newsListDirective", "./config"], function (require, exports, newsListController, newsListDirective, newsListConfig) {
    exports.configuration = {
        list: {
            "newsListCtrl": {
                callback: ["$scope", "$routeParams", "initConfigService", "EventService", "$location", "configurationService", newsListController.NewsListController],
                type: "controller"
            },
            "dwxWidgetNewsList": { callback: [newsListDirective.NewsListDirective], type: "directive" },
            "newsListConfig": { callback: [newsListConfig.NewsListConfig], type: "config" }
        },
        ngModules: ['ngResource']
    };
});

