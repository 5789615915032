define('widget-pir-box/../../project_components/dwx-widget-pir-box/src/controllers/pirBoxCtrl',["require", "exports", '../../../../scripts/owui-widget.constant'], function (require, exports, OwuiWidgetConstant) {
    var PirBoxController = (function () {
        function PirBoxController($scope, initConfigService, configurationService, EventService, profileService) {
            this.$scope = $scope;
            this.configurationService = configurationService;
            this.EventService = EventService;
            this.profileService = profileService;
            this.owuiWidgetPirBoxName = 6 /* WIDGET_PIR_BOX */;
            var _this = this;
            $scope.events = this;
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetAdvisor';
            _this.widgetConfig = initConfigService[_this.widgetId];
            if (!!_this.profileService.loggedUser) {
                var behalfCode = _this.profileService.loggedUser.userInfos.BEHALF_USER_CODE;
                _this.widgetProp = {
                    "customer-code": !!behalfCode ? behalfCode.value : _this.profileService.loggedUser.userInfos.USER_CODE.value,
                    "include-contract-with-zero-balance": env.includeContractWithZeroBalance,
                    "isolated-resource-mode": true
                };
                _this.widgetConfig.status.mode = 'on';
            }
            var loggedUserHandler = EventService.subscribe('profileService:logged-user-ready', function (loggedUser) {
                var behalfCode = loggedUser.userInfos.BEHALF_USER_CODE;
                _this.widgetProp = {
                    "customer-code": !!behalfCode ? behalfCode.value : loggedUser.userInfos.USER_CODE.value,
                    "include-contract-with-zero-balance": env.includeContractWithZeroBalance,
                    "isolated-resource-mode": true
                };
                _this.widgetConfig.status.mode = 'on';
            });
            $scope.$on("$destroy", function () {
                _this.EventService.unsubscribe(loggedUserHandler);
            });
        }
        PirBoxController.prototype.getManifestAssets = function () {
            return this.configurationService.manifestJson;
        };
        return PirBoxController;
    })();
    exports.PirBoxController = PirBoxController;
});

