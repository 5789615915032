define('boot',["require", "exports", 'angular', 'jquery', './engine', './errors-handler', './utility', './layout-base/layout-base', './init-config-be', './widget-login/widget-login', './portfolio-core', './widget-portfolio-ownership-view/widget-portfolio-ownership-view', './widget-portfolio-summary/widget-portfolio-summary', './widget-portfolio-holdings/widget-portfolio-holdings', './widget-user-data/widget-user-data', './widget-user-data-advanced/widget-user-data-advanced', './widget-my-documents', './widget-media-library', './azimut-utility', './widget-advisor/widget-advisor', './widget-html-content', './widget-dossier-selector', './widget-support-page/widget-support-page', './widget-portfolio-performance-azimut', './widget-first-access', './widget-unlock-user', '../bower_components/dwx-analytics-tracker/src/app', './widget-settings-customer/widget-settings-customer', './widget-news/widget-news', './widget-news-list/widget-news-list', './widget-focus/widget-focus', './widget-pir-box/widget-pir-box'], function (require, exports, angular, $, engine, errorsHandler, utility, layoutBase, initConfigBE, login, portfolioCore, portfolioOwnershipView, portfolioSummary, portfolioHoldings, userData, userDataAdvanced, myDocuments, mediaLibrary, azimutUtility, advisor, htmlContent, dossierSelector, supportPage, portfolioPerformanceAzimut, firstAccessAzimut, unlockUser, analyticsTracker, settingsCustomer, news, newsList, focus, pirBox) {
    console.log("Config call! bootstrap");
    var concatUnique = function (array1, array2) {
        var myArray = array1;
        return myArray.concat(array2.filter(function (i) {
            return myArray.indexOf(i) == -1;
        }));
    };
    var newList = {};
    var ngModules = [];
    var bootstrap = function () {
        var app = angular.module('MyApp', ngModules);
        for (var key in newList) {
            if (newList[key].type != 'config' && newList[key].type != 'run') {
                app[newList[key].type](key, newList[key].callback);
            }
            else
                app[newList[key].type](newList[key].callback);
        }
        setTimeout(function () {
            angular.bootstrap(document, ["MyApp"]);
        }, 1000);
    };
    var registerWidget = function (configuration) {
        ngModules = concatUnique(ngModules, configuration.ngModules);
        angular.extend(newList, configuration.list);
    };
    console.log("bootstrap 2");
    $(document).ready(function () {
        console.log("MyApp" + "!");
        registerWidget(engine.configuration);
        registerWidget(utility.configuration);
        registerWidget(layoutBase.configuration);
        registerWidget(initConfigBE.configuration);
        registerWidget(errorsHandler.configuration);
        registerWidget(login.configuration);
        registerWidget(advisor.configuration);
        registerWidget(portfolioCore.configuration);
        registerWidget(portfolioOwnershipView.configuration);
        registerWidget(portfolioSummary.configuration);
        registerWidget(portfolioHoldings.configuration);
        registerWidget(userData.configuration);
        registerWidget(userDataAdvanced.configuration);
        registerWidget(azimutUtility.configuration);
        registerWidget(mediaLibrary.configuration);
        registerWidget(htmlContent.configuration);
        registerWidget(dossierSelector.configuration);
        registerWidget(supportPage.configuration);
        registerWidget(portfolioPerformanceAzimut.configuration);
        registerWidget(firstAccessAzimut.configuration);
        registerWidget(myDocuments.configuration);
        registerWidget(unlockUser.configuration);
        registerWidget(analyticsTracker.configuration);
        registerWidget(settingsCustomer.configuration);
        registerWidget(news.configuration);
        registerWidget(newsList.configuration);
        registerWidget(focus.configuration);
        registerWidget(pirBox.configuration);
        bootstrap();
    });
});

