define('../bower_components/azimut-utility/src/directives/compileDirective',["require", "exports"], function (require, exports) {
    var CompileDirective = (function () {
        function CompileDirective($compile) {
            this.restrict = 'A';
            var _this = this;
            this.link = function (scope, element, attrs) {
                scope.$watch(function (scope) {
                    return scope.$eval(attrs.compile);
                }, function (value) {
                    element.html(value);
                    $compile(element.contents())(scope);
                });
            };
        }
        CompileDirective.Factory = function () {
            var directive = function ($compile) {
                return new CompileDirective($compile);
            };
            directive['$inject'] = ['EventService', '$translatePartialLoader'];
            return directive;
        };
        return CompileDirective;
    })();
    exports.CompileDirective = CompileDirective;
});

