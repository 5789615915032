define('layout-base/../../bower_components/dwx-utility/src/services/paginationService',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var PaginationService = (function () {
            function PaginationService($rootScope, $location, loginService, EventService, initConfigService) {
                return function (items, itemsPerPage, currentPage, numberOfKeys) {
                    var self, updateItemsListWithoutCurrentPageReset;
                    self = this;
                    updateItemsListWithoutCurrentPageReset = function (items) {
                        if (typeof items !== 'undefined') {
                            self.size = items.length;
                        }
                        else {
                            self.size = 0;
                        }
                        if (self.size > 0) {
                            self.pages = Math.ceil(self.size / self.itemsPerPage);
                        }
                        else {
                            self.pages = 0;
                        }
                        self.pagesArray = new Array(self.pages);
                    };
                    self.updateItems = function (items) {
                        updateItemsListWithoutCurrentPageReset(items);
                        self.changePage(1);
                    };
                    self.changePage = function (newPage) {
                        self.currentPage = newPage;
                        if (self.currentPage < self.pages) {
                            self.itemsInPage = self.itemsPerPage;
                        }
                        else if (self.currentPage === self.pages) {
                            self.itemsInPage = self.size % self.itemsPerPage || self.itemsPerPage;
                        }
                        else {
                            self.itemsInPage = 0;
                        }
                        self.endIndex = self.currentPage * self.itemsPerPage;
                        self.startIndex = self.endIndex - self.itemsPerPage;
                    };
                    (function (items, itemsPerPage, currentPage, numberOfKeys) {
                        self.itemsPerPage = itemsPerPage;
                        if (numberOfKeys != undefined) {
                            self.numberOfKeys = numberOfKeys;
                        }
                        else {
                            self.numberOfKeys = 7;
                        }
                        updateItemsListWithoutCurrentPageReset(items);
                        self.changePage(currentPage || 1);
                    })(items, itemsPerPage, currentPage, numberOfKeys);
                };
            }
            return PaginationService;
        })();
        Utility.PaginationService = PaginationService;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

