define('layout-base/../../bower_components/dwx-layout-base/src/directives/navigationDirective',["require", "exports"], function (require, exports) {
    var Layout;
    (function (Layout) {
        var LayoutNavigationDirective = (function () {
            function LayoutNavigationDirective() {
                this.restrict = 'A';
                this.templateUrl = 'bower_components/dwx-layout/dist/src/views/navigation-sidebar.html';
                this.controller = "NavigationCtrl as NavigationCtrl";
                var _this = this;
            }
            LayoutNavigationDirective.Factory = function () {
                var directive = function () {
                    return new LayoutNavigationDirective();
                };
                return directive;
            };
            return LayoutNavigationDirective;
        })();
        Layout.LayoutNavigationDirective = LayoutNavigationDirective;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

