define('widget-settings-customer/../../bower_components/dwx-widget-settings-customer/src/services/settingsService',["require", "exports"], function (require, exports) {
    var SettingsService = (function () {
        function SettingsService($resource) {
            return {
                settings: $resource("" + apiPrefix + "/settings/:widgetId", {
                    widgetId: '@widgetId'
                }),
                formData: $resource("" + apiPrefix + "/formdata/settings", {}),
                updatePassword: $resource("" + apiPrefix + "/accountManagement/updateUserPassword", {}, {
                    update: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                getStatus: $resource(apiPrefix + '/firstaccess/status/:processName', { processName: '@processName' }),
                startProcess: $resource(apiPrefix + '/firstaccess/start/process/:processName', { processName: '@processName' }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                submit: $resource(apiPrefix + '/firstaccess/submit/:processName/:stepCode/:stepId', {
                    processName: '@processName',
                    stepCode: '@stepCode',
                    stepId: '@stepId'
                }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                complete: $resource(apiPrefix + '/firstaccess/complete/:processName/:stepCode/:stepId', {
                    processName: '@processName',
                    stepCode: '@stepCode',
                    stepId: '@stepId'
                }, {
                    save: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                abort: $resource(apiPrefix + '/firstaccess/cancel/:processName', {
                    processName: '@processName',
                }, {
                    save: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                updateSettings: $resource(apiPrefix + "/settings/update", {}, {
                    save: {
                        method: 'POST'
                    }
                })
            };
        }
        return SettingsService;
    })();
    exports.SettingsService = SettingsService;
});

