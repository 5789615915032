define('widget-pir-box/../../project_components/dwx-widget-pir-box/src/directives/pirBoxDirective',["require", "exports"], function (require, exports) {
    var PirBoxDirective = (function () {
        function PirBoxDirective() {
            this.scope = { identifier: '=' };
            this.replace = true;
            this.controller = 'pirBoxCtrl as pirBox';
            this.templateUrl = 'bower_components/dwx-widget-pir-box/dist/src/views/partials/main.html';
        }
        PirBoxDirective.Factory = function () {
            var directive = function () {
                return new PirBoxDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return PirBoxDirective;
    })();
    exports.PirBoxDirective = PirBoxDirective;
});

