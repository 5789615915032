define('widget-user-data/widget-user-data',["require", "exports", './config', '../../bower_components/dwx-widget-user-data/src/app', '../../bower_components/dwx-widget-user-data/src/controllers/userDataCtrl'], function (require, exports, config, WidgetUserData, WidgetUserDataController) {
    var newList = WidgetUserData.configuration.list;
    newList['userDataConfig'] = { callback: ['$translateProvider', config.UserdataConfig], type: "config" };
    newList['UserDataCtrl'] = {
        callback: ["$scope", "$rootScope", "$attrs", "userDataService", "$timeout", "$http", "$element", "initConfigService", "errorsHandlerService", "$translatePartialLoader", "profileService", 'EventService', WidgetUserDataController.UserDataCtrl],
        type: 'controller'
    };
    WidgetUserData.configuration.list = newList;
    exports.configuration = WidgetUserData.configuration;
});

