define('../bower_components/dwx-widget-first-access/src/app',["require", "exports", './directives/loginAzimutDirective', './controllers/firstAccessCtrl', './services/firstAccessService'], function (require, exports, loginAzimutDirective, firstAccessController, fAService) {
    exports.configuration = {
        list: {
            "dwxWidgetFirstAccessCtrl": { callback: ['$scope', 'initConfigService', 'dwxWidgetFirstAccessService', 'profileService', '$cookieStore', 'EventService', '$translatePartialLoader', '$translate', firstAccessController.FirstAccessCtrl], type: "controller" },
            "dwxWidgetLoginAzimut": { callback: [loginAzimutDirective.LoginAzimutDirective.Factory()], type: "directive" },
            "dwxWidgetFirstAccessService": { callback: ['$resource', fAService.FirstAccessService], type: "service" },
        },
        ngModules: ['ngAnimate']
    };
});

