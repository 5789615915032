define('../bower_components/dwx-widget-first-access/src/directives/loginAzimutDirective',["require", "exports"], function (require, exports) {
    exports.basePath = 'widgets/first-access/';
    var LoginAzimutDirective = (function () {
        function LoginAzimutDirective() {
            this.replace = false;
            this.restrict = 'A';
            this.scope = {
                identifier: '=',
            };
            this.templateUrl = exports.basePath + 'main.html';
        }
        LoginAzimutDirective.Factory = function () {
            var directive = function () {
                return new LoginAzimutDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return LoginAzimutDirective;
    })();
    exports.LoginAzimutDirective = LoginAzimutDirective;
});

