define('../bower_components/dwx-widget-html-content/src/controllers/htmlContentCtrl',["require", "exports"], function (require, exports) {
    var Controllers;
    (function (Controllers) {
        var HtmlContentController = (function () {
            function HtmlContentController($scope, eventService, htmlContentService, initConfigService, $window) {
                this.$scope = $scope;
                this.initConfigService = initConfigService;
                this.$window = $window;
                var _this = this;
                this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetHtmlContent';
                _this.widgetConfig = _this.initConfigService[_this.widgetId];
                if (_this.widgetConfig.status.mode !== 'locked') {
                    _this.baseUrl = baseUrl;
                    htmlContentService.get({
                        widgetId: _this.widgetConfig.id
                    }, function (response) {
                        if (response.outcome === 'OK') {
                            _this.content = response.content;
                            _this.media = _this.content[0];
                            _this.widgetConfig.status.mode = 'on';
                        }
                        else {
                            _this.widgetConfig.status.mode = 'error';
                            _this.widgetConfig.status.message = "dashboard.error." + response.messageKey;
                            eventService.publish('http-response-error', [response.messageKey]);
                        }
                    }, function (error) {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = "dashboard.error.connection_error";
                        eventService.publish('http-request-error', [error]);
                    });
                }
            }
            HtmlContentController.prototype.isTouch = function () {
                return (Modernizr.touch && (typeof cordova != 'undefined'));
            };
            HtmlContentController.prototype.openLinkValue = function (url) {
                if (!this.isTouch()) {
                    this.$window.open(url, "_blank");
                }
                else {
                    showPdf(url);
                }
            };
            return HtmlContentController;
        })();
        Controllers.HtmlContentController = HtmlContentController;
    })(Controllers = exports.Controllers || (exports.Controllers = {}));
});

