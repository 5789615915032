define('../bower_components/dwx-widget-html-content/src/app',["require", "exports", './controllers/htmlContentCtrl', './directives/htmlContentDirective', './services/htmlContentService', "angular-animate"], function (require, exports, controller, directive, service) {
    exports.configuration = {
        list: {
            'HtmlContentCtrl': { callback: ["$scope", "EventService", "htmlContentService", "initConfigService", "$window", controller.Controllers.HtmlContentController], type: 'controller' },
            'dwxWidgetHtmlContent': { callback: [directive.Directives.WidgetHtmlContentDirective], type: 'directive' },
            'dwxHtmlContentSimple': { callback: ['EventService', directive.Directives.HtmlContentSimpleDirective], type: 'directive' },
            'dwxHtmlContentCarousel': { callback: [directive.Directives.HtmlContentCarouselDirective], type: 'directive' },
            'dwxHtmlContentSmartCarousel': { callback: [directive.Directives.HtmlContentSmartCarouselDirective], type: 'directive' },
            'dwxHtmlContentSlideshow': { callback: ['$timeout', directive.Directives.HtmlContentSlideshowDirective], type: 'directive' },
            'owlCarousel': { callback: [directive.Directives.owlCarouselDirective], type: 'directive' },
            'htmlContentService': { callback: ["$resource", service.Services.HtmlContentService], type: 'service' }
        },
        ngModules: ['ngResource', 'pascalprecht.translate']
    };
});

