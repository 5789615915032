define('layout-base/../../bower_components/dwx-utility/src/directives/domDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var DomDirective = (function () {
            function DomDirective($timeout) {
                this.$timeout = $timeout;
                this.restrict = "A";
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(element);
                };
            }
            DomDirective.prototype.myLink = function (element) {
                var _this = this;
                _this.$timeout(function () {
                    $(element[0]).focus();
                });
            };
            DomDirective.Factory = function () {
                var directive = function ($timeout) {
                    return new DomDirective($timeout);
                };
                directive['$inject'] = ['$timeout'];
                return directive;
            };
            return DomDirective;
        })();
        Utility.DomDirective = DomDirective;
        var PreventDefault = (function () {
            function PreventDefault() {
                this.restrict = "E";
                var _this = this;
                this.link = function (scope, element, attrs) {
                    _this.myLink(scope, element, attrs);
                };
            }
            PreventDefault.prototype.myLink = function (scope, element, attrs) {
                if (attrs.ngClick || attrs.href === '' || attrs.href === '#') {
                    element.on('click', function (e) {
                        e.preventDefault();
                    });
                }
            };
            PreventDefault.Factory = function () {
                var directive = function () {
                    return new PreventDefault();
                };
                directive['$inject'] = [];
                return directive;
            };
            return PreventDefault;
        })();
        Utility.PreventDefault = PreventDefault;
        var FormatOnBlurDirective = (function () {
            function FormatOnBlurDirective($filter) {
                this.restrict = "A";
                this.scope = { dwxFormatParam: '=', error: '=', errorMessage: '=', errorId: '=?', minValue: '=' };
                this.require = '?ngModel';
                var _this = this;
                this.link = function (scope, elem, attrs, ctrl) {
                    if (!ctrl)
                        return;
                    ctrl.$formatters.unshift(function (modelValue) {
                        return $filter(attrs.dwxFormat)(modelValue, scope.dwxFormatParam);
                    });
                    if (scope.validate) {
                        ctrl.$validators.push(function (viewValue) {
                            return "";
                        });
                    }
                    ctrl.$parsers.push(function (viewValue) {
                        if (!viewValue) {
                            ctrl.$setValidity('required', true);
                            return "";
                        }
                        else {
                            ctrl.$setValidity('required', false);
                        }
                        if (language == 'en-gb') {
                            var plainNumber = viewValue.replace(/[^\d|\-+|\.+]/g, '');
                            if (isNaN(plainNumber) || Number(viewValue) < scope.minValue) {
                                scope.error = true;
                                scope.errorId = attrs.id;
                                scope.errorMessage = 'dashboard.widget.simpleTrade.invalid.number';
                                ctrl.$setValidity('min', false);
                                return "";
                            }
                            else {
                                ctrl.$setValidity('min', true);
                                scope.error = false;
                                if (plainNumber.indexOf('.') > -1) {
                                    plainNumber = parseFloat(plainNumber).toFixed(scope.dwxFormatParam);
                                }
                                return plainNumber;
                            }
                        }
                        else {
                            var plainNumber = viewValue.replace(/[^\d|\-+|\,+]/g, '');
                            if ((plainNumber.split(",").length - 1) > 1 || Number(viewValue) < scope.minValue) {
                                scope.error = true;
                                scope.errorId = attrs.id;
                                scope.errorMessage = 'dashboard.widget.simpleTrade.invalid.number';
                                ctrl.$setValidity('min', false);
                                return "";
                            }
                            else {
                                ctrl.$setValidity('min', true);
                                scope.error = false;
                                if (plainNumber.indexOf(',') > -1) {
                                    var tmp = plainNumber.replace(',', '.');
                                    plainNumber = parseFloat(tmp).toFixed(scope.dwxFormatParam);
                                }
                                return plainNumber;
                            }
                        }
                    });
                    elem.on('focus', function (e) {
                        if (language == 'en-gb') {
                            var plainNumber = e.currentTarget.value.replace(/[^\d|\-+|\.+]/g, '');
                            ctrl.$setViewValue(plainNumber);
                            ctrl.$render();
                            return plainNumber;
                        }
                        else {
                            var plainNumber = e.currentTarget.value.replace(/[^\d|\-+|\,+]/g, '');
                            ctrl.$setViewValue(plainNumber);
                            ctrl.$render();
                            return plainNumber;
                        }
                    });
                    _this.myLink(scope, elem, attrs, $filter);
                };
            }
            FormatOnBlurDirective.prototype.myLink = function (scope, elem, attrs, $filter) {
                var _this = this;
                elem.bind('keydown', function (e) {
                    if (language == 'en-gb') {
                        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 || (e.keyCode == 65 && e.ctrlKey === true) || (e.keyCode == 67 && e.ctrlKey === true) || (e.keyCode == 88 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 39)) {
                            return;
                        }
                        if (String.fromCharCode((96 <= e.keyCode && e.keyCode <= 105) ? e.keyCode - 48 : e.keyCode).match(/[\d|\.+]/g)) {
                            return;
                        }
                        else {
                            e.preventDefault();
                        }
                    }
                    else {
                        if ($.inArray(e.keyCode, [46, 8, 9, 27, 13, 188]) !== -1 || (e.keyCode == 65 && e.ctrlKey === true) || (e.keyCode == 67 && e.ctrlKey === true) || (e.keyCode == 88 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 39)) {
                            return;
                        }
                        console.log("codice inserito: " + e.keyCode + " carattere corrispondente " + String.fromCharCode((96 <= e.keyCode && e.keyCode <= 105) ? e.keyCode - 48 : e.keyCode));
                        if (String.fromCharCode((96 <= e.keyCode && e.keyCode <= 105) ? e.keyCode - 48 : e.keyCode).match(/[\d|\,+]/g)) {
                            return;
                        }
                        else {
                            e.preventDefault();
                        }
                    }
                });
                scope.$watch('dwxFormatParam', function () {
                    elem.bind('blur', function () {
                        var oldVal = elem.val();
                        var plainNumber;
                        if (language == 'en-gb') {
                            plainNumber = oldVal.replace(/[^\d|\-+|\.+]/g, '');
                            plainNumber = parseFloat(plainNumber).toFixed(scope.dwxFormatParam);
                        }
                        else {
                            plainNumber = oldVal.replace(/[^\d|\-+|\,+]/g, '');
                            var tmp = plainNumber.replace(',', '.');
                            plainNumber = parseFloat(tmp).toFixed(scope.dwxFormatParam);
                        }
                        console.log(language);
                        var newVal = $filter(attrs.dwxFormat)(plainNumber, scope.dwxFormatParam ? scope.dwxFormatParam : '');
                        elem.val(newVal);
                    });
                });
            };
            FormatOnBlurDirective.Factory = function () {
                var directive = function ($filter) {
                    return new FormatOnBlurDirective($filter);
                };
                directive['$inject'] = ['$filter'];
                return directive;
            };
            return FormatOnBlurDirective;
        })();
        Utility.FormatOnBlurDirective = FormatOnBlurDirective;
        var FormatXml = (function () {
            function FormatXml() {
                this.restrict = "A";
                this.require = '?ngModel';
                this.scope = { formatXml: '=' };
                var _this = this;
                this.link = function (scope, elem, attrs, ctrl) {
                    if (!ctrl)
                        return;
                    ctrl.$formatters.unshift(function (modelValue) {
                        return vkBeautify.xml(modelValue, scope.formatXml ? scope.formatXml : null);
                    });
                };
            }
            FormatXml.Factory = function () {
                var directive = function () {
                    return new FormatXml();
                };
                directive['$inject'] = [];
                return directive;
            };
            return FormatXml;
        })();
        Utility.FormatXml = FormatXml;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

