define('../bower_components/dwx-widget-dossier-selector/src/controllers/dossierSelectorController',["require", "exports"], function (require, exports) {
    var DossierSelectorController = (function () {
        function DossierSelectorController($scope, portfolioCoreService, EventService, initConfigService, $filter) {
            this.portfolioCoreService = portfolioCoreService;
            this.EventService = EventService;
            this.$filter = $filter;
            var _this = this;
            this.widgetId = $scope.identifier || (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetDossierSelector';
            this.widgetConfig = initConfigService[this.widgetId];
            this.counterNestedCheck = {};
            console.log(sessionStorage);
            if (this.widgetConfig.status.mode !== 'locked') {
                var handleRefreshPortfolioWithDossier = EventService.subscribe('dossierSelected', function () {
                    _this.initDossier();
                });
                _this.initDossier();
            }
            _this.EventService.subscribe('modalClosed', function () {
                angular.forEach(_this.dossierToggle, function (value, key) {
                    _this.dossierToggle[key] = false;
                });
            });
            var handleDossierSelectedReponseError = _this.EventService.subscribe('dossierSelectedResponseError', function (message) {
                _this.widgetConfig.status.mode = 'error';
                _this.widgetConfig.status.message = message;
            });
            var handleDossierSelectedHttpReponseError = _this.EventService.subscribe('dossierSelectedResponseError', function (message) {
                _this.widgetConfig.status.mode = 'error';
                _this.widgetConfig.status.message = "dashboard.error.connection_error";
            });
            $scope.$on("$destroy", function () {
                _this.EventService.unsubscribe(handleRefreshPortfolioWithDossier, false);
                _this.EventService.unsubscribe(handleDossierSelectedReponseError, false);
                _this.EventService.unsubscribe(handleDossierSelectedHttpReponseError, false);
            });
        }
        DossierSelectorController.prototype.initDossier = function () {
            var _this = this;
            if (_this.portfolioCoreService['dossierList'] !== void 0) {
                _this.dossierList = _this.portfolioCoreService['dossierList'];
                angular.forEach(_this.dossierList, function (value, key) {
                    if (!value.checked) {
                        var checkedContracts = _this.$filter('filter')(value.contractElements, { checked: true });
                        _this.counterNestedCheck[key] = checkedContracts.length;
                    }
                    else {
                        if (_this.counterNestedCheck != undefined) {
                            _this.counterNestedCheck[key] = value.contractElements.length;
                        }
                        else {
                            _this.counterNestedCheck = {};
                            _this.counterNestedCheck[key] = value.contractElements.length;
                        }
                    }
                });
                _this.widgetConfig.status.mode = 'on';
            }
        };
        DossierSelectorController.prototype.checkAllContracts = function (dossierIndex) {
            var _this = this;
            var check;
            if (_this.dossierList[dossierIndex].checked) {
                _this.counterNestedCheck[dossierIndex] = _this.dossierList[dossierIndex].contractElements.length;
                check = true;
            }
            else {
                _this.counterNestedCheck[dossierIndex] = 0;
                check = false;
            }
            angular.forEach(_this.dossierList[dossierIndex].contractElements, function (value, key) {
                value.checked = check;
            });
        };
        DossierSelectorController.prototype.showChecked = function (contractIndex, dossierIndex) {
            var _this = this;
            if (_this.dossierList[dossierIndex].contractElements[contractIndex].checked) {
                _this.counterNestedCheck[dossierIndex]++;
                _this.dossierList[dossierIndex].checked = true;
            }
            else {
                _this.counterNestedCheck[dossierIndex]--;
                if (_this.counterNestedCheck[dossierIndex] == 0) {
                    _this.dossierList[dossierIndex].checked = false;
                }
            }
        };
        DossierSelectorController.prototype.refreshPortfolio = function () {
            var _this = this;
            _this.portfolioCoreService['dossierList'] = _this.dossierList;
            _this.EventService.publish("refreshPortfolioWithDossier", [], false);
        };
        DossierSelectorController.prototype.getDossierNumber = function (dossier) {
            var result = dossier.ndg;
            if (!!dossier.serviceTypeDescription && !!dossier.serviceContractNumber) {
                result += " - " + dossier.serviceTypeDescription + " - " + dossier.serviceContractNumber;
            }
            return result;
        };
        return DossierSelectorController;
    })();
    exports.DossierSelectorController = DossierSelectorController;
});

