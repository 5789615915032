define('layout-base/../../bower_components/dwx-layout-base/src/directives/bannerContainerDirective',["require", "exports"], function (require, exports) {
    var BannerContainerDirective = (function () {
        function BannerContainerDirective() {
            this.replace = true;
            this.templateUrl = 'views/components/banner-container.html';
        }
        BannerContainerDirective.Factory = function () {
            var directive = function () {
                return new BannerContainerDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return BannerContainerDirective;
    })();
    exports.BannerContainerDirective = BannerContainerDirective;
});

