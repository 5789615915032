define('widget-user-data/../../bower_components/dwx-widget-user-data/src/directives/userDataDirective',["require", "exports"], function (require, exports) {
    var UserDataDirective = (function () {
        function UserDataDirective() {
            this.scope = {
                identifier: '='
            };
            this.replace = true;
            this.templateUrl = 'bower_components/dwx-widget-user-data/dist/src/views/partials/main.html';
            this.controller = 'UserDataCtrl as userCtrl';
        }
        UserDataDirective.Factory = function () {
            var directive = function () {
                return new UserDataDirective();
            };
            return directive;
        };
        return UserDataDirective;
    })();
    exports.UserDataDirective = UserDataDirective;
});

