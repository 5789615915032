define('layout-base/../../bower_components/dwx-layout-base/src/directives/bannerTimeoutDirective',["require", "exports"], function (require, exports) {
    var BannerTimeoutDirective = (function () {
        function BannerTimeoutDirective() {
            this.scope = {
                bannerTimeout: '='
            };
            this.link = function (scope, el) {
                if (!!scope['bannerTimeout']) {
                    setTimeout(function () {
                        return el.remove();
                    }, scope['bannerTimeout'] - new Date().getTime());
                }
            };
        }
        BannerTimeoutDirective.Factory = function () {
            var directive = function () {
                return new BannerTimeoutDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return BannerTimeoutDirective;
    })();
    exports.BannerTimeoutDirective = BannerTimeoutDirective;
});

