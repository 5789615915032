define('widget-advisor/../../project_components/dwx-widget-advisor/src/directives/advisorDirective',["require", "exports"], function (require, exports) {
    var AdvisorHeaderDirective = (function () {
        function AdvisorHeaderDirective($window, $timeout, EventService) {
            this.restrict = 'A';
            var _this = this;
            this.link = function ($scope) {
                var advisorSidebar, closeMe, handleScroll, oldScrollPos;
                var ctrl = this;
                ctrl.handleCloseOtherSidebars = EventService.subscribe('close-other-sidebars', function (me) {
                    if (me !== 'advisor') {
                        return closeMe();
                    }
                });
                ctrl.handleCloseSidebars = EventService.subscribe('close-sidebars', function () {
                    return closeMe();
                });
                advisorSidebar = $("#advisor-sidebar");
                closeMe = function () {
                    advisorSidebar.removeClass("open");
                    $(".main").removeClass("sidebar-padding-right");
                    return advisorSidebar.css('height', '');
                };
                $('.advisor-sidebar-toggle').click(function (e) {
                    if (advisorSidebar.hasClass("open")) {
                        EventService.publish('close-sidebars', [], false);
                        advisorSidebar.removeClass("open");
                        $(".main").removeClass("sidebar-padding-right");
                    }
                    else {
                        EventService.publish('close-other-sidebars', ['advisor'], false);
                        EventService.publish('open-sidebar', [], false);
                        advisorSidebar.addClass("open");
                        $(".main").addClass("sidebar-padding-right");
                    }
                    handleScroll();
                    $timeout(function () {
                        return $($window).trigger('resize');
                    }, 500);
                });
                oldScrollPos = 0;
                handleScroll = function () {
                    var countdown, menuPosition;
                    if (advisorSidebar.hasClass("open")) {
                        if (oldScrollPos !== $($window).scrollTop()) {
                            oldScrollPos = $($window).scrollTop();
                            menuPosition = $(".dwx-navbar").outerHeight();
                            if ($($window).scrollTop() >= menuPosition) {
                                advisorSidebar.css("position", "fixed");
                                advisorSidebar.css("top", "0");
                            }
                            else {
                                advisorSidebar.css("position", "absolute");
                                advisorSidebar.css("top", "");
                            }
                        }
                        return countdown = $timeout(function () {
                            return handleScroll();
                        }, 100);
                    }
                };
                $scope.$on("$destroy", function () {
                    var _this = this;
                    return EventService.unsubscribe(_this.handleCloseOtherSidebars, false);
                });
                $scope.$on("$destroy", function () {
                    var _this = this;
                    return EventService.unsubscribe(_this.handleCloseSidebars, false);
                });
            };
        }
        AdvisorHeaderDirective.Factory = function () {
            var directive = function ($window, $timeout, EventService) {
                return new AdvisorHeaderDirective($window, $timeout, EventService);
            };
            directive['$inject'] = ['$window', '$timeout', 'EventService'];
            return directive;
        };
        return AdvisorHeaderDirective;
    })();
    exports.AdvisorHeaderDirective = AdvisorHeaderDirective;
    var AdvisorDirective = (function () {
        function AdvisorDirective() {
            this.scope = { identifier: '=' };
            this.replace = true;
            this.controller = 'advisorCtrl as advisor';
            this.templateUrl = 'bower_components/dwx-widget-advisor/dist/src/views/partials/main.html';
            var _this = this;
        }
        AdvisorDirective.Factory = function () {
            var directive = function () {
                return new AdvisorDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return AdvisorDirective;
    })();
    exports.AdvisorDirective = AdvisorDirective;
});

