define('layout-base/../../bower_components/dwx-utility/src/directives/customTableDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var CustomTableSmallTogglerDirective = (function () {
            function CustomTableSmallTogglerDirective($compile) {
                this.$compile = $compile;
                this.restrict = "A";
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(scope, element, _this.$compile);
                };
            }
            CustomTableSmallTogglerDirective.prototype.myLink = function (scope, element, $compile) {
                $(element).click(function (event) {
                    var row, rowClone, tableWidth;
                    row = $(element).parent(".ct-row");
                    if (row.hasClass("ct-row-small-expanded")) {
                        $(window).unbind("resize");
                        row.removeClass("ct-row-small-expanded");
                        row.css("width", "auto").css("margin-right", "auto");
                        row.next().remove();
                    }
                    else {
                        $(window).resize(function () {
                            row.removeClass("ct-row-small-expanded");
                            row.css("width", "auto").css("margin-right", "auto");
                            return row.next(".ct-small-expanded-clone").remove();
                        });
                        row.addClass("ct-row-small-expanded");
                        tableWidth = row.parent(".custom-table").width();
                        row.css("width", tableWidth).css("margin-right", -tableWidth);
                        rowClone = row.clone();
                        rowClone.addClass("ct-small-expanded-clone");
                        row.next(".ct-actions").css("width", tableWidth).css("margin-right", -tableWidth);
                        row.after(rowClone);
                        $("a.button.expand", rowClone).each(function () {
                            var content = $(this);
                            angular.element(document).injector().invoke(function ($compile) {
                                var scope = angular.element(content).scope();
                                $compile(content)(scope);
                            });
                        });
                    }
                });
            };
            CustomTableSmallTogglerDirective.Factory = function () {
                var directive = function ($compile) {
                    return new CustomTableSmallTogglerDirective($compile);
                };
                directive['$inject'] = ['$compile'];
                return directive;
            };
            return CustomTableSmallTogglerDirective;
        })();
        Utility.CustomTableSmallTogglerDirective = CustomTableSmallTogglerDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

