define('widget-user-data-advanced/../../bower_components/dwx-widget-user-data-advanced/src/directives/userDataAdvancedDirective',["require", "exports"], function (require, exports) {
    var UserDataAdvancedDirective = (function () {
        function UserDataAdvancedDirective() {
            this.scope = {
                identifier: '='
            };
            this.replace = true;
            this.templateUrl = 'bower_components/dwx-widget-user-data/dist/src/views/partials/main.html';
            this.controller = 'UserDataCtrl as userCtrl';
        }
        UserDataAdvancedDirective.Factory = function () {
            var directive = function () {
                return new UserDataAdvancedDirective();
            };
            return directive;
        };
        return UserDataAdvancedDirective;
    })();
    exports.UserDataAdvancedDirective = UserDataAdvancedDirective;
});

