define('widget-settings-customer/../../bower_components/dwx-widget-settings-customer/src/directives/settingsDirective',["require", "exports"], function (require, exports) {
    var WidgetSettings;
    (function (WidgetSettings) {
        var SettingsDirective = (function () {
            function SettingsDirective() {
                this.scope = {
                    identifier: '='
                };
                this.replace = true;
                this.templateUrl = 'bower_components/dwx-widget-settings-customer/dist/src/views/partials/main.html';
                this.controller = 'SettingsCtrl as settingsCtrl';
            }
            SettingsDirective.Factory = function () {
                var directive = function () {
                    return new SettingsDirective();
                };
                return directive;
            };
            return SettingsDirective;
        })();
        WidgetSettings.SettingsDirective = SettingsDirective;
        var PinChangePopupDirective = (function () {
            function PinChangePopupDirective(events) {
                var _this = this;
                this.events = events;
                this.priority = 0;
                this.replace = true;
                this.templateUrl = 'widgets/settings-customer/pin-change-modal.html';
                this.link = function (scope) {
                    scope.pinCheck = function () {
                        _this.events.publish("runPinChecked");
                    };
                };
            }
            PinChangePopupDirective.Factory = function () {
                var directive = function (events) {
                    return new PinChangePopupDirective(events);
                };
                directive['$inject'] = ['EventService'];
                return directive;
            };
            return PinChangePopupDirective;
        })();
        WidgetSettings.PinChangePopupDirective = PinChangePopupDirective;
    })(WidgetSettings = exports.WidgetSettings || (exports.WidgetSettings = {}));
});

