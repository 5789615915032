define('layout-base/../../bower_components/dwx-layout-base/src/directives/foundationDropdown',["require", "exports", "jquery"], function (require, exports) {
    var Layout;
    (function (Layout) {
        var DropDownContentDirective = (function () {
            function DropDownContentDirective($compile) {
                return {
                    restrict: "A",
                    link: function (scope, element, attrs) {
                        if (!$(document).foundation('dropdown')) {
                            $(document).foundation('dropdown');
                        }
                        $(element).on('opened.fndtn.dropdown', '', function (e) {
                            var html = $(element).prop('outerHTML');
                            var e = $compile(html)(scope);
                            element.replaceWith(e);
                        });
                        $(element).click('a', function () {
                            $(document).foundation('dropdown', 'close', $(element));
                        });
                    }
                };
            }
            return DropDownContentDirective;
        })();
        Layout.DropDownContentDirective = DropDownContentDirective;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

