define('widget-news/../../project_components/dwx-widget-news/src/directives/newsDirective',["require", "exports"], function (require, exports) {
    var NewsDirective = (function () {
        function NewsDirective() {
            this.scope = { identifier: '=' };
            this.replace = true;
            this.controller = 'newsCtrl as news';
            this.templateUrl = 'bower_components/dwx-widget-news/dist/src/views/partials/main.html';
            var _this = this;
        }
        NewsDirective.Factory = function () {
            var directive = function () {
                return new NewsDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return NewsDirective;
    })();
    exports.NewsDirective = NewsDirective;
});

