define('layout-base/../../bower_components/dwx-utility/src/filters/currencyToSymbolFilter',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var CurrencyToSymbolFilter = (function () {
            function CurrencyToSymbolFilter($sce) {
                this.$sce = $sce;
            }
            CurrencyToSymbolFilter.Factory = function ($sce) {
                var instance = new CurrencyToSymbolFilter($sce);
                instance.myFilter = instance.myFilter.bind(instance);
                return instance.myFilter;
            };
            CurrencyToSymbolFilter.prototype.myFilter = function (input, arg) {
                var currencies;
                currencies = {
                    AFN: {
                        html: '&#1547;',
                        utf8: '؋'
                    },
                    ALL: {
                        html: '&#76;&#101;&#107;',
                        utf8: 'Lek'
                    },
                    ANG: {
                        html: '&#402;',
                        utf8: 'ƒ'
                    },
                    ARS: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    AUD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    AWG: {
                        html: '&#402;',
                        utf8: 'ƒ'
                    },
                    AZN: {
                        html: '&#1084;&#1072;&#1085;',
                        utf8: 'ман'
                    },
                    BAM: {
                        html: '&#75;&#77;',
                        utf8: 'KM'
                    },
                    BBD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    BGN: {
                        html: '&#1083;&#1074;',
                        utf8: 'лв'
                    },
                    BMD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    BND: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    BOB: {
                        html: '&#36;&#98;',
                        utf8: '$'
                    },
                    BRL: {
                        html: '&#82;&#36;',
                        utf8: 'R'
                    },
                    BSD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    BWP: {
                        html: '&#80;',
                        utf8: 'P'
                    },
                    BYR: {
                        html: '&#112;&#46;',
                        utf8: 'p'
                    },
                    BZD: {
                        html: '&#66;&#90;&#36;',
                        utf8: 'B$'
                    },
                    CAD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    CHF: {
                        html: '&#67;&#72;&#70;',
                        utf8: 'CHF'
                    },
                    CLP: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    CNY: {
                        html: '&#20803;',
                        utf8: '¥'
                    },
                    COP: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    CRC: {
                        html: '&#8353;',
                        utf8: '₡'
                    },
                    CUP: {
                        html: '&#8369;',
                        utf8: '₱'
                    },
                    CZK: {
                        html: '&#75;&#269;',
                        utf8: 'Kč'
                    },
                    DKK: {
                        html: '&#107;&#114;',
                        utf8: 'kr'
                    },
                    DOP: {
                        html: '&#82;&#68;&#36;',
                        utf8: 'R$'
                    },
                    EEK: {
                        html: '&#107;&#114;',
                        utf8: 'kr'
                    },
                    EGP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    EUR: {
                        html: '&#8364;',
                        utf8: '€'
                    },
                    FJD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    FKP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    GBP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    GGP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    GHC: {
                        html: '&#162;',
                        utf8: '¢'
                    },
                    GIP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    GTQ: {
                        html: '&#81;',
                        utf8: 'Q'
                    },
                    GYD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    HKD: {
                        html: '&#72;&#75;&#36;',
                        utf8: '$'
                    },
                    HNL: {
                        html: '&#76;',
                        utf8: 'L'
                    },
                    HRK: {
                        html: '&#107;&#110;',
                        utf8: 'kn'
                    },
                    HUF: {
                        html: '&#70;&#116;',
                        utf8: 'Ft'
                    },
                    IDR: {
                        html: '&#82;&#112;',
                        utf8: 'Rp'
                    },
                    ILS: {
                        html: '&#8362;',
                        utf8: '₪'
                    },
                    IMP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    INR: {
                        html: '&#8377;',
                        utf8: '₹'
                    },
                    IRR: {
                        html: '&#65020;',
                        utf8: '﷼'
                    },
                    ISK: {
                        html: '&#107;&#114;',
                        utf8: 'kr'
                    },
                    JEP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    JMD: {
                        html: '&#74;&#36;',
                        utf8: 'J'
                    },
                    JPY: {
                        html: '&#165;',
                        utf8: '¥'
                    },
                    KGS: {
                        html: '&#1083;&#1074;',
                        utf8: 'лв'
                    },
                    KHR: {
                        html: '&#6107;',
                        utf8: '៛'
                    },
                    KPW: {
                        html: '&#8361;',
                        utf8: '₩'
                    },
                    KRW: {
                        html: '&#8361;',
                        utf8: '₩'
                    },
                    KYD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    KZT: {
                        html: '&#1083;&#1074;',
                        utf8: 'лв'
                    },
                    LAK: {
                        html: '&#8365;',
                        utf8: '₭'
                    },
                    LBP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    LKR: {
                        html: '&#8360;',
                        utf8: '₨'
                    },
                    LRD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    LTL: {
                        html: '&#76;&#116;',
                        utf8: 'Lt'
                    },
                    LVL: {
                        html: '&#76;&#115;',
                        utf8: 'Ls'
                    },
                    MKD: {
                        html: '&#1076;&#1077;&#1085;',
                        utf8: 'ден'
                    },
                    MNT: {
                        html: '&#8366;',
                        utf8: '₮'
                    },
                    MUR: {
                        html: '&#8360;',
                        utf8: '₨'
                    },
                    MXN: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    MYR: {
                        html: '&#82;&#77;',
                        utf8: 'RM'
                    },
                    MZN: {
                        html: '&#77;&#84;',
                        utf8: 'MT'
                    },
                    NAD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    NGN: {
                        html: '&#8358;',
                        utf8: '₦'
                    },
                    NIO: {
                        html: '&#67;&#36;',
                        utf8: 'C'
                    },
                    NOK: {
                        html: '&#107;&#114;',
                        utf8: 'kr'
                    },
                    NPR: {
                        html: '&#8360;',
                        utf8: '₨'
                    },
                    NZD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    OMR: {
                        html: '&#65020;',
                        utf8: '﷼'
                    },
                    PAB: {
                        html: '&#66;&#47;&#46;',
                        utf8: 'B/.'
                    },
                    PEN: {
                        html: '&#83;&#47;&#46;',
                        utf8: 'S/.'
                    },
                    PHP: {
                        html: '&#80;&#104;&#112;',
                        utf8: '₱'
                    },
                    PKR: {
                        html: '&#8360;',
                        utf8: '₨'
                    },
                    PLN: {
                        html: '&#122;&#322;',
                        utf8: 'zł'
                    },
                    PYG: {
                        html: '&#71;&#115;',
                        utf8: 'Gs'
                    },
                    QAR: {
                        html: '&#65020;',
                        utf8: '﷼'
                    },
                    RON: {
                        html: '&#108;&#101;&#105;',
                        utf8: 'lei'
                    },
                    RSD: {
                        html: '&#1044;&#1080;&#1085;&#46;',
                        utf8: 'Дн.'
                    },
                    RUB: {
                        html: '&#1088;&#1091;&#1073;',
                        utf8: 'руб'
                    },
                    SAR: {
                        html: '&#65020;',
                        utf8: '﷼'
                    },
                    SBD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    SCR: {
                        html: '&#8360;',
                        utf8: '₨'
                    },
                    SEK: {
                        html: '&#107;&#114;',
                        utf8: 'kr'
                    },
                    SGD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    SHP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    SOS: {
                        html: '&#83;',
                        utf8: 'S'
                    },
                    SRD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    SVC: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    SYP: {
                        html: '&#163;',
                        utf8: '£'
                    },
                    THB: {
                        html: '&#3647;',
                        utf8: '฿'
                    },
                    TRL: {
                        html: '&#8356;',
                        utf8: '₤'
                    },
                    TTD: {
                        html: '&#84;&#84;&#36;',
                        utf8: 'T$'
                    },
                    TVD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    TWD: {
                        html: '&#78;&#84;&#36;',
                        utf8: 'N$'
                    },
                    UAH: {
                        html: '&#8372;',
                        utf8: '₴'
                    },
                    USD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    UYU: {
                        html: '&#36;&#85;',
                        utf8: '$'
                    },
                    UZS: {
                        html: '&#1083;&#1074;',
                        utf8: 'лв'
                    },
                    VEF: {
                        html: '',
                        utf8: 'Bs'
                    },
                    VND: {
                        html: '&#8363;',
                        utf8: '₫'
                    },
                    XCD: {
                        html: '&#36;',
                        utf8: '$'
                    },
                    YER: {
                        html: '&#65020;',
                        utf8: '﷼'
                    },
                    ZAR: {
                        html: '&#82;',
                        utf8: 'R'
                    },
                    ZWD: {
                        html: '&#90;&#3;',
                        utf8: 'Z'
                    }
                };
                if (input === null) {
                    return "";
                }
                if (currencies[input]) {
                    if (arg === 'utf8') {
                        return currencies[input].utf8 || this.$sce.trustAsHtml(currencies[input].html) || input;
                    }
                    else {
                        return this.$sce.trustAsHtml(currencies[input].html) || currencies[input].utf8 || input;
                    }
                }
                else {
                    return input;
                }
            };
            return CurrencyToSymbolFilter;
        })();
        Utility.CurrencyToSymbolFilter = CurrencyToSymbolFilter;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

