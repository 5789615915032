define('widget-portfolio-summary/../../bower_components/dwx-widget-portfolio-summary/src/directives/portfolioSummaryDirective',["require", "exports"], function (require, exports) {
    var PortfolioSummaryDirective = (function () {
        function PortfolioSummaryDirective() {
            this.scope = { identifier: '=', widgetStatus: '=' };
            this.replace = true;
            this.templateUrl = 'bower_components/widget-portfolio-summary/dist/src/views/partials/main.html';
            var _this = this;
            this.link = function ($scope) {
                _this.myLink($scope);
            };
        }
        PortfolioSummaryDirective.prototype.myLink = function (scope) {
        };
        PortfolioSummaryDirective.Factory = function () {
            var directive = function () {
                return new PortfolioSummaryDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        PortfolioSummaryDirective.$inject = [];
        PortfolioSummaryDirective.ngID = 'dwxWidgetPortfolioSummary';
        PortfolioSummaryDirective.ngType = 'directive';
        return PortfolioSummaryDirective;
    })();
    exports.PortfolioSummaryDirective = PortfolioSummaryDirective;
    exports.colorPattern = undefined;
});

