define('widget-login/../../bower_components/dwx-widget-login/src/directives/loginDirective',["require", "exports"], function (require, exports) {
    var WidgetLogin;
    (function (WidgetLogin) {
        var LoginDirective = (function () {
            function LoginDirective() {
                return {
                    priority: 1001,
                    replace: true,
                    templateUrl: 'bower_components/dwx-widget-login/dist/src/views/partials/main.html',
                    scope: {
                        identifier: '='
                    },
                    controller: 'loginCtrl as loginCtrl'
                };
            }
            return LoginDirective;
        })();
        WidgetLogin.LoginDirective = LoginDirective;
        var SmartAuthenticationDirective = (function () {
            function SmartAuthenticationDirective(events) {
                var _this = this;
                this.events = events;
                this.priority = 0;
                this.replace = true;
                this.templateUrl = 'widgets/login/smart-auth-modal.html';
                this.scope = {
                    show: '=',
                    smartAuthModel: '=',
                    userSuccessfullyAuthenticated: '&',
                    userChoosenSmartAuthenticationMethods: '&'
                };
                this.link = function (scope) {
                    scope.userSuccessfullyAuthenticated = function () {
                        _this.events.publish("runUserSuccessfullyAuthenticated");
                    };
                    scope.userChoosenSmartAuthenticationMethods = function () {
                        _this.events.publish("runUserChoosenSmartAuthenticationMethods");
                    };
                };
            }
            SmartAuthenticationDirective.Factory = function () {
                var directive = function (events) {
                    return new SmartAuthenticationDirective(events);
                };
                directive['$inject'] = ['EventService'];
                return directive;
            };
            return SmartAuthenticationDirective;
        })();
        WidgetLogin.SmartAuthenticationDirective = SmartAuthenticationDirective;
    })(WidgetLogin = exports.WidgetLogin || (exports.WidgetLogin = {}));
});

