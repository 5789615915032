define('layout-base/../../bower_components/dwx-layout-base/src/controllers/navigationCtrl',["require", "exports", "../../../../scripts/smartAuthenticationManager"], function (require, exports, SM) {
    exports.subscribeToPortolioSelectedEvent = true;
    var Layout;
    (function (Layout) {
        var NavigationController = (function () {
            function NavigationController($scope, $rootScope, $routeParams, navigationService, EventService, $translatePartialLoaderProvider) {
                this.$scope = $scope;
                this.$rootScope = $rootScope;
                this.$routeParams = $routeParams;
                this.navigationService = navigationService;
                this.EventService = EventService;
                if ($translatePartialLoaderProvider != undefined) {
                    $translatePartialLoaderProvider.addPart('.');
                }
                var eventHandler;
                var portfolioSelectedEventHandler;
                var refreshMenuEventHandler;
                var _this = this;
                _this.menu = {};
                _this.getMenu();
                eventHandler = EventService.subscribe('user-session-changed', function () {
                    _this.getMenu();
                }, false);
                if (exports.subscribeToPortolioSelectedEvent) {
                    portfolioSelectedEventHandler = EventService.subscribe('portfolioSelected', function () {
                        _this.getMenu();
                    }, false);
                }
                refreshMenuEventHandler = EventService.subscribe('refresh-menu', function () {
                    _this.getMenu();
                }, false);
                $rootScope.$on('$routeChangeStart', function (evt, _route) {
                    return _this.currentPageId = _route.params.pageId || landingPageId;
                });
                $scope.$on("$destroy", function () {
                    _this.EventService.unsubscribe(eventHandler, false);
                    if (exports.subscribeToPortolioSelectedEvent) {
                        _this.EventService.unsubscribe(portfolioSelectedEventHandler, false);
                    }
                    _this.EventService.unsubscribe(refreshMenuEventHandler, false);
                });
            }
            NavigationController.prototype.getMenu = function () {
                var _this = this;
                var smartAuthManager = new SM.SmartAuthenticationManager();
                var currentLoggedUsername = window.sessionStorage.currentUserUsername || "";
                return this.navigationService.menu.get({}, function (response) {
                    _this.menu = response.content;
                    var menuHome = {
                        "weight": 1,
                        "id": 0,
                        "name": "home",
                        "isSubmenu": false,
                        "menuId": 0,
                        "keyName": "Home",
                        "type": "default",
                        "parentMenuItemId": null,
                        "childMenuItems": [],
                        "linkedPageId": 25,
                        "visible": true,
                        "badge": null
                    };
                    _this.menu.menuItems.unshift(menuHome);
                    smartAuthManager.canShowSmartAuthenticationSettingsForUser(currentLoggedUsername, function (canShowForUsername) {
                        if (!canShowForUsername) {
                            _this.menu.menuItems = _this.menu.menuItems.filter(function (menuItem) {
                                return menuItem.keyName !== "mi.settings.customer";
                            });
                        }
                        if (!landingPageId)
                            var landingPageId = '';
                        _this.currentPageId = _this.$routeParams.pageId || landingPageId;
                    });
                });
            };
            return NavigationController;
        })();
        Layout.NavigationController = NavigationController;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

