define('../bower_components/dwx-widget-dossier-selector/src/directives/dossierSelectorDirective',["require", "exports"], function (require, exports) {
    exports.basePath = 'bower_components/dwx-widget-dossier-selector/dist/src/views/partials';
    var DossierSelectorDirective = (function () {
        function DossierSelectorDirective() {
            this.scope = { identifier: '=' };
            this.replace = true;
            this.controller = 'dossierSelectorCtrl as dossierSelectorCtrl';
            this.templateUrl = exports.basePath + '/main.html';
            var _this = this;
        }
        DossierSelectorDirective.Factory = function () {
            var directive = function () {
                return new DossierSelectorDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return DossierSelectorDirective;
    })();
    exports.DossierSelectorDirective = DossierSelectorDirective;
});

