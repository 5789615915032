define('layout-base/../../bower_components/dwx-utility/src/directives/dynamicNameDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var DynamicNameDirective = (function () {
            function DynamicNameDirective($compile, $parse) {
                this.restrict = "A";
                this.terminal = true;
                this.priority = 100000;
                this.$parse = $parse;
                this.$compile = $compile;
                var _this = this;
                this.link = function (scope, elem) {
                    _this.myLink(scope, elem);
                };
            }
            DynamicNameDirective.prototype.myLink = function (scope, elem) {
                var name;
                var _this = this;
                name = this.$parse(elem.attr("dynamic-name"))(scope);
                elem.removeAttr("dynamic-name");
                elem.attr("name", name);
                this.$compile(elem)(scope);
            };
            DynamicNameDirective.Factory = function () {
                var directive = function ($compile, $parse) {
                    return new DynamicNameDirective($compile, $parse);
                };
                directive['$inject'] = ['$compile', '$parse'];
                return directive;
            };
            return DynamicNameDirective;
        })();
        Utility.DynamicNameDirective = DynamicNameDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

