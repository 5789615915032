define('../bower_components/dwx-engine/src/run',["require", "exports"], function (require, exports) {
    exports.redirectToPreviousPage = true;
    exports.choosePreferredLanguage;
    var Engine;
    (function (Engine) {
        exports.choosePreferredLanguage = function (lang, EventService) {
            if (!lang && (typeof language === 'undefined' || language == null)) {
                language = 'en-gb';
            }
            else {
                language = lang;
            }
            EventService.publish("switchLanguage", [language], true);
        };
        var EngineRun = (function () {
            function EngineRun($rootScope, $cookies, $location, $routeParams, languageService, configurationService, profileService, EventService, LayoutService) {
                var _this = this;
                this.showProspectModal = function () {
                    localStorage.setItem('showProspectModal', 'true');
                    sweetAlert({
                        title: "",
                        text: "Prendo atto che l’esecuzione degli ordini di investimento da me sottoscritti è sospesa, fino a che il processo di Onboarding non sarà concluso con l’accettazione da parte di Azimut del Contratto di Apertura Rapporti",
                        confirmButtonColor: "#385696",
                        confirmButtonText: "Chiudi",
                    });
                };
                this.showOnboardingProcessCompleteModal = function () {
                    sessionStorage.removeItem('showOnboardingCompleteProcessMessage');
                    sweetAlert({
                        title: "",
                        text: "Gentile Cliente, il processo di registrazione si è concluso correttamente. A breve riceverà la User Id via eMail ed una Password temporanea via SMS che dovrà utilizzare per l’accesso",
                        confirmButtonColor: "#385696",
                        confirmButtonText: "Chiudi",
                    });
                };
                webapp = {
                    status: 'loading'
                };
                if (sessionStorage.getItem("showOnboardingCompleteProcessMessage")) {
                    this.showOnboardingProcessCompleteModal();
                }
                var pathParameters = $location.search();
                if (Object.keys(pathParameters).indexOf("onboarding_redirect") > -1) {
                    sessionStorage.setItem("showOnboardingCompleteProcessMessage", "true");
                }
                language = $cookies.locale;
                exports.choosePreferredLanguage(language, EventService);
                availableLanguages = [];
                languageService.language.query({}, function (response) {
                    availableLanguages = response.content;
                });
                $rootScope.$on("$translateChangeSuccess", function () {
                    EventService.publish("translateChangeSuccess", [], false);
                });
                EventService.subscribe('user-session-changed', function () {
                    configurationService.manifestThule.get({}, function (manifestThule) {
                        configurationService.manifestJson = manifestThule;
                        configurationService.start.get({}, function (response) {
                            if (response.outcome === 'OK') {
                                settings = response.content;
                                if (settings.userLoggedIn) {
                                    LayoutService.getBannerList.get({}).$promise.then(function (bannerResponse) {
                                        LayoutService.bannerList = bannerResponse.result;
                                    }).catch(function (error) {
                                        EventService.publish('http-request-error', [error], false);
                                    }).finally(function () {
                                        profileService.dikeConfiguration.get({}).$promise.then(function (dikeConfiguration) {
                                            if (dikeConfiguration.code === 'OK') {
                                                realEconomyUsePaidAmount = dikeConfiguration.result['realEconomy.usePaidAmount'] === 'true';
                                            }
                                        }).finally(function () {
                                            profileService.profile.get({}, function (response) {
                                                profileService.loggedUser = response;
                                                EventService.publish('profileService:logged-user-ready', [profileService.loggedUser]);
                                                if (profileService.loggedUser.userInfos.SIGN_WARNING) {
                                                    signWarning = profileService.loggedUser.userInfos.SIGN_WARNING.value;
                                                }
                                                landingPageId = response.mainRole.landingPageId || 0;
                                                EventService.publish("new-profile-loaded");
                                                var prospectRole = response.roles.filter(function (role) {
                                                    return role.roleName === 'PROSPECT';
                                                });
                                                if (prospectRole.length > 0 && !localStorage.getItem('showProspectModal')) {
                                                    _this.showProspectModal();
                                                }
                                            });
                                        }).finally(function () {
                                            profileService.userData.get({}).$promise.then(function (response) {
                                                if (response.outcome === 'OK') {
                                                    LayoutService.dateCustomerMigrated = response.content['dateCustomerMigrated'];
                                                }
                                            });
                                        }).finally(function () {
                                            profileService.daysWarnMigration.get({}).$promise.then(function (response) {
                                                if (response.code === 'OK') {
                                                    LayoutService.daysWarnMigration = response.result['DAYS_WARN_MIGRATION'];
                                                }
                                            });
                                        }).finally(function () {
                                            profileService.getWarnMigrationMessage.get({}).$promise.then(function (response) {
                                                if (response.code === 'OK') {
                                                    LayoutService.warnMigrationMessage = response.result[0]['description'];
                                                }
                                            });
                                        });
                                    });
                                }
                                else if (window.location.hash.indexOf('page') < 0 && window.location.hash.indexOf('landing') < 0 && window.location.hash.indexOf('unlock') < 0 && window.location.hash !== '#/') {
                                    window.location.href = '#/';
                                    window.location.reload();
                                }
                            }
                        }, function () {
                            if (window.location.hash.indexOf('login') < 0 && window.location.hash.indexOf('unlock') < 0) {
                                $location.path('/login');
                                $location.replace();
                            }
                        });
                    });
                });
                EventService.publish('user-session-changed');
                EventService.subscribe('redirect-to-home', function () {
                    var currentUrl;
                    if ($routeParams.pageId !== 'landing') {
                        if (exports.redirectToPreviousPage) {
                            currentUrl = $location.path();
                        }
                        else {
                            currentUrl = "/";
                        }
                        window.location.href = '#/';
                        window.location.reload();
                    }
                });
            }
            return EngineRun;
        })();
        Engine.EngineRun = EngineRun;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

