define('widget-portfolio-ownership-view/../../bower_components/dwx-widget-portfolio-ownership-view/src/directives/portfolioOwnershipViewDirective',["require", "exports"], function (require, exports) {
    exports.basePath = 'bower_components/dwx-widget-portfolio-ownership-view/dist/src/views/partials';
    var PortfolioOwnershipViewDirective = (function () {
        function PortfolioOwnershipViewDirective() {
            this.scope = { identifier: '=' };
            this.replace = true;
            this.templateUrl = exports.basePath + '/main.html';
            this.bindToController = true;
            var _this = this;
            this.link = function ($scope) {
                _this.myLink($scope);
            };
        }
        PortfolioOwnershipViewDirective.prototype.myLink = function (scope) {
        };
        PortfolioOwnershipViewDirective.Factory = function () {
            var directive = function () {
                new PortfolioOwnershipViewDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return PortfolioOwnershipViewDirective;
    })();
    exports.PortfolioOwnershipViewDirective = PortfolioOwnershipViewDirective;
});

