define('widget-advisor/../../project_components/dwx-widget-advisor/src/config',["require", "exports"], function (require, exports) {
    var AdvisorConfig = (function () {
        function AdvisorConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return AdvisorConfig;
    })();
    exports.AdvisorConfig = AdvisorConfig;
});

