define('widget-login/../../bower_components/dwx-widget-login/src/controllers/loginCtrl',["require", "exports", "../../../../scripts/smartAuthenticationManager", '../../../../scripts/owui-widget.constant'], function (require, exports, SM, OwuiWidgetConstant) {
    exports.SMART_AUTH_MODAL_SWITCH_VALUES = {
        "TO_CHOOSE_AUTH": "TO_CHOOSE_AUTH",
        "CHOOSEN_PIN_AUTH": "CHOOSEN_PIN_AUTH"
    };
    var WidgetLogin;
    (function (WidgetLogin) {
        var LoginController = (function () {
            function LoginController($scope, $rootScope, $location, loginService, EventService, initConfigService, $cookieStore, $translatePartialLoaderProvider, configurationService, $window, profileService) {
                this.$scope = $scope;
                this.$rootScope = $rootScope;
                this.$location = $location;
                this.loginService = loginService;
                this.EventService = EventService;
                this.$cookieStore = $cookieStore;
                this.configurationService = configurationService;
                this.$window = $window;
                this.profileService = profileService;
                this.isEnabledPinSmartAuth = false;
                this.isEnabledPinSmartAuthLink = false;
                this.isEnabledTouchIdSmartAuthLink = false;
                this.isEnabledFaceIdAuth = false;
                this.owuiWidgetLoginName = 5 /* WIDGET_LOGIN */;
                this.showUpdateAlert = function () {
                    sweetAlert({
                        title: "Aggiornamento",
                        type: "info",
                        text: "per utilizzare la funzione è necessario aggiornare",
                        confirmButtonColor: "#385696",
                        confirmButtonText: "Aggiorna",
                        cancelButtonText: "Annulla",
                        closeOnConfirm: true,
                        closeOnCancel: true
                    }, function (isConfirm) {
                        window.location.reload();
                    });
                };
                var _this = this;
                this.userSuccessfullyAuthenticatedEvent = this.EventService.subscribe('runUserSuccessfullyAuthenticated', function () {
                    _this.userSuccessfullyAuthenticated();
                });
                this.userChoosenSmartAuthenticationMethodsEvent = this.EventService.subscribe('runUserChoosenSmartAuthenticationMethods', function () {
                    _this.userChoosenSmartAuthenticationMethods();
                });
                this.auth = {
                    username: "",
                    password: "",
                    selectedAuthenticationMode: "DEFAULT",
                    errorMessage: "",
                    wrongPinCounter: 0
                };
                this.rememberMe = this.$cookieStore.get('rememberMe') ? true : false;
                if (this.rememberMe) {
                    this.auth.username = this.$cookieStore.get('userID');
                }
                var reg = /^\d+$/;
                $scope.$watch(angular.bind(this, function () {
                    return this.smartAuthModel.config.pin;
                }), function (newVal, oldVal) {
                    if (!(newVal == "") && !reg.test(newVal)) {
                        console.log("KO - symbol not valid for pin");
                        _this.smartAuthModel.config.pin = oldVal;
                    }
                });
                $scope.$watch(angular.bind(this, function () {
                    return this.smartAuthModel.config.pinConfirm;
                }), function (newVal, oldVal) {
                    if (!(newVal == "") && !reg.test(newVal)) {
                        console.log("KO - symbol not valid for pin");
                        _this.smartAuthModel.config.pinConfirm = oldVal;
                    }
                });
                this.widgetId = $scope.identifier || (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetLogin';
                this.widgetConfig = initConfigService[this.widgetId];
                if ($translatePartialLoaderProvider != undefined) {
                    $translatePartialLoaderProvider.addPart('bower_components/dwx-widget-login');
                }
                this.EventService.publish('remove-style', [true], false);
                this.widgetConfig.status.mode = 'on';
                this.startSmartAuthFlow();
                this.owuiWidgetEventHandler = EventService.subscribe('owuiWidgetDirective:widgetLoaded', function (widgetName) {
                    var widgetLoginTag = OwuiWidgetConstant.OwuiWidgetMap[5 /* WIDGET_LOGIN */].widgetTag;
                    if (widgetName === widgetLoginTag) {
                        console.log('owuiWidgetDirective:widgetLoaded', widgetName);
                        setTimeout(function () {
                            var widgetEl = document.getElementsByTagName(widgetLoginTag)[0];
                            if (!!widgetEl) {
                                var shadowRoot = widgetEl.shadowRoot;
                                var elements = shadowRoot.querySelectorAll(LoginController.WIDGET_LOGIN_FAST_CLICK_ELEMENTS);
                                elements.forEach(function (element) {
                                    element.addEventListener('touchstart', function (e) {
                                        e.stopImmediatePropagation();
                                    }, { passive: false });
                                });
                            }
                        }, 0);
                        document.getElementsByTagName(widgetLoginTag)[0].addEventListener(widgetLoginTag, function (event) {
                            switch (event.detail.type) {
                                case 'trust-contact-selected':
                                    _this.postAuth();
                                    break;
                                case 'login-successfully':
                                    _this.auth.username = event.detail.data.username;
                                    _this.auth.password = event.detail.data.password;
                                    _this.postAuth();
                                    break;
                                case 'touch-id-login':
                                case 'face-id-login':
                                    _this.authenticateWithBiometricId();
                                    break;
                                case 'pin-login':
                                    _this.smartAuthModel.insertedSmartAuthPin = event.detail.data.pin;
                                    _this.userAuthenticateWithPin();
                                    break;
                                case 'onboarding-successfully':
                                    _this.postOnboarding(event.detail.data);
                                    break;
                                case 'unlock-user-successfully':
                                    _this.postUnlockUser(event.detail.data);
                                    break;
                                case 'download-cookie-policy':
                                    _this.$window.open("" + apiPrefix + "/documents/downloadCookiePolicy", "_blank");
                                    break;
                            }
                        });
                    }
                });
                $scope.$on("$destroy", function () {
                    _this.EventService.unsubscribe(_this.userSuccessfullyAuthenticatedEvent, false);
                    _this.EventService.unsubscribe(_this.userChoosenSmartAuthenticationMethodsEvent, false);
                    _this.EventService.unsubscribe(_this.owuiWidgetEventHandler);
                });
            }
            LoginController.prototype.startSmartAuthFlow = function () {
                var _this = this;
                this.smartAuthModel = {
                    "smartAuthModalSwitch": exports.SMART_AUTH_MODAL_SWITCH_VALUES.TO_CHOOSE_AUTH,
                    "insertedSmartAuthPin": "",
                    "availableAuthentications": [],
                    "config": {
                        "pin": "",
                        "pinConfirm": "",
                        "notRememberMe": false,
                        "choosenAuthentication": "DEFAULT",
                    }
                };
                this.smartAuthentication = new SM.SmartAuthenticationManager();
                this.smartAuthentication.getActiveProfile(function (credentials) {
                    if (credentials) {
                        _this.auth.wrongPinCounter = credentials.wrongPinCounter;
                        _this.auth.selectedAuthenticationMode = credentials.selectedAuthenticationMode;
                        _this.isEnabledPinSmartAuth = ((credentials.selectedAuthenticationMode === SM.ProfileType.PIN) && (credentials.wrongPinCounter < SM.MAX_PIN_ATTEMPTS));
                        _this.isEnabledPinSmartAuthLink = _this.isEnabledPinSmartAuth;
                        _this.isEnabledTouchIdSmartAuthLink = credentials.selectedAuthenticationMode === SM.ProfileType.TOUCHID;
                        _this.isEnabledFaceIdAuth = credentials.selectedAuthenticationMode === SM.ProfileType.FACEID;
                        if (_this.isEnabledSmartAuthentication) {
                            _this.widgetProp = {
                                'is-mobile': (typeof cordova != 'undefined'),
                                'biometric-login-type': _this.isEnabledPinSmartAuthLink ? 'pin' : _this.isEnabledTouchIdSmartAuthLink ? 'touch-id' : 'face-id',
                                step: _this.isEnabledPinSmartAuthLink ? 'PIN_LOGIN' : 'LANDING'
                            };
                            if (_this.auth.selectedAuthenticationMode === undefined)
                                delete _this.widgetProp['biometric-login-type'];
                            console.log("---->  ", _this.profileService.loggedUser.userInfos.ACCESSORY_USER_CODE.value);
                        }
                        if (_this.isEnabledTouchIdSmartAuthLink) {
                            _this.authenticateWithBiometricId();
                        }
                    }
                    else {
                        _this.widgetProp = {};
                    }
                });
            };
            LoginController.prototype.isEnabledSmartAuthentication = function () {
                return (this.auth.selectedAuthenticationMode !== SM.ProfileType.DEFAULT) && (this.auth.selectedAuthenticationMode !== undefined);
            };
            LoginController.prototype.chooseSmartAuthenticationMethods = function () {
                var _this = this;
                var pluginAvailable = false;
                this.smartAuthentication.canShowSmartAuthenticationViewForUser(this.auth.username, function (canShowForUsername) {
                    pluginAvailable = true;
                    if (canShowForUsername) {
                        _this.smartAuthentication.getAvailableAuthentications(function (availableAuthentications) {
                            console.log("this.smartAuthentication.getAvailableAuthentications:" + availableAuthentications);
                            if (availableAuthentications && availableAuthentications.length == 1 && availableAuthentications.indexOf(SM.ProfileType.DEFAULT) == 0) {
                                _this.userSuccessfullyAuthenticated();
                            }
                            else {
                                _this.smartAuthModel.availableAuthentications = availableAuthentications;
                                _this.$scope.$apply();
                                $('#smartAuthenticationModal').foundation('reveal', 'open');
                            }
                        });
                    }
                    else {
                        _this.userSuccessfullyAuthenticated();
                    }
                });
                setTimeout(function () {
                    if (!pluginAvailable) {
                        _this.userSuccessfullyAuthenticated();
                    }
                }, 1000);
            };
            LoginController.prototype.userChoosenSmartAuthenticationMethods = function () {
                var _this = this;
                if (this.smartAuthModel.config.choosenAuthentication === SM.ProfileType.PIN) {
                    if (this.smartAuthModel.smartAuthModalSwitch === exports.SMART_AUTH_MODAL_SWITCH_VALUES.TO_CHOOSE_AUTH) {
                        this.smartAuthModel.smartAuthModalSwitch = exports.SMART_AUTH_MODAL_SWITCH_VALUES.CHOOSEN_PIN_AUTH;
                        return;
                    }
                    else if (!this.smartAuthModel.config.pin) {
                        sweetAlert("Attenzione", "Inserire un PIN numerico", "error");
                        return;
                    }
                    else if (this.smartAuthModel.config.pin.toString().length === 0) {
                        sweetAlert("Attenzione", "Nessun PIN inserito", "error");
                        return;
                    }
                    else if (this.smartAuthModel.config.pin.toString().length !== SM.PIN_LENGHT) {
                        sweetAlert("Attenzione", "Il codice PIN deve essere esattamente di 6 cifre.", "error");
                        return;
                    }
                    else if (this.smartAuthModel.config.pin !== this.smartAuthModel.config.pinConfirm) {
                        sweetAlert("Conferma codice errata", "Non è stato possibile memorizzare il PIN", "error");
                        return;
                    }
                    else if (!/^\d+$/.test(this.smartAuthModel.config.pin)) {
                        sweetAlert("Attenzione", "PIN inserito non valido", "error");
                        return;
                    }
                }
                if (this.smartAuthModel.config.choosenAuthentication === SM.ProfileType.PIN) {
                    var successMessage = this.smartAuthModel.config.notRememberMe ? "E' possibile modificare la modalità di accesso in qualsiasi momento dalle IMPOSTAZIONI - Accesso e Sicurezza" : "Il codice " + this.smartAuthModel.config.choosenAuthentication + " è stato registrato correttamente";
                }
                else {
                    var successMessage = this.smartAuthModel.config.notRememberMe ? "E' possibile modificare la modalità di accesso in qualsiasi momento dalle IMPOSTAZIONI - Accesso e Sicurezza" : "La modalità di accesso " + this.smartAuthModel.config.choosenAuthentication + " è stata impostata";
                }
                var saveSaveProfileData = new SM.SaveProfileData();
                saveSaveProfileData.username = this.auth.username;
                saveSaveProfileData.password = this.auth.password;
                saveSaveProfileData.pin = this.smartAuthModel.config.pin;
                saveSaveProfileData.authenticationMode = this.smartAuthModel.config.choosenAuthentication;
                saveSaveProfileData.smartAuthenticationModalViewEnabled = (!this.smartAuthModel.config.notRememberMe) && (this.smartAuthModel.config.choosenAuthentication === SM.ProfileType.DEFAULT);
                this.smartAuthentication.saveProfile(saveSaveProfileData, function (result) {
                    sweetAlert({
                        title: "La tua scelta è stata salvata",
                        text: successMessage,
                        type: "success",
                        showCancelButton: false,
                        closeOnConfirm: true,
                        confirmButtonText: "Chiudi",
                    }, function (isConfirm) {
                        _this.userSuccessfullyAuthenticated();
                    });
                });
            };
            LoginController.prototype.getStyle = function () {
                var _this = this;
                _this.loginService.getStyle.get({}, function (response) {
                    if (response.outcome === "OK") {
                        _this.EventService.publish('style-retrieved', [(response.content != undefined) ? response.content.styleId : ''], false);
                    }
                    else {
                        _this.EventService.publish('style-retrieved', [], false);
                    }
                }, function () {
                    _this.EventService.publish('webapp-loaded', [true], false);
                });
            };
            LoginController.prototype.postAuth = function () {
                var _this = this;
                if (_this.isEnabledSmartAuthentication()) {
                    _this.userSuccessfullyAuthenticated();
                }
                else {
                    _this.chooseSmartAuthenticationMethods();
                }
            };
            LoginController.prototype.postOnboarding = function (payload) {
                var pageId = payload.isReboarding ? env.reboardingPageId : env.onboardingPageId;
                if (!payload.isReboarding)
                    sessionStorage.setItem("sendOnboardingPopupParameter", "true");
                this.$location.path('/page/' + pageId);
                settings = {
                    showHeader: true,
                    showLandingPage: true,
                    showMenu: true,
                    userLoggedIn: true
                };
                this.$scope.$apply();
            };
            LoginController.prototype.postUnlockUser = function (message) {
                var _this = this;
                this.setSweetAlert('Sblocco utenza', message, function () {
                    _this.goToHome();
                });
            };
            LoginController.prototype.userAuthenticateWithPin = function () {
                if (this.smartAuthModel.insertedSmartAuthPin == undefined) {
                    sweetAlert("Attenzione", "Inserire un PIN numerico", "error");
                }
                else {
                    var _t = this;
                    this.smartAuthentication.authenticateWithPin(this.smartAuthModel.insertedSmartAuthPin, function (authenticationResponse, credential) {
                        _t.$scope.$apply();
                        if (!authenticationResponse.success) {
                            sweetAlert("Attenzione", authenticationResponse.errorMessage, "error");
                            if (authenticationResponse.errorCode === "106") {
                                _t.isEnabledPinSmartAuth = false;
                                _t.isEnabledPinSmartAuthLink = false;
                                _t.$scope.$apply();
                            }
                            return;
                        }
                        _t.auth.username = credential.username;
                        _t.auth.password = credential.password;
                        var widgetLoginTag = OwuiWidgetConstant.OwuiWidgetMap[5 /* WIDGET_LOGIN */].widgetTag;
                        var loginEvent = new CustomEvent('do-login', {
                            detail: {
                                username: credential.username,
                                password: credential.password
                            }
                        });
                        document.getElementsByTagName(widgetLoginTag)[0].dispatchEvent(loginEvent);
                    });
                }
            };
            LoginController.prototype.authenticateWithBiometricId = function () {
                var _this = this;
                var authTypeParam = this.isEnabledTouchIdSmartAuthLink ? 'authenticateWithTouchId' : 'authenticateWithFaceId';
                this.smartAuthentication.authenticateWithBiometricId(authTypeParam, function (authenticationResponse, credential) {
                    console.log("AUTH - authenticationResponse:", authenticationResponse);
                    var _t = _this;
                    if (!authenticationResponse.success && (authenticationResponse.errorCode === SM.ANDROID_FINGERPRINT_TOO_MANY_ATTEMPTS_ERROR_CODE || authenticationResponse.errorCode === SM.ANDROID_FINGERPRINT_UNRECOGNIZED_ERROR_CODE || authenticationResponse.errorCode === SM.IOS_TOUCHID_TOO_MANY_ATTEMPTS_ERROR_CODE)) {
                        sweetAlert("Attenzione", authenticationResponse.errorMessage, "error");
                    }
                    _this.$scope.$apply();
                    _this.auth.username = credential.username;
                    _this.auth.password = credential.password;
                    var widgetLoginTag = OwuiWidgetConstant.OwuiWidgetMap[5 /* WIDGET_LOGIN */].widgetTag;
                    var loginEvent = new CustomEvent('do-login', {
                        detail: {
                            username: _this.auth.username,
                            password: _this.auth.password
                        }
                    });
                    document.getElementsByTagName(widgetLoginTag)[0].dispatchEvent(loginEvent);
                });
            };
            LoginController.prototype.userSuccessfullyAuthenticated = function () {
                $('#smartAuthenticationModal').foundation('reveal', 'close');
                this.smartAuthentication.updatePassword(this.auth.username, this.auth.password);
                if (typeof cordova != 'undefined') {
                    window.sessionStorage.currentUserUsername = this.auth.username;
                    window.sessionStorage.currentUserPassword = this.auth.password;
                }
                this.EventService.publish('webapp-loaded', [false], false);
                this.getStyle();
                if (this.rememberMe) {
                    this.$cookieStore.put('userID', this.auth.username);
                    this.$cookieStore.put('rememberMe', true);
                }
                else {
                    this.$cookieStore.remove('userID');
                    this.$cookieStore.remove('rememberMe');
                }
                this.$location.path('/');
                this.$location.replace();
                this.EventService.publish('user-session-changed');
                this.$rootScope.$broadcast("$rootChangeSuccess", "landing");
                if ((typeof cordova != 'undefined') && (cordova.plarformId == 'ios') && (location.href.indexOf('conectus') != -1)) {
                    window['startParse']();
                }
            };
            LoginController.prototype.setSweetAlert = function (title, text, func) {
                sweetAlert({
                    title: title,
                    text: text,
                }, func);
            };
            LoginController.prototype.goToHome = function () {
                window.location.assign("/#/");
                window.location.reload();
            };
            LoginController.prototype.getManifestAssets = function () {
                return this.configurationService.manifestJson;
            };
            LoginController.prototype.isOwuiWidgetReady = function () {
                return this.getManifestAssets() && !!this.widgetProp;
            };
            LoginController.WIDGET_LOGIN_FAST_CLICK_ELEMENTS = [
                '#app',
                'input',
                'button',
                'span',
                '.Button',
                '.AuthenticationMyAzimut__registrationLink',
                '.AuthenticationMyAzimut__retrieveCredentialLink',
                '.AuthenticationMyAzimut__biometricPin',
                '.AuthenticationMyAzimut__biometricId',
                '.AuthenticationMyAzimut__bannerLink',
                '.AuthenticationMyAzimut__closeBanner',
                '.GoBackContainer__link'
            ];
            return LoginController;
        })();
        WidgetLogin.LoginController = LoginController;
    })(WidgetLogin = exports.WidgetLogin || (exports.WidgetLogin = {}));
});

