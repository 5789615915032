define('layout-base/../../bower_components/dwx-layout-base/src/local-layout-configurations/portfolio-ownership-view',["require", "exports"], function (require, exports) {
    exports.config = {
        "weight": null,
        "id": 142,
        "name": "PortfolioPageOwnershipView",
        "status": "online",
        "keyName": null,
        "view": "Widget",
        "containerId": 142,
        "widgetTemplate": {
            "id": 61,
            "name": "portfolio-ownership-view",
            "minWidth": null,
            "maxWidth": null,
            "defaultWidth": null,
            "htmlPageUrl": null,
            "tileUrl": null,
            "keyName": null,
            "widgetTemplateCatalogId": 1,
            "infos": {},
            "operations": {}
        },
        "widgetInfos": {
            "ALLOW_OVERFLOW": {
                "id": 343,
                "name": "ALLOW_OVERFLOW",
                "value": "0",
                "arrayValue": null,
                "widgetTemplateInfo": {
                    "id": 61,
                    "name": "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_SMARTPHONE",
                    "type": "Json",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 11
                },
                "widgetId": 142
            },
            "TITLE": {
                "id": 344,
                "name": "TITLE",
                "value": "PortfolioPageOwnershipView",
                "arrayValue": null,
                "widgetTemplateInfo": {
                    "id": 61,
                    "name": "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_SMARTPHONE",
                    "type": "Json",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 11
                },
                "widgetId": 142
            }
        },
        "visible": true
    };
});

