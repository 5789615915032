define('../bower_components/dwx-widget-media-library/src/directives/mediaLibraryDirective',["require", "exports"], function (require, exports) {
    exports.basePath = 'bower_components/widget-unlock-user/dist/src/views/partials/';
    var MediaLibraryDirective = (function () {
        function MediaLibraryDirective(mediaLibraryService) {
            this.mediaLibraryService = mediaLibraryService;
            this.replace = false;
            this.scope = {
                identifier: '=',
                widgetType: '@',
            };
            this.templateUrl = exports.basePath + 'main.html';
            var _this = this;
            _this.compile = function (element, attributes) {
                return {
                    pre: function (scope, element, attributes, controller, transcludeFn) {
                        scope.widget = { status: { mode: "loading" } };
                        _this.mediaLibraryService.widgetType = scope.widgetType;
                    },
                };
            };
        }
        MediaLibraryDirective.Factory = function () {
            var directive = function (unlockUserService) {
                return new MediaLibraryDirective(unlockUserService);
            };
            directive['$inject'] = ['mediaLibraryService'];
            return directive;
        };
        return MediaLibraryDirective;
    })();
    exports.MediaLibraryDirective = MediaLibraryDirective;
});

