define('widget-login/../../bower_components/dwx-widget-login/src/services/loginService',["require", "exports"], function (require, exports) {
    var WidgetLogin;
    (function (WidgetLogin) {
        var LoginService = (function () {
            function LoginService($resource) {
                return {
                    login: $resource("" + apiPrefix + "/login", {}, {
                        save: {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            }
                        }
                    }),
                    getStyle: $resource("" + apiPrefix + "/layout/style", {}),
                    retrievePassword: $resource("" + apiPrefix + "/accountManagement/retrievePassword/", {}, {
                        save: {
                            method: 'POST',
                            isArray: false
                        }
                    }),
                    unlockUser: $resource("" + apiPrefix + "/accountManagement/unlockAccountToken", { userId: '@userId' }),
                    updatePassword: $resource("" + apiPrefix + "/accountManagement/updatePassword", {}, {
                        update: {
                            method: 'PUT',
                            isArray: false
                        }
                    }),
                    registerUser: $resource("" + apiPrefix + "/accountManagement/register", {}, {
                        save: {
                            method: 'POST',
                            isArray: false
                        }
                    })
                };
            }
            return LoginService;
        })();
        WidgetLogin.LoginService = LoginService;
    })(WidgetLogin = exports.WidgetLogin || (exports.WidgetLogin = {}));
});

