define('layout-base/../../bower_components/dwx-layout-base/src/services/headerService',["require", "exports"], function (require, exports) {
    var Layout;
    (function (Layout) {
        var HeaderService = (function () {
            function HeaderService($resource) {
                return {
                    header: $resource("" + apiPrefix + "/header", {})
                };
            }
            return HeaderService;
        })();
        Layout.HeaderService = HeaderService;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

