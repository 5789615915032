define('layout-base/../../bower_components/dwx-layout-base/src/local-layout-configurations/pir-box-widget-config',["require", "exports"], function (require, exports) {
    exports.config = {
        "weight": null,
        "id": 53,
        "name": "PirBox_inPirBoxPage",
        "status": "online",
        "keyName": null,
        "view": "Widget",
        "containerId": 50,
        "widgetTemplate": {
            "id": 7,
            "name": "pir-box",
            "minWidth": null,
            "maxWidth": null,
            "defaultWidth": null,
            "htmlPageUrl": null,
            "tileUrl": null,
            "keyName": null,
            "widgetTemplateCatalogId": 1,
            "infos": {
                "ALLOW_OVERFLOW": {
                    "id": 15,
                    "name": "ALLOW_OVERFLOW",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 7
                },
                "TITLE": {
                    "id": 16,
                    "name": "TITLE",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 7
                }
            },
            "operations": {}
        },
        "widgetInfos": {
            "ALLOW_OVERFLOW": {
                "id": 230,
                "name": "ALLOW_OVERFLOW",
                "value": "1",
                "arrayValue": null,
                "widgetTemplateInfo": {
                    "id": 15,
                    "name": "ALLOW_OVERFLOW",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 7
                },
                "widgetId": 53
            },
            "TITLE": {
                "id": 229,
                "name": "TITLE",
                "value": "pir-box",
                "arrayValue": null,
                "widgetTemplateInfo": {
                    "id": 16,
                    "name": "TITLE",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 7
                },
                "widgetId": 53
            }
        },
        "visible": true
    };
});

