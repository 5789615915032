define('../bower_components/dwx-widget-media-library/src/app',["require", "exports", './controllers/mediaLibraryCtrl', './services/mediaLibraryService', './directives/mediaLibraryDirective', './directives/mediaLibraryHtmlDirective', './directives/videoProviders/brightcoveVideo', './config'], function (require, exports, mediaLibraryController, mediaLibraryService, mediaLibraryDirective, mediaLibraryHtmlDirective, brightcoveVideoProvider, config) {
    exports.configuration = {
        list: {
            "mediaLibraryCtrl": { callback: ['$scope', 'mediaLibraryService', 'initConfigService', 'paginationService', "$timeout", '$translatePartialLoader', '$translate', '$location', mediaLibraryController.MediaLibraryController], type: "controller" },
            "mediaLibraryService": { callback: ['$resource', mediaLibraryService.MediaLibraryService], type: "service" },
            "dwxMediaLibrary": { callback: ['mediaLibraryService', mediaLibraryDirective.MediaLibraryDirective.Factory()], type: "directive" },
            "dwxMediaLibraryHtml": { callback: [mediaLibraryHtmlDirective.Directives.MediaLibraryHtmlDirective], type: "directive" },
            "config": { callback: ['$translateProvider', config.MediaLibraryConfig], type: "config" },
            "dwxBrightcoveStudioVideo": {
                callback: ['$timeout', brightcoveVideoProvider.BrightcoveVideoDirective.Factory()],
                type: "directive"
            }
        },
        ngModules: ['pascalprecht.translate']
    };
});

