define('../bower_components/dwx-widget-my-documents/src/services/groupedTableService',["require", "exports"], function (require, exports) {
    var GroupedTableService = (function () {
        function GroupedTableService() {
            this.indexFieldForNestedTable = [];
            this.reverseOrderForNestedTable = [[]];
            this.lastGroupedItems = [];
            this.selectedGroup = '';
        }
        return GroupedTableService;
    })();
    exports.GroupedTableService = GroupedTableService;
});

