define('../bower_components/azimut-utility/src/directives/brightcoveVideo',["require", "exports"], function (require, exports) {
    var BrightcoveVideoDirective = (function () {
        function BrightcoveVideoDirective($timeout) {
            this.restrict = 'A';
            this.replace = true;
            this.transclude = false;
            this.template = '<div class="flex-video">\
              <object id="myExperience{{ videoId }}" class="BrightcoveExperience"">\
              <param name="bgcolor" value="#000000">\
              <param name="playerID" value="3639386432001">\
              <param name="playerKey" value="AQ~~%2CAAADG-o42zk~%2CUOpegeN-2aawn2DOB2L8Pyf8XUbdQLyW">\
              <param name="isVid" value="true">\
              <param name="isUI" value="true">\
              <param name="dynamicStreaming" value="true">\
              <param name="autoStart" value="false">\
              <param name="wmode" value="transparent">\
              <param name="@videoPlayer" value="ref:video_objectway_videopage">\
              <param name="secureConnections" value="true">\
              <param name="secureHTMLConnections" value="true">\
              <param name="htmlFallback" value="true" />\
              <param name="forceHTML" value="true" />\
            </object>\
            </div>';
            var _this = this;
            this.link = function ($scope) {
                $timeout(function () {
                    return brightcove.createExperiences();
                });
            };
        }
        BrightcoveVideoDirective.Factory = function () {
            var directive = function ($timeout) {
                return new BrightcoveVideoDirective($timeout);
            };
            directive['$inject'] = ['$timeout'];
            return directive;
        };
        return BrightcoveVideoDirective;
    })();
    exports.BrightcoveVideoDirective = BrightcoveVideoDirective;
});

