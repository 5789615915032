define('widget-user-data/../../bower_components/dwx-widget-user-data/src/services/userDataService',["require", "exports"], function (require, exports) {
    var UserDataService = (function () {
        function UserDataService($resource) {
            return {
                userData: $resource("" + apiPrefix + "/userdata/:widgetId", {
                    widgetId: '@widgetId'
                }),
                formData: $resource("" + apiPrefix + "/formdata/userdata", {}),
                updatePassword: $resource("" + apiPrefix + "/accountManagement/updateUserPassword", {}, {
                    update: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                getStatus: $resource(apiPrefix + '/firstaccess/status/:processName', { processName: '@processName' }),
                startProcess: $resource(apiPrefix + '/firstaccess/start/process/:processName', { processName: '@processName' }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                submit: $resource(apiPrefix + '/firstaccess/submit/:processName/:stepCode/:stepId', {
                    processName: '@processName',
                    stepCode: '@stepCode',
                    stepId: '@stepId'
                }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                complete: $resource(apiPrefix + '/firstaccess/complete/:processName/:stepCode/:stepId', {
                    processName: '@processName',
                    stepCode: '@stepCode',
                    stepId: '@stepId'
                }, {
                    save: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                abort: $resource(apiPrefix + '/firstaccess/cancel/:processName', {
                    processName: '@processName',
                }, {
                    save: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                updateData: $resource(apiPrefix + "/userdata/update", {}, {
                    save: {
                        method: 'POST'
                    }
                })
            };
        }
        return UserDataService;
    })();
    exports.UserDataService = UserDataService;
});

