define('../bower_components/dwx-widget-my-documents/src/controllers/myDocumentsNestedTableCtrl',["require", "exports", '../domain/rto-second-step-checkbox-conf'], function (require, exports, RTOSecondStep) {
    var MyDocumentsNestedTableController = (function () {
        function MyDocumentsNestedTableController($scope, myDocumentsConfigService, groupedTableService, $filter) {
            var _this = this;
            this.$scope = $scope;
            this.myDocumentsConfigService = myDocumentsConfigService;
            this.groupedTableService = groupedTableService;
            this.$filter = $filter;
            this.checkExistDispositions = function (key) {
                return _this.groupedTableService.nestedTableDocuments[key].filter(function (document) {
                    return document.attributes.moduleType === 'DISP';
                }).length > 0;
            };
            this.evalNgClassForNestedTable = function (index, category, columnName) {
                var reverseOrderForNestedTable = _this.groupedTableService.reverseOrderForNestedTable[category];
                var evalIsOrdAsc = typeof (reverseOrderForNestedTable) != 'undefined' && typeof (reverseOrderForNestedTable[index]) != 'undefined' && !reverseOrderForNestedTable[index];
                var evalIsOrdDesc = typeof (reverseOrderForNestedTable) != 'undefined' && typeof (reverseOrderForNestedTable[index]) != 'undefined' && reverseOrderForNestedTable[index];
                var result = {
                    'FlexTable__cell--large': index == 0,
                    'is-ordAsc': evalIsOrdAsc,
                    'is-ordDesc': evalIsOrdDesc
                };
                result[columnName] = true;
                return result;
            };
            this.evalNgClassForNestedTableCell = function ($index, column) {
                var result = {
                    'FlexTable__cell--large': $index == 0,
                    'FlexTable__cell--withColumns': $index == 0,
                    'FlexTable__cell--alignCenter': column.columnName === 'acknowledgment' || 'acceptProposal'
                };
                result[column.columnName] = true;
                return result;
            };
            this.changeOrderForNestedTable = function (indexField, nestedTableIndex, field) {
                var reverseOrderForNestedTable = _this.groupedTableService.reverseOrderForNestedTable[nestedTableIndex];
                var indexFieldForNestedTable = _this.groupedTableService.indexFieldForNestedTable[nestedTableIndex];
                if (reverseOrderForNestedTable != undefined) {
                    if (indexField === indexFieldForNestedTable && reverseOrderForNestedTable[indexField] !== void 0) {
                        reverseOrderForNestedTable[indexField] = !reverseOrderForNestedTable[indexField];
                    }
                    else {
                        reverseOrderForNestedTable = [];
                        reverseOrderForNestedTable[indexField] = false;
                    }
                }
                else {
                    reverseOrderForNestedTable = [];
                    reverseOrderForNestedTable[indexField] = false;
                }
                _this.groupedTableService.indexFieldForNestedTable[nestedTableIndex] = indexField;
                console.log("field nested table", field);
                var fields = [];
                angular.forEach(field.fieldsList, function (fieldConf) {
                    for (var i = 0; i < fieldConf.length; i++) {
                        fields.push(fieldConf[i].fieldName);
                    }
                });
                var categoryIndex = nestedTableIndex.indexOf('pdob') > -1 ? 'pdobDocuments' : _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
                var lastGroupedItems = _this.groupedTableService.lastGroupedItems[categoryIndex][_this.groupedTableService.selectedGroup];
                var normalizedIndex = nestedTableIndex.replace('pdob-', '');
                var nestedItems = (!_this.myDocumentsConfigService.myDocConfig.remotePagination) ? lastGroupedItems[normalizedIndex] : lastGroupedItems[normalizedIndex].documentsList;
                if (field.fieldsList[1][0].attribute) {
                    if (field.fieldsList[2] && field.fieldsList[2][0].fieldName == 'practiceId') {
                        var orderBy = 'title';
                        nestedItems = _this.$filter('dwxMyDocumentsSortFieldsInAttributes')(nestedItems, [orderBy, 'practiceId'], reverseOrderForNestedTable[indexField]);
                    }
                    else {
                        nestedItems = _this.$filter('dwxMyDocumentsSortFieldsInAttributes')(nestedItems, fields, reverseOrderForNestedTable[indexField]);
                    }
                }
                else {
                    nestedItems = _this.$filter('orderBy')(nestedItems, fields, reverseOrderForNestedTable[indexField]);
                }
                _this.groupedTableService.nestedTableDocuments[nestedTableIndex] = nestedItems;
                _this.groupedTableService.reverseOrderForNestedTable[nestedTableIndex] = reverseOrderForNestedTable;
            };
            this.getCheckboxLabel = function (index, product) {
                var checkboxConfig = _this.rtoSecondStepCheckboxConfig[index];
                var rtoOrderInfo = product.rtoOrderInfo;
                return checkboxConfig.customField ? checkboxConfig.checkboxLabel[rtoOrderInfo[checkboxConfig.customField]] : rtoOrderInfo[checkboxConfig.checkboxLabel].description;
            };
            this.nestedTableColumns = this.myDocumentsConfigService.myDocConfig.documentCategories[this.myDocumentsConfigService.myDocConfig.documentCategoryIndex].listViewConf.listViewData;
            this.nestedMaxTableColumns = this.myDocumentsConfigService.maxTableConfiguration;
            this.nestedSimulaTableColumns = this.myDocumentsConfigService.simulaTableConfiguration;
            this.nestedRTOTableColumns = this.myDocumentsConfigService.rtoTableConfiguration;
            this.nestedRTOSecondStepTableColumns = this.myDocumentsConfigService.rtoSecondStepTableConfiguration;
            this.nestedAdvisorTableColumns = this.myDocumentsConfigService.advisorTableConfiguration;
            this.nestedAdvisorDocumentsTableColumns = this.myDocumentsConfigService.advisorDocumentsTableConfiguration;
            this.rtoSecondStepCheckboxConfig = RTOSecondStep.checkboxConf;
        }
        return MyDocumentsNestedTableController;
    })();
    exports.MyDocumentsNestedTableController = MyDocumentsNestedTableController;
});

