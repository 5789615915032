define('../bower_components/dwx-widget-first-access/src/services/firstAccessService',["require", "exports"], function (require, exports) {
    var FirstAccessService = (function () {
        function FirstAccessService($resource) {
            return {
                getStatus: $resource(apiPrefix + '/firstaccess/status/:processName', { processName: '@processName' }),
                cancel: $resource(apiPrefix + '/firstaccess/cancel/:processName', { processName: '@processName' }),
                startProcess: $resource(apiPrefix + '/firstaccess/start/process/:processName', { processName: '@processName' }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                submit: $resource(apiPrefix + '/firstaccess/submit/:processName/:stepCode/:stepId', { processName: '@processName', stepCode: '@stepCode', stepId: '@stepId' }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                complete: $resource(apiPrefix + '/firstaccess/complete/:processName/:stepCode/:stepId', { processName: '@processName', stepCode: '@stepCode', stepId: '@stepId' }, {
                    save: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                updatePassword: $resource("" + apiPrefix + "/accountManagement/updatePassword", {}, {
                    update: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                login: $resource("" + apiPrefix + "/login", {}, {
                    save: {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                }),
            };
        }
        return FirstAccessService;
    })();
    exports.FirstAccessService = FirstAccessService;
});

