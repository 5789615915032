define('layout-base/../../bower_components/dwx-layout-base/src/controllers/layoutCtrl',["require", "exports", '../local-layout-configurations/landing', '../local-layout-configurations/portoflioPage', '../local-layout-configurations/news-list-widget-config', '../local-layout-configurations/focus-widget-config', '../local-layout-configurations/portfolio-ownership-view', '../local-layout-configurations/pir-box-widget-config', '../local-layout-configurations/login-widget-config', '../local-layout-configurations/header-widget-config'], function (require, exports, landingConfig, portfolioPageConfig, newsListWidgetConfig, focusWidgetConfig, portfolioOwnershipViewWidgetConfig, pirBoxWidgetConfig, loginConfig, headerConfig) {
    var Layout;
    (function (Layout) {
        var LayoutController = (function () {
            function LayoutController($scope, $routeParams, LayoutService, EventService, localStorageService, $cookieStore, profileService, portfolioCoreService, $location) {
                this.$scope = $scope;
                this.$routeParams = $routeParams;
                this.LayoutService = LayoutService;
                this.EventService = EventService;
                this.localStorageService = localStorageService;
                this.$cookieStore = $cookieStore;
                this.profileService = profileService;
                this.portfolioCoreService = portfolioCoreService;
                this.$location = $location;
                this.globalOffCanvas = '';
                this.webappLoaded = false;
                this.tabclass = "hiddentab";
                this.disableHeader = false;
                this.headerConf = headerConfig.config;
                var eventHandler, styleHandler, removeStyleHandler, webappHandler, layoutHandler, disableHeaderHandler;
                var closeSidebarEventHandler;
                var _this = this;
                _this.pageId = _this.$routeParams.pageId || 'landing';
                if (localStorageService != undefined && localStorageService.isSupported) {
                    if (localStorageService.get("styleId") != undefined) {
                        _this.styleId = localStorageService.get("styleId");
                    }
                }
                else if ($cookieStore != undefined) {
                    if ($cookieStore.get('styleId') != undefined) {
                        _this.styleId = $cookieStore.get('styleId');
                    }
                }
                _this.newsListWidgetConfig = newsListWidgetConfig.config;
                _this.focusWidgetConfig = focusWidgetConfig.config;
                _this.portfolioOwnershipViewWidgetConfig = portfolioOwnershipViewWidgetConfig.config;
                _this.pirBoxWidgetConfig = pirBoxWidgetConfig.config;
                _this.loginConfig = loginConfig.config;
                layoutHandler = EventService.subscribe('layout-loaded', function (value) {
                    if (value) {
                        _this.tabclass = "visibletab";
                    }
                }, false);
                webappHandler = EventService.subscribe('webapp-loaded', function (loaded) {
                    _this.webappLoaded = loaded;
                }, false);
                styleHandler = EventService.subscribe('style-retrieved', function (style) {
                    _this.styleId = style;
                    if (localStorageService != undefined && localStorageService.isSupported) {
                        localStorageService.set("styleId", _this.styleId);
                    }
                    else if ($cookieStore != undefined) {
                        $cookieStore.put('styleId', _this.styleId);
                    }
                }, false);
                removeStyleHandler = EventService.subscribe('remove-style', function (emptyVar) {
                    if (_this.localStorageService != undefined && _this.localStorageService.isSupported) {
                        _this.localStorageService.remove("styleId");
                    }
                    else if (_this.$cookieStore != undefined) {
                        _this.$cookieStore.remove('styleId');
                    }
                    if (emptyVar) {
                        _this.styleId = undefined;
                    }
                }, false);
                disableHeaderHandler = EventService.subscribe('disable-header', function (disable) {
                    _this.disableHeader = disable;
                });
                _this.layout = _this.getLayout();
                eventHandler = _this.EventService.subscribe('user-session-changed', function () {
                    _this.getLayout();
                }, false);
                closeSidebarEventHandler = EventService.subscribe('close-sidebar', function () {
                    _this.globalOffCanvas = '';
                }, false);
                $scope.$on("$destroy", function () {
                    _this.EventService.unsubscribe(eventHandler, false);
                    _this.EventService.unsubscribe(closeSidebarEventHandler, false);
                    _this.EventService.unsubscribe(webappHandler, false);
                    _this.EventService.unsubscribe(layoutHandler, false);
                    _this.EventService.unsubscribe(styleHandler, false);
                    _this.EventService.unsubscribe(removeStyleHandler, false);
                    _this.EventService.unsubscribe(disableHeaderHandler, false);
                });
                _this.webappLoaded = true;
            }
            LayoutController.prototype.getLayout = function () {
                var _this = this;
                _this.LayoutService.layout.get({
                    pageId: _this.pageId
                }, function (response) {
                    if (!response.hasOwnProperty('outcome')) {
                        _this.EventService.publish('redirect-to-home', [response.messageKey], false);
                    }
                    if (response.outcome === 'OK') {
                        _this.layout = response.content.pageComponents;
                        _this.overridePageConfiguration();
                        _this.EventService.publish('webapp-loaded', [true], false);
                        setTimeout(function () {
                            var tabElements = document.getElementsByClassName('ViewTab');
                            if (tabElements.length > 0) {
                                document.getElementsByClassName('ViewTab')[1].addEventListener('click', function (event) {
                                    event.stopPropagation();
                                    _this.setCoowner('COOWNER');
                                });
                                document.getElementsByClassName('ViewTab')[0].addEventListener('click', function (event) {
                                    event.stopPropagation();
                                    _this.setCoowner('OWNER');
                                });
                            }
                        }, 1000);
                    }
                    else {
                        if (response.messageKey == 'user_not_authorized') {
                            _this.profileService.logout.query({}, function () {
                                _this.$cookieStore.remove('portfolioSelected');
                                _this.$cookieStore.remove('perspectiveSelected');
                                _this.$cookieStore.remove('translateChangeSuccess');
                                _this.$cookieStore.remove('refreshPortfolioWithDossier');
                                _this.$cookieStore.remove('switchLanguage');
                                _this.EventService.publish('webapp-loaded', [false], false);
                                _this.EventService.publish('remove-style', [], false);
                                window.location.href = '#/';
                                window.location.reload();
                            });
                        }
                        else {
                            _this.EventService.publish('redirect-to-home', [response.messageKey], false);
                            _this.EventService.publish('http-response-error', [response.messageKey], false);
                            _this.EventService.publish('remove-style', [], false);
                            _this.styleId = undefined;
                        }
                    }
                }, function (error) {
                    _this.EventService.publish('http-request-error', [error], false);
                    if (window.location.hash !== '#/' && window.location.hash !== '#/login' && window.location.hash.indexOf('#/unlock') < 0) {
                        _this.EventService.publish('redirect-to-home', [error], false);
                    }
                });
            };
            LayoutController.prototype.setCoowner = function (value) {
                if (this.$location.path() !== '/pirbox')
                    sessionStorage.setItem('pirBoxBack', this.$location.path());
                var isDisabled = value ? this.isCoOwnerDisabled() : this.isOwnerDisabled();
                var hasChangedValue = this.coowner !== value;
                if (hasChangedValue && !isDisabled) {
                    this.coowner = value;
                    if (value === LayoutController.COOWNER.toString()) {
                        sessionStorage.setItem('coowner', 'true');
                    }
                    else {
                        sessionStorage.removeItem('coowner');
                    }
                    this.$location.path(sessionStorage.getItem('pirBoxBack'));
                    this.EventService.publish('changeOwnership', [], false);
                    this.$scope.$apply();
                }
            };
            LayoutController.prototype.isOwnerDisabled = function () {
                var dossierOwnerList = this.portfolioCoreService['dossierOwnerList'];
                var isOwnerNotActive = !this.isOwnerActive();
                var isDossierOwnerListEmpty = !(dossierOwnerList && dossierOwnerList.length > 0);
                return isOwnerNotActive && isDossierOwnerListEmpty;
            };
            LayoutController.prototype.isCoOwnerDisabled = function () {
                var dossierCoOwnerList = this.portfolioCoreService['dossierCoOwnerList'];
                var isCoOwnerNotActive = !this.isCoOwnerActive();
                var isDossierCoOwnerListEmpty = !(dossierCoOwnerList && dossierCoOwnerList.length > 0);
                return isCoOwnerNotActive && isDossierCoOwnerListEmpty;
            };
            LayoutController.prototype.isOwnerActive = function () {
                return this.coowner === LayoutController.OWNER.toString();
            };
            LayoutController.prototype.isCoOwnerActive = function () {
                return this.coowner === LayoutController.COOWNER.toString();
            };
            LayoutController.prototype.isWebapp = function (status) {
                return webapp.status === status;
            };
            LayoutController.prototype.isSetOption = function (setting) {
                if (typeof setting !== 'undefined' && typeof settings !== 'undefined') {
                    if (setting === 'showHeader' && this.disableHeader) {
                        return false;
                    }
                    else {
                        return settings[setting];
                    }
                }
            };
            LayoutController.prototype.isEmpty = function (ob) {
                for (var i in ob) {
                    return false;
                }
                return true;
            };
            LayoutController.prototype.isTouch = function () {
                return Modernizr.touch;
            };
            LayoutController.prototype.tabSelected = function () {
                var _this = this;
                _this.EventService.publish('tab-changed', [], false);
            };
            LayoutController.prototype.getSignWarning = function () {
                return typeof signWarning !== 'undefined' ? signWarning : false;
            };
            LayoutController.prototype.closeSignWarningAlert = function () {
                signWarning = false;
            };
            LayoutController.prototype.getBannerList = function () {
                var _this = this;
                return _this.LayoutService.bannerList;
            };
            LayoutController.prototype.showWarnMigrationMessage = function () {
                var _this = this;
                var migrationDate = parseInt(_this.LayoutService.dateCustomerMigrated) / 1000;
                if (!migrationDate || !_this.LayoutService.daysWarnMigration || !_this.LayoutService.warnMigrationMessage)
                    return false;
                var days = migrationDate - (Math.floor(Date.now() / 1000));
                var notice = parseInt(_this.LayoutService.daysWarnMigration) * 86400;
                return days < notice;
            };
            LayoutController.prototype.getWarnMigrationMessage = function () {
                var _this = this;
                var message = _this.LayoutService.warnMigrationMessage;
                var operationDate = new Date(parseInt(_this.LayoutService.dateCustomerMigrated));
                var migrationDate = new Date(parseInt(_this.LayoutService.dateCustomerMigrated) + 86400000);
                var printedOperationDate = ("0" + operationDate.getDate()).slice(-2) + "/" + ("0" + (operationDate.getMonth() + 1)).slice(-2) + "/" + operationDate.getFullYear();
                var printedMigrationDate = ("0" + migrationDate.getDate()).slice(-2) + "/" + ("0" + (migrationDate.getMonth() + 1)).slice(-2) + "/" + migrationDate.getFullYear();
                return message.replace('{operationDate}', printedOperationDate).replace('{migrationDate}', printedMigrationDate);
            };
            LayoutController.prototype.iAmInHome = function () {
                var _this = this;
                return (_this.pageId === 'landing' && window.location.href.indexOf('news') === -1) || _this.pageId === '25';
            };
            LayoutController.prototype.overridePageConfiguration = function () {
                var _this = this;
                switch (_this.pageId) {
                    case 'landing':
                    case '25':
                        if (Object.keys(_this.layout).length > 1)
                            _this.layout = landingConfig.config;
                        break;
                    case '9':
                        _this.layout = portfolioPageConfig.config;
                }
            };
            LayoutController.prototype.useHeaderLayoutConf = function () {
                return window.location.href.indexOf('101') > -1 || window.location.href.indexOf('102') > -1;
            };
            LayoutController.OWNER = 'OWNER';
            LayoutController.COOWNER = 'COOWNER';
            return LayoutController;
        })();
        Layout.LayoutController = LayoutController;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

