define('widget-settings-customer/../../scripts/smartAuthenticationManager',["require", "exports"], function (require, exports) {
    exports.PIN_LENGHT = 6;
    exports.USER_CANCELLED_TOUCHID_ERROR_CODE = "108";
    exports.MAX_PIN_ATTEMPTS_ERROR_CODE = "106";
    exports.MAX_PIN_ATTEMPTS = 3;
    exports.ANDROID_FINGERPRINT_UNRECOGNIZED_ERROR_CODE = "104";
    exports.ANDROID_FINGERPRINT_TOO_MANY_ATTEMPTS_ERROR_CODE = "7";
    exports.IOS_TOUCHID_TOO_MANY_ATTEMPTS_ERROR_CODE = "-8";
    exports.ProfileType = {
        "DEFAULT": "DEFAULT",
        "FACEID": "FACEID",
        "TOUCHID": "TOUCHID",
        "PIN": "PIN"
    };
    var AuthenticationOperationResponse = (function () {
        function AuthenticationOperationResponse() {
        }
        return AuthenticationOperationResponse;
    })();
    exports.AuthenticationOperationResponse = AuthenticationOperationResponse;
    var Credential = (function () {
        function Credential() {
        }
        return Credential;
    })();
    exports.Credential = Credential;
    var SaveProfileData = (function () {
        function SaveProfileData() {
        }
        return SaveProfileData;
    })();
    exports.SaveProfileData = SaveProfileData;
    var cordovaErrorCallback = function (errorMessage) {
        sweetAlert("CORDOVA ERROR", errorMessage);
    };
    var SmartAuthenticationManager = (function () {
        function SmartAuthenticationManager() {
            this.AuthenticationPluginName = "AuthenticationPlugin";
        }
        SmartAuthenticationManager.prototype.isCordovaDefined = function () {
            return typeof cordova !== 'undefined' && Modernizr.touch;
        };
        SmartAuthenticationManager.prototype.getActiveProfile = function (success) {
            var _this = this;
            console.log("calling ", this.AuthenticationPluginName + ".getActiveProfile");
            if (!this.isCordovaDefined()) {
                console.log("CORDOVA IS NOT DEFINED: ", this.AuthenticationPluginName + ".getActiveProfile returned success");
                success(null);
                return;
            }
            cordova.exec(function (cordovaResult) {
                console.log(_this.AuthenticationPluginName + ".getActiveProfile returned success");
                success(cordovaResult);
            }, cordovaErrorCallback, this.AuthenticationPluginName, "getActiveProfile", []);
        };
        SmartAuthenticationManager.prototype.canShowSmartAuthenticationViewForUser = function (username, success) {
            var _this = this;
            console.log("calling ", this.AuthenticationPluginName + ".canShowSmartAuthenticationViewForUser");
            if (!this.isCordovaDefined()) {
                console.log("CORDOVA IS NOT DEFINED: ", this.AuthenticationPluginName + ".canShowSmartAuthenticationViewForUser returned success");
                success(false);
                return;
            }
            cordova.exec(function (cordovaResult) {
                console.log(_this.AuthenticationPluginName + ".canShowSmartAuthenticationViewForUser returned success");
                success(cordovaResult);
            }, cordovaErrorCallback, this.AuthenticationPluginName, "canShowSmartAuthenticationViewForUser", [{ "username": username }]);
        };
        SmartAuthenticationManager.prototype.canShowSmartAuthenticationSettingsForUser = function (username, success) {
            var _this = this;
            console.log("calling ", this.AuthenticationPluginName + ".canShowSmartAuthenticationSettingsForUser");
            if (!this.isCordovaDefined()) {
                console.log("CORDOVA IS NOT DEFINED: ", this.AuthenticationPluginName + ".canShowSmartAuthenticationSettingsForUser returned success");
                success(false);
                return;
            }
            cordova.exec(function (cordovaResult) {
                console.log(_this.AuthenticationPluginName + ".canShowSmartAuthenticationSettingsForUser returned success");
                success(cordovaResult);
            }, cordovaErrorCallback, this.AuthenticationPluginName, "canShowSmartAuthenticationSettingsForUser", [{ "username": username }]);
        };
        SmartAuthenticationManager.prototype.getAvailableAuthentications = function (success) {
            var _this = this;
            if (!this.isCordovaDefined()) {
                console.log("CORDOVA IS NOT DEFINED: ", this.AuthenticationPluginName + ".getActiveProfile returned success");
                success([exports.ProfileType.DEFAULT]);
                return;
            }
            console.log("calling ", this.AuthenticationPluginName + ".getAvailableAuthentications");
            cordova.exec(function (cordovaResult) {
                console.log(_this.AuthenticationPluginName + ".getAvailableAuthentications returned success");
                success(cordovaResult.availableAuthentications);
            }, cordovaErrorCallback, this.AuthenticationPluginName, "getAvailableAuthentications", []);
        };
        SmartAuthenticationManager.prototype.saveProfile = function (profileData, success) {
            var _this = this;
            console.log("calling ", this.AuthenticationPluginName + ".saveProfile");
            cordova.exec(function (cordovaResult) {
                console.log(_this.AuthenticationPluginName + ".saveProfile returned success");
                success(cordovaResult);
            }, cordovaErrorCallback, this.AuthenticationPluginName, "saveProfile", [profileData]);
        };
        SmartAuthenticationManager.prototype.updatePassword = function (username, password) {
            var _this = this;
            if (!this.isCordovaDefined()) {
                console.log("CORDOVA IS NOT DEFINED: ", this.AuthenticationPluginName + ".updatePassword returned success");
                return;
            }
            var params = {
                "username": username,
                "password": password
            };
            console.log("calling ", this.AuthenticationPluginName + ".authenticateWithPin");
            cordova.exec(function (cordovaResult) {
                console.log(_this.AuthenticationPluginName + ".authenticateWithTouchId returned success");
            }, cordovaErrorCallback, this.AuthenticationPluginName, "updatePassword", [params]);
        };
        SmartAuthenticationManager.prototype.authenticateWithPin = function (pin, success) {
            var _this = this;
            console.log("calling ", this.AuthenticationPluginName + ".authenticateWithPin");
            cordova.exec(function (cordovaResult) {
                console.log(_this.AuthenticationPluginName + ".authenticateWithPin returned success");
                success(cordovaResult, cordovaResult.credentials);
            }, cordovaErrorCallback, this.AuthenticationPluginName, "authenticateWithPin", [{ "pin": "" + pin + "" }]);
        };
        SmartAuthenticationManager.prototype.authenticateWithBiometricId = function (authTypeParam, success) {
            var _this = this;
            console.log("calling ", "" + this.AuthenticationPluginName + "." + authTypeParam);
            cordova.exec(function (cordovaResult) {
                console.log("" + _this.AuthenticationPluginName + "." + authTypeParam + " returned success");
                success(cordovaResult, cordovaResult.credentials);
            }, cordovaErrorCallback, this.AuthenticationPluginName, authTypeParam, []);
        };
        return SmartAuthenticationManager;
    })();
    exports.SmartAuthenticationManager = SmartAuthenticationManager;
});

