define('layout-base/../../bower_components/dwx-utility/src/filters/trustAs',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var TrustAsHtmlFilter = (function () {
            function TrustAsHtmlFilter($sce) {
                this.$sce = $sce;
            }
            TrustAsHtmlFilter.Factory = function ($sce) {
                var instance = new TrustAsHtmlFilter($sce);
                instance.myFilter = instance.myFilter.bind(instance);
                return instance.myFilter;
            };
            TrustAsHtmlFilter.prototype.myFilter = function (input) {
                return this.$sce.trustAsHtml(input);
            };
            return TrustAsHtmlFilter;
        })();
        Utility.TrustAsHtmlFilter = TrustAsHtmlFilter;
        var TrustAsResourceUriFilter = (function () {
            function TrustAsResourceUriFilter($sce) {
                this.$sce = $sce;
            }
            TrustAsResourceUriFilter.Factory = function ($sce) {
                var instance = new TrustAsResourceUriFilter($sce);
                instance.myFilter = instance.myFilter.bind(instance);
                return instance.myFilter;
            };
            TrustAsResourceUriFilter.prototype.myFilter = function (input) {
                return this.$sce.trustAsResourceUrl(input);
            };
            return TrustAsResourceUriFilter;
        })();
        Utility.TrustAsResourceUriFilter = TrustAsResourceUriFilter;
        var TrustAsDelegateFilter = (function () {
            function TrustAsDelegateFilter($sce, $sceDelegate) {
                this.$sce = $sce;
                this.$sceDelegate = $sceDelegate;
            }
            TrustAsDelegateFilter.Factory = function ($sce, $sceDelegate) {
                var instance = new TrustAsDelegateFilter($sce, $sceDelegate);
                instance.myFilter = instance.myFilter.bind(instance);
                return instance.myFilter;
            };
            TrustAsDelegateFilter.prototype.myFilter = function (input) {
                return this.$sceDelegate.trustAs(this.$sce.RESOURCE_URL, input);
            };
            return TrustAsDelegateFilter;
        })();
        Utility.TrustAsDelegateFilter = TrustAsDelegateFilter;
        var TrustAsResourceUrlFilter = (function () {
            function TrustAsResourceUrlFilter($sce) {
                this.$sce = $sce;
            }
            TrustAsResourceUrlFilter.Factory = function ($sce) {
                var instance = new TrustAsResourceUrlFilter($sce);
                instance.myFilter = instance.myFilter.bind(instance);
                return instance.myFilter;
            };
            TrustAsResourceUrlFilter.prototype.myFilter = function (input) {
                return this.$sce.trustAsResourceUrl(input);
            };
            return TrustAsResourceUrlFilter;
        })();
        Utility.TrustAsResourceUrlFilter = TrustAsResourceUrlFilter;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

