define('widget-focus/../../project_components/dwx-widget-focus/src/app',["require", "exports", "./controllers/focusCtrl", "./directives/focusDirective", "./config"], function (require, exports, focusController, focusDirective, focusConfig) {
    exports.configuration = {
        list: {
            "focusCtrl": {
                callback: ["$scope", "initConfigService", "EventService", "$location", "configurationService", "profileService", focusController.FocusController],
                type: "controller"
            },
            "dwxWidgetFocus": { callback: [focusDirective.FocusDirective], type: "directive" },
            "focusConfig": { callback: [focusConfig.FocusConfig], type: "config" }
        },
        ngModules: ['ngResource']
    };
});

