define('../bower_components/dwx-widget-my-documents/src/directives/myDocumentsDirective',["require", "exports"], function (require, exports) {
    exports.basePath = 'bower_components/widget-my-documents/dist/src/views/partials/';
    var MyDocumentsDirective = (function () {
        function MyDocumentsDirective(myDocumentsService) {
            this.myDocumentsService = myDocumentsService;
            this.replace = false;
            this.scope = {
                identifier: '=',
                widgetType: '@',
            };
            this.templateUrl = exports.basePath + 'main.html';
            var _this = this;
            _this.compile = function (element, attributes) {
                return {
                    pre: function (scope, element, attributes, controller, transcludeFn) {
                        scope.widget = { status: { mode: "loading" } };
                        _this.myDocumentsService.widgetType = scope.widgetType;
                    },
                };
            };
        }
        MyDocumentsDirective.Factory = function () {
            var directive = function (myDocumentsService) {
                return new MyDocumentsDirective(myDocumentsService);
            };
            directive['$inject'] = ['myDocumentsService'];
            return directive;
        };
        return MyDocumentsDirective;
    })();
    exports.MyDocumentsDirective = MyDocumentsDirective;
});

