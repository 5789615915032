define('widget-settings-customer/../../bower_components/dwx-widget-settings-customer/src/app',["require", "exports", './directives/settingsDirective', './controllers/settingsCtrl', './directives/settingsDirective', './services/settingsService'], function (require, exports, directive, settingsController, settingsDirective, settingsService) {
    exports.configuration = {
        list: {
            'SettingsCtrl': { callback: ["$scope", "$rootScope", "$attrs", "settingsService", "$timeout", "$http", "initConfigService", "errorsHandlerService", settingsController.SettingsCtrl], type: 'controller' },
            'dwxWidgetSettings': { callback: [settingsDirective.WidgetSettings.SettingsDirective.Factory()], type: 'directive' },
            'settingsService': { callback: ["$resource", settingsService.SettingsService], type: 'service' },
            'pinCheckModal': { callback: [directive.WidgetSettings.PinChangePopupDirective.Factory()], type: "directive" }
        },
        ngModules: []
    };
});

