define('../bower_components/dwx-widget-portfolio-performance-azimut/src/controllers/portfolioPerformanceAzimutController',["require", "exports", '../config/configuration'], function (require, exports, performanceConfig) {
    var PortfolioPerformanceAzimutController = (function () {
        function PortfolioPerformanceAzimutController($scope, $window, initConfigService, paginationService, $filter, EventService, $http, $translatePartialLoaderProvider, $translate, portfolioCoreService, profileService) {
            var _this = this;
            this.$scope = $scope;
            this.$window = $window;
            this.$filter = $filter;
            this.EventService = EventService;
            this.$http = $http;
            this.$translate = $translate;
            this.portfolioCoreService = portfolioCoreService;
            this.profileService = profileService;
            this.rowOpened = [];
            this.reverseOrdering = {};
            this.categoryError = false;
            this.startDate = '';
            this.endDate = '';
            this.updateButtonDisabled = true;
            this.showAnomaliaDisclaimer = false;
            this.isTouch = function () {
                return typeof cordova !== 'undefined' && Modernizr.touch;
            };
            this.isMobileBrowser = function () {
                return Modernizr.touch;
            };
            this.isAndroid = function () {
                return md.os() == "AndroidOS";
            };
            this.widgetId = $scope.identifier || (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetPortfolioPerformanceAzimut';
            this.widgetConfig = initConfigService[this.widgetId];
            $translatePartialLoaderProvider.addPart('bower_components/dwx-widget-portfolio-performance-azimut');
            if (this.widgetConfig.status.mode !== 'locked') {
                this.configuration = performanceConfig.config;
                this.categories = this.configuration.categories;
                this.categoryIndex = this.configuration.defaultCategory;
                this.timeRangeList = this.configuration.timeRangeList;
                this.tableConfiguration = this.configuration.fields;
                this.widgetConfig.status.mode = 'on';
                var handleDossierSelectedReponseError = EventService.subscribe('dossierSelectedResponseError', function (message) {
                    _this.widgetConfig.status.mode = 'error';
                    _this.widgetConfig.status.message = message;
                });
                var handleDossierSelectedHttpReponseError = EventService.subscribe('dossierSelectedResponseError', function (message) {
                    _this.widgetConfig.status.mode = 'error';
                    _this.widgetConfig.status.message = "dashboard.error.connection_error";
                });
                var handleDossierSelected = EventService.subscribe('dossierSelected', function () {
                    _this.refreshPerformanceAllocation(_this.categoryIndex, _this.configuration.defaultTimeRange, '', '');
                });
                var handleRefreshPortfolioWithDossier = EventService.subscribe('refreshPortfolioWithDossier', function () {
                    _this.refreshPerformanceAllocation(_this.categoryIndex, _this.configuration.defaultTimeRange, '', '');
                });
                if (this.portfolioCoreService['dossierList'] !== void 0) {
                    this.refreshPerformanceAllocation(this.categoryIndex, this.configuration.defaultTimeRange, '', '');
                }
                $scope.$on("$destroy", function () {
                    _this.EventService.unsubscribe(handleDossierSelected, false);
                    _this.EventService.unsubscribe(handleRefreshPortfolioWithDossier, false);
                    _this.EventService.unsubscribe(handleDossierSelectedReponseError, false);
                    _this.EventService.unsubscribe(handleDossierSelectedHttpReponseError, false);
                });
            }
        }
        PortfolioPerformanceAzimutController.prototype.showDateFilter = function () {
            return this.timeRangeIndex == 'PERF_INTERVAL';
        };
        PortfolioPerformanceAzimutController.prototype.isUpdateButtonEnaled = function () {
            if (this.startDate != undefined && this.endDate != undefined && this.startDate.toString().trim() != '' && this.endDate.toString().trim() != '') {
                var todayDate = new Date();
                var startUTCDate = new Date(this.startDate);
                var endUTCDate = new Date(this.endDate);
                if (startUTCDate <= todayDate && endUTCDate >= startUTCDate) {
                    return true;
                }
                else {
                    return false;
                }
            }
        };
        PortfolioPerformanceAzimutController.prototype.refreshPerformanceAllocation = function (category, timeRange, startDate, endDate) {
            var _this = this;
            this.widgetConfig.status.mode = 'loading';
            this.reverseOrdering = {};
            this.indexColumnField = undefined;
            if (startDate != '' && startDate != null)
                startDate = this.$filter('date')(startDate, 'dd/MM/yyyy');
            if (endDate != '' && endDate != null)
                endDate = this.$filter('date')(endDate, 'dd/MM/yyyy');
            if (startDate == null && endDate == null) {
                startDate = "";
                endDate = "";
                timeRange = this.configuration.defaultTimeRange;
            }
            this.portfolioCoreService.getPortfolioDisclaimer('myAzimutPerformance').then(function (resource) {
                _this.disclaimers = resource;
            }).finally(function () {
                _this.portfolioCoreService.performanceByDossier.save({
                    category: category,
                    interval: timeRange,
                    startDate: startDate,
                    endDate: endDate,
                    firstHolder: !sessionStorage.getItem("coowner")
                }, _this.portfolioCoreService['dossierList'], function (response) {
                    if (response.outcome === 'OK') {
                        _this.categoryError = false;
                        _this.showAnomaliaDisclaimer = false;
                        _this.performanceAllocation = response.content;
                        response.content.performances.forEach(function (item, index) {
                            if (item.flgHidePerformance === 1 || item.flgHidePerformance === true) {
                                _this.performanceAllocation.performances[index].plusMinus = 'N/D';
                                _this.performanceAllocation.performances[index].averageCapital = 'N/D';
                                _this.performanceAllocation.performances[index].performance = 'N/D';
                            }
                            if (item.anomaliaCod === 'NOT_CLOSEDOUT_POS') {
                                _this.showAnomaliaDisclaimer = true;
                                _this.performanceAllocation.performances[index].productName = "" + item.productName + " **";
                            }
                        });
                        if (!!_this.timeRangeIndex)
                            _this.calculateNavDisclaimer();
                        _this.hideDataOnFirstLoad();
                        _this.widgetConfig.status.mode = 'on';
                        _this.$scope.$evalAsync(function () {
                            if (_this.$translate != undefined) {
                                _this.$translate.refresh();
                            }
                        });
                    }
                    else {
                        _this.categoryError = true;
                        _this.widgetConfig.status.mode = 'on';
                        _this.widgetConfig.status.message = response.message;
                    }
                }, function (error) {
                    _this.widgetConfig.status.mode = 'error';
                    _this.widgetConfig.status.message = "dashboard.error.connection_error";
                    _this.EventService.publish('http-response-error', [error]);
                });
            });
        };
        PortfolioPerformanceAzimutController.prototype.changeTabFilter = function (category) {
            this.categoryIndex = category;
            this.startDate = "";
            this.endDate = "";
            this.timeRangeIndex = undefined;
            this.refreshPerformanceAllocation(category, this.configuration.defaultTimeRange, null, null);
        };
        PortfolioPerformanceAzimutController.prototype.changeTimeRange = function (timeRange, startDate, endDate) {
            if (startDate && !endDate) {
                endDate = startDate;
                this.endDate = startDate;
            }
            else if (!startDate && endDate) {
                startDate = endDate;
                this.startDate = endDate;
            }
            else if (startDate > endDate) {
                startDate = endDate;
                this.startDate = endDate;
            }
            else {
                this.timeRangeIndex = timeRange;
                this.refreshPerformanceAllocation(this.categoryIndex, timeRange, startDate, endDate);
            }
        };
        PortfolioPerformanceAzimutController.prototype.changeOrder = function (field, index) {
            this.indexColumnField = index;
            if (this.reverseOrdering[field] != undefined) {
                this.reverseOrdering[field] = !this.reverseOrdering[field];
            }
            else {
                this.reverseOrdering[field] = false;
            }
            this.performanceAllocation['performances'] = this.$filter('orderBy')(this.performanceAllocation['performances'], field, this.reverseOrdering[field]);
        };
        PortfolioPerformanceAzimutController.prototype.exportPortfolioPerformance = function (fileType, event) {
            var _this = this;
            event.stopPropagation();
            var params;
            var startDate, endDate;
            startDate = this.$filter('date')(this.startDate, 'dd/MM/yyyy');
            endDate = this.$filter('date')(this.endDate, 'dd/MM/yyyy');
            params = fileType + '?category=' + this.categoryIndex + '&interval=' + this.timeRangeIndex + "&startDate=" + startDate + "&endDate=" + endDate + "&firstHolder=" + !sessionStorage.getItem('coowner');
            var ndgListParam = '';
            this.portfolioCoreService['dossierList'].forEach(function (dossier) {
                if (dossier.ndg !== _this.profileService.loggedUser.userInfos.USER_CODE.value) {
                    if (dossier.checked) {
                        var contractParam = '';
                        ndgListParam += 'dossier=' + dossier.ndg + '_';
                        dossier.contractElements.forEach(function (contract) {
                            if (contract.checked) {
                                contractParam += contract.code + ';';
                            }
                        });
                        if (!contractParam.length) {
                            ndgListParam = ndgListParam.slice(0, ndgListParam.length - 1);
                        }
                        ndgListParam += contractParam.slice(0, contractParam.length - 1) + '&';
                    }
                }
            });
            var fileURL = "" + window.location.protocol + "//" + window.location.host + apiPrefix + "/portfolio/performance-allocations/exp/" + params + "&" + ndgListParam.slice(0, ndgListParam.length - 1);
            if (!this.isTouch()) {
                window.open(fileURL, '_blank');
            }
            else {
                showFile(fileURL, null, null, null);
            }
        };
        PortfolioPerformanceAzimutController.prototype.hideDataOnFirstLoad = function () {
            var _this = this;
            if (this.timeRangeIndex) {
                this.performanceAllocation.performances.forEach(function (performance) {
                    Object.keys(performance).forEach(function (field) {
                        if (_this.tableConfiguration.indexOf(field) > -1 && field !== 'productName' && field !== 'productType' && field !== 'contractCode') {
                            performance[field] = performance[field] === null ? 'N/D' : performance[field];
                        }
                    });
                });
                this.performanceAllocation.totals[0].totalValue = this.performanceAllocation.totals[0].totalValue === null ? 'N/D' : this.performanceAllocation.totals[0].totalValue;
                this.performanceAllocation.totals[0].performance = this.performanceAllocation.totals[0].performance === null ? 'N/D' : this.performanceAllocation.totals[0].performance;
                return;
            }
            this.performanceAllocation.performances.forEach(function (performance) {
                Object.keys(performance).forEach(function (field) {
                    if (_this.tableConfiguration.indexOf(field) > -1 && field !== 'productName' && field !== 'productType' && field !== 'contractCode') {
                        performance[field] = 'N/D';
                    }
                });
            });
            this.performanceAllocation.totals[0].totalValue = 'N/D';
            this.performanceAllocation.totals[0].performance = 'N/D';
        };
        PortfolioPerformanceAzimutController.prototype.calculateNavDisclaimer = function () {
            this.productNavList = this.performanceAllocation.performances.filter(function (product) {
                return !product.productWithNav;
            }).map(function (product) { return product.productName; });
        };
        PortfolioPerformanceAzimutController.prototype.showNavDisclaimer = function () {
            return this.categoryIndex === 'ECONOMIAREALE' && !!this.timeRangeIndex && !!this.productNavList && this.productNavList.length > 0;
        };
        PortfolioPerformanceAzimutController.prototype.getNavDisclaimer = function () {
            var disclaimerIndex = '(1)';
            var disclaimer = "" + disclaimerIndex + " " + this.disclaimers[0].items.filter(function (item) { return item.linkId === 'allNoNav'; })[0].text;
            if (!!this.performanceAllocation && this.productNavList.length < this.performanceAllocation.performances.length) {
                disclaimer = "" + disclaimerIndex + " " + this.disclaimers[0].items.filter(function (item) { return item.linkId === 'underThreeNoNav'; })[0].text + "</br>";
                if (this.productNavList.length < 4) {
                    this.productNavList.forEach(function (productName) {
                        disclaimer += "<li>" + productName + "</li>";
                    });
                }
                else {
                    disclaimer = "" + disclaimerIndex + " " + this.disclaimers[0].items.filter(function (item) { return item.linkId === 'overThreeNoNav'; })[0].text;
                }
            }
            return disclaimer;
        };
        PortfolioPerformanceAzimutController.prototype.getMergedFundDisclaimerIndex = function (allocation, field) {
            var disclaimerIndex = null;
            if (this.timeRangeIndex && field === 'productName' && (allocation.hasListMergedPositions || allocation.isAMergedPosition)) {
                disclaimerIndex = this.showNavDisclaimer() ? '2' : '1';
            }
            return disclaimerIndex;
        };
        PortfolioPerformanceAzimutController.prototype.showMergedFundsDisclaimer = function () {
            return this.timeRangeIndex && this.performanceAllocation && this.performanceAllocation.performances.filter(function (product) {
                return product.hasListMergedPositions || product.isAMergedPosition;
            }).length > 0;
        };
        PortfolioPerformanceAzimutController.prototype.getGarantitoDisclaimerIndex = function (allocation, field) {
            var disclaimerIndex = null;
            if (field === 'productName' && (allocation.productCode === '3411E' || allocation.productCode === '3412E')) {
                if (this.showNavDisclaimer() && this.showMergedFundsDisclaimer()) {
                    disclaimerIndex = '3';
                }
                else {
                    if (!this.showNavDisclaimer() && !this.showMergedFundsDisclaimer()) {
                        disclaimerIndex = '1';
                    }
                    else {
                        disclaimerIndex = '2';
                    }
                }
            }
            return disclaimerIndex;
        };
        PortfolioPerformanceAzimutController.prototype.showGarantitoDisclaimer = function () {
            return !!this.performanceAllocation && this.performanceAllocation.performances.filter(function (item) {
                return item.productCode === '3411E' || item.productCode === '3412E';
            }).length > 0;
        };
        PortfolioPerformanceAzimutController.prototype.getGarantitoDisclaimer = function () {
            if (this.showNavDisclaimer() && this.showMergedFundsDisclaimer()) {
                return '(3) Quote trasferite dal comparto garantito al comparto obbligazionario di Azimut Previdenza';
            }
            else {
                if (!this.showNavDisclaimer() && !this.showMergedFundsDisclaimer()) {
                    return '(1) Quote trasferite dal comparto garantito al comparto obbligazionario di Azimut Previdenza';
                }
                else {
                    return '(2) Quote trasferite dal comparto garantito al comparto obbligazionario di Azimut Previdenza';
                }
            }
        };
        PortfolioPerformanceAzimutController.prototype.getDisclaimer = function (linkId) {
            var disclaimer = this.disclaimers[0].items.find(function (disclaimer) {
                return disclaimer.linkId === linkId;
            });
            return !!disclaimer ? "(" + (this.showNavDisclaimer() ? '2' : '1') + ") " + disclaimer.text : '';
        };
        return PortfolioPerformanceAzimutController;
    })();
    exports.PortfolioPerformanceAzimutController = PortfolioPerformanceAzimutController;
});

