define('widget-my-documents',["require", "exports", '../bower_components/dwx-widget-my-documents/src/app', '../bower_components/dwx-widget-my-documents/src/directives/myDocumentsDirective', '../bower_components/dwx-widget-my-documents/src/controllers/myDocumentsCtrl'], function (require, exports, myDocuments, myDocumentsDirective, myDocumentsCtrl) {
    myDocumentsDirective.basePath = 'widgets/my-documents/';
    myDocumentsCtrl.changeOrderByFilter = function (indexFilter, isGridView, categoryName, fieldName, excludeReverse) {
        var result;
        var _this = this;
        _this.indexOrderByFilter = indexFilter;
        var reverse;
        if (typeof fieldName == "object") {
            reverse = fieldName.ascending;
            fieldName = fieldName.title;
            if (_this.reverseOrder[categoryName] == void 0) {
                _this.reverseOrder[categoryName] = {};
            }
            _this.reverseOrder[categoryName][fieldName] = reverse;
        }
        var orderBy;
        var orderByListIndex = _this.myDocumentsConfigService.myDocConfig.orderByListIndex[_this.indexOrderByFilter];
        if (typeof (orderByListIndex) == 'object') {
            orderBy = orderByListIndex.title;
            reverse = !orderByListIndex.ascending;
        }
        else {
            orderBy = orderByListIndex;
        }
        var documentCategory = _this.myDocumentsConfigService.myDocConfig.documentCategories[categoryName];
        if (!isGridView) {
            if (categoryName !== void 0 && (categoryName !== '' && categoryName !== 'unread')) {
                if (typeof (_this.paginationForTabView[categoryName]) !== 'undefined') {
                    _this.paginationForTabView[categoryName].changePage(1);
                }
                var fields = null;
                if (excludeReverse) {
                    fields = [orderBy];
                }
                else {
                    fields = documentCategory.listViewConf.listViewData[indexFilter].fieldsList[1];
                }
                if (_this.reverseOrder[categoryName] !== void 0 && _this.reverseOrder[categoryName][fieldName] !== void 0) {
                    _this.reverseOrder[categoryName][fieldName] = !_this.reverseOrder[categoryName][fieldName];
                }
                else {
                    _this.reverseOrder[categoryName] = {};
                    _this.reverseOrder[categoryName][fieldName] = false;
                }
                if (documentCategory.fieldsInAttributes.indexOf(fields[0]) > -1) {
                    if (fieldName == 'practiceId') {
                        _this.filteredDocumentsForTableView[categoryName] = _this.$filter('dwxMyDocumentsSortFieldsInAttributes')(_this.filteredDocumentsForTableView[categoryName], [orderBy, 'practiceId'], _this.reverseOrder[categoryName][fieldName]);
                    }
                    else {
                        _this.filteredDocumentsForTableView[categoryName] = _this.$filter('dwxMyDocumentsSortFieldsInAttributes')(_this.filteredDocumentsForTableView[categoryName], fields, _this.reverseOrder[categoryName][fieldName]);
                    }
                }
                else {
                    _this.filteredDocumentsForTableView[categoryName] = _this.$filter('orderBy')(_this.filteredDocumentsForTableView[categoryName], fields, _this.reverseOrder[categoryName][fieldName]);
                }
            }
        }
        else {
            fieldName = (fieldName) ? fieldName : orderBy;
            var documentCategoryIndex = _this.myDocumentsConfigService.myDocConfig.documentCategoryIndex;
            var documentCategorySelected = _this.myDocumentsConfigService.myDocConfig.documentCategories[documentCategoryIndex];
            if ((documentCategoryIndex == 'disposition' || documentCategoryIndex == "signRoom") && orderBy == 'totalAmount') {
                _this.filteredDocuments = _this.$filter('dwxMyDocumentsSortFieldsInAttributes')(_this.filteredDocuments, [orderBy], true);
            }
            else {
                (_this.paginationForGridView[documentCategoryIndex]) ? _this.paginationForGridView[documentCategoryIndex].changePage(1) : '';
                if (documentCategoryIndex != '' && documentCategoryIndex != 'unread' && !_.isUndefined(documentCategoryIndex) && documentCategory.fieldsInAttributes.indexOf(orderBy) > -1) {
                    if (documentCategory.fieldsInAttributes.indexOf(orderBy) > -1) {
                        _this.filteredDocuments = _this.$filter('dwxMyDocumentsSortFieldsInAttributes')(_this.filteredDocuments, [orderBy], reverse);
                    }
                }
                else {
                    if (_this.reverseOrder[categoryName] !== void 0 && _this.reverseOrder[categoryName][fieldName] !== void 0) {
                        _this.filteredDocuments = _this.$filter('orderBy')(_this.filteredDocuments, orderBy, !_this.reverseOrder[categoryName][fieldName]);
                        _this.reverseOrder[categoryName][fieldName] = {};
                    }
                    else if (reverse != undefined) {
                        _this.filteredDocuments = _this.$filter('orderBy')(_this.filteredDocuments, orderBy, reverse);
                        reverse = null;
                    }
                    else {
                        _this.filteredDocuments = _this.$filter('orderBy')(_this.filteredDocuments, orderBy);
                    }
                }
            }
        }
    };
    myDocumentsCtrl.showDetails = function (document) {
        var _this = this;
        if (typeof document.products === 'string')
            document.products = JSON.parse(document.products);
        if (typeof document.documentUsers === 'string')
            document.documentUsers = JSON.parse(document.documentUsers);
        _this.documentDetails = document;
    };
    function changeTabFilter(tabTitle) {
        if (tabTitle !== void 0 && typeof tabTitle !== void 0 && tabTitle !== null && tabTitle != 'unread') {
            this.documentCategoryIndex = tabTitle;
        }
        else {
            this.documentCategoryIndex = '';
        }
        if (this.myDocConfig.remotePagination) {
            this.viewType = this.myDocConfig.defaultView;
            this.documentCategoryIndex = tabTitle;
            this.genericDocumentsPaginatedServiceParams.setSort(null);
        }
        else {
            var _this = this;
            _this.resetPagination();
            _this.isGroupedBy = false;
            var category = _this.documentCategories[_this.documentCategoryIndex];
            var defaultGrouping = (category && category.defaultGrouping) ? category.defaultGrouping : undefined;
            _this.groupByDocumentLabel = (defaultGrouping != undefined && defaultGrouping) ? defaultGrouping : "document";
            _this.changeGroup(_this.groupByDocumentLabel);
            _this.setOrderByList();
            _this.indexOrderByFilter = 0;
            var isGridView = (this.viewType) ? this.viewType.toLowerCase() == 'grid' : true;
            if (_this.documentCategoryIndex != 'unread') {
                _this.filteredDocuments = _this.$filter('filter')(_this.documentList, { topic: _this.documentCategoryIndex });
                _this.changeOrderByFilter(_this.indexOrderByFilter, isGridView, _this.documentCategoryIndex, _this.orderByListIndex[_this.indexOrderByFilter], true);
            }
            else {
                var tag = ['unread'];
                _this.myDocumentsService.getGenericDocuments.get({
                    docCategories: _this.categories,
                    docTags: tag,
                    widgetId: this.widgetId
                }, function (response) {
                    if (response.outcome === 'OK') {
                        _this.filteredDocuments = response.content;
                        _this.changeOrderByFilter(_this.indexOrderByFilter, isGridView, _this.documentCategoryIndex, _this.orderByListIndex[_this.indexOrderByFilter], true);
                        _this.setWidgetReady();
                    }
                    else {
                        _this.widgetConfig.status.message = response.message;
                        _this.EventService.publish('http-response-error', [response.messageKey]);
                        _this.widgetConfig.status.mode = 'error';
                    }
                }, function (error) {
                    _this.widgetConfig.status.mode = 'error';
                    _this.widgetConfig.status.message = "dashboard.error.connection_error";
                    _this.EventService.publish('http-response-error', [error]);
                });
            }
        }
    }
    exports.configuration = myDocuments.configuration;
});

