define('../bower_components/dwx-engine/src/config',["require", "exports"], function (require, exports) {
    var Engine;
    (function (Engine) {
        var EngineConfiguration = (function () {
            function EngineConfiguration($translateProvider, tmhDynamicLocaleProvider, $compileProvider, $httpProvider, $routeProvider) {
                this.sessionErrorList = ["session_expired", "user_not_authenticated"];
                $translateProvider.useStaticFilesLoader({
                    prefix: apiPrefix + "/l10n/messages/",
                    suffix: ''
                });
                tmhDynamicLocaleProvider.localeLocationPattern('bower_components/angular-i18n/angular-locale_{{locale}}.js');
                $compileProvider.debugInfoEnabled = false;
                if (!$httpProvider.defaults.headers.get) {
                    $httpProvider.defaults.headers.get = {};
                }
                $httpProvider.defaults.xsrfHeaderName = "X-CSRF-TOKEN";
                if (location.hash.indexOf("dwxua") > -1) {
                    $httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
                    $httpProvider.defaults.headers.get['Pragma'] = 'no-cache';
                    $httpProvider.defaults.headers.common['DwxUa'] = 'dwx_windows_smartphone';
                }
                $httpProvider.interceptors.push(function ($q) {
                    return {
                        'request': function (config) {
                            if (location.href.indexOf('access_token') !== -1) {
                                var queryToken = (config.url.indexOf("?") != -1) ? "&" : "?";
                                config.url = config.url + queryToken + location.href.substring(location.href.indexOf('access_token'));
                                console.log(config.url);
                            }
                            return config;
                        },
                        'response': function (response) {
                            if (this.sessionErrorList.indexOf(response.messageKey) >= 0 || this.sessionErrorList.indexOf(response.data.messageKey) >= 0) {
                                this.EventService.publish('user-session-changed');
                                this.EventService.publish('redirect-to-home');
                            }
                            return response;
                        }
                    };
                });
                var pageSessionId = new Date().getTime();
                var prevPage;
                $httpProvider.interceptors.push(function ($q, $location) {
                    return {
                        'request': function (config) {
                            if (prevPage != $location.path()) {
                                pageSessionId = new Date().getTime();
                                prevPage = $location.path();
                            }
                            config.headers.pageSessionId = pageSessionId;
                            config.headers.currentPage = prevPage;
                            return config;
                        }
                    };
                });
                $routeProvider.when('/', {
                    templateUrl: 'views/main-ng-view.html',
                    label: 'dashboard.breadcrumb.home',
                    menu: 'home',
                    controller: 'LayoutCtrl',
                    controllerAs: 'layoutCtrl',
                    access: {
                        allowAnonymous: false
                    },
                    reloadOnSearch: false
                }).when('/page/:pageId', {
                    templateUrl: 'views/main-ng-view.html',
                    label: 'dashboard.breadcrumb.home',
                    menu: 'page',
                    controller: 'LayoutCtrl',
                    controllerAs: 'layoutCtrl',
                    access: {
                        allowAnonymous: false
                    },
                    reloadOnSearch: false
                }).when('/news', {
                    templateUrl: 'views/news-list-view.html',
                    label: 'dashboard.breadcrumb.newsList',
                    menu: 'page',
                    controller: 'LayoutCtrl',
                    controllerAs: 'layoutCtrl',
                    access: {
                        allowAnonymous: false
                    },
                    reloadOnSearch: false
                }).when('/focus', {
                    templateUrl: 'views/focus-view.html',
                    label: 'dashboard.breadcrumb.focus',
                    menu: 'page',
                    controller: 'LayoutCtrl',
                    controllerAs: 'layoutCtrl',
                    access: {
                        allowAnonymous: false
                    },
                    reloadOnSearch: false
                }).when('/pirbox', {
                    templateUrl: 'views/pirbox-view.html',
                    label: 'dashboard.breadcrumb.pirbox',
                    menu: 'page',
                    controller: 'LayoutCtrl',
                    controllerAs: 'layoutCtrl',
                    access: {
                        allowAnonymous: false
                    },
                    reloadOnSearch: false
                }).when('/unlock', {
                    templateUrl: 'views/unlock-view.html',
                    label: 'dashboard.breadcrumb.unlock',
                    menu: 'page',
                    controller: 'LayoutCtrl',
                    controllerAs: 'layoutCtrl',
                    access: {
                        allowAnonymous: true
                    },
                    reloadOnSearch: false
                }).when('/login', {
                    templateUrl: 'views/login-view.html',
                    label: 'dashboard.breadcrumb.unlock',
                    menu: 'page',
                    controller: 'LayoutCtrl',
                    controllerAs: 'layoutCtrl',
                    access: {
                        allowAnonymous: true
                    },
                    reloadOnSearch: false
                }).otherwise({
                    redirectTo: '/'
                });
            }
            return EngineConfiguration;
        })();
        Engine.EngineConfiguration = EngineConfiguration;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

