define('../bower_components/dwx-widget-first-access/src/controllers/firstAccessCtrl',["require", "exports"], function (require, exports) {
    var FirstAccessCtrl = (function () {
        function FirstAccessCtrl($scope, initConfigService, firstAccessService, profileService, $cookieStore, EventService, $translatePartialLoaderProvider, $translate, $location) {
            var _this = this;
            this.$scope = $scope;
            this.firstAccessService = firstAccessService;
            this.profileService = profileService;
            this.$cookieStore = $cookieStore;
            this.EventService = EventService;
            this.$translatePartialLoaderProvider = $translatePartialLoaderProvider;
            this.$translate = $translate;
            this.$location = $location;
            this.promotionAcceptance = undefined;
            this.notAccepted = false;
            this.acceptDisclaimer = true;
            this.promotionNotAccepted = false;
            this.waiting = false;
            this.processName = 'firstAccess';
            this.initLabel = function () {
                _this.titleForEmailForm = 'Inserimento email';
                _this.stringOnTopForEmailForm = 'Confermare o inserire l’email che desideri associare a MyAzimut';
                _this.stringOnTopForEmailFormToCheck = 'Email inserita';
                _this.invalidMessageForEmailForm = 'Email non corretta. Si prega di controllare';
                _this.checkEmailTitle = 'Inserisci il codice che è stato inviato all\'indirizzo e-mail scelto.\n' + 'In caso di mancata ricezione verifica nella cartella spam. Se non hai ricevuto l\'e-mail,' + ' contatta il tuo Consulente Finanziario';
                _this.patternEmail = {
                    pattern: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|tax|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
                };
                _this.titleForPhoneNumberForm = 'Inserimento numero di cellulare';
                _this.stringOnTopForPhoneNumberForm = 'Inserire il numero di cellulare che desideri associare a MyAzimut';
                _this.stringOnTopForPhoneNumberFormToCheck = 'Numero di cellulare inserito';
                _this.invalidMessageForPhoneNumberForm = 'Numero di cellulare non corretto. Si prega di inserire il numero nel formato internazionale. +39xxxxxxxxx';
                _this.checkPhoneNumberlTitle = 'Inserisci il codice che è stato inviato al numero di telefono scelto.';
                _this.patternPhoneNumber = '^(([\\+])[0-9]{1,4})[0-9]{7,}$';
            };
            this.init = function () {
                _this.firstAccessService.getStatus.get({ processName: _this.processName }, function (response) {
                    _this.checkResponse(response, _this.initCallBack);
                });
                _this.$scope.$evalAsync(function () {
                    if (_this.$translate != undefined) {
                        _this.$translate.refresh();
                    }
                });
            };
            this.initCallBack = function (content) {
                if (content) {
                    _this.setStatus(content.stepCode, content.stepId);
                    _this.setUserData(content.additionalInfo);
                    _this.setCheckMessageError(content.additionalInfo._remainingRetry);
                }
                else {
                    _this.setStatus(null, null);
                }
            };
            this.startProcess = function () {
                _this.setWaiting(true);
                _this.firstAccessService.startProcess.save({ processName: _this.processName }, function (response) {
                    _this.checkResponse(response, _this.startProcessCallBack);
                });
            };
            this.submit = function (body) {
                _this.setWaiting(true);
                var bodyToSend = {};
                if (body) {
                    bodyToSend = body;
                }
                _this.firstAccessService.submit.save({
                    processName: _this.processName,
                    stepCode: _this.firstAccessStatus,
                    stepId: _this.firstAccessStepIdStatus
                }, bodyToSend, function (response) {
                    _this.checkResponse(response, _this.submitCallBack);
                }, function (error) {
                    _this.firstAccessStatus = 'ERROR';
                    _this.statusMessageError = error;
                    _this.setWaiting(false);
                });
            };
            this.startProcessCallBack = function (content) {
                _this.setStatus(content.stepCode, content.stepId);
                _this.setWaiting(false);
            };
            this.submitCallBack = function (content) {
                _this.setStatus(content.stepCode, content.stepId);
                _this.setUserData(content.additionalInfo);
                _this.setCheckMessageError(content.additionalInfo._remainingRetry);
                _this.setWaiting(false);
            };
            this.complete = function () {
                _this.setWaiting(true);
                _this.firstAccessService.complete.save({
                    processName: _this.processName,
                    stepCode: _this.firstAccessStatus,
                    stepId: _this.firstAccessStepIdStatus
                }, function (response) {
                    _this.setWaiting(false);
                    if (response.outcome == 'OK') {
                        _this.setStatus("CHANGE_PASSWORD", 0);
                    }
                    else {
                        _this.firstAccessStatus = 'ERROR';
                        _this.statusMessageError = response.message;
                        _this.setWaiting(false);
                    }
                }, function (errore) {
                    _this.setWaiting(false);
                });
            };
            this.submitEmail = function () {
                var body = _this.setRequestBody('userEmail_', _this.emailRegistration);
                _this.submit(body);
            };
            this.submitCheckCode = function () {
                var body = _this.setRequestBody('userCheckCode_', _this.checkCode);
                _this.submit(body);
            };
            this.submitCellPhone = function () {
                var body = _this.setRequestBody('userMobileNumber_', _this.phoneNumber);
                _this.submit(body);
            };
            this.submitAcceptance = function () {
                var body = _this.setRequestBody('privacyAccepted_', _this.promotionAcceptance);
                _this.submit(body);
            };
            this.checkAcceptance = function () {
                _this.notAccepted = (_this.acceptDisclaimer) ? false : true;
                _this.promotionNotAccepted = (_this.promotionAcceptance) ? false : true;
                if (_this.promotionAcceptance == "false") {
                    _this.setSweetAlertForPrivacy("Attenzione", "Il consenso al trattamento dei dati per le finalità indicate al punto c) è facoltativo e le Società del Gruppo Azimut potranno trattare i dati solo con il consenso del Cliente. In tale ipotesi, un eventuale rifiuto non pregiudica in alcun modo la prestazione dei servizi richiesti.", _this.submitAcceptance, true);
                }
                else if (_this.promotionAcceptance) {
                    _this.submitAcceptance();
                }
            };
            this.setStatus = function (stepCode, stepId) {
                _this.setFirstAccessStatus(stepCode);
                _this.setFirstAccessStepIdStatus(stepId);
            };
            this.setFirstAccessStatus = function (status) {
                _this.firstAccessStatus = status;
            };
            this.setFirstAccessStepIdStatus = function (stepId) {
                _this.firstAccessStepIdStatus = stepId;
            };
            this.setRequestBody = function (key, value) {
                var body = {};
                value = String(value);
                body[key] = value;
                return body;
            };
            this.setWaiting = function (waiting) {
                _this.waiting = waiting;
            };
            this.checkResponse = function (response, callBack) {
                if (response.outcome == 'OK') {
                    callBack(response.content);
                }
                else {
                    _this.firstAccessStatus = 'ERROR';
                    _this.statusMessageError = response.message;
                    _this.setWaiting(false);
                }
            };
            this.setCheckMessageError = function (remainingRetry) {
                _this.checkEmailMessageError = 'Attenzione il codice inserito non è valido. Hai a disposizione ancora ' + remainingRetry + ' tentativi/o, dopodichè ti verrà chiesto di reinserire la mail';
                _this.checkPhoneNumberMessageError = 'Attenzione il codice inserito non è valido. Hai a disposizione ancora ' + remainingRetry + ' tentativi/o, dopodichè ti verrà chiesto di reinserire il numero di telefono';
            };
            if ($translatePartialLoaderProvider != undefined) {
                this.$translatePartialLoaderProvider.addPart('bower_components/dwx-widget-first-access');
            }
            this.initLabel();
            this.init();
        }
        FirstAccessCtrl.prototype.logout = function (redirectToHome) {
            var _this = this;
            this.profileService.logout.query({}, function (response) {
                _this.$cookieStore.remove('portfolioSelected');
                _this.$cookieStore.remove('perspectiveSelected');
                _this.$cookieStore.remove('translateChangeSuccess');
                _this.$cookieStore.remove('refreshPortfolioWithDossier');
                _this.$cookieStore.remove('switchLanguage');
                _this.EventService.publish('webapp-loaded', [false], false);
                _this.EventService.publish('remove-style', [], false);
                if (redirectToHome) {
                    _this.goToHome();
                }
            });
        };
        FirstAccessCtrl.prototype.goToHome = function () {
            window.location.assign("/#/");
            window.location.reload();
        };
        FirstAccessCtrl.prototype.validatePassword = function () {
            var _this = this;
            var message;
            var regexPassword = /((?=.*\d)(?=.*[a-z])((?=.*[A-Z])|(?=.*[@#$%!?_-])).{4,})/;
            if (this['newPassword'] == this['confirmNewPassword']) {
                var newPassword = this['newPassword'];
                var oldPassword = this['oldPassword'];
                if (newPassword.match(regexPassword) && newPassword.match(regexPassword).length > 0) {
                    this.setWaiting(true);
                    this.firstAccessService.updatePassword.update({}, {
                        username: this.userName,
                        password: oldPassword,
                        newPassword: newPassword,
                    }, function (response) {
                        var title;
                        var func;
                        _this.setWaiting(false);
                        if (response.outcome == 'OK') {
                            title = 'MyAzimut';
                            message = response.message;
                            func = function () {
                                _this.logout(true);
                            };
                        }
                        else {
                            title = 'Errore';
                            message = response.message;
                        }
                        _this.setSweetAlert(title, message, func);
                    });
                }
                else {
                    message = 'Attenzione la  nuova password non rispecchia le specifiche di sicurezza richieste';
                }
            }
            else if (this['newPassword'] != this['confirmNewPassword']) {
                message = 'Attenzione le due password inserite non combaciano.';
            }
            if (message) {
                this.setWaiting(false);
                this.setSweetAlert('Recupera Password', message);
            }
        };
        FirstAccessCtrl.prototype.setSweetAlertForPrivacy = function (title, text, func, showCancelBtn) {
            sweetAlert({
                title: title,
                text: text,
                showCancelButton: showCancelBtn,
                confirmButtonText: "Procedi",
                cancelButtonText: "Annulla",
                confirmButtonColor: "#385696"
            }, func);
        };
        FirstAccessCtrl.prototype.setSweetAlert = function (title, text, func) {
            sweetAlert({
                title: title,
                text: text,
            }, func);
        };
        FirstAccessCtrl.prototype.setUserData = function (info) {
            this.userName = info._userName;
            this.firstName = info._userFirstName;
            this.lastName = info._userSurname;
            this.emailRegistration = info._userEmail;
            this.phoneNumber = info._userMobileNumber;
            this.welcomeMsg = 'Gentile ' + this.firstName + ' ' + this.lastName + ',';
        };
        return FirstAccessCtrl;
    })();
    exports.FirstAccessCtrl = FirstAccessCtrl;
});

