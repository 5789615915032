define('../bower_components/dwx-widget-my-documents/src/domain/process-type-max',["require", "exports"], function (require, exports) {
    exports.types = [
        'CONAVA',
        'CONAVAFULL',
        'i-MAX',
        'CONSMAX',
        'CONMAXFUNDS',
        'OMNIBUS'
    ];
});

