define('widget-settings-customer/../../bower_components/dwx-widget-settings-customer/src/controllers/settingsCtrl',["require", "exports", "../../../../scripts/smartAuthenticationManager"], function (require, exports, SM) {
    var SettingsCtrl = (function () {
        function SettingsCtrl($scope, $rootScope, $attrs, settingsService, $timeout, $http, $element, initConfigService, errorsHandlerService, $translatePartialLoaderProvider, EventService) {
            var _this = this;
            this.$scope = $scope;
            this.$rootScope = $rootScope;
            this.settingsService = settingsService;
            this.$http = $http;
            this.$element = $element;
            this.initConfigService = initConfigService;
            this.errorsHandlerService = errorsHandlerService;
            this.$translatePartialLoaderProvider = $translatePartialLoaderProvider;
            this.EventService = EventService;
            this.waiting = false;
            this.keepLoading = false;
            this.closeSidebar = function () {
                _this.EventService.publish('close-sidebar');
            };
            this.initStep = function () {
                _this.settingsService.getStatus.get({ processName: _this.processName }, function (response) {
                    _this.inputConfiguration[_this.selectedInput].needToRetry = false;
                });
            };
            this.setWaiting = function (waiting) {
                _this.waiting = waiting;
            };
            this.inputConfiguration = {
                "loginMode": {
                    label: "Metodo di autenticazione",
                    value: "N/A",
                    noteditable: false
                },
            };
            this.init();
        }
        SettingsCtrl.prototype.init = function () {
            this.widgetId = this.$scope.identifier || (typeof this.$scope.widget != 'undefined') ? this.$scope.widget.id : 'widgetSettingsCustomer';
            this.widgetConfig = this.initConfigService[this.widgetId];
            this.widgetConfig.status.mode = 'loading';
            if (this.$translatePartialLoaderProvider != undefined) {
                this.$translatePartialLoaderProvider.addPart('bower_components/dwx-widget-settings-customer');
            }
            var _t = this;
            this.pinCheckedEvent = this.EventService.subscribe('runPinChecked', function () {
                _t.pinCheck();
            });
            this.$scope.$on("$destroy", function () {
                _t.EventService.unsubscribe(_t.pinCheckedEvent, false);
            });
            this.initSmartAuth();
        };
        SettingsCtrl.prototype.initSmartAuth = function () {
            var _this = this;
            this.activeAuthentication = new SM.Credential();
            this.smartAuthentication = new SM.SmartAuthenticationManager();
            if ((typeof cordova !== 'undefined') && (Modernizr.touch)) {
                this.$scope.smartAuthModel = {
                    "faceIdSelected": false,
                    "touchSelected": false,
                    "pinSelected": false,
                    "defaultSelected": true,
                    "availableAuthentications": [],
                    "errorMsg": "",
                    "pin": {
                        "oldPin": "",
                        "pin": "",
                        "pinConfirm": ""
                    }
                };
                console.log("SMART_AUTH: Retrieve del profilo Smart Authentication");
                var currentLoggedUsername = window.sessionStorage.currentUserUsername || "";
                this.smartAuthentication.canShowSmartAuthenticationSettingsForUser(currentLoggedUsername, function (canShowForUsername) {
                    if (!canShowForUsername) {
                        $('#widgetSettingsId').children().attr('disabled', 'disabled');
                        _this.widgetConfig.status.mode = 'on';
                        _this.$scope.$apply();
                    }
                    else {
                        console.log("SMART_AUTH: found a smart auth");
                        _this.smartAuthentication.getActiveProfile(function (activeAuth) {
                            if (!activeAuth || activeAuth.username == undefined) {
                                console.log("SMART_AUTH: no active profile found");
                                activeAuth = new SM.Credential();
                                activeAuth.selectedAuthenticationMode = SM.ProfileType.DEFAULT;
                                activeAuth.username = window.sessionStorage.currentUserUsername;
                                activeAuth.password = window.sessionStorage.currentUserPassword;
                            }
                            _this.activeAuthentication = activeAuth;
                            if (_this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.TOUCHID) {
                                $(".WSettings__touchId").addClass("checked");
                            }
                            else if (_this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.PIN) {
                                $(".WSettings__pin").addClass("checked");
                            }
                            _this.$scope.smartAuthModel.faceIdSelected = _this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.FACEID;
                            _this.$scope.smartAuthModel.touchSelected = _this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.TOUCHID;
                            _this.$scope.smartAuthModel.pinSelected = _this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.PIN;
                            _this.showModifyPinButton = _this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.PIN;
                            _this.$scope.smartAuthModel.defaultSelected = _this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.DEFAULT;
                            _this.smartAuthentication.getAvailableAuthentications(function (availableAuthentications) {
                                _this.$scope.smartAuthModel.availableAuthentications = availableAuthentications;
                                console.log("SMART_AUTH: trovati: ", _this.$scope.smartAuthModel);
                                _this.widgetConfig.status.mode = 'on';
                                _this.$scope.$apply();
                            });
                        });
                    }
                });
            }
            else {
                console.log("ATTENZIONE: Cordova non presente. Questa parte di codice non avrebbe dovuto essere invocata.");
            }
        };
        SettingsCtrl.prototype.settingsUpdate = function (selectedAuth) {
            var _this = this;
            if ((typeof cordova !== 'undefined') && (Modernizr.touch)) {
                this.activeAuthentication.selectedAuthenticationMode = selectedAuth;
                console.log("SMART_AUTH: Sto impostando il metodo di autenticazione a " + selectedAuth);
                var saveProfileData = new SM.SaveProfileData();
                saveProfileData.username = this.activeAuthentication.username;
                saveProfileData.password = this.activeAuthentication.password;
                saveProfileData.authenticationMode = selectedAuth;
                saveProfileData.smartAuthenticationModalViewEnabled = selectedAuth === SM.ProfileType.DEFAULT;
                saveProfileData.pin = this.$scope.smartAuthModel.pin.pin;
                this.$scope.smartAuthModel.faceIdSelected = selectedAuth === SM.ProfileType.FACEID;
                this.$scope.smartAuthModel.touchSelected = selectedAuth === SM.ProfileType.TOUCHID;
                this.$scope.smartAuthModel.pinSelected = selectedAuth === SM.ProfileType.PIN;
                this.showModifyPinButton = selectedAuth === SM.ProfileType.PIN;
                this.$scope.smartAuthModel.defaultSelected = selectedAuth === SM.ProfileType.DEFAULT;
                this.smartAuthentication.saveProfile(saveProfileData, function (result) {
                    _this.activeAuthentication.selectedAuthenticationMode = selectedAuth;
                    if (selectedAuth == SM.ProfileType.PIN) {
                        _this.activeAuthentication.pin = saveProfileData.pin;
                        _this.$scope.smartAuthModel.pin.pin = "";
                        _this.$scope.smartAuthModel.pin.pinConfirm = "";
                        _this.$scope.smartAuthModel.pin.oldPin = "";
                    }
                    var messageSweetAlert;
                    if (selectedAuth === SM.ProfileType.PIN) {
                        messageSweetAlert = "Il codice pin è stato registrato correttamente";
                    }
                    else {
                        messageSweetAlert = "La modalità di accesso è stata impostata";
                    }
                    sweetAlert({
                        title: "La tua scelta è stata salvata",
                        text: messageSweetAlert,
                        type: "success",
                        showCancelButton: false,
                        closeOnConfirm: true,
                        confirmButtonText: "Chiudi",
                    }, function (isConfirm) {
                        $('#pin-change').foundation('reveal', 'close');
                    });
                });
                setTimeout(function () {
                    if (_this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.TOUCHID) {
                        $(".WSettings__touchId").addClass("checked");
                        $(".WSettings__pin").removeClass("checked");
                    }
                    else {
                        $(".WSettings__touchId").removeClass("checked");
                    }
                    _this.$scope.$digest();
                }, 0);
                console.log("SMART_AUTH: Impostato il metodo di autenticazione a " + selectedAuth);
            }
            else {
                console.log("ATTENZIONE: Cordova non presente. Questa parte di codice non avrebbe dovuto essere invocata.");
            }
        };
        SettingsCtrl.prototype.confirmSettingsUpdate = function (selectedAuth) {
            var _this = this;
            if (this.activeAuthentication.selectedAuthenticationMode != SM.ProfileType.DEFAULT) {
                var question = "";
                switch (this.activeAuthentication.selectedAuthenticationMode) {
                    case SM.ProfileType.TOUCHID:
                        question = "Sei sicuro di voler cancellare la modalità di accesso tramite impronta digitale?";
                        break;
                    case SM.ProfileType.FACEID:
                        question = "Sei sicuro di voler cancellare la modalità di accesso tramite Face ID?";
                        break;
                    case SM.ProfileType.PIN:
                        question = "Sei sicuro di voler cancellare la modalità di accesso tramite PIN e il relativo codice?";
                        break;
                }
                sweetAlert({
                    title: "",
                    text: question,
                    type: "warning",
                    confirmButtonColor: "#385696",
                    confirmButtonText: "Conferma",
                    cancelButtonText: "Annulla",
                    showCancelButton: true,
                    closeOnConfirm: true,
                    closeOnCancel: true
                }, function (isConfirm) {
                    if (isConfirm) {
                        if (selectedAuth === SM.ProfileType.PIN) {
                            _this.showPinChangeModal();
                        }
                        else {
                            console.log("SMART_AUTH: confermato cambio metodo auth a: " + selectedAuth);
                            _this.settingsUpdate(selectedAuth);
                        }
                    }
                    else {
                        console.log("SMART_AUTH: annullato cambio metodo auth a " + selectedAuth + ", il metodo in uso rimane " + _this.activeAuthentication.selectedAuthenticationMode);
                    }
                });
            }
            else {
                if (selectedAuth === SM.ProfileType.PIN) {
                    this.showPinChangeModal();
                }
                else {
                    this.settingsUpdate(selectedAuth);
                }
            }
        };
        SettingsCtrl.prototype.pinCheck = function () {
            this.fixUndefinedValues();
            this.deleteExceedingDigits();
            var oldPinCorrect = ((this.activeAuthentication.selectedAuthenticationMode !== SM.ProfileType.PIN || this.$scope.smartAuthModel.pin.oldPin.toString() === this.activeAuthentication.pin) || (this.$scope.smartAuthModel.pin.oldPin.toString() === "" && this.$scope.smartAuthModel.pin.pin.toString() === ""));
            var newPinsMatch = ((this.$scope.smartAuthModel.pin.pin === this.$scope.smartAuthModel.pin.pinConfirm && this.$scope.smartAuthModel.pin.pin.toString().length > 0 && this.$scope.smartAuthModel.pin.pinConfirm.toString().length > 0) || this.$scope.smartAuthModel.pin.pinConfirm.toString().length === 0);
            var newPinsLenghtOk = (this.$scope.smartAuthModel.pin.pin.toString().length === SM.PIN_LENGHT) || (this.$scope.smartAuthModel.pin.pin.toString().length === 0);
            var newPinDifferentFromOld = (this.activeAuthentication.selectedAuthenticationMode !== SM.ProfileType.PIN) || (this.$scope.smartAuthModel.pin.pin.toString() !== this.activeAuthentication.pin.toString());
            var pinIsNumeric = false;
            if (Number(this.$scope.smartAuthModel.pin.pin) || this.$scope.smartAuthModel.pin.pin.toString().length === 0) {
                pinIsNumeric = true;
            }
            var errorMsg = "";
            if (!oldPinCorrect) {
                errorMsg = "Il vecchio PIN non corrisponde";
            }
            else if (!pinIsNumeric) {
                errorMsg = "Il PIN può contenere solo cifre da 0 a 9";
            }
            else if (!newPinsLenghtOk) {
                errorMsg = "Il nuovo PIN deve essere di 6 cifre";
            }
            else if (!newPinDifferentFromOld) {
                errorMsg = "Il nuovo PIN deve essere differente dal precedente";
            }
            else if (!newPinsMatch) {
                errorMsg = "La conferma del PIN non corrisponde";
            }
            this.$scope.smartAuthModel.errorMsg = errorMsg;
            if (this.showOldPinField()) {
                return (oldPinCorrect && newPinsMatch && newPinsLenghtOk && newPinDifferentFromOld && pinIsNumeric) && this.$scope.smartAuthModel.pin.pin.toString().length > 0 && this.$scope.smartAuthModel.pin.pinConfirm.toString().length > 0 && this.$scope.smartAuthModel.pin.oldPin.toString().length > 0;
            }
            else {
                return (oldPinCorrect && newPinsMatch && newPinsLenghtOk && newPinDifferentFromOld && pinIsNumeric) && this.$scope.smartAuthModel.pin.pin.toString().length > 0 && this.$scope.smartAuthModel.pin.pinConfirm.toString().length > 0;
            }
        };
        SettingsCtrl.prototype.deleteExceedingDigits = function () {
            if (this.$scope.smartAuthModel.pin.pin.toString().length > SM.PIN_LENGHT) {
                this.$scope.smartAuthModel.pin.pin.substr(0, 5);
            }
        };
        SettingsCtrl.prototype.fixUndefinedValues = function () {
            if (this.$scope.smartAuthModel.pin.pin === undefined) {
                this.$scope.smartAuthModel.pin.pin = "";
            }
            if (this.$scope.smartAuthModel.pin.pinConfirm === undefined) {
                this.$scope.smartAuthModel.pin.pinConfirm = "";
            }
            if (this.$scope.smartAuthModel.pin.oldPin === undefined) {
                this.$scope.smartAuthModel.pin.oldPin = "";
            }
        };
        SettingsCtrl.prototype.pinChangeAbort = function () {
            if ((typeof cordova !== 'undefined') && (Modernizr.touch)) {
                console.log("SMART_AUTH: annullato inserimento PIN");
                if (!this.showOldPinField()) {
                    this.$scope.smartAuthModel.pin.pin = "";
                    this.$scope.smartAuthModel.pin.pinConfirm = "";
                    this.$scope.smartAuthModel.pinSelected = false;
                    this.showModifyPinButton = false;
                }
                $('#pin-change').foundation('reveal', 'close');
            }
        };
        SettingsCtrl.prototype.pinCheckboxToggled = function () {
            if (this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.PIN) {
                console.log("SMART_AUTH: Deselezionata checkbox PIN");
                $(".WSettings__pin").removeClass("checked");
                this.confirmSettingsUpdate(SM.ProfileType.DEFAULT);
            }
            else {
                console.log("SMART_AUTH: Selezionata checkbox PIN");
                $(".WSettings__pin").addClass("checked");
                if (this.activeAuthentication.selectedAuthenticationMode != SM.ProfileType.DEFAULT) {
                    this.confirmSettingsUpdate(SM.ProfileType.PIN);
                }
                else {
                    this.showPinChangeModal();
                }
            }
        };
        SettingsCtrl.prototype.touchCheckboxToggled = function () {
            if (this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.TOUCHID) {
                console.log("SMART_AUTH: Deselezionata checkbox TOUCH");
                this.confirmSettingsUpdate(SM.ProfileType.DEFAULT);
            }
            else {
                console.log("SMART_AUTH: Selezionata checkbox TOUCH");
                this.confirmSettingsUpdate(SM.ProfileType.TOUCHID);
            }
        };
        SettingsCtrl.prototype.faceIdCheckboxToggled = function () {
            if (this.activeAuthentication.selectedAuthenticationMode === SM.ProfileType.FACEID) {
                console.log("SMART_AUTH: Deselezionata checkbox FACE ID");
                this.confirmSettingsUpdate(SM.ProfileType.DEFAULT);
            }
            else {
                console.log("SMART_AUTH: Selezionata checkbox FACE ID");
                this.confirmSettingsUpdate(SM.ProfileType.FACEID);
            }
        };
        SettingsCtrl.prototype.showPinChangeModal = function () {
            this.$scope.smartAuthModel.pinSelected = true;
            this.showModifyPinButton = true;
            $('#pin-change').foundation('reveal', 'open');
            console.log("SMART_AUTH: Mostro la popup per impostare il PIN");
        };
        SettingsCtrl.prototype.showOldPinField = function () {
            return this.activeAuthentication.selectedAuthenticationMode == SM.ProfileType.PIN && this.activeAuthentication.pin != undefined;
        };
        SettingsCtrl.prototype.touchIdSelectable = function () {
            if (this.$scope.smartAuthModel.availableAuthentications && this.$scope.smartAuthModel.availableAuthentications.length === 1 && this.$scope.smartAuthModel.availableAuthentications.indexOf(SM.ProfileType.DEFAULT) === -1) {
                return false;
            }
            else {
                return _.contains(this.$scope.smartAuthModel.availableAuthentications, SM.ProfileType.TOUCHID);
            }
        };
        SettingsCtrl.prototype.faceIdSelectable = function () {
            if (this.$scope.smartAuthModel.availableAuthentications && this.$scope.smartAuthModel.availableAuthentications.length === 1 && this.$scope.smartAuthModel.availableAuthentications.indexOf(SM.ProfileType.DEFAULT) === -1) {
                return false;
            }
            else {
                return _.contains(this.$scope.smartAuthModel.availableAuthentications, SM.ProfileType.FACEID);
            }
        };
        return SettingsCtrl;
    })();
    exports.SettingsCtrl = SettingsCtrl;
});

