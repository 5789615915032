define('layout-base/../../bower_components/dwx-utility/src/filters/reverseArray',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var ReverseArrayFilter = (function () {
            function ReverseArrayFilter() {
            }
            ReverseArrayFilter.Factory = function () {
                var instance = new ReverseArrayFilter();
                return Utility.ReverseArrayFilter.myFilter;
            };
            ReverseArrayFilter.myFilter = function (arr) {
                if (arr !== void 0 && arr.length > 0) {
                    return arr.slice().reverse();
                }
                else {
                    return [];
                }
            };
            return ReverseArrayFilter;
        })();
        Utility.ReverseArrayFilter = ReverseArrayFilter;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

