define('layout-base/../../bower_components/dwx-utility/src/filters/sliceArray',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var SliceArrayFilter = (function () {
            function SliceArrayFilter() {
            }
            SliceArrayFilter.Factory = function () {
                var instance = new SliceArrayFilter();
                return Utility.SliceArrayFilter.myFilter;
            };
            SliceArrayFilter.myFilter = function (arr, start, end) {
                if (arr !== void 0 && arr.length > 0) {
                    return arr.slice(start, end);
                }
                else {
                    return [];
                }
            };
            return SliceArrayFilter;
        })();
        Utility.SliceArrayFilter = SliceArrayFilter;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

