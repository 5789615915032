define('widget-portfolio-holdings/../../bower_components/dwx-widget-portfolio-holdings/src/controllers/portfolioHoldingsCtrl',["require", "exports"], function (require, exports) {
    var PortfolioHoldings;
    (function (PortfolioHoldings) {
        var PortfolioHoldingsController = (function () {
            function PortfolioHoldingsController($scope, $rootScope, EventService, portfolioCoreService, initConfigService, $filter, $location, paginationService, $sce, $translatePartialLoaderProvider, $translate, $timeout, profileService) {
                this.$scope = $scope;
                this.EventService = EventService;
                this.portfolioCoreService = portfolioCoreService;
                this.$filter = $filter;
                this.$location = $location;
                this.paginationService = paginationService;
                this.$sce = $sce;
                this.$translate = $translate;
                this.$timeout = $timeout;
                this.profileService = profileService;
                this.reverseOrdering = [];
                this.tempOrderField = [];
                this.predicate = [];
                this.managementTypeRetail = '10002';
                this.holdingsAvailable = false;
                this.almostOneBlocked = {};
                this.predicateModal = [];
                this.tempOrderModalField = [];
                this.reverseModalOrdering = [];
                this.tableFieldsPercentage = [];
                this.tableFieldsToRound = {};
                this.tableFieldsToFormat = {};
                this.tableModalFieldsToRound = {};
                this.detailsTabIndex = 0;
                this.changingModalPage = false;
                this.showButtonsForUnderlyings = true;
                this.exportXLSUrlForUnderlyings = '';
                this.exportPDFUrlForUnderlyings = '';
                this.altMessageList = [];
                this.enableProductTitleSupMap = {};
                this.changeProductDisclaimerMap = {};
                this.disclaimerForCategoriesMap = {};
                this.alt2ProductDisclaimerMap = {};
                this.isTouch = function () {
                    if (typeof cordova !== 'undefined' && Modernizr.touch) {
                        return true;
                    }
                    return false;
                };
                this.openFile = function (url, extension, event) {
                    if (!event) {
                        showFile(url, extension, '', undefined);
                    }
                    else {
                        showFile(url, extension, '', event.target);
                    }
                };
                this.accordionGroup = [];
                var widgetInfos, handlePerspectiveSelected, handlePortfolioSelected, handleDossierSelected;
                var _this = this;
                _this.widgetId = $scope.identifier || (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetLogin';
                _this.widgetConfig = initConfigService[this.widgetId];
                if ($translatePartialLoaderProvider != undefined) {
                    $translatePartialLoaderProvider.addPart('bower_components/dwx-widget-portfolio-holdings');
                }
                if (_this.widgetConfig.status.mode !== 'locked') {
                    portfolioCoreService.holdingsConfiguration.get({}, function (response) {
                        _this.configuration = response.content;
                        _this.initConfiguration(widgetInfos);
                    }, function () {
                    });
                    if (paginationService != undefined) {
                        var pageSize = 10;
                        var pageIndex = 1;
                        _this.modalPagination = new paginationService([], pageSize, pageIndex);
                    }
                    _this.widget = {
                        title: _this.widgetConfig.widgetInfos.TITLE.value
                    };
                    _this.table_fields = [];
                    if (_this.widgetConfig.widgetInfos.CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_DESKTOP) {
                        var fileds_category_map_desktop = JSON.parse(_this.widgetConfig.widgetInfos.CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_DESKTOP.value);
                        angular.forEach(fileds_category_map_desktop, function (val, key) {
                            if (!_this.table_fields[key]) {
                                _this.table_fields[key] = {};
                                _this.table_fields[key]['default'] = [];
                            }
                            angular.forEach(val, function (val2, key2) {
                                _this.table_fields[key]['default'].push(val2);
                            });
                        });
                    }
                    if (_this.widgetConfig.widgetInfos.SIMPLE_TRADE_PAGE_ID != undefined) {
                        _this.simpleTradePageId = _this.widgetConfig.widgetInfos.SIMPLE_TRADE_PAGE_ID.value;
                    }
                    _this.toolTipMessageClose = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.close");
                    _this.toolTipMessageSell = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.sell");
                    _this.toolTipMessageBuy = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.buy");
                    _this.toolTipMessageLocked = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.locked");
                    _this.actions = [
                        {
                            label: _this.toolTipMessageClose,
                            value: "close"
                        },
                        {
                            label: _this.toolTipMessageBuy,
                            value: "buy"
                        },
                        {
                            label: _this.toolTipMessageSell,
                            value: "sell"
                        }
                    ];
                    $rootScope.$on("$translateChangeSuccess", function () {
                        _this.toolTipMessageClose = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.close");
                        _this.toolTipMessageSell = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.sell");
                        _this.toolTipMessageBuy = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.buy");
                        _this.toolTipMessageLocked = _this.$filter('translate')("dashboard.widget.portfolio.table.tooltip.locked");
                        _this.actions = [
                            {
                                label: _this.toolTipMessageClose,
                                value: "close"
                            },
                            {
                                label: _this.toolTipMessageBuy,
                                value: "buy"
                            },
                            {
                                label: _this.toolTipMessageSell,
                                value: "sell"
                            }
                        ];
                    });
                    widgetInfos = [];
                    angular.forEach(_this.widgetConfig.widgetInfos, function (val, key) {
                        var deviceType, key_match, tableType;
                        key_match = key.match(/CUSTOM_TABLE_FIELDS_FOR_([^_\.]+)_([^\.]+)/i);
                        if (key_match) {
                            tableType = angular.lowercase(key_match[1]);
                            deviceType = angular.lowercase(key_match[2]);
                            if (!_this.table_fields[tableType]) {
                                _this.table_fields[tableType] = {};
                            }
                            _this.table_fields[tableType][deviceType] = val.arrayValue.length > 0 ? val.arrayValue : deviceType !== 'header' ? _this.widgetConfig.widgetInfos.DEFAULT_TABLE_FIELDS.arrayValue : _this.widgetConfig.widgetInfos.DEFAULT_TABLE_FIELDS_FOR_HEADER.arrayValue;
                        }
                        else {
                            try {
                                if (_this.widgetConfig.widgetInfos[key].widgetTemplateInfo.type === 'Array') {
                                    widgetInfos[key] = val.arrayValue;
                                }
                                else {
                                    widgetInfos[key] = val.value;
                                }
                            }
                            catch (_error) {
                                if (_this.widgetConfig.widgetInfos[key].widgetTemplateInfo.mandatory) {
                                    throw new Error('Error retrieving ' + _this.widgetConfig.widgetInfos[key].widgetTemplateInfo.name + ' property for widget portfolio holdings');
                                }
                            }
                        }
                    });
                    _this.perspectiveId = widgetInfos['DEFAULT_PERSPECTIVE'];
                    _this.perspectiveLevel = widgetInfos['DEFAULT_PERSPECTIVE_LEVEL'];
                    _this.viewType = widgetInfos['SUPPORTED_VIEW'];
                    _this.portfolioSelected = widgetInfos['DEFAULT_PORTFOLIO'];
                    _this.defaultView = widgetInfos['DEFAULT_VIEW'];
                    _this.defaultFieldHeader = widgetInfos['DEFAULT_TABLE_FIELDS_FOR_HEADER'];
                    _this.defaultField = widgetInfos['DEFAULT_TABLE_FIELDS'];
                    _this.selection = _this.EventService.getParamByKeyFromQueryString('viewCategoryList');
                    _this.tableFieldsForSmartphone = widgetInfos['CUSTOM_TABLE_FIELDS_FOR_SMARTPHONE'] ? widgetInfos['CUSTOM_TABLE_FIELDS_FOR_SMARTPHONE'] : _this.defaultField;
                    _this.tableFieldsForDesktop = widgetInfos['CUSTOM_TABLE_FIELDS_FOR_DESKTOP'] ? widgetInfos['CUSTOM_TABLE_FIELDS_FOR_DESKTOP'] : _this.defaultField;
                    _this.tableFieldsNoWrap = widgetInfos['TABLE_FIELDS_NO_WRAP'];
                    _this.tableFieldsTextRight = widgetInfos['TABLE_FIELDS_TEXT_RIGHT'];
                    this.initConfiguration(widgetInfos);
                    if (_this.selection === void 0) {
                        _this.selection = _this.defaultView;
                    }
                    var handleDossierSelectedReponseError = _this.EventService.subscribe('dossierSelectedResponseError', function (message) {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = message;
                    });
                    var handleDossierSelectedHttpReponseError = _this.EventService.subscribe('dossierSelectedResponseError', function (message) {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = "dashboard.error.connection_error";
                    });
                    handlePerspectiveSelected = _this.EventService.subscribe('perspectiveSelected', function (perspective) {
                        _this.perspectiveId = perspective.selectedPerspectiveId;
                        _this.perspectiveLevel = perspective.selectedPerspectiveLevel;
                        _this.initPortfolioHoldings();
                    });
                    handlePortfolioSelected = _this.EventService.subscribe('portfolioSelected', function (portfolioIndex) {
                        _this.widgetConfig.status.mode = 'loading';
                        _this.initPortfolioHoldings();
                    });
                    handleDossierSelected = _this.EventService.subscribe('dossierSelected', function () {
                        _this.widgetConfig.status.mode = 'loading';
                        _this.initPortfolioHoldings();
                    });
                    _this.initPortfolioHoldings();
                    _this.EventService.subscribe("changeViewPortfolioCategoryList", function (event, changeView) {
                        $scope.selection = changeView;
                    });
                    $scope.$on("$destroy", function () {
                        _this.EventService.unsubscribe(handlePortfolioSelected, false);
                        _this.EventService.unsubscribe(handlePerspectiveSelected, false);
                        _this.EventService.unsubscribe(handleDossierSelected, false);
                        _this.EventService.unsubscribe(handleRefreshPortfolioWithDossier, false);
                        _this.EventService.unsubscribe(handleDossierSelectedReponseError, false);
                        _this.EventService.unsubscribe(handleDossierSelectedHttpReponseError, false);
                    });
                    _this.EventService.subscribe('getPortfolioError', function () {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = 'Error occurred while retrieve portfolio';
                    });
                    var handleRefreshPortfolioWithDossier = EventService.subscribe('refreshPortfolioWithDossier', function () {
                        _this.widgetConfig.status.mode = 'waiting';
                        _this.initPortfolioHoldings();
                    });
                    $scope.$evalAsync(function () {
                        if (_this.$translate != undefined) {
                            _this.$translate.refresh();
                        }
                    });
                }
            }
            PortfolioHoldingsController.prototype.initConfiguration = function (widgetInfos) {
                var _this = this;
                _this.tableFieldsCurrency = (this.configuration) ? this.configuration['currencyFields'] : widgetInfos['TABLE_FIELDS_CURRENCY'];
                if (!!_this.tableFieldsCurrency) {
                    _this.tableFieldsCurrency.push('ctvGrossAmount');
                    _this.tableFieldsCurrency.push('amountBankTransferFia');
                    _this.tableFieldsCurrency.push('ctvCashFlows');
                    _this.tableFieldsCurrency.push('incomeTransitItems');
                    _this.tableFieldsCurrency.push('closePrice');
                    _this.tableFieldsCurrency.push('commitment');
                }
                _this.tableFieldsDate = (this.configuration) ? this.configuration['dateFields'] : widgetInfos['TABLE_FIELDS_DATE'];
                _this.tableFieldsPercentage = (this.configuration) ? this.configuration['percentageFields'] : widgetInfos['TABLE_FIELDS_PERCENTAGE'];
                _this.tableFieldsToRound = (this.configuration) ? this.configuration["fieldsToRound"] : widgetInfos['TABLE_FIELDS_TO_ROUND'];
                _this.tableFieldsToRound = {
                    commitment: 2,
                    ctvGrossAmount: 2,
                    amountBankTransferFia: 2,
                    marketValue: 2,
                    ctvCashFlows: 2
                };
                if (!_this.tableFieldsToRound)
                    _this.tableFieldsToRound = { 'shareNumber': 3, 'histAverageFxRate': 4, 'fxRate': 4 };
                _this.tableModalFieldsToRound = {
                    'amount': 2,
                    "netAmount": 2,
                    "currencyPrice": 3,
                    'internalFee': 2,
                    'externalFee': 2,
                    'taxes': 2,
                    'quantity': 5,
                    'price': 3,
                    'exchange': 2
                };
                _this.tableModalUnderlyingFieldsToRound = {
                    "DEFAULT": {
                        'shareNumber': 3,
                        'price': 2,
                        'percentage': 6,
                        'histAveragePurchasePrice': 2,
                        "marketValue": 2
                    },
                    "GPM": {
                        'shareNumber': 3,
                        'histAveragePurchasePrice': 3,
                        'loadChange': 3,
                        'rate': 5,
                        'price': 3,
                        'marketChange': 3,
                        'percentage': 3,
                        'riskFactor': 2,
                        "marketValue": 2
                    },
                    "PUP": {
                        'shareNumber': 3,
                        'price': 2,
                        'percentage': 6,
                        'histAveragePurchasePrice': 2,
                        "marketValue": 2
                    }
                };
                _this.tableModalFieldsTextRight = [];
                _this.tableModalFieldsSortable = ["date", "settlementDate", "valueDate"];
                _this.tableModalFieldsDate = ["date", "settlementDate", "valueDate", 'exchangeDate'];
                _this.tableModalFieldsCurrency = ['amount', "netAmount", "currencyPrice", 'internalFee', 'externalFee', 'taxes', 'price'];
                _this.tableModalFieldsNoWrap = ["date", "settlementDate", "valueDate", "transactionType", "reasonForPayment", "amount", "internalFee", "taxes", "externalFee", "netAmount", "quantity", "price", "exchange", "currencyPrice", "exchangeDate"];
                _this.categoriesMapForDesktop = (this.configuration) ? this.configuration['tableFieldsCategoriesMapForDesktop'] : '';
                _this.categoriesMapForDesktop['CDL'] = ["productName", "accountNumber", "cashBalanceAccounting", "cashBalanceAccountingDate", "cashBalanceLiquid", "cashBalanceLiquidDate", "cashBalanceAvailable", "cashBalanceAvailableDate"];
                var realEconomyConf = ["productName", "accountNumber", "commitment", "ctvGrossAmount", "amountBankTransferFia", "marketValue", "priceDate", "ctvCashFlows"];
                var previdenza = ["productName", "accountNumber", "priceDate", "marketValue", "incomeTransitItems", "commitment"];
                _this.categoriesMapForDesktop['FPD'] = realEconomyConf;
                _this.categoriesMapForDesktop['FPE'] = realEconomyConf;
                _this.categoriesMapForDesktop['FVC'] = realEconomyConf;
                _this.categoriesMapForDesktop['FRA'] = realEconomyConf;
                _this.categoriesMapForDesktop['FBM'] = realEconomyConf;
                _this.categoriesMapForDesktop['FIP'] = previdenza;
                _this.categoriesMapForSmartphone = (this.configuration) ? this.configuration['tableFieldsCategoriesMapForSmartphone'] : '';
                _this.categoriesMapForTablet = (this.configuration) ? this.configuration['tableFieldsCategoriesMapForTablet'] : '';
                _this.categoriesMapForModalUnderlyingDesktop = {
                    "DEFAULT": ["productName", "percentage", "histAveragePurchasePrice", "shareNumber", "price", "priceDate", "marketValue"],
                    "GPM": ["productName", "shareNumber", "histAveragePurchasePrice", "loadChange", "rate", "price", "marketChange", "marketValue", "percentage", "riskFactor"],
                    "PUP": ["productName", "percentage", "histAveragePurchasePrice", "shareNumber", "price", "priceDate", "marketValue"],
                };
                _this.categoriesMapForModalUnderlyingOnlyDesktop = {
                    "DEFAULT": ["productName", "percentage", "histAveragePurchasePrice", "shareNumber", "price", "priceDate", "marketValue"],
                    "GPM": ["productName", "shareNumber", "histAveragePurchasePrice", "loadChange", "rate", "price", "marketChange", "marketValue", "percentage", "riskFactor"],
                    "PUP": ["productName", "marketValue"]
                };
                _this.categoriesMapForModalUnderlyingSmartphone = ["productName"];
                _this.tableModalUnderlyingFieldsDate = ["priceDate"];
                _this.tableModalUnderlyingFieldsCurrency = ["histAveragePurchasePrice", "marketValue", "price"];
                _this.tableModalUnderlyingFieldsPercentage = ["percentage", "riskFactor"];
                _this.modalFields = ["date", "settlementDate", "valueDate", "transactionType", "reasonForPayment", "amount", "internalFee", "taxes", "externalFee", "netAmount", "quantity", "price", "exchange", "currencyPrice", "exchangeDate"];
            };
            PortfolioHoldingsController.prototype.extractIpoClubPositions = function (holdingGroups) {
                var _this = this;
                holdingGroups.forEach(function (holdingGroup) {
                    if (holdingGroup.holdingGroups) {
                        _this.extractIpoClubPositions(holdingGroup.holdingGroups);
                    }
                    if (holdingGroup.holdingItemsDwx != null && holdingGroup.holdingItemsDwx.length > 0) {
                        holdingGroup.holdingItemsDwx.forEach(function (holdingItem) {
                            if (holdingItem.commitment != null && holdingItem.commitment > 0) {
                                _this.positionIpoClub = holdingItem;
                            }
                        });
                    }
                });
            };
            PortfolioHoldingsController.prototype.buildPortfolioHoldings = function (portfolioId) {
                var _this = this;
                var hasRealEconomyCategory = false;
                _this.portfolioCoreService.holdingsByDossier.save({
                    portfolioId: portfolioId,
                    grouping1Level: _this.perspectiveLevel,
                    grouping1PerspectiveId: _this.perspectiveId,
                    firstHolder: !sessionStorage.getItem("coowner")
                }, _this.portfolioCoreService['dossierList'], function (response) {
                    if (response.outcome === 'OK') {
                        _this.portfolioCoreService.getPortfolioDisclaimer('myAzimutHoldings').then(function (resource) {
                            _this.disclaimers = resource;
                        }).finally(function () {
                            var changeProductCounterDisclaimer = 1;
                            _this.counterDisclaimer = 1;
                            _this.portfolioHoldings = response.content;
                            _this.portfolioHoldings.forEach(function (category) {
                                hasRealEconomyCategory = hasRealEconomyCategory || category.categoryKey === '011';
                                category.holdingGroups.forEach(function (parentHoldingGroup) {
                                    parentHoldingGroup.holdingGroups.forEach(function (holdingGroup) {
                                        holdingGroup.holdingItemsDwx.forEach(function (holdingItem) {
                                            if (holdingItem && holdingItem.marketValue && holdingItem.marketValue.description === '**') {
                                                _this.counterDisclaimer++;
                                                holdingItem.marketValue.description = _this.counterDisclaimer;
                                                holdingItem.ytdUnRealizedProfitLossInVC.description = _this.counterDisclaimer;
                                                var amountALTProduct;
                                                if (!!holdingItem.amountALTProduct)
                                                    amountALTProduct = "" + _this.$filter('number')(holdingItem.amountALTProduct.value, 2) + " " + holdingItem.amountALTProduct.currency;
                                                var message = _this.$filter('translate')('dashboard.widget.portfolio.alt.message', {
                                                    index: _this.counterDisclaimer,
                                                    amountALTProduct: amountALTProduct || ''
                                                });
                                                if (_this.altMessageList.indexOf(message) === -1)
                                                    _this.altMessageList.push(message);
                                            }
                                            _this.alt2ProductDisclaimerMap[category.categoryKey] = _this.alt2ProductDisclaimerMap[category.categoryKey] || {};
                                            if (holdingItem.alt2Product) {
                                                _this.alt2ProductDisclaimerMap[category.categoryKey][holdingItem.productName] = {
                                                    sup: _this.counterDisclaimer,
                                                    disclaimer: "(" + _this.counterDisclaimer + ") " + _this.$filter('translate')('dashboard.widget.portfolio.alt2.message', {
                                                        amount: _this.$filter('number')(holdingItem.amountALT2Product.value, 2)
                                                    })
                                                };
                                                _this.counterDisclaimer++;
                                            }
                                            _this.enableProductTitleSupMap[holdingItem.productName] = _this.enableProductTitleSupMap[holdingItem.productName] || [];
                                            _this.disclaimerForCategoriesMap[category.categoryKey] = _this.disclaimerForCategoriesMap[category.categoryKey] || [];
                                            if (holdingItem && holdingItem.pirDepositType === 'UCITS' && _this.enableProductTitleSupMap[holdingItem.productName].indexOf('pirUcits') < 0) {
                                                _this.enableProductTitleSupMap[holdingItem.productName].push('pirUcits');
                                                _this.disclaimerForCategoriesMap[category.categoryKey].push('pirUcits');
                                            }
                                            if (holdingItem && holdingItem.pirDepositType === 'FIA' && _this.enableProductTitleSupMap[holdingItem.productName].indexOf('pirFia') < 0) {
                                                _this.enableProductTitleSupMap[holdingItem.productName].push('pirFia');
                                                _this.disclaimerForCategoriesMap[category.categoryKey].push('pirFia');
                                            }
                                            if (holdingItem && holdingItem.hasListMergedPositions && _this.enableProductTitleSupMap[holdingItem.productName].indexOf('mergedFundsHoldings') < 0) {
                                                _this.disclaimerForCategoriesMap[category.categoryKey].push('mergedFundsHoldings');
                                                _this.enableProductTitleSupMap[holdingItem.productName].push('mergedFundsHoldings');
                                            }
                                            if (holdingItem && (holdingItem.productIdentificationCode1Value === '3411E' || holdingItem.productIdentificationCode1Value === '3412E' || holdingItem.productIdentificationCode2Value === '3411E' || holdingItem.productIdentificationCode2Value === '3412E') && _this.enableProductTitleSupMap[holdingItem.productName].indexOf('compartoObbligazionario') < 0) {
                                                _this.disclaimerForCategoriesMap[category.categoryKey].push('compartoObbligazionario');
                                                _this.enableProductTitleSupMap[holdingItem.productName].push('compartoObbligazionario');
                                            }
                                            if (holdingItem && holdingItem.anomaliaCod === 'NOT_CLOSEDOUT_POS' && _this.enableProductTitleSupMap[holdingItem.productName].indexOf('notClosedoutPos') < 0) {
                                                _this.disclaimerForCategoriesMap[category.categoryKey].push('notClosedoutPos');
                                                _this.enableProductTitleSupMap[holdingItem.productName].push('notClosedoutPos');
                                            }
                                            if (category.categoryKey === '012') {
                                                holdingItem.commitment = holdingItem.marketValue;
                                            }
                                            if (category.categoryKey === '011') {
                                                holdingItem.commitment = {
                                                    value: holdingItem.commitment,
                                                    currency: 'EUR',
                                                    description: null
                                                };
                                            }
                                            _this.changeProductDisclaimerMap[category.categoryKey] = _this.changeProductDisclaimerMap[category.categoryKey] || {};
                                            if (holdingItem.productMigrationInfo) {
                                                _this.changeProductDisclaimerMap[category.categoryKey][holdingItem.productName] = {
                                                    sup: "(R" + changeProductCounterDisclaimer + ")",
                                                    disclaimer: "(R" + changeProductCounterDisclaimer + ") ex " + holdingItem.productMigrationInfo.productNameFrom + " sino al " + _this.$filter('date')(holdingItem.productMigrationInfo.migrationDate, 'shortDate')
                                                };
                                                changeProductCounterDisclaimer++;
                                            }
                                        });
                                    });
                                });
                            });
                            _this.handlerDisclaimers(hasRealEconomyCategory);
                            var lastIndex = 0;
                            _this.disclaimers = _this.disclaimers[0].items.map(function (disclaimer) {
                                lastIndex = _this.counterDisclaimer++;
                                return {
                                    linkId: disclaimer.linkId,
                                    text: "(" + lastIndex + ") " + disclaimer.text
                                };
                            }).concat([{
                                linkId: 'notClosedoutPos',
                                text: "(" + (lastIndex + 2) + ") La polizza è chiusa e la Compagnia Assicurativa, alla data odierna, non ha trasmesso il movimento di liquidazione del Prodotto."
                            }, {
                                linkId: 'compartoObbligazionario',
                                text: "(" + (lastIndex + 1) + ") Quote trasferite dal comparto garantito al comparto obbligazionario di Azimut Previdenza"
                            }]);
                            _this.extractIpoClubPositions(_this.portfolioHoldings);
                            _this.widgetConfig.status.mode = 'on';
                            _this.EventService.publish('layout-loaded', [true]);
                        });
                    }
                    else {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = "dashboard.error." + response.messageKey;
                        _this.EventService.publish('http-response-error', [response.messageKey]);
                    }
                }, function (error) {
                    _this.widgetConfig.status.mode = 'error';
                    _this.widgetConfig.status.message = "dashboard.error.connection_error";
                    _this.EventService.publish('http-response-error', [error]);
                });
            };
            PortfolioHoldingsController.prototype.initPortfolioHoldings = function () {
                var _this = this;
                _this.portfolioHoldings = {};
                if (_this.portfolioCoreService['dossierList'] !== void 0) {
                    _this.buildPortfolioHoldings(null);
                    _this.holdingsXlsUrl = apiPrefix + "/portfolio/holdings/xls?grouping1Level=" + _this.perspectiveId + "&grouping1PerspectiveId=" + _this.perspectiveLevel + "&portfolioId=" + null + "&firstHolder=" + !sessionStorage.getItem('coowner');
                    _this.holdingsPdfUrl = apiPrefix + "/portfolio/holdings/pdf?grouping1Level=" + _this.perspectiveId + "&grouping1PerspectiveId=" + _this.perspectiveLevel + "&portfolioId=" + null + "&firstHolder=" + !sessionStorage.getItem('coowner');
                }
            };
            PortfolioHoldingsController.prototype.orderByField = function (field, category) {
                var _this = this;
                if (_this.tableFieldsCurrency.indexOf(field) != -1) {
                    _this.predicate[category] = field + ".value";
                }
                else {
                    _this.predicate[category] = field;
                }
                if (_this.tempOrderField[category] === field) {
                    _this.reverseOrdering[category] = !_this.reverseOrdering[category];
                }
                else {
                    _this.reverseOrdering[category] = false;
                }
                _this.tempOrderField[category] = field;
            };
            PortfolioHoldingsController.prototype.orderModalByField = function (field, category) {
                var _this = this;
                if (_this.tableModalFieldsCurrency.indexOf(field) != -1) {
                    _this.predicateModal[category] = field + ".value";
                }
                else {
                    _this.predicateModal[category] = field;
                }
                if (_this.tempOrderModalField[category] === field) {
                    _this.reverseModalOrdering[category] = !_this.reverseModalOrdering[category];
                }
                else {
                    _this.reverseModalOrdering[category] = false;
                }
                _this.tempOrderModalField[category] = field;
            };
            PortfolioHoldingsController.prototype.goToSimpleTrade = function ($event, productObjId, mode) {
                var _this = this;
                var backUrl;
                if (productObjId == 'null' || productObjId == undefined) {
                    var productObjId = $($event.currentTarget).data('prod');
                }
                $($event.currentTarget).trigger('mouseleave');
                backUrl = _this.$location.path();
                _this.$location.path('/page/' + _this.simpleTradePageId).search({
                    md: mode,
                    t: 'fund',
                    s: 1,
                    pid: productObjId,
                    bu: backUrl
                });
            };
            PortfolioHoldingsController.prototype.getProductMovements = function (prod, categoryKey) {
                var _this = this;
                this.$timeout(function () {
                    _this.widgetConfig.status.mode = "waiting";
                    if (_this.categoriesMapForModalUnderlyingDesktop[categoryKey] != undefined) {
                        _this.selectedUnderlyingsCategory = categoryKey;
                    }
                    else {
                        _this.selectedUnderlyingsCategory = "DEFAULT";
                    }
                    _this.productMovements = [];
                    _this.productUnderlying = [];
                    _this.productDetailsLink = "";
                    _this.productKidLink = "";
                    _this.waitForProductsList = true;
                    _this.totalUnderliying = undefined;
                    _this.totalUnderliyingCurrency = undefined;
                    _this.selectedProduct = prod;
                    _this.showButtonsForUnderlyings = false;
                    console.log(prod.holdingObjId);
                    _this.transactionsUrl = apiPrefix + "/portfolio/transactions/" + prod.holdingObjId;
                    _this.exportXLSUrlForUnderlyings = apiPrefix + "/portfolio/details/xls?holdingCode=" + prod.holdingObjId + "&productCode=" + prod.productIdentificationCode1Value + "&firstHolder=" + !sessionStorage.getItem('coowner');
                    _this.exportPDFUrlForUnderlyings = apiPrefix + "/portfolio/details/pdf?holdingCode=" + prod.holdingObjId + "&productCode=" + prod.productIdentificationCode1Value + "&firstHolder=" + !sessionStorage.getItem('coowner');
                    _this.portfolioCoreService.productDetails.get({
                        holdingCode: prod.holdingObjId,
                        productCode: prod.productIdentificationCode1Value,
                        firstHolder: !sessionStorage.getItem('coowner')
                    }, function (response) {
                        if (response.outcome === 'OK') {
                            if (!!response.content && !!response.content.portfolioDetails) {
                                for (var i = 0; i < response.content.portfolioDetails.length; i++) {
                                    var movement = response.content.portfolioDetails[i];
                                    switch (movement.name) {
                                        case "transactions":
                                            _this.productMovements = movement.details;
                                            _this.waitForProductsList = false;
                                            break;
                                        case "underlings":
                                            _this.showButtonsForUnderlyings = categoryKey == 'GPM';
                                            _this.productUnderlying = movement.details;
                                            _this.totalUnderliying = movement.total;
                                            _this.totalUnderliyingCurrency = movement.currency;
                                            break;
                                        case "productDetailsLink":
                                            _this.productDetailsLink = (movement.details && movement.details[0] != null) ? _this.$sce.trustAsResourceUrl(movement.details[0]) : "";
                                            break;
                                        case "productKidLink":
                                            _this.productKidLink = (movement.details && movement.details[0] != null) ? _this.$sce.trustAsResourceUrl(movement.details[0]) : "";
                                            break;
                                    }
                                }
                                _this.amountBankTransferFia = prod.amountBankTransferFia || {};
                                _this.amountBankTransferFia.productName = prod.productName;
                                _this.detailsTabIndex = 0;
                            }
                            _this.modalPagination.updateItems(_this.productMovements);
                            _this.widgetConfig.status.mode = "on";
                            _this.$scope.$evalAsync();
                            console.log(_this.productMovements.length);
                        }
                        else {
                            _this.widgetConfig.status.mode = 'error';
                            if (response.messageKey == 'user_not_authenticated') {
                                _this.EventService.publish('user-session-changed');
                            }
                            _this.widgetConfig.status.message = "dashboard.error." + response.messageKey;
                            _this.EventService.publish('closeModal');
                        }
                    }, function () {
                        _this.widgetConfig.status.mode = "error";
                        _this.EventService.publish('closeModal');
                        _this.$scope.$evalAsync();
                    });
                }, 0);
            };
            PortfolioHoldingsController.prototype.changePage = function (index) {
                var _this = this;
                this.$timeout(function () {
                    _this.changingModalPage = true;
                    _this.modalPagination.changePage(index);
                }, 0);
                this.$timeout(function () {
                    _this.changingModalPage = false;
                }, 10);
            };
            PortfolioHoldingsController.prototype.openAccordionGroup = function (categoryInd, $index) {
                var _this = this;
                this.widgetConfig.status.mode = "waiting";
                if (!this.accordionGroup[categoryInd]) {
                    this.accordionGroup[categoryInd] = [];
                }
                this.accordionGroup[categoryInd][$index] = !this.accordionGroup[categoryInd][$index];
                this.$timeout(function () {
                    _this.widgetConfig.status.mode = "on";
                }, 0);
            };
            PortfolioHoldingsController.prototype.isFirstHolder = function () {
                return !sessionStorage.getItem('coowner');
            };
            PortfolioHoldingsController.prototype.goToFocusPage = function () {
                this.$location.path('focus');
            };
            PortfolioHoldingsController.prototype.getDisclaimer = function (linkId) {
                var disclaimer = this.disclaimers.filter(function (disclaimer) {
                    return disclaimer.linkId === linkId;
                })[0];
                return !!disclaimer ? disclaimer.text : '';
            };
            PortfolioHoldingsController.prototype.getMovementsFiaDisclaimer = function () {
                var disclaimer = this.movementsFiaDisclaimer;
                return !!disclaimer && !!this.amountBankTransferFia.value ? disclaimer.replace('{{productName}}', this.amountBankTransferFia.productName).replace('{{amount}}', this.$filter('number')(this.amountBankTransferFia.value)).replace('{{currency}}', this.amountBankTransferFia.currency) : '';
            };
            PortfolioHoldingsController.prototype.isRealEconomyCategory = function (categoryKey) {
                return categoryKey === '011';
            };
            PortfolioHoldingsController.prototype.showRealEconomyTotalDisclaimer = function (categoryKey) {
                return this.isRealEconomyCategory(categoryKey) && realEconomyUsePaidAmount;
            };
            PortfolioHoldingsController.prototype.getDisclaimerIndex = function (linkId) {
                var disclaimer = this.disclaimers.filter(function (disclaimer) {
                    return disclaimer.linkId === linkId;
                })[0];
                return !!disclaimer ? disclaimer.text.match(/([0-9])/g)[0] : '';
            };
            PortfolioHoldingsController.prototype.handlerDisclaimers = function (hasRealEconomyCategory) {
                var _this = this;
                if (!realEconomyUsePaidAmount) {
                    var indexREPaidAmount = this.disclaimers[0].items.findIndex(function (disclaimer) {
                        return disclaimer.linkId === "realEconomyTotal";
                    });
                    this.disclaimers[0].items.splice(indexREPaidAmount, 1);
                }
                var indexREFia = this.disclaimers[0].items.findIndex(function (disclaimer) {
                    return disclaimer.linkId === "realEconomyFiaTotal";
                });
                if (indexREFia > -1 && !hasRealEconomyCategory) {
                    this.disclaimers[0].items.splice(indexREFia, 1);
                }
                else {
                    if (indexREFia > -1) {
                        var fiaTotal = this.disclaimers[0].items.splice(indexREFia, 1)[0];
                        this.disclaimers[0].items.unshift(fiaTotal);
                    }
                }
                var disclaimerMovementsFiaIndex = this.disclaimers[0].items.findIndex(function (disclaimer) {
                    return disclaimer.linkId === 'movementsFia';
                });
                var disclaimerMovementsFia = this.disclaimers[0].items.filter(function (disclaimer) {
                    return disclaimer.linkId === 'movementsFia';
                })[0];
                this.movementsFiaDisclaimer = disclaimerMovementsFia ? disclaimerMovementsFia.text : '';
                this.disclaimers[0].items.splice(disclaimerMovementsFiaIndex, 1);
                var supList = [];
                Object.keys(this.disclaimerForCategoriesMap).forEach(function (cat) { return supList = supList.concat(_this.disclaimerForCategoriesMap[cat]); });
                var disclaimerPirFia = this.disclaimers[0].items.findIndex(function (disclaimer) {
                    return disclaimer.linkId === 'pirFia';
                });
                if (supList.indexOf('pirFia'))
                    this.disclaimers[0].items.splice(disclaimerPirFia, 1);
                var disclaimerPirUcits = this.disclaimers[0].items.findIndex(function (disclaimer) {
                    return disclaimer.linkId === 'pirUcits';
                });
                if (supList.indexOf('pirUcits'))
                    this.disclaimers[0].items.splice(disclaimerPirUcits, 1);
                var disclaimerMergedFundsHoldings = this.disclaimers[0].items.findIndex(function (disclaimer) {
                    return disclaimer.linkId === 'mergedFundsHoldings';
                });
                if (supList.indexOf('mergedFundsHoldings'))
                    this.disclaimers[0].items.splice(disclaimerMergedFundsHoldings, 1);
            };
            PortfolioHoldingsController.prototype.enableFocusLink = function () {
                return env.enableFocusLink === 'true';
            };
            PortfolioHoldingsController.prototype.showProductTitleDisclaimers = function (type) {
                var _this = this;
                return Object.keys(this.enableProductTitleSupMap).map(function (productName) {
                    var index = _this.enableProductTitleSupMap[productName].indexOf(type);
                    if (index > -1)
                        return _this.enableProductTitleSupMap[productName][index];
                }).length > 0;
            };
            PortfolioHoldingsController.prototype.showDisclaimerForCategory = function (category, disclaimerName) {
                return this.disclaimerForCategoriesMap[category] && this.disclaimerForCategoriesMap[category].indexOf(disclaimerName) > -1;
            };
            PortfolioHoldingsController.prototype.downloadFile = function (type) {
                var _this = this;
                var params = "" + type + "?grouping1Level=" + _this.perspectiveId + "&grouping1PerspectiveId=" + _this.perspectiveLevel + "&firstHolder=" + !sessionStorage.getItem("coowner");
                var ndgListParam = '';
                _this.portfolioCoreService['dossierList'].forEach(function (dossier) {
                    if (dossier.ndg !== _this.profileService.loggedUser.userInfos.USER_CODE.value) {
                        if (dossier.checked) {
                            var contractParam = '';
                            ndgListParam += 'dossier=' + dossier.ndg + '_';
                            dossier.contractElements.forEach(function (contract) {
                                if (contract.checked) {
                                    contractParam += contract.code + ';';
                                }
                            });
                            if (!contractParam.length) {
                                ndgListParam = ndgListParam.slice(0, ndgListParam.length - 1);
                            }
                            ndgListParam += contractParam.slice(0, contractParam.length - 1) + '&';
                        }
                    }
                });
                var fileURL = "" + window.location.protocol + "//" + window.location.host + apiPrefix + "/portfolio/holdings/exp/" + params + "&" + ndgListParam.slice(0, ndgListParam.length - 1);
                if (!_this.isTouch()) {
                    window.open(fileURL, '_blank');
                }
                else {
                    showFile(fileURL, null, null, null);
                }
            };
            PortfolioHoldingsController.prototype.downloadXls = function () {
                var _this = this;
                _this.portfolioCoreService.downloadHoldingsFile(_this.portfolioCoreService['dossierList'], "xls?grouping1Level=" + _this.perspectiveId + "&grouping1PerspectiveId=" + _this.perspectiveLevel + "&firstHolder=" + !sessionStorage.getItem("coowner")).then(function (response) {
                    if (!_this.isTouch()) {
                        saveAs(new Blob([response.data], {
                            type: 'application/xls;charset=utf-8',
                        }), "portfolio_holdings.xls");
                    }
                    else {
                        var file = new Blob([response.data], { type: 'application/vnd.ms-excel' });
                        var fileURL = URL.createObjectURL(file);
                        showFile(fileURL, null, null, null);
                    }
                });
            };
            PortfolioHoldingsController.prototype.showInfo = function (flag, field) {
                if (field === 'histAveragePurchasePrice' || field === 'closePrice' || field === 'commitment' || field === 'priceDate') {
                    return flag != 1;
                }
                else {
                    return true;
                }
            };
            return PortfolioHoldingsController;
        })();
        PortfolioHoldings.PortfolioHoldingsController = PortfolioHoldingsController;
    })(PortfolioHoldings = exports.PortfolioHoldings || (exports.PortfolioHoldings = {}));
});

