define('../bower_components/dwx-widget-unlock-user/src/services/unlockUserService',["require", "exports"], function (require, exports) {
    var UnlockUserService = (function () {
        function UnlockUserService($resource) {
            return {
                unlockUser: $resource(apiPrefix + "/accountManagement/unlockAccount?token=:token", { token: '@token' }, {
                    save: {
                        method: 'POST'
                    }
                })
            };
        }
        return UnlockUserService;
    })();
    exports.UnlockUserService = UnlockUserService;
});

