define('layout-base/../../bower_components/dwx-utility/src/directives/hasTooltipDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var HasTooltipDirective = (function () {
            function HasTooltipDirective($timeout) {
                this.restrict = 'A';
                this.scope = { titleBundled: '@' };
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(scope, element, $timeout);
                };
            }
            HasTooltipDirective.prototype.myLink = function (scope, element, $timeout) {
                scope.$watch('titleBundled', function () {
                    if (typeof hasTooltip === 'undefined') {
                        hasTooltip = $("<div class='tooltip'></div>");
                        $('body').append(hasTooltip);
                    }
                    $timeout(function () {
                        if (!$(element).attr("title")) {
                            !$(element).attr("title", scope.titleBundled);
                        }
                        $(element).data("title", $(element).attr("title")).removeAttr("title");
                        if (!Modernizr.touch) {
                            $(element).bind("mouseover", function (event) {
                                hasTooltip.text($(element).data("title")).css("top", $(element).offset().top).css("left", $(element).offset().left).addClass("open");
                                return $timeout(function () {
                                    return hasTooltip.css("margin-top", hasTooltip.outerHeight() * -1);
                                });
                            });
                            $(element).mouseleave(function (event) {
                                return hasTooltip.removeClass("open");
                            });
                        }
                        else {
                            $(element).bind("touchstart", function (event) {
                                hasTooltip.text($(element).data("title")).css("top", $(element).offset().top).css("left", $(element).offset().left).addClass("open");
                                return $timeout(function () {
                                    hasTooltip.css("margin-top", hasTooltip.outerHeight() * -1);
                                    $timeout(function () {
                                        return hasTooltip.removeClass("open");
                                    }, 3000);
                                });
                            });
                        }
                    });
                });
            };
            HasTooltipDirective.Factory = function () {
                var directive = function ($timeout) {
                    return new HasTooltipDirective($timeout);
                };
                directive['$inject'] = ['$timeout'];
                return directive;
            };
            return HasTooltipDirective;
        })();
        Utility.HasTooltipDirective = HasTooltipDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

