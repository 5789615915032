define('layout-base/../../bower_components/dwx-layout-base/src/local-layout-configurations/header-widget-config',["require", "exports"], function (require, exports) {
    exports.config = {
        11: {
            "weight": null,
            "id": 122,
            "name": "userDataForHeaderOnboarding",
            "status": "online",
            "keyName": null,
            "view": "HeaderView",
            "containerId": null,
            "widgetTemplate": {
                "id": 8,
                "name": "user-data-advanced",
                "minWidth": null,
                "maxWidth": null,
                "defaultWidth": null,
                "htmlPageUrl": null,
                "tileUrl": null,
                "keyName": null,
                "widgetTemplateCatalogId": 1,
                "infos": {
                    "ALLOW_OVERFLOW": {
                        "id": 17,
                        "name": "ALLOW_OVERFLOW",
                        "type": "String",
                        "defaultValue": null,
                        "mandatory": false,
                        "widgetTemplateId": 8
                    },
                    "USERDATA_PROVIDER": {
                        "id": 18,
                        "name": "USERDATA_PROVIDER",
                        "type": "String",
                        "defaultValue": null,
                        "mandatory": true,
                        "widgetTemplateId": 8
                    },
                    "CONFIGURATION": {
                        "id": 20,
                        "name": "CONFIGURATION",
                        "type": "Json",
                        "defaultValue": null,
                        "mandatory": true,
                        "widgetTemplateId": 8
                    },
                    "TITLE": {
                        "id": 19,
                        "name": "TITLE",
                        "type": "String",
                        "defaultValue": null,
                        "mandatory": false,
                        "widgetTemplateId": 8
                    },
                    "USER_DATA_PAGE_ID": {
                        "id": 21,
                        "name": "USER_DATA_PAGE_ID",
                        "type": "String",
                        "defaultValue": null,
                        "mandatory": true,
                        "widgetTemplateId": 8
                    }
                },
                "operations": {}
            },
            "widgetInfos": {
                "ALLOW_OVERFLOW": {
                    "id": 326,
                    "name": "ALLOW_OVERFLOW",
                    "value": "0",
                    "arrayValue": null,
                    "widgetTemplateInfo": {
                        "id": 8,
                        "name": "ALLOW_OVERFLOW",
                        "type": "String",
                        "defaultValue": null,
                        "mandatory": false,
                        "widgetTemplateId": 4
                    },
                    "widgetId": 122
                },
                "CONFIGURATION": {
                    "id": 324,
                    "name": "CONFIGURATION",
                    "value": "{\"configurationData\":[{\"fieldName\":\"name\",\"action\":null},{\"fieldName\":\"password\",\"action\":null},{\"fieldName\":\"mail\",\"action\":null},{\"fieldName\":\"telephoneNumber\",\"action\":null},{\"fieldName\":\"newMail\",\"action\":null},{\"fieldName\":\"newTelephoneNumber\",\"action\":null}]}",
                    "arrayValue": null,
                    "widgetTemplateInfo": {
                        "id": 8,
                        "name": "ALLOW_OVERFLOW",
                        "type": "String",
                        "defaultValue": null,
                        "mandatory": false,
                        "widgetTemplateId": 4
                    },
                    "widgetId": 122
                },
                "TITLE": {
                    "id": 325,
                    "name": "TITLE",
                    "value": "userDataForHeaderOnboarding",
                    "arrayValue": null,
                    "widgetTemplateInfo": {
                        "id": 8,
                        "name": "ALLOW_OVERFLOW",
                        "type": "String",
                        "defaultValue": null,
                        "mandatory": false,
                        "widgetTemplateId": 4
                    },
                    "widgetId": 122
                }
            },
            "visible": true
        }
    };
});

