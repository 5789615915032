define('../bower_components/dwx-analytics-tracker/src/run',["require", "exports"], function (require, exports) {
    var AnalyticsTrackerRun = (function () {
        function AnalyticsTrackerRun($rootScope, ProfileService, EventService) {
            var _this = this;
            this.$rootScope = $rootScope;
            this.ProfileService = ProfileService;
            this.EventService = EventService;
            this.currentUserId = "anonymous";
            this.initialize = function () {
                _this.EventService.subscribe("new-profile-loaded", function () {
                    _this.setCurrentUserName();
                });
                _this.$rootScope.$on("$routeChangeSuccess", function (event, current) {
                    var currentPage = current && current.params && current.params.hasOwnProperty('pageId') ? current.params.pageId : "landing";
                    if (currentPage == "landing" && (!settings || !settings.userLoggedIn)) {
                        currentPage = "login";
                    }
                    newrelic.addPageAction("navigate to", { "userId": _this.currentUserId, "pageId": currentPage });
                });
            };
            this.setCurrentUserName = function () {
                _this.currentUserId = _this.ProfileService.loggedUser.id || "anonymous";
                newrelic.setCustomAttribute("userId", _this.currentUserId);
            };
            if (typeof newrelic != "undefined") {
                this.initialize();
            }
        }
        return AnalyticsTrackerRun;
    })();
    exports.AnalyticsTrackerRun = AnalyticsTrackerRun;
});

