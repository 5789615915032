define('../bower_components/dwx-widget-media-library/src/services/mediaLibraryService',["require", "exports"], function (require, exports) {
    var MediaLibraryService = (function () {
        function MediaLibraryService($resource) {
            return {
                getPlayList: $resource(apiPrefix + "/medialib/plid/:playlistId/:mediaCount", { playlistId: '@playlistId', mediaCount: '@mediaCount' }, {
                    save: {
                        method: 'POST'
                    }
                }),
                getPlayListMock: $resource("bower_components/dwx-widget-media-library/mock/mock.json", {}, {
                    get: {}
                }),
            };
        }
        return MediaLibraryService;
    })();
    exports.MediaLibraryService = MediaLibraryService;
});

