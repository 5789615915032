define('widget-portfolio-summary/widget-portfolio-summary',["require", "exports", './config', '../../bower_components/dwx-widget-portfolio-summary/src/app', '../../bower_components/dwx-widget-portfolio-summary/src/controllers/portfolioSummaryCtrl'], function (require, exports, config, WidgetPortfolioSummary, WidgetPortfolioSummaryController) {
    var newList = WidgetPortfolioSummary.WidgetPortfolioSummary.configuration.list;
    newList['portfolioSummaryConfig'] = { callback: ['$translateProvider', config.PortfolioSummaryConfig], type: "config" };
    newList['dwxPortfolioSummaryCtrl'] = {
        callback: ["$scope", "EventService", "portfolioCoreService", "initConfigService", "configurationService", "profileService", "$location", WidgetPortfolioSummaryController.PortfolioSummaryCtrl],
        type: 'controller'
    };
    WidgetPortfolioSummary.WidgetPortfolioSummary.configuration.list = newList;
    exports.configuration = WidgetPortfolioSummary.WidgetPortfolioSummary.configuration;
});

