define('layout-base/../../bower_components/dwx-layout-base/src/controllers/headerCtrl',["require", "exports"], function (require, exports) {
    exports.subscribeToPortolioSelectedEvent = true;
    var Layout;
    (function (Layout) {
        var HeaderController = (function () {
            function HeaderController($scope, headerService, EventService, headerApiService, profileService) {
                this.headerService = headerService;
                this.EventService = EventService;
                this.headerApiService = headerApiService;
                this.profileService = profileService;
                var eventHandler;
                var portfolioSelectedEventHandler;
                var _this = this;
                _this.headerItems = [];
                _this.getHeaderItems();
                eventHandler = EventService.subscribe('user-session-changed', function () {
                    _this.getHeaderItems();
                }, false);
                if (exports.subscribeToPortolioSelectedEvent) {
                    portfolioSelectedEventHandler = EventService.subscribe('portfolioSelected', function () {
                        _this.getHeaderItems();
                    }, false);
                }
                $scope.$on("$destroy", function () {
                    EventService.unsubscribe(eventHandler, false);
                    if (exports.subscribeToPortolioSelectedEvent) {
                        EventService.unsubscribe(portfolioSelectedEventHandler, false);
                    }
                });
            }
            HeaderController.prototype.closeSidebar = function () {
                this.EventService.publish('close-sidebar');
            };
            HeaderController.prototype.getHeaderItems = function () {
                var _this = this;
                _this.headerService.header.get({}, function (response) {
                    if (response.outcome === 'OK') {
                        if (response.content) {
                            _this.headerItems = response.content;
                        }
                    }
                });
            };
            HeaderController.prototype.showResetLink = function () {
                var _this = this;
                var check = !!_this.profileService.loggedUser && !!_this.profileService.loggedUser.userInfos.ACCESSORY_USER_CODE && !!_this.profileService.loggedUser.userInfos.ACCESSORY_USER_CODE.value;
                if (check)
                    _this.trustCompanyCode = _this.profileService.loggedUser.userInfos.ACCESSORY_USER_NOMINATIVE.value;
                return check;
            };
            HeaderController.prototype.resetTrustCompany = function () {
                var _this = this;
                _this.headerApiService.resetTrustCompany();
            };
            return HeaderController;
        })();
        Layout.HeaderController = HeaderController;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

