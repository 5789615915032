define('widget-support-page/../../bower_components/dwx-widget-support-page/src/services/supportPageService',["require", "exports"], function (require, exports) {
    var SupportPageService = (function () {
        function SupportPageService($resource) {
            return {
                retrieveFAQs: $resource(apiPrefix + "/widget/survey/faqs", {})
            };
        }
        return SupportPageService;
    })();
    exports.SupportPageService = SupportPageService;
});

