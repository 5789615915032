define('layout-base/../../bower_components/dwx-layout-base/src/local-layout-configurations/portoflioPage',["require", "exports"], function (require, exports) {
    exports.config = {
        "1": [
            {
                "id": 142,
                "position": 1,
                "rowNum": 1,
                "width": 12,
                "offset": 0,
                "containingPageId": 9,
                "containingPageComponentId": null,
                "containedPageComponents": {},
                "widget": {
                    "weight": null,
                    "id": 142,
                    "name": "PortfolioPageOwnershipView",
                    "status": "online",
                    "keyName": null,
                    "view": "Widget",
                    "containerId": 142,
                    "widgetTemplate": {
                        "id": 61,
                        "name": "portfolio-ownership-view",
                        "minWidth": null,
                        "maxWidth": null,
                        "defaultWidth": null,
                        "htmlPageUrl": null,
                        "tileUrl": null,
                        "keyName": null,
                        "widgetTemplateCatalogId": 1,
                        "infos": {},
                        "operations": {}
                    },
                    "widgetInfos": {
                        "ALLOW_OVERFLOW": {
                            "id": 343,
                            "name": "ALLOW_OVERFLOW",
                            "value": "0",
                            "arrayValue": null,
                            "widgetTemplateInfo": {
                                "id": 61,
                                "name": "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_SMARTPHONE",
                                "type": "Json",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 11
                            },
                            "widgetId": 142
                        },
                        "TITLE": {
                            "id": 344,
                            "name": "TITLE",
                            "value": "PortfolioPageOwnershipView",
                            "arrayValue": null,
                            "widgetTemplateInfo": {
                                "id": 61,
                                "name": "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_SMARTPHONE",
                                "type": "Json",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 11
                            },
                            "widgetId": 142
                        }
                    },
                    "visible": true
                }
            }
        ],
        "2": [
            {
                "id": 21,
                "position": 1,
                "rowNum": 2,
                "width": 12,
                "offset": 0,
                "containingPageId": 9,
                "containingPageComponentId": null,
                "containedPageComponents": {},
                "widget": {
                    "weight": null,
                    "id": 20,
                    "name": "DossierSelector_inPtfPage",
                    "status": "online",
                    "keyName": null,
                    "view": "Widget",
                    "containerId": 21,
                    "widgetTemplate": {
                        "id": 15,
                        "name": "dossier-selector",
                        "minWidth": null,
                        "maxWidth": null,
                        "defaultWidth": null,
                        "htmlPageUrl": null,
                        "tileUrl": null,
                        "keyName": null,
                        "widgetTemplateCatalogId": 1,
                        "infos": {
                            "ALLOW_OVERFLOW": {
                                "id": 79,
                                "name": "ALLOW_OVERFLOW",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 15
                            },
                            "TITLE": {
                                "id": 80,
                                "name": "TITLE",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 15
                            }
                        },
                        "operations": {}
                    },
                    "widgetInfos": {
                        "ALLOW_OVERFLOW": {
                            "id": 85,
                            "name": "ALLOW_OVERFLOW",
                            "value": "1",
                            "arrayValue": null,
                            "widgetTemplateInfo": {
                                "id": 79,
                                "name": "ALLOW_OVERFLOW",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 15
                            },
                            "widgetId": 20
                        },
                        "TITLE": {
                            "id": 86,
                            "name": "TITLE",
                            "value": "dossier_selector",
                            "arrayValue": null,
                            "widgetTemplateInfo": {
                                "id": 80,
                                "name": "TITLE",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 15
                            },
                            "widgetId": 20
                        }
                    },
                    "visible": true
                }
            }
        ],
        "3": [
            {
                "id": 48,
                "position": 1,
                "rowNum": 2,
                "width": 12,
                "offset": 0,
                "containingPageId": 25,
                "containingPageComponentId": null,
                "containedPageComponents": {},
                "widget": {
                    "weight": null,
                    "id": 51,
                    "name": "PtfSummary_inHomePage",
                    "status": "online",
                    "keyName": null,
                    "view": "Widget",
                    "containerId": 48,
                    "widgetTemplate": {
                        "id": 9,
                        "name": "portfolio-summary",
                        "minWidth": null,
                        "maxWidth": null,
                        "defaultWidth": null,
                        "htmlPageUrl": null,
                        "tileUrl": null,
                        "keyName": null,
                        "widgetTemplateCatalogId": 1,
                        "infos": {
                            "ALLOW_OVERFLOW": {
                                "id": 29,
                                "name": "ALLOW_OVERFLOW",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "SUPPORTED_VIEW": {
                                "id": 24,
                                "name": "SUPPORTED_VIEW",
                                "type": "Array",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "FILTER_IN_MENU": {
                                "id": 27,
                                "name": "FILTER_IN_MENU",
                                "type": "Array",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "DEFAULT_VIEW": {
                                "id": 31,
                                "name": "DEFAULT_VIEW",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "PORTFOLIO_PAGE_ID": {
                                "id": 25,
                                "name": "PORTFOLIO_PAGE_ID",
                                "type": "Long",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "GROUPING_MODE": {
                                "id": 26,
                                "name": "GROUPING_MODE",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "DEFAULT_PERSPECTIVE_LEVEL": {
                                "id": 32,
                                "name": "DEFAULT_PERSPECTIVE_LEVEL",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "HAS_FILTER_IN_MENU": {
                                "id": 33,
                                "name": "HAS_FILTER_IN_MENU",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "HAS_MENU": {
                                "id": 28,
                                "name": "HAS_MENU",
                                "type": "boolean",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "NUMBER_OF_ITEMS": {
                                "id": 23,
                                "name": "NUMBER_OF_ITEMS",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "DEFAULT_PORTFOLIO": {
                                "id": 30,
                                "name": "DEFAULT_PORTFOLIO",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "TITLE": {
                                "id": 34,
                                "name": "TITLE",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "DEFAULT_PERSPECTIVE": {
                                "id": 22,
                                "name": "DEFAULT_PERSPECTIVE",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            }
                        },
                        "operations": {
                            "PORTFOLIO_SUMMARY_LIST": {
                                "id": 190,
                                "name": "PORTFOLIO_SUMMARY_LIST"
                            },
                            "PORTFOLIO_HOLDINGS_PER_SUMMARY": {
                                "id": 188,
                                "name": "PORTFOLIO_HOLDINGS_PER_SUMMARY"
                            },
                            "PORTFOLIO_SUMMARY_BY_ID": {
                                "id": 189,
                                "name": "PORTFOLIO_SUMMARY_BY_ID"
                            }
                        }
                    },
                    "widgetInfos": {
                        "ALLOW_OVERFLOW": {
                            "id": 217,
                            "name": "ALLOW_OVERFLOW",
                            "value": "0",
                            "arrayValue": null,
                            "widgetTemplateInfo": {
                                "id": 29,
                                "name": "ALLOW_OVERFLOW",
                                "type": "String",
                                "defaultValue": null,
                                "mandatory": false,
                                "widgetTemplateId": 9
                            },
                            "widgetId": 51
                        },
                    },
                    "visible": true
                }
            }
        ],
        "4": [
            {
                "id": 1,
                "position": 1,
                "rowNum": 4,
                "width": 12,
                "offset": 0,
                "containingPageId": 9,
                "containingPageComponentId": null,
                "containedPageComponents": {
                    "1": [
                        {
                            "id": 3,
                            "position": 1,
                            "rowNum": 1,
                            "width": 12,
                            "offset": 0,
                            "containingPageId": null,
                            "containingPageComponentId": 1,
                            "containedPageComponents": {},
                            "widget": {
                                "weight": null,
                                "id": 2,
                                "name": "PtfHoldings_inPtfPage",
                                "status": "online",
                                "keyName": null,
                                "view": "Widget",
                                "containerId": 3,
                                "widgetTemplate": {
                                    "id": 11,
                                    "name": "portfolio-holdings",
                                    "minWidth": null,
                                    "maxWidth": null,
                                    "defaultWidth": null,
                                    "htmlPageUrl": null,
                                    "tileUrl": null,
                                    "keyName": null,
                                    "widgetTemplateCatalogId": 1,
                                    "infos": {
                                        "ALLOW_OVERFLOW": {
                                            "id": 62,
                                            "name": "ALLOW_OVERFLOW",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "SUPPORTED_VIEW": {
                                            "id": 54,
                                            "name": "SUPPORTED_VIEW",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "FILTER_IN_MENU": {
                                            "id": 60,
                                            "name": "FILTER_IN_MENU",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "CUSTOM_TABLE_FIELDS_FOR_SMARTPHONE": {
                                            "id": 53,
                                            "name": "CUSTOM_TABLE_FIELDS_FOR_SMARTPHONE",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_DESKTOP": {
                                            "id": 63,
                                            "name": "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_DESKTOP",
                                            "type": "Json",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "CUSTOM_TABLE_FIELDS_FOR_TABLET": {
                                            "id": 55,
                                            "name": "CUSTOM_TABLE_FIELDS_FOR_TABLET",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "DEFAULT_VIEW": {
                                            "id": 68,
                                            "name": "DEFAULT_VIEW",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "TABLE_FIELDS_CURRENCY": {
                                            "id": 57,
                                            "name": "TABLE_FIELDS_CURRENCY",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_TABLET": {
                                            "id": 69,
                                            "name": "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_TABLET",
                                            "type": "Json",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "SIMPLE_TRADE_PAGE_ID": {
                                            "id": 58,
                                            "name": "SIMPLE_TRADE_PAGE_ID",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": true,
                                            "widgetTemplateId": 11
                                        },
                                        "DEFAULT_PERSPECTIVE_LEVEL": {
                                            "id": 67,
                                            "name": "DEFAULT_PERSPECTIVE_LEVEL",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "HAS_FILTER_IN_MENU": {
                                            "id": 70,
                                            "name": "HAS_FILTER_IN_MENU",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "TABLE_FIELDS_PERCENTAGE": {
                                            "id": 56,
                                            "name": "TABLE_FIELDS_PERCENTAGE",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "HAS_MENU": {
                                            "id": 64,
                                            "name": "HAS_MENU",
                                            "type": "boolean",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "TABLE_FIELDS_TEXT_RIGHT": {
                                            "id": 51,
                                            "name": "TABLE_FIELDS_TEXT_RIGHT",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "TABLE_TO_DISPLAY": {
                                            "id": 65,
                                            "name": "TABLE_TO_DISPLAY",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_SMARTPHONE": {
                                            "id": 61,
                                            "name": "CUSTOM_TABLE_FIELDS_CATEGORIES_MAP_FOR_SMARTPHONE",
                                            "type": "Json",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "DEFAULT_TABLE_FIELDS": {
                                            "id": 50,
                                            "name": "DEFAULT_TABLE_FIELDS",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": true,
                                            "widgetTemplateId": 11
                                        },
                                        "TABLE_FIELDS_NO_WRAP": {
                                            "id": 52,
                                            "name": "TABLE_FIELDS_NO_WRAP",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "TABLE_FIELDS_DATE": {
                                            "id": 59,
                                            "name": "TABLE_FIELDS_DATE",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "CUSTOM_TABLE_FIELDS_FOR_DESKTOP": {
                                            "id": 66,
                                            "name": "CUSTOM_TABLE_FIELDS_FOR_DESKTOP",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "TITLE": {
                                            "id": 71,
                                            "name": "TITLE",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "DEFAULT_PERSPECTIVE": {
                                            "id": 48,
                                            "name": "DEFAULT_PERSPECTIVE",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "TABLE_FIELDS_TO_ROUND": {
                                            "id": 49,
                                            "name": "TABLE_FIELDS_TO_ROUND",
                                            "type": "Json",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        }
                                    },
                                    "operations": {
                                        "READ_PORTFOLIO_TRANSACTIONS": {
                                            "id": 197,
                                            "name": "READ_PORTFOLIO_TRANSACTIONS"
                                        },
                                        "PORTFOLIO_HOLDINGS_BY_ID_EXPORT_XLS": {
                                            "id": 186,
                                            "name": "PORTFOLIO_HOLDINGS_BY_ID_EXPORT_XLS"
                                        },
                                        "PORTFOLIO_HOLDINGS_BY_ID": {
                                            "id": 185,
                                            "name": "PORTFOLIO_HOLDINGS_BY_ID"
                                        },
                                        "PORTFOLIO_PRODUCT_DATASHEET": {
                                            "id": 343,
                                            "name": "PORTFOLIO_PRODUCT_DATASHEET"
                                        },
                                        "PORTFOLIO_SUMMARY_LIST": {
                                            "id": 190,
                                            "name": "PORTFOLIO_SUMMARY_LIST"
                                        },
                                        "PORTFOLIO_DETAILS_EXPORT_XLS": {
                                            "id": 248,
                                            "name": "PORTFOLIO_DETAILS_EXPORT_XLS"
                                        },
                                        "PORTFOLIO_HOLDINGS_PERSPECTIVE": {
                                            "id": 187,
                                            "name": "PORTFOLIO_HOLDINGS_PERSPECTIVE"
                                        },
                                        "PORTFOLIO_DETAILS_EXPORT_PDF": {
                                            "id": 247,
                                            "name": "PORTFOLIO_DETAILS_EXPORT_PDF"
                                        }
                                    }
                                },
                                "widgetInfos": {
                                    "ALLOW_OVERFLOW": {
                                        "id": 5,
                                        "name": "ALLOW_OVERFLOW",
                                        "value": "0",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 62,
                                            "name": "ALLOW_OVERFLOW",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "SUPPORTED_VIEW": {
                                        "id": 7,
                                        "name": "SUPPORTED_VIEW",
                                        "value": null,
                                        "arrayValue": [
                                            "Table",
                                            "Donut"
                                        ],
                                        "widgetTemplateInfo": {
                                            "id": 54,
                                            "name": "SUPPORTED_VIEW",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "CUSTOM_TABLE_FIELDS_FOR_SMARTPHONE": {
                                        "id": 10,
                                        "name": "CUSTOM_TABLE_FIELDS_FOR_SMARTPHONE",
                                        "value": null,
                                        "arrayValue": [
                                            "productName",
                                            "accountNumber",
                                            "shareNumber",
                                            "histAveragePurchasePrice",
                                            "histIndexPercentage",
                                            "marketValue",
                                            "price",
                                            "priceDate",
                                            "ytdUnRealizedProfitLossInVC",
                                            "histAverageFxRate",
                                            "fxRate",
                                            "histIndexAnnualPercentage",
                                            "cashBalanceAccounting",
                                            "cashBalanceAccountingDate",
                                            "cashBalanceAvailable",
                                            "cashBalanceAvailableDate",
                                            "cashBalanceLiquid",
                                            "cashBalanceLiquidDate"
                                        ],
                                        "widgetTemplateInfo": {
                                            "id": 53,
                                            "name": "CUSTOM_TABLE_FIELDS_FOR_SMARTPHONE",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "DEFAULT_VIEW": {
                                        "id": 12,
                                        "name": "DEFAULT_VIEW",
                                        "value": "Table",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 68,
                                            "name": "DEFAULT_VIEW",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "CUSTOM_TABLE_FIELDS_FOR_TABLET": {
                                        "id": 13,
                                        "name": "CUSTOM_TABLE_FIELDS_FOR_TABLET",
                                        "value": null,
                                        "arrayValue": [
                                            "productName",
                                            "accountNumber",
                                            "shareNumber",
                                            "histAveragePurchasePrice",
                                            "histIndexPercentage",
                                            "marketValue",
                                            "price",
                                            "priceDate",
                                            "ytdUnRealizedProfitLossInVC",
                                            "histAverageFxRate",
                                            "fxRate",
                                            "histIndexAnnualPercentage",
                                            "cashBalanceAccounting",
                                            "cashBalanceAccountingDate",
                                            "cashBalanceAvailable",
                                            "cashBalanceAvailableDate",
                                            "cashBalanceLiquid",
                                            "cashBalanceLiquidDate"
                                        ],
                                        "widgetTemplateInfo": {
                                            "id": 55,
                                            "name": "CUSTOM_TABLE_FIELDS_FOR_TABLET",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "DEFAULT_PERSPECTIVE_LEVEL": {
                                        "id": 6,
                                        "name": "DEFAULT_PERSPECTIVE_LEVEL",
                                        "value": "0",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 67,
                                            "name": "DEFAULT_PERSPECTIVE_LEVEL",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "HAS_FILTER_IN_MENU": {
                                        "id": 3,
                                        "name": "HAS_FILTER_IN_MENU",
                                        "value": "1",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 70,
                                            "name": "HAS_FILTER_IN_MENU",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "HAS_MENU": {
                                        "id": 11,
                                        "name": "HAS_MENU",
                                        "value": "1",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 64,
                                            "name": "HAS_MENU",
                                            "type": "boolean",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "TABLE_FIELDS_TEXT_RIGHT": {
                                        "id": 9,
                                        "name": "TABLE_FIELDS_TEXT_RIGHT",
                                        "value": null,
                                        "arrayValue": [
                                            "accountNumber",
                                            "shareNumber",
                                            "histAveragePurchasePrice",
                                            "histIndexPercentage",
                                            "marketValue",
                                            "price",
                                            "priceDate",
                                            "ytdUnRealizedProfitLossInVC",
                                            "histAverageFxRate",
                                            "fxRate",
                                            "histIndexAnnualPercentage",
                                            "cashBalanceAccounting",
                                            "cashBalanceAccountingDate",
                                            "cashBalanceAvailable",
                                            "cashBalanceAvailableDate",
                                            "cashBalanceLiquid",
                                            "cashBalanceLiquidDate"
                                        ],
                                        "widgetTemplateInfo": {
                                            "id": 51,
                                            "name": "TABLE_FIELDS_TEXT_RIGHT",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "TABLE_TO_DISPLAY": {
                                        "id": 16,
                                        "name": "TABLE_TO_DISPLAY",
                                        "value": "0",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 65,
                                            "name": "TABLE_TO_DISPLAY",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "DEFAULT_TABLE_FIELDS": {
                                        "id": 8,
                                        "name": "DEFAULT_TABLE_FIELDS",
                                        "value": null,
                                        "arrayValue": [
                                            "productName",
                                            "accountNumber",
                                            "shareNumber",
                                            "histAveragePurchasePrice",
                                            "histIndexPercentage",
                                            "marketValue",
                                            "price",
                                            "priceDate",
                                            "ytdUnRealizedProfitLossInVC",
                                            "histAverageFxRate",
                                            "fxRate",
                                            "histIndexAnnualPercentage",
                                            "cashBalanceAccounting",
                                            "cashBalanceAccountingDate",
                                            "cashBalanceAvailable",
                                            "cashBalanceAvailableDate",
                                            "cashBalanceLiquid",
                                            "cashBalanceLiquidDate"
                                        ],
                                        "widgetTemplateInfo": {
                                            "id": 50,
                                            "name": "DEFAULT_TABLE_FIELDS",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": true,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "CUSTOM_TABLE_FIELDS_FOR_DESKTOP": {
                                        "id": 4,
                                        "name": "CUSTOM_TABLE_FIELDS_FOR_DESKTOP",
                                        "value": null,
                                        "arrayValue": [
                                            "productName",
                                            "accountNumber",
                                            "shareNumber",
                                            "histAveragePurchasePrice",
                                            "histIndexPercentage",
                                            "marketValue",
                                            "price",
                                            "priceDate",
                                            "ytdUnRealizedProfitLossInVC",
                                            "histAverageFxRate",
                                            "fxRate",
                                            "histIndexAnnualPercentage",
                                            "cashBalanceAccounting",
                                            "cashBalanceAccountingDate",
                                            "cashBalanceAvailable",
                                            "cashBalanceAvailableDate",
                                            "cashBalanceLiquid",
                                            "cashBalanceLiquidDate"
                                        ],
                                        "widgetTemplateInfo": {
                                            "id": 66,
                                            "name": "CUSTOM_TABLE_FIELDS_FOR_DESKTOP",
                                            "type": "Array",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "TITLE": {
                                        "id": 15,
                                        "name": "TITLE",
                                        "value": "portfolio.category.list",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 71,
                                            "name": "TITLE",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    },
                                    "DEFAULT_PERSPECTIVE": {
                                        "id": 14,
                                        "name": "DEFAULT_PERSPECTIVE",
                                        "value": "0",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 48,
                                            "name": "DEFAULT_PERSPECTIVE",
                                            "type": "String",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 11
                                        },
                                        "widgetId": 2
                                    }
                                },
                                "visible": true
                            }
                        }
                    ],
                    "2": [
                        {
                            "id": 2,
                            "position": 2,
                            "rowNum": 2,
                            "width": 12,
                            "offset": 0,
                            "containingPageId": null,
                            "containingPageComponentId": 1,
                            "containedPageComponents": {},
                            "widget": {
                                "weight": null,
                                "id": 1,
                                "name": "PtfPerformances_inPtfPage",
                                "status": "online",
                                "keyName": null,
                                "view": "Widget",
                                "containerId": 2,
                                "widgetTemplate": {
                                    "id": 1,
                                    "name": "portfolio-performance-azimut",
                                    "minWidth": null,
                                    "maxWidth": null,
                                    "defaultWidth": null,
                                    "htmlPageUrl": null,
                                    "tileUrl": null,
                                    "keyName": null,
                                    "widgetTemplateCatalogId": 1,
                                    "infos": {
                                        "CONFIGURATION": {
                                            "id": 1,
                                            "name": "CONFIGURATION",
                                            "type": "json",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 1
                                        }
                                    },
                                    "operations": {}
                                },
                                "widgetInfos": {
                                    "CONFIGURATION": {
                                        "id": 1,
                                        "name": "CONFIGURATION",
                                        "value": "{\"fields\":[\"productName\",\"productType\",\"contractCode\",\"totalInvested\",\"switchIn\",\"dividendsAndWithdrawn\",\"switchOut\",\"averageCapital\",\"totalValue\",\"plusMinus\",\"performance\"],\"currencyFields\":[],\"dateFields\":[],\"percentageFields\":[\"performance\"],\"fieldsToRound\":{\"averageCapital\":2,\"performance\":2,\"plusMinus\":2,\"switchIn\":2,\"totalInvested\":2,\"dividendsAndWithdrawn\":2,\"totalValue\":2,\"switchOut\":2},\"timeRangeList\":[\"PERF_FROM_INITIAL_YEAR\",\"PERF_FROM_ONE_YEAR\",\"PERF_INTERVAL\"],\"categories\":[\"DEFAULT\",\"ADMINISTERED\"],\"defaultTimeRange\":\"PERF_FROM_INITIAL_YEAR\",\"defaultCategory\":\"DEFAULT\"}",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 1,
                                            "name": "CONFIGURATION",
                                            "type": "json",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 1
                                        },
                                        "widgetId": 1
                                    },
                                    "TITLE": {
                                        "id": 2,
                                        "name": "TITLE",
                                        "value": "performances",
                                        "arrayValue": null,
                                        "widgetTemplateInfo": {
                                            "id": 1,
                                            "name": "CONFIGURATION",
                                            "type": "json",
                                            "defaultValue": null,
                                            "mandatory": false,
                                            "widgetTemplateId": 1
                                        },
                                        "widgetId": 1
                                    }
                                },
                                "visible": true
                            }
                        }
                    ]
                },
                "widget": null
            }
        ]
    };
});

