define('layout-base/../../bower_components/dwx-layout-base/src/run',["require", "exports"], function (require, exports) {
    var Layout;
    (function (Layout) {
        var LayoutRun = (function () {
            function LayoutRun($timeout, EventService, $rootScope, $translate) {
                $rootScope.$on('$translatePartialLoaderStructureChanged', function () {
                    $translate.refresh();
                });
                this.ID = 'run';
                var foundationInit, timeoutInterchangeReflow;
                var _this = this;
                $timeout(function () {
                    $(function () {
                        foundationInit();
                    });
                }, 1000).then($timeout(function () {
                    webapp.status = 'loaded';
                }, 1000));
                EventService.subscribe('user-session-changed', function () {
                    webapp.status = 'loading';
                    return $timeout(function () {
                        $(document).foundation('interchange', {
                            named_queries: {
                                medium_landscape: 'only screen and (min-width: 960px)'
                            }
                        });
                        $(document).foundation();
                        webapp.status = 'loaded';
                        $(function () {
                            foundationInit();
                        });
                    }, 1000);
                }, false);
                timeoutInterchangeReflow = null;
                EventService.subscribe('foundation-interchange-reflow', function () {
                    if (timeoutInterchangeReflow !== null) {
                        $timeout.cancel(timeoutInterchangeReflow);
                    }
                    timeoutInterchangeReflow = $timeout(function () {
                        return $(document).foundation('interchange', 'reflow');
                    }, 800);
                }, false);
                foundationInit = function () {
                    $(document).foundation('interchange', {
                        named_queries: {
                            medium_landscape: 'only screen and (min-width: 960px)'
                        }
                    });
                    $(document).foundation();
                };
            }
            return LayoutRun;
        })();
        Layout.LayoutRun = LayoutRun;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

