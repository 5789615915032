define('../bower_components/dwx-widget-my-documents/src/domain/myDocumentsConfiguration',["require", "exports"], function (require, exports) {
    var MyDocumentsConfiguration = (function () {
        function MyDocumentsConfiguration() {
        }
        MyDocumentsConfiguration.prototype.setEnablePolling = function (enablePolling) {
            this.enablePolling = enablePolling;
            return this;
        };
        MyDocumentsConfiguration.prototype.setSummaryTagsList = function (summaryTagsList) {
            this.summaryTagsList = summaryTagsList;
            return this;
        };
        MyDocumentsConfiguration.prototype.setRemotePagination = function (remotePagination) {
            this.remotePagination = remotePagination;
            return this;
        };
        MyDocumentsConfiguration.prototype.setDefaultGrouping = function (defaultGrouping) {
            this.defaultGrouping = defaultGrouping;
            return this;
        };
        MyDocumentsConfiguration.prototype.setDocumentCategoryIndex = function (documentCategoryIndex) {
            this.documentCategoryIndex = documentCategoryIndex;
            return this;
        };
        MyDocumentsConfiguration.prototype.setCategories = function (categories) {
            this.categories = categories;
            return this;
        };
        MyDocumentsConfiguration.prototype.setDocumentCategories = function (documentCategories) {
            this.documentCategories = documentCategories;
            return this;
        };
        MyDocumentsConfiguration.prototype.setDefaultView = function (defaultView) {
            this.defaultView = defaultView;
            return this;
        };
        MyDocumentsConfiguration.prototype.setViewType = function (viewType) {
            this.viewType = viewType;
            return this;
        };
        MyDocumentsConfiguration.prototype.setOrderByListIndex = function (orderByListIndex) {
            this.orderByListIndex = orderByListIndex;
            return this;
        };
        MyDocumentsConfiguration.prototype.setNumberItemsInPageGridView = function (numberItemsInPageGridView) {
            this.numberItemsInPageGridView = numberItemsInPageGridView;
            return this;
        };
        MyDocumentsConfiguration.prototype.setNumberItemsInPageListView = function (numberItemsInPageListView) {
            this.numberItemsInPageListView = numberItemsInPageListView;
            return this;
        };
        MyDocumentsConfiguration.prototype.setShowFilterBar = function (showFilterBar) {
            this.showFilterBar = showFilterBar;
            return this;
        };
        MyDocumentsConfiguration.prototype.setShowAllDocumentsEnabled = function (showAllDocumentsEnabled) {
            this.showAllDocumentsEnabled = showAllDocumentsEnabled;
            return this;
        };
        MyDocumentsConfiguration.prototype.setShowNewDocumentsEnabled = function (showNewDocumentsEnabled) {
            this.showNewDocumentsEnabled = showNewDocumentsEnabled;
            return this;
        };
        MyDocumentsConfiguration.prototype.setShowFilters = function (showFilters) {
            this.showFilters = showFilters;
            return this;
        };
        MyDocumentsConfiguration.prototype.setShowGridViewButton = function (showGridViewButton) {
            this.showGridViewButton = showGridViewButton;
            return this;
        };
        MyDocumentsConfiguration.prototype.setShowListViewButton = function (showListViewButton) {
            this.showListViewButton = showListViewButton;
            return this;
        };
        MyDocumentsConfiguration.prototype.setShowSignView = function (showSignView) {
            this.showSignView = showSignView;
            return this;
        };
        return MyDocumentsConfiguration;
    })();
    exports.MyDocumentsConfiguration = MyDocumentsConfiguration;
});

