define('layout-base/../../bower_components/dwx-utility/src/filters/dateTranslateFilter',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var DateTranslateFilter = (function () {
            function DateTranslateFilter($filter) {
                this.$filter = $filter;
            }
            DateTranslateFilter.Factory = function ($filter) {
                var instance = new DateTranslateFilter($filter);
                instance.myFilter = instance.myFilter.bind(instance);
                return instance.myFilter;
            };
            DateTranslateFilter.prototype.myFilter = function (input, arg) {
                var dateFormatL10n;
                dateFormatL10n = this.$filter('translate')(arg);
                return this.$filter('date')(input, dateFormatL10n);
            };
            return DateTranslateFilter;
        })();
        Utility.DateTranslateFilter = DateTranslateFilter;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

