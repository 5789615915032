define('widget-advisor/../../project_components/dwx-widget-advisor/src/services/advisorService',["require", "exports"], function (require, exports) {
    var AdvisorService = (function () {
        function AdvisorService($resource) {
            return {
                advisorData: $resource(apiPrefix + "/pfpbdata", {}, {
                    get: {
                        method: 'GET'
                    }
                })
            };
        }
        return AdvisorService;
    })();
    exports.AdvisorService = AdvisorService;
});

