define('layout-base/../../bower_components/dwx-utility/src/services/eventService',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var EventService = (function () {
            function EventService($rootScope, $location, $cookieStore) {
                var addParamsToQueryString, cache, deleteKeyFromQueryString, deleteParamsFromQueryString, eventsQueue, getParamByKeyFromQueryString, listener, publish, subscribe, trigger, unsubscribe;
                trigger = function (eventName, paramsObj) {
                    $rootScope.$broadcast(eventName, paramsObj);
                };
                listener = function ($scope, eventName, handler) {
                    $scope.$on(eventName, function (event, paramsObj) {
                        handler(paramsObj);
                    });
                };
                addParamsToQueryString = function (paramsObj) {
                    var currentQueryString;
                    currentQueryString = $location.search();
                    angular.extend(currentQueryString, paramsObj);
                    $location.search(currentQueryString);
                };
                deleteKeyFromQueryString = function (key) {
                    var currentQueryString;
                    currentQueryString = $location.search();
                    delete currentQueryString[key];
                    $location.search(currentQueryString);
                };
                deleteParamsFromQueryString = function (paramsObj) {
                    angular.forEach(paramsObj, function (value, key) {
                        deleteKeyFromQueryString(key);
                    });
                };
                getParamByKeyFromQueryString = function (key) {
                    var currentQueryString;
                    currentQueryString = $location.search();
                    return currentQueryString[key];
                };
                cache = {};
                if (eventsQueue === void 0) {
                    eventsQueue = [];
                }
                publish = function (topic, args, addToEventsQueue) {
                    var i, thisTopic;
                    if (cache[topic]) {
                        thisTopic = cache[topic];
                        i = thisTopic.length - 1;
                        i;
                        while (i >= 0) {
                            thisTopic[i].apply(this, args || []);
                            i -= 1;
                        }
                    }
                    if (addToEventsQueue) {
                        eventsQueue[topic] = {
                            'args': args
                        };
                        $cookieStore.put(topic, args);
                    }
                };
                subscribe = function (topic, callback, notExecuteIfInQueue) {
                    if (!notExecuteIfInQueue) {
                        if (eventsQueue[topic]) {
                            callback.apply(this, eventsQueue[topic].args || []);
                        }
                        else if ($cookieStore.get(topic)) {
                            eventsQueue[topic] = {
                                'args': $cookieStore.get(topic)
                            };
                            callback.apply(this, eventsQueue[topic].args || []);
                        }
                    }
                    if (!cache[topic]) {
                        cache[topic] = [];
                    }
                    cache[topic].push(callback);
                    return [topic, callback];
                };
                unsubscribe = function (handle, completly) {
                    var i, t;
                    t = handle[0];
                    i = cache[t].length - 1;
                    if (cache[t]) {
                        while (i >= 0) {
                            if (cache[t][i] === handle[1]) {
                                cache[t].splice(i, 1);
                                if (completly) {
                                    delete cache[t];
                                }
                            }
                            i -= 1;
                        }
                    }
                };
                return {
                    trigger: trigger,
                    listener: listener,
                    addParamsToQueryString: addParamsToQueryString,
                    deleteKeyFromQueryString: deleteKeyFromQueryString,
                    deleteParamsFromQueryString: deleteParamsFromQueryString,
                    getParamByKeyFromQueryString: getParamByKeyFromQueryString,
                    publish: publish,
                    subscribe: subscribe,
                    unsubscribe: unsubscribe
                };
            }
            return EventService;
        })();
        Utility.EventService = EventService;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

