define('widget-login/widget-login',["require", "exports", '../../bower_components/dwx-widget-login/src/controllers/loginCtrl', '../../bower_components/dwx-widget-login/src/directives/loginDirective', '../../bower_components/dwx-widget-login/src/services/loginService', './config'], function (require, exports, WidgetLoginController, WidgetLoginDirective, WidgetLoginService, config) {
    exports.configuration = {
        list: {
            "config": { callback: ['$translateProvider', config.LoginConfig], type: "config" },
            "loginService": { callback: ["$resource", WidgetLoginService.WidgetLogin.LoginService], type: "factory" },
            "loginCtrl": {
                callback: ['$scope', '$rootScope', '$location', 'loginService', 'EventService', 'initConfigService', '$cookieStore', '$translatePartialLoader', 'configurationService', '$window', WidgetLoginController.WidgetLogin.LoginController],
                type: "controller"
            },
            "dwxWidgetLogin": { callback: [WidgetLoginDirective.WidgetLogin.LoginDirective], type: "directive" },
            "smartAuthModal": { callback: ['EventService', WidgetLoginDirective.WidgetLogin.SmartAuthenticationDirective.Factory()], type: "directive" }
        },
        ngModules: ['ngResource', 'pascalprecht.translate']
    };
});

