define('widget-support-page/../../bower_components/dwx-widget-support-page/src/app',["require", "exports", './controller/supportPageCtrl', './services/supportPageService'], function (require, exports, supportPageCtrl, supportPageService) {
    var WidgetSupportPage;
    (function (WidgetSupportPage) {
        WidgetSupportPage.configuration = {
            list: {
                "supportPageCtrl": { callback: ['$scope', 'supportPageService', 'EventService', 'initConfigService', "errorsHandlerService", supportPageCtrl.SupportPageCtrl], type: "controller" },
                "supportPageService": { callback: ["$resource", supportPageService.SupportPageService], type: "factory" }
            },
            ngModules: ['ngResource']
        };
    })(WidgetSupportPage = exports.WidgetSupportPage || (exports.WidgetSupportPage = {}));
});

