define('../bower_components/dwx-widget-dossier-selector/src/app',["require", "exports", './controllers/dossierSelectorController', './directives/dossierSelectorDirective'], function (require, exports, controller, directive) {
    exports.configuration = {
        list: {
            "dossierSelectorCtrl": { callback: ['$scope', 'portfolioCoreService', 'EventService', 'initConfigService', '$filter', controller.DossierSelectorController], type: "controller" },
            "dwxWidgetDossierSelector": { callback: [directive.DossierSelectorDirective], type: "directive" },
        },
        ngModules: []
    };
});

