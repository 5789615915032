define('../bower_components/dwx-widget-my-documents/src/directives/myDocumentsNestedTableDirective',["require", "exports"], function (require, exports) {
    var MyDocumentsNestedTableDirective = (function () {
        function MyDocumentsNestedTableDirective() {
            this.replace = false;
            this.scope = false;
            this.templateUrl = 'widgets/my-documents/_nested-table.html';
            this.controller = 'myDocumentsNestedTableCtrl';
            this.controllerAs = 'nestedTableCtrl';
            var _this = this;
            _this.link = function (scope, el, attrs) {
                scope.getKey = function (key) {
                    return "" + (attrs.keyPrefix || '') + key;
                };
            };
        }
        MyDocumentsNestedTableDirective.Factory = function () {
            var directive = function () {
                return new MyDocumentsNestedTableDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return MyDocumentsNestedTableDirective;
    })();
    exports.MyDocumentsNestedTableDirective = MyDocumentsNestedTableDirective;
});

