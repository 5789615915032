define('../bower_components/dwx-init-config-be/src/services/initConfigService',["require", "exports"], function (require, exports) {
    var InitConfigService = (function () {
        function InitConfigService() {
            return {};
        }
        return InitConfigService;
    })();
    exports.InitConfigService = InitConfigService;
});

