define('layout-base/../../bower_components/dwx-utility/src/directives/dwxInputValidatorDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var DwxInputValidatorDirective = (function () {
            function DwxInputValidatorDirective($filter, EventService) {
                this.$filter = $filter;
                this.EventService = EventService;
                this.restrict = "A";
                this.require = '?ngModel';
                this.scope = { requiredValue: '=?', numDecimal: '=?', minValue: '=?', maxValue: '=?', maxLength: '=?', formName: '=?', calcFun: '=?', widgetCtrl: '=?' };
                this.forcedParse = false;
                var _this = this;
                _this.link = function (scope, element, attrs, ctrl) {
                    if (!ctrl)
                        return;
                    if (attrs.type == 'number') {
                        element.on('focus', function (e) {
                            var plainNumber;
                            if (e.currentTarget.value && e.currentTarget.value != "") {
                                if (language == 'en-gb') {
                                    plainNumber = e.currentTarget.value.replace(/[^\d|\-+|\.+]/g, '');
                                }
                                else {
                                    plainNumber = e.currentTarget.value.replace(/[^\d|\-+|\,+]/g, '');
                                }
                                ctrl.$setViewValue(plainNumber);
                                ctrl.$render();
                            }
                            return plainNumber;
                        });
                        element.bind('keydown', function (e) {
                            var allowedCharCodes;
                            var separator;
                            var regex;
                            allowedCharCodes = [46, 8, 9, 27, 13];
                            regex = new RegExp("[\\d]", "g");
                            if (language == 'en-gb') {
                                separator = '.';
                                if (Number(scope.numDecimal) > 0) {
                                    allowedCharCodes.push(110);
                                    allowedCharCodes.push(190);
                                }
                            }
                            else {
                                separator = ',';
                                if (Number(scope.numDecimal) > 0) {
                                    allowedCharCodes.push(188);
                                }
                            }
                            if (Number(scope.numDecimal) > 0) {
                                regex = new RegExp("[\\d|\\" + separator + "+]", "g");
                            }
                            if ($.inArray(e.keyCode, allowedCharCodes) !== -1 || (e.keyCode == 65 && e.ctrlKey === true) || (e.keyCode == 67 && e.ctrlKey === true) || (e.keyCode == 88 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 39)) {
                                return;
                            }
                            if (String.fromCharCode((96 <= e.keyCode && e.keyCode <= 105) ? e.keyCode - 48 : e.keyCode).match(regex)) {
                                if (scope.maxLength && this.value.length >= scope.maxLength) {
                                    e.preventDefault();
                                }
                                else {
                                    return;
                                }
                            }
                            else {
                                e.preventDefault();
                            }
                        });
                    }
                    ctrl.$parsers.unshift(function (viewValue) {
                        _this.initValidity(ctrl);
                        if (scope.requiredValue && !_this.validRequired(ctrl, viewValue)) {
                            ctrl.$setViewValue(0);
                            ctrl.$render();
                            return viewValue;
                        }
                        if (!_this.validType(ctrl, viewValue, attrs.type)) {
                            return viewValue;
                        }
                        if (scope.minValue && !_this.validMin(ctrl, viewValue, scope.minValue)) {
                            return viewValue;
                        }
                        if (attrs.type == 'number') {
                            viewValue = _this.formatViewValue(viewValue, scope.numDecimal);
                            ctrl.$setViewValue(viewValue);
                            ctrl.$render();
                        }
                        return viewValue;
                    });
                };
            }
            DwxInputValidatorDirective.prototype.formatViewValue = function (oldVal, numDecimal) {
                var _this = this;
                var plainNumber;
                if (language == 'en-gb') {
                    plainNumber = oldVal.replace(/[^\d|\-+|\.+]/g, '');
                    plainNumber = parseFloat(plainNumber).toFixed(Number(numDecimal));
                }
                else {
                    plainNumber = oldVal.replace(/[^\d|\-+|\,+]/g, '');
                    var tmp = plainNumber.replace(',', '.');
                    plainNumber = parseFloat(tmp).toFixed(Number(numDecimal));
                }
                console.log(language);
                var newVal = _this.$filter('number')(plainNumber, numDecimal ? numDecimal : 0);
                return newVal;
            };
            DwxInputValidatorDirective.prototype.initValidity = function (ctrl) {
                ctrl.$setValidity('required', true);
                ctrl.$setValidity('number', true);
                ctrl.$setValidity('integer', true);
                ctrl.$setValidity('min', true);
                ctrl.$setValidity('max', true);
            };
            DwxInputValidatorDirective.prototype.validType = function (ctrl, value, type) {
                var _this = this;
                switch (type) {
                    case 'number':
                        if (value === "" || isNaN(value)) {
                            ctrl.$setValidity(type, false);
                            return false;
                        }
                        else {
                            return true;
                        }
                        break;
                    case 'text':
                        return true;
                        break;
                }
            };
            DwxInputValidatorDirective.prototype.validRequired = function (ctrl, value) {
                if (!value || value == "") {
                    ctrl.$setValidity('required', false);
                    return false;
                }
                return true;
            };
            DwxInputValidatorDirective.prototype.validMin = function (ctrl, value, min) {
                ctrl.$setValidity('min', value >= min);
                return value >= min;
            };
            DwxInputValidatorDirective.prototype.validMax = function (ctrl, value, max) {
                ctrl.$setValidity('max', value <= max);
                return value <= max;
            };
            DwxInputValidatorDirective.Factory = function () {
                var directive = function ($filter, EventService) {
                    return new DwxInputValidatorDirective($filter, EventService);
                };
                directive['$inject'] = ['$filter'];
                return directive;
            };
            return DwxInputValidatorDirective;
        })();
        Utility.DwxInputValidatorDirective = DwxInputValidatorDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

