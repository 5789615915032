define('widget-settings-customer/widget-settings-customer',["require", "exports", './config', '../../bower_components/dwx-widget-settings-customer/src/app', '../../bower_components/dwx-widget-settings-customer/src/controllers/settingsCtrl'], function (require, exports, config, WidgetSettingsCustomer, WidgetSettingsCustomerController) {
    var newList = WidgetSettingsCustomer.configuration.list;
    newList['settingsCustomerConfig'] = { callback: ['$translateProvider', config.SettingsCustomerConfig], type: "config" };
    newList['SettingsCtrl'] = {
        callback: ["$scope", "$rootScope", "$attrs", "settingsService", "$timeout", "$http", "$element", "initConfigService", "errorsHandlerService", "$translatePartialLoader", 'EventService', WidgetSettingsCustomerController.SettingsCtrl],
        type: 'controller'
    };
    WidgetSettingsCustomer.configuration.list = newList;
    exports.configuration = WidgetSettingsCustomer.configuration;
});

