define('layout-base/../../bower_components/dwx-layout-base/src/controllers/layoutHandlerCtrl',["require", "exports"], function (require, exports) {
    var LayoutHandler;
    (function (LayoutHandler) {
        var LayoutHandlerController = (function () {
            function LayoutHandlerController($scope, EventService, localStorageService, $cookieStore) {
                this.$scope = $scope;
                this.EventService = EventService;
                this.localStorageService = localStorageService;
                this.$cookieStore = $cookieStore;
                this.globalOffCanvas = '';
                this.webappLoaded = false;
                var eventHandler, styleHandler, removeStyleHandler, webappHandler;
                var closeSidebarEventHandler;
                var _this = this;
                if (localStorageService != undefined && localStorageService.isSupported) {
                    if (localStorageService.get("styleId") != undefined) {
                        _this.styleId = localStorageService.get("styleId");
                    }
                }
                else if ($cookieStore != undefined) {
                    if ($cookieStore.get('styleId') != undefined) {
                        _this.styleId = $cookieStore.get('styleId');
                    }
                }
                webappHandler = EventService.subscribe('webapp-loaded', function (loaded) {
                    _this.webappLoaded = loaded;
                }, false);
                styleHandler = EventService.subscribe('style-retrieved', function (style) {
                    _this.styleId = style;
                    _this.webappLoaded = true;
                    if (localStorageService != undefined && localStorageService.isSupported) {
                        localStorageService.set("styleId", _this.styleId);
                    }
                    else if ($cookieStore != undefined) {
                        $cookieStore.put('styleId', _this.styleId);
                    }
                }, false);
                removeStyleHandler = EventService.subscribe('remove-style', function (emptyVar) {
                    if (_this.localStorageService != undefined && _this.localStorageService.isSupported) {
                        _this.localStorageService.remove("styleId");
                    }
                    else if (_this.$cookieStore != undefined) {
                        _this.$cookieStore.remove('styleId');
                    }
                    if (emptyVar) {
                        _this.styleId = undefined;
                    }
                }, false);
                closeSidebarEventHandler = EventService.subscribe('close-sidebar', function () {
                    _this.globalOffCanvas = '';
                }, false);
                $scope.$on("$destroy", function () {
                    _this.EventService.unsubscribe(eventHandler, false);
                    _this.EventService.unsubscribe(closeSidebarEventHandler, false);
                    _this.EventService.unsubscribe(webappHandler, false);
                    _this.EventService.unsubscribe(styleHandler, false);
                    _this.EventService.unsubscribe(removeStyleHandler, false);
                });
                _this.webappLoaded = true;
            }
            LayoutHandlerController.prototype.isWebapp = function (status) {
                return webapp.status === status;
            };
            LayoutHandlerController.prototype.isSetOption = function (setting) {
                if (typeof setting !== 'undefined' && typeof settings !== 'undefined') {
                    return settings[setting];
                }
            };
            LayoutHandlerController.prototype.isTouch = function () {
                return Modernizr.touch;
            };
            LayoutHandlerController.prototype.tabSelected = function () {
                var _this = this;
                _this.EventService.publish('tab-changed', [], false);
            };
            return LayoutHandlerController;
        })();
        LayoutHandler.LayoutHandlerController = LayoutHandlerController;
    })(LayoutHandler = exports.LayoutHandler || (exports.LayoutHandler = {}));
});

