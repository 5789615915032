define('widget-portfolio-summary/../../bower_components/dwx-widget-portfolio-summary/src/app',["require", "exports", './controllers/portfolioSummaryCtrl', './directives/portfolioSummaryDirective'], function (require, exports, portfolioSummaryCtrl, portfolioSummaryDirectives) {
    var WidgetPortfolioSummary;
    (function (WidgetPortfolioSummary) {
        WidgetPortfolioSummary.configuration = {
            list: {
                'dwxPortfolioSummaryCtrl': {
                    callback: ["$scope", "$rootScope", "EventService", "portfolioCoreService", "$filter", "initConfigService", portfolioSummaryCtrl.PortfolioSummaryCtrl],
                    type: 'controller'
                },
                'dwxWidgetPortfolioSummary': {
                    callback: [portfolioSummaryDirectives.PortfolioSummaryDirective.Factory()],
                    type: 'directive'
                },
            },
            ngModules: []
        };
    })(WidgetPortfolioSummary = exports.WidgetPortfolioSummary || (exports.WidgetPortfolioSummary = {}));
});

