define('../bower_components/dwx-engine/src/services/profileService',["require", "exports"], function (require, exports) {
    var Engine;
    (function (Engine) {
        var ProfileService = (function () {
            function ProfileService($resource) {
                return {
                    userProfile: {},
                    profile: $resource(apiPrefix + "/profile", {}),
                    dikeConfiguration: $resource("/api/dike/public/config/get?key=realEconomy.usePaidAmount", {}),
                    daysWarnMigration: $resource("/api/dike/public/config/get?key=DAYS_WARN_MIGRATION", {}),
                    getWarnMigrationMessage: $resource("/api/octopus/utility/viewLabels?context=HOME&domain=MYAZIMUT", {}),
                    userData: $resource(apiPrefix + "/userdata/22", {}),
                    logout: $resource(apiPrefix + "/logout", {}, {
                        query: {
                            method: "GET",
                            params: {},
                            isArray: false
                        }
                    })
                };
            }
            return ProfileService;
        })();
        Engine.ProfileService = ProfileService;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

