define('portfolio-core',["require", "exports", '../bower_components/dwx-portfolio-core/src/app', '../bower_components/dwx-portfolio-core/src/services/portfolioCoreService'], function (require, exports, PortfolioCore, PortfolioCoreService) {
    PortfolioCoreService.switchPortfolioSave = function (_service, loggedUser) {
        var _this = this;
        var getDossierListOwner = this.getDossierList({ firstHolder: true });
        var getDossierListCoOwner = this.getDossierList({ firstHolder: false });
        var behalfCode = loggedUser.userInfos.BEHALF_USER_CODE;
        var portfolioLoad = this.getPortfolioLoad(!!behalfCode ? behalfCode.value : loggedUser.userInfos.USER_CODE.value);
        console.log('PORTFOLIO WINDOW LOCATION', window.location.href);
        var promises = [getDossierListOwner, getDossierListCoOwner];
        if (window.location.href.indexOf('101') < 0 && window.location.href.indexOf('102') < 0 && window.location.href.indexOf('login') < 0) {
            promises.push(portfolioLoad);
        }
        this.$q.all(promises).then(function (promiseList) {
            if (promiseList[0].outcome === "OK" && promiseList[1].outcome === "OK") {
                _service['dossierOwnerList'] = promiseList[0].content;
                _service['dossierCoOwnerList'] = promiseList[1].content;
                _service['portfolioLoad'] = promiseList[2];
                if (_service['dossierOwnerList'] && _service['dossierOwnerList'].length > 0) {
                    sessionStorage.removeItem('coowner');
                }
                else if (_service['dossierCoOwnerList'] && _service['dossierCoOwnerList'].length > 0) {
                    sessionStorage.setItem('coowner', 'true');
                }
                else {
                    sessionStorage.removeItem('coowner');
                }
                _service['dossierList'] = sessionStorage.getItem('coowner') ? _service['dossierCoOwnerList'] : _service['dossierOwnerList'];
                console.log("PORTFOLIO CORE -- DOSSIER LOADED");
                _this.EventService.publish('dossierSelected', [], true);
                sessionStorage.setItem('dossierLoaded', 'true');
            }
            else {
                _this.EventService.publish('dossierSelectedResponseError', [promiseList[0].message | promiseList[1].message]);
            }
        }).catch(function (error) {
            _this.EventService.publish('dossierSelectedHttpResponseError', [error]);
            _this.EventService.publish('http-response-error', [error]);
        });
    };
    PortfolioCoreService.PortfolioCore.initPortfolioService = function (portfolioList, _service, loggedUser) {
        var _this = this;
        _this.switchPortfolioSave(_service, loggedUser);
        _this.EventService.subscribe('changeOwnership', function () {
            _service['dossierList'] = sessionStorage.getItem('coowner') ? _service['dossierCoOwnerList'] : _service['dossierOwnerList'];
            _this.EventService.publish('dossierSelected', [], false);
        });
    };
    exports.configuration = PortfolioCore.PortfolioCore.configuration;
});

