define('widget-portfolio-holdings/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var PortfolioHoldingsConfig = (function () {
        function PortfolioHoldingsConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return PortfolioHoldingsConfig;
    })();
    exports.PortfolioHoldingsConfig = PortfolioHoldingsConfig;
});

