define('../bower_components/dwx-engine/src/controllers/profileCtrl',["require", "exports"], function (require, exports) {
    var Engine;
    (function (Engine) {
        var ProfileController = (function () {
            function ProfileController(profileService, EventService, $cookieStore, $location, $scope) {
                this.profileService = profileService;
                this.EventService = EventService;
                this.$cookieStore = $cookieStore;
                this.$location = $location;
                this.$scope = $scope;
                var _this = this;
                var logoutElement = document.getElementsByClassName('hide-for-large-up mobile-logout')[0];
                if (!!logoutElement) {
                    logoutElement.addEventListener('touchstart', function (event) {
                        _this.logout();
                        event.preventDefault();
                    });
                    logoutElement.addEventListener('click', function (event) {
                        _this.logout();
                        event.preventDefault();
                    });
                }
            }
            ProfileController.prototype.logout = function () {
                var _this = this;
                _this.profileService.logout.query({}, function (response) {
                    _this.$cookieStore.remove('portfolioSelected');
                    _this.$cookieStore.remove('perspectiveSelected');
                    _this.$cookieStore.remove('translateChangeSuccess');
                    _this.$cookieStore.remove('refreshPortfolioWithDossier');
                    _this.$cookieStore.remove('switchLanguage');
                    localStorage.removeItem('showProspectModal');
                    sessionStorage.removeItem('dossierLoaded');
                    sessionStorage.removeItem('pirBoxBack');
                    _this.EventService.publish('webapp-loaded', [false], false);
                    _this.EventService.publish('remove-style', [], false);
                    _this.$location.path("/#/");
                    _this.$scope.$apply();
                    window.location.reload();
                });
            };
            return ProfileController;
        })();
        Engine.ProfileController = ProfileController;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

