define('../bower_components/dwx-widget-my-documents/src/domain/report-file-name-map',["require", "exports"], function (require, exports) {
    exports.map = {
        210: 'Rendiconto PIR Ordinario al @@',
        PIRALT: 'Rendiconto PIR Alternative al @@',
        AZGP: 'Sintesi della gestione Patrimoniale al @@',
        AZMAX: 'MAX – rendiconto periodico al @@',
        AZMAXFUND: 'MAX Fund  – rendiconto periodico al @@',
        AZRTO: 'RTO Deposito accessorio Titoli e liquidità a Custodia ed Amministrazione al @@',
        AZMAXOMNIBUS: 'MAX - Deposito accessorio Titoli e liquidità a Custodia ed Amministrazione al @@',
        AZGPEXPOST: 'Sintesi della gestione Patrimoniale al @@ **',
        AZRTOEXPOST: 'RTO Deposito accessorio Titoli e liquidità a Custodia ed Amministrazione al @@ **',
        AZMAXEXP: 'MAX - rendiconto periodico al @@ **',
        AZMAXFUNDEXP: 'MAX Fund - rendiconto periodico al @@ **',
        AZCDL: 'Rendiconto Conto deposito liquidità al @@ **',
    };
});

