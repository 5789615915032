define('widget-support-page/../../bower_components/dwx-widget-support-page/src/controller/supportPageCtrl',["require", "exports"], function (require, exports) {
    var SupportPageCtrl = (function () {
        function SupportPageCtrl($scope, supportPageService, EventService, initConfigService, errorsHandlerService, $translatePartialLoaderProvider) {
            this.$scope = $scope;
            this.supportPageService = supportPageService;
            this.errorsHandlerService = errorsHandlerService;
            this.$translatePartialLoaderProvider = $translatePartialLoaderProvider;
            var _this = this;
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetInbox';
            _this.widgetConfig = initConfigService[_this.widgetId];
            _this.currentOpenFaq = '';
            _this.init();
            EventService.subscribe("languageChangeForFaqs", function () {
                _this.init();
            });
            if (_this.$translatePartialLoaderProvider != undefined) {
                _this.$translatePartialLoaderProvider.addPart('bower_components/dwx-widget-support-page');
            }
            ;
        }
        SupportPageCtrl.prototype.init = function () {
            var _this = this;
            _this.glossaryLink = "https://www.treetopam.com/en/treetop-in-the-news/glossary";
            _this.externalFaqLink = "https://www.treetopam.com/en/support-help/faqs/investing";
            _this.securityLink = "https://www.treetopam.com/en/why-treetop/security-and-confidentiality";
            if (language != undefined) {
                if (language.indexOf("fr") > -1) {
                    _this.glossaryLink = "https://www.treetopam.com/fr/art-d-investir-followjack/glossaire";
                    _this.externalFaqLink = "https://www.treetopam.com/fr/support-aide/faqs/investir";
                    _this.securityLink = "https://www.treetopam.com/fr/pourquoi-treetop/securite-et-confidentialite";
                }
                else if (language.indexOf("nl") > -1) {
                    _this.glossaryLink = "https://www.treetopam.com/nl/de-kunst-van-het-beleggen/glossaire";
                    _this.externalFaqLink = "https://www.treetopam.com/nl/support/faqs/beleggen";
                    _this.securityLink = "https://www.treetopam.com/nl/waarom-treetop/veiligheid-en-vertrouwelijkheid";
                }
            }
            _this.faqList = _this.getFaqList();
        };
        SupportPageCtrl.prototype.getFaqList = function () {
            var _this = this;
            if (_this.widgetConfig.status.mode != 'loading') {
                _this.widgetConfig.status.mode = 'waiting';
            }
            _this.supportPageService.retrieveFAQs.get({}, function (response) {
                if (response.outcome === 'OK') {
                    _this.faqList = response.content.surveySteps[0].surveyViews[0].surveySections[0].questions;
                    _this.widgetConfig.status.mode = 'on';
                }
                else {
                    _this.errorsHandlerService.handleResponseError(response, _this.widgetConfig);
                }
            }, function (error) {
                _this.errorsHandlerService.handleResponseError(error, _this.widgetConfig);
            });
        };
        return SupportPageCtrl;
    })();
    exports.SupportPageCtrl = SupportPageCtrl;
});

