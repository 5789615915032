define('../bower_components/dwx-widget-my-documents/src/directives/contentTableDirective',["require", "exports"], function (require, exports) {
    var ContentTableDirective = (function () {
        function ContentTableDirective($filter) {
            var _this = this;
            this.$filter = $filter;
            this.replace = true;
            this.scope = {
                classModifier: '@',
                myDocConfiguration: '=',
                fieldList: '=',
                document: '=',
                getFieldValue: '='
            };
            this.templateUrl = 'widgets/my-documents/_content-table.html';
            this.link = function (scope) {
                scope['classValue'] = 'FlexTable__values--' + scope['classModifier'];
                var fieldListLength = scope['fieldList'].length;
                if (fieldListLength > 1) {
                    for (var i = 0; i < fieldListLength; i++) {
                        scope['contentValue'] = scope['contentValue'] + scope['getFieldValue'](scope['document'], scope['fieldList'][i]) + ' ';
                    }
                }
                else {
                    if (scope['fieldList'][0] == 'practiceId') {
                        var practiceIdPrefix = _this.$filter('translate')('widget.my_docs.table.' + scope['fieldList'][0].fieldName) + ' ';
                        scope['contentValue'] = practiceIdPrefix + scope['getFieldValue'](scope['document'], scope['fieldList'][0]);
                    }
                    else {
                        scope['contentValue'] = scope['getFieldValue'](scope['document'], scope['fieldList'][0]);
                    }
                }
            };
        }
        ContentTableDirective.Factory = function () {
            var directive = function ($filter) {
                return new ContentTableDirective($filter);
            };
            directive['$inject'] = ['$filter'];
            return directive;
        };
        return ContentTableDirective;
    })();
    exports.ContentTableDirective = ContentTableDirective;
});

