define('widget-focus/../../project_components/dwx-widget-focus/src/controllers/focusCtrl',["require", "exports", '../../../../scripts/owui-widget.constant'], function (require, exports, OwuiWidgetConstant) {
    var FocusController = (function () {
        function FocusController($scope, initConfigService, EventService, $location, configurationService, profileService) {
            this.$scope = $scope;
            this.EventService = EventService;
            this.$location = $location;
            this.configurationService = configurationService;
            this.profileService = profileService;
            this.owuiWidgetFocusName = 4 /* WIDGET_FOCUS */;
            var _this = this;
            $scope.events = this;
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetAdvisor';
            _this.widgetConfig = initConfigService[_this.widgetId];
            if (!!_this.profileService.loggedUser) {
                var behalfCode = _this.profileService.loggedUser.userInfos.BEHALF_USER_CODE;
                _this.widgetProp = {
                    "customer-code": !!behalfCode ? behalfCode.value : _this.profileService.loggedUser.userInfos.USER_CODE.value,
                    "include-contract-with-zero-balance": env.includeContractWithZeroBalance,
                    "use-amount": !realEconomyUsePaidAmount
                };
                _this.widgetConfig.status.mode = 'on';
            }
            var loggedUserHandler = EventService.subscribe('profileService:logged-user-ready', function (loggedUser) {
                var behalfCode = loggedUser.userInfos.BEHALF_USER_CODE;
                _this.widgetProp = {
                    "customer-code": !!behalfCode ? behalfCode.value : loggedUser.userInfos.USER_CODE.value,
                    "include-contract-with-zero-balance": env.includeContractWithZeroBalance,
                    "use-amount": !realEconomyUsePaidAmount
                };
                _this.widgetConfig.status.mode = 'on';
            });
            $scope.$on("$destroy", function () {
                _this.EventService.unsubscribe(_this.owuiWidgetEventHandler);
                _this.EventService.unsubscribe(loggedUserHandler);
            });
            this.owuiWidgetEventHandler = EventService.subscribe('owuiWidgetDirective:widgetLoaded', function (widgetName) {
                var widgetFocusTag = OwuiWidgetConstant.OwuiWidgetMap[4 /* WIDGET_FOCUS */].widgetTag;
                if (widgetName === widgetFocusTag) {
                    console.log('owuiWidgetDirective:widgetLoaded', widgetName);
                    document.getElementsByTagName(widgetFocusTag)[0].addEventListener(widgetFocusTag, function (event) {
                        switch (event.detail.type) {
                            case 'go-back':
                                _this.$location.path('/page/9');
                                _this.$scope.$apply();
                        }
                    });
                }
            });
        }
        FocusController.prototype.getManifestAssets = function () {
            return this.configurationService.manifestJson;
        };
        return FocusController;
    })();
    exports.FocusController = FocusController;
});

