define('widget-portfolio-ownership-view/../../bower_components/dwx-widget-portfolio-ownership-view/src/controllers/portfolioOwnershipViewCtrl',["require", "exports"], function (require, exports) {
    var PortfolioOwnershipViewCtrl = (function () {
        function PortfolioOwnershipViewCtrl($scope, portfolioCoreService, EventService, initConfigService, $location, profileService) {
            var _this = this;
            this.$scope = $scope;
            this.portfolioCoreService = portfolioCoreService;
            this.EventService = EventService;
            this.$location = $location;
            this.profileService = profileService;
            this.initialize = function () {
                console.log('Initializing PortfolioOwnershipViewCtrl...');
                if (_this.$location.path() !== '/pirbox')
                    sessionStorage.setItem('pirBoxBack', _this.$location.path());
                if (sessionStorage.getItem('dossierLoaded')) {
                    _this.setWidgetReady();
                }
                _this.EventService.subscribe('dossierSelected', function () {
                    _this.setWidgetReady();
                });
            };
            this.isOwnerDisabled = function () {
                var dossierOwnerList = _this.portfolioCoreService['dossierOwnerList'];
                var isOwnerNotActive = !_this.isOwnerActive();
                var isDossierOwnerListEmpty = !(dossierOwnerList && dossierOwnerList.length > 0);
                return isOwnerNotActive && isDossierOwnerListEmpty;
            };
            this.isCoOwnerDisabled = function () {
                var dossierCoOwnerList = _this.portfolioCoreService['dossierCoOwnerList'];
                var isCoOwnerNotActive = !_this.isCoOwnerActive();
                var isDossierCoOwnerListEmpty = !(dossierCoOwnerList && dossierCoOwnerList.length > 0);
                return isCoOwnerNotActive && isDossierCoOwnerListEmpty;
            };
            this.widgetId = this.$scope.identifier || (typeof this.$scope.widget != 'undefined') ? this.$scope.widget.id : 'widgetPortfolioOwnershipView';
            this.widgetConfig = initConfigService[this.widgetId];
            if (this.widgetConfig.status.mode !== 'locked') {
                this.initialize();
            }
        }
        PortfolioOwnershipViewCtrl.prototype.setWidgetReady = function () {
            this.coowner = !!sessionStorage.getItem('coowner') ? PortfolioOwnershipViewCtrl.COOWNER.toString() : PortfolioOwnershipViewCtrl.OWNER.toString();
            if (this.$location.path() === '/pirbox')
                this.coowner = PortfolioOwnershipViewCtrl.PIR_BOX.toString();
            this.widgetConfig.status.mode = 'on';
        };
        PortfolioOwnershipViewCtrl.prototype.goToPirBoxPage = function () {
            this.coowner = PortfolioOwnershipViewCtrl.PIR_BOX.toString();
            this.$location.path('/pirbox');
        };
        PortfolioOwnershipViewCtrl.prototype.isPirBoxActive = function () {
            return this.coowner === PortfolioOwnershipViewCtrl.PIR_BOX.toString();
        };
        PortfolioOwnershipViewCtrl.prototype.isOwnerActive = function () {
            return this.coowner === PortfolioOwnershipViewCtrl.OWNER.toString();
        };
        PortfolioOwnershipViewCtrl.prototype.isCoOwnerActive = function () {
            return this.coowner === PortfolioOwnershipViewCtrl.COOWNER.toString();
        };
        PortfolioOwnershipViewCtrl.prototype.isEnabledPir = function () {
            return env.enablePir === 'true' && !!this.portfolioCoreService.portfolioLoad && this.portfolioCoreService.portfolioLoad.extension.flagPir;
        };
        PortfolioOwnershipViewCtrl.prototype.isPgCustomer = function () {
            return !!this.profileService.loggedUser && !!this.profileService.loggedUser.userInfos.BEHALF_USER_NOMINATIVE;
        };
        PortfolioOwnershipViewCtrl.OWNER = 'OWNER';
        PortfolioOwnershipViewCtrl.COOWNER = 'COOWNER';
        PortfolioOwnershipViewCtrl.PIR_BOX = 'PIR_BOX';
        return PortfolioOwnershipViewCtrl;
    })();
    exports.PortfolioOwnershipViewCtrl = PortfolioOwnershipViewCtrl;
});

