define('widget-user-data/../../bower_components/dwx-widget-user-data/src/app',["require", "exports", './controllers/userDataCtrl', './directives/userDataDirective', './services/userDataService'], function (require, exports, userDataController, userDataDirective, userDataService) {
    exports.configuration = {
        list: {
            'UserDataCtrl': { callback: ["$scope", "$rootScope", "$attrs", "userDataService", "$timeout", "$http", "initConfigService", "errorsHandlerService", userDataController.UserDataCtrl], type: 'controller' },
            'dwxWidgetUserData': { callback: [userDataDirective.UserDataDirective.Factory()], type: 'directive' },
            'userDataService': { callback: ["$resource", userDataService.UserDataService], type: 'service' }
        },
        ngModules: []
    };
});

