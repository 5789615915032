define('layout-base/../../bower_components/dwx-layout-base/src/directives/widgetPlaceholderDirective',["require", "exports"], function (require, exports) {
    var Layout;
    (function (Layout) {
        var WidgetPlaceholderDirective = (function () {
            function WidgetPlaceholderDirective() {
                return {
                    restrict: "A",
                    scope: {
                        widget: '=?',
                        title: '=?',
                        message: '=?',
                        widgetTitle: '=?',
                        widgetId: '='
                    },
                    link: function (scope, element, attrs) {
                        if (scope.widget.status === 'online') {
                            scope.widget.status = {};
                            scope.widget.status.mode = 'loading';
                        }
                        if (scope.status === 'offline') {
                            scope.widget.status = {};
                            scope.status.mode = 'locked';
                            if (typeof scope.title === 'undefined') {
                                scope.title = 'Currently disabled';
                            }
                            if (typeof scope.message === 'undefined') {
                                scope.message = 'It will be available on 1st Oct 15:30';
                            }
                        }
                        return scope.$watchCollection('widget.status.mode', function () {
                            if (scope.widget.status.mode === 'error') {
                                scope.title = 'Cannot load content';
                                if (scope.widget.status.message === 'undefined') {
                                    return scope.message = 'Please try again later';
                                }
                                else {
                                    return scope.message = scope.widget.status.message;
                                }
                            }
                        });
                    }
                };
            }
            return WidgetPlaceholderDirective;
        })();
        Layout.WidgetPlaceholderDirective = WidgetPlaceholderDirective;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

