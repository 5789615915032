define('layout-base/../../bower_components/dwx-utility/src/directives/modalBoxDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var ModalBoxDirective = (function () {
            function ModalBoxDirective($filter, eventService) {
                this.$filter = $filter;
                this.eventService = eventService;
                this.restrict = 'A';
                this.scope = {
                    modalBoxOpen: '=',
                    modalBoxClass: '=',
                    modalBoxTitle: '=',
                    modalBoxTitleClass: '='
                };
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(scope, element, eventService);
                };
            }
            ModalBoxDirective.prototype.myLink = function (scope, element, eventService) {
                var _this = this;
                modalBox = $("#modalBox");
                if (modalBox.length >= 1) {
                    modalBox.remove();
                }
                scope.$watch(['modalBoxTitleClass', 'modalBoxClass', 'modalBoxTitle'], function () {
                    if (scope.modalBoxTitleClass != undefined && scope.modalBoxTitle != undefined && scope.modalBoxClass != undefined) {
                        modalBox = $("#modalBox");
                        if (modalBox.length < 1) {
                            var modalCloseHtml = $('<a href="" class="modal-close">&times;</a>').click(function (event) {
                                event.preventDefault();
                                eventService.publish("close-modal", [], false);
                            });
                            var modalTitleHtml = $('<h2 class="modal-title"><i class="icon ' + scope.modalBoxTitleClass + '"></i><span>' + _this.$filter('translate')(scope.modalBoxTitle) + '</span></h2>');
                            var modalHeading = $('<div class="heading"></div>').append(modalCloseHtml, modalTitleHtml);
                            var modalBodyHtml = $('<div class="modal-body"></div>');
                            var modalContent = $('<div class="content"></div>').append(modalHeading, modalBodyHtml);
                            var modalWrapper = $('<div class="wrapper"></div>').append(modalContent);
                            var modalHtml = $('<div id="modalBox" class="modal ' + scope.modalBoxClass + '"></div>').append(modalWrapper);
                            $('body').append(modalHtml);
                            modalBox = modalHtml;
                        }
                    }
                });
                scope.$watch('modalBoxOpen', function () {
                    modalBox = $("#modalBox");
                    if (scope.modalBoxOpen != undefined) {
                        if (scope.modalBoxOpen) {
                            modalBox.find('.modal-body').append($(element));
                            modalBox.addClass("open");
                        }
                    }
                });
                var modalCloseEventListener = eventService.subscribe("close-modal", function () {
                    var modal = $('.modal-close').parents('.modal');
                    modal.removeClass('open');
                    modal.find('.modal-body').empty();
                    scope.modalBoxOpen = false;
                    scope.$parent.modalIsOpen = false;
                    scope.$evalAsync();
                }, false);
                scope.$on("$destroy", function () {
                    eventService.unsubscribe(modalCloseEventListener, false);
                });
            };
            ModalBoxDirective.Factory = function () {
                var directive = function ($filter, eventService) {
                    return new ModalBoxDirective($filter, eventService);
                };
                directive['$inject', 'EventService'] = [];
                return directive;
            };
            return ModalBoxDirective;
        })();
        Utility.ModalBoxDirective = ModalBoxDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

