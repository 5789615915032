define('../bower_components/dwx-engine/src/services/layoutService',["require", "exports"], function (require, exports) {
    var Engine;
    (function (Engine) {
        var LayoutService = (function () {
            function LayoutService($resource, $rootScope) {
                var _layoutService;
                _layoutService = {
                    layout: $resource(apiPrefix + "/layout/page/:pageId", {}, {
                        reset: {
                            method: 'PUT'
                        }
                    }),
                    getBannerList: $resource("/api/octopus/news/urgent/list", {}, {
                        reset: {
                            method: 'GET'
                        }
                    }),
                    bannerList: null,
                    daysWarnMigration: null,
                    warnMigrationMessage: null,
                    dateCustomerMigrated: null,
                    getWidgetScopeById: function (widgetId) {
                        this.widgetScope = angular.element(document.querySelector("#widget-" + widgetId)).scope();
                        return this.widgetScope.pageComponent.widget;
                    },
                    removeOverlayByWidgetId: function (widgetId) {
                        return $rootScope.$broadcast("remove-overlay-widget-" + widgetId);
                    }
                };
                _layoutService['updateWidgetScopeById'] = function (widgetId, widgetProperties) {
                    var widgetScope;
                    widgetScope = _layoutService.getWidgetScopeById(widgetId);
                    angular.forEach(widgetProperties, function (value, key) {
                        return widgetScope[key] = value;
                    });
                };
                return _layoutService;
            }
            return LayoutService;
        })();
        Engine.LayoutService = LayoutService;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

