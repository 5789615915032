define('../bower_components/dwx-widget-media-library/src/directives/mediaLibraryHtmlDirective',["require", "exports"], function (require, exports) {
    var Directives;
    (function (Directives) {
        Directives.basePath = "bower_components/dwx-widget-html-content/dist/src/views/partials/";
        var MediaLibraryHtmlDirective = (function () {
            function MediaLibraryHtmlDirective() {
                return {
                    replace: true,
                    scope: {
                        identifier: '=',
                        type: "=mediaType",
                    },
                    controller: 'mediaLibraryCtrl as mediaLibraryCtrl',
                    template: '<div data-ng-switch="" data-on="{{type}}"> <div data-ng-switch-when="SIMPLE" data-dwx-html-content-simple="data-dwx-html-content-simple"></div> <div data-ng-switch-when="CAROUSEL" data-dwx-html-content-carousel="data-dwx-html-content-carousel"></div> <div data-ng-switch-when="SMART-CAROUSEL" data-dwx-html-content-smart-carousel="data-dwx-html-content-smart-carousel"></div> <div data-ng-switch-when="SLIDESHOW" data-dwx-html-content-slideshow="data-dwx-html-content-slideshow"></div> <div data-ng-switch-when="IFRAME"> <iframe data-ng-attr-src="{{HtmlContentCtrl.media.contents.DEFAULT | trustAsDelegate}}" allowtransparency="allowtransparency" frameborder="0" sandbox="allow-same-origin allow-forms allow-scripts"></iframe> </div> </div>'
                };
            }
            return MediaLibraryHtmlDirective;
        })();
        Directives.MediaLibraryHtmlDirective = MediaLibraryHtmlDirective;
        var HtmlContentSimpleDirective = (function () {
            function HtmlContentSimpleDirective(EventService) {
                var compile;
                return {
                    priority: 2,
                    restrict: 'A',
                    templateUrl: Directives.basePath + "simple.html",
                    compile: compile = function () {
                        var postLink, preLink;
                        return {
                            pre: preLink = function (scope, iElement, iAttrs, controller) {
                            },
                            post: postLink = function (scope, iElement, iAttrs, controller) {
                                EventService.publish('foundation-interchange-reflow', [], false);
                            }
                        };
                    }
                };
            }
            return HtmlContentSimpleDirective;
        })();
        Directives.HtmlContentSimpleDirective = HtmlContentSimpleDirective;
        var HtmlContentCarouselDirective = (function () {
            function HtmlContentCarouselDirective() {
                return {
                    priority: 1,
                    restrict: 'A',
                    templateUrl: Directives.basePath + "carousel.html"
                };
            }
            return HtmlContentCarouselDirective;
        })();
        Directives.HtmlContentCarouselDirective = HtmlContentCarouselDirective;
        var HtmlContentSmartCarouselDirective = (function () {
            function HtmlContentSmartCarouselDirective() {
                return {
                    priority: 1,
                    restrict: 'A',
                    templateUrl: Directives.basePath + "smart-carousel.html"
                };
            }
            return HtmlContentSmartCarouselDirective;
        })();
        Directives.HtmlContentSmartCarouselDirective = HtmlContentSmartCarouselDirective;
        var HtmlContentSlideshowDirective = (function () {
            function HtmlContentSlideshowDirective($timeout) {
                return {
                    priority: 1,
                    restrict: 'A',
                    templateUrl: Directives.basePath + "slideshow.html",
                    controller: 'HtmlContentCtrl as HtmlContentCtrl',
                    link: function (scope, element) {
                        var parent;
                        scope.slideToShow = 1;
                        parent = $(element).find('.slideshow-wrapper');
                        $timeout(function () {
                            var slideContainer;
                            slideContainer = $(element).find('.slide-container');
                            if (slideContainer.length) {
                                slideContainer = slideContainer[0];
                            }
                            angular.element(slideContainer).scope().media.visible = true;
                            return angular.element(slideContainer).find('img[usemap]').rwdImageMaps();
                        }, 3000);
                        $(element).on('click', '[data-go-to-slide]', function (e) {
                            var slideContainer, slideScope, slideToGo;
                            e.preventDefault();
                            slideToGo = $(this).data('go-to-slide');
                            angular.element($(this).parents('.slide-container')).scope().media.visible = false;
                            slideContainer = parent.find("[data-slide=" + slideToGo + "]").parents('.slide-container');
                            if (slideContainer.length) {
                                slideScope = angular.element(slideContainer).scope();
                                slideContainer.find('img[usemap]').rwdImageMaps();
                                slideScope.media.visible = true;
                                scope.$apply();
                            }
                        });
                    }
                };
            }
            return HtmlContentSlideshowDirective;
        })();
        Directives.HtmlContentSlideshowDirective = HtmlContentSlideshowDirective;
        var owlCarouselDirective = (function () {
            function owlCarouselDirective() {
                var compile;
                return {
                    priority: 0,
                    restrict: 'A',
                    scope: {
                        owlSettings: '=owlCarousel'
                    },
                    compile: compile = function (tElement, tAttrs, transclude) {
                        var postlink, prelink;
                        return {
                            pre: prelink = function (scope, iElement, iAttrs, controller) {
                            },
                            post: postlink = function (scope, iElement, iAttrs, controller) {
                                var checkExist;
                                checkExist = setInterval(function () {
                                    if ($(iElement).find('[data-hash]').length) {
                                        console.log("carousel", scope.owlSettings);
                                        console.log($(iElement).find('[data-hash]'));
                                        clearInterval(checkExist);
                                    }
                                }, 100);
                            }
                        };
                    },
                    link: function (scope, element, attrs) {
                    }
                };
            }
            return owlCarouselDirective;
        })();
        Directives.owlCarouselDirective = owlCarouselDirective;
    })(Directives = exports.Directives || (exports.Directives = {}));
});

