define('../bower_components/dwx-portfolio-core/src/services/portfolioCoreService',["require", "exports"], function (require, exports) {
    exports.switchPortfolioSave;
    exports.dossierCached;
    var PortfolioCore;
    (function (PortfolioCore) {
        exports.switchPortfolioSave = function (_service) {
            var _this = this;
            _service.switchPortfolio.save({}, JSON.stringify(_service['portfolio']), function (response) {
                if (response.outcome === "OK") {
                    _this.EventService.publish('portfolioSelected', [], false);
                    _this.EventService.publish('choosePortfolio');
                }
                else {
                    _this.EventService.publish('getPortfolioError', []);
                    console.error('Error switching portfolio');
                }
            }, function () {
                _this.EventService.publish('getPortfolioError', []);
                console.error('Error switching portfolio');
            });
        };
        PortfolioCore.initPortfolioService = function (portfolioList, _service, loggedUser) {
            var _this = this;
            var portfolioSelected;
            portfolioSelected = 0;
            portfolioList.get({}, function (response) {
                var portfolioCookie;
                if (response.outcome === 'OK') {
                    _service['portfolioList'] = response.content;
                    if (_service.portfolioList !== void 0 && _service.portfolioList.length > 0) {
                        var _this = this;
                        portfolioCookie = _this.$cookieStore.get('portfolioSelected');
                        if (portfolioCookie && portfolioCookie < _service.portfolioList.length) {
                            portfolioSelected = portfolioCookie;
                        }
                        _service['portfolio'] = _service.portfolioList[portfolioSelected];
                        _this.switchPortfolioSave(_service, portfolioSelected);
                    }
                }
                else {
                    _this.EventService.publish('getPortfolioError', []);
                }
            }, function (error) {
                _this.EventService.publish('getPortfolioError', []);
            });
        };
        var PortfolioCoreService = (function () {
            function PortfolioCoreService($resource, EventService, $cookieStore, $rootScope, $q, $http, profileService) {
                this.EventService = EventService;
                this.$cookieStore = $cookieStore;
                this.$rootScope = $rootScope;
                this.$q = $q;
                this.$http = $http;
                this.profileService = profileService;
                this.getDossierList = function (params) {
                    var deferred = this.$q.defer();
                    var url = apiPrefix + "/portfolio/dossier";
                    this.$http.get(url, { params: params }).then(function (success) {
                        if (success.data['outcome'] === 'OK') {
                            deferred.resolve(success.data);
                        }
                        else {
                            deferred.reject(success.data);
                        }
                    }, function (error) {
                        deferred.reject({ type: 'function', error: error });
                    });
                    return deferred.promise;
                };
                this.getPortfolioLoad = function (loggedUser) {
                    var deferred = this.$q.defer();
                    var url = "/api/portfolio/portfolio/load";
                    this.$http.post(url, { customerCode: loggedUser, productInfoGroups: ["BASE", "COMMON"] }).then(function (success) {
                        if (success.data['code'] === 'OK') {
                            deferred.resolve(success.data.result);
                        }
                        else {
                            deferred.reject(success.data);
                        }
                    }, function (error) {
                        deferred.reject({ type: 'function', error: error });
                    });
                    return deferred.promise;
                };
                this.initPortfolioService = PortfolioCore.initPortfolioService;
                this.switchPortfolioSave = exports.switchPortfolioSave;
                var portfolioList, _service;
                var _this = this;
                portfolioList = $resource(apiPrefix + "/portfolios", {});
                _service = {
                    perspectiveId: 0,
                    perspectiveLevel: 0,
                    portfolioLoad: null,
                    switchPortfolio: $resource("" + apiPrefix + "/portfolio/switch", {}, {
                        save: {
                            method: 'POST'
                        }
                    }),
                    portfolioList: {},
                    dossierList: [],
                    dossierOwnerList: [],
                    dossierCoOwnerList: [],
                    perspective: $resource(apiPrefix + "/holdings-perspective", {}),
                    holdings: $resource(apiPrefix + "/portfolio/holdings", {}),
                    holdingsConfiguration: $resource(apiPrefix + "/portfolio/holdings/configuration", {}),
                    holdingsMockConfiguration: $resource("bower_components/dwx-widget-portfolio-holdings/config/configuration.json", {}),
                    holdingsToXls: $resource(apiPrefix + "/portfolio/holdings/xls", {}),
                    holdingsToPdf: $resource(apiPrefix + "/portfolio/holdings/pdf", {}),
                    holdingsByDossier: $resource(apiPrefix + "/portfolio/holdingsByDossiers", {}, {
                        save: {
                            method: 'POST',
                            data: {
                                ndg: true
                            }
                        }
                    }),
                    performanceByDossier: $resource(apiPrefix + "/portfolio/performanceByDossiers", {}, {
                        save: {
                            method: 'POST',
                            data: {
                                ndg: true
                            }
                        }
                    }),
                    summary: $resource(apiPrefix + "/portfolio/summary", {}),
                    summarybyholdings: $resource(apiPrefix + "/portfolio/summarybyholdings", {}),
                    summaryByDossier: $resource(apiPrefix + "/portfolio/summaryByDossiers", {}, {
                        save: {
                            method: 'POST',
                            data: {
                                ndg: true
                            }
                        }
                    }),
                    movements: $resource(apiPrefix + "/portfolio/transactions", {}),
                    productMovements: $resource(apiPrefix + "/portfolio/transactions/:productCode", { productCode: '@productCode' }),
                    productDetails: $resource(apiPrefix + "/portfolio/details?holdingCode=:holdingCode&productCode=:productCode&firstHolder=:firstHolder", {
                        holdingCode: '@holdingCode',
                        productCode: '@productCode',
                        firstHolder: '@firstHolder'
                    }),
                    productDataSheet: $resource(apiPrefix + "/portfolio/details/datasheet", { productCode: '@productCode' }),
                    productMovementsToXls: $resource(apiPrefix + "/portfolio/transactions/:productCode/xls", { productCode: '@productCode' }),
                    productMovementsToPdf: $resource(apiPrefix + "/portfolio/transactions/:productCode/pdf", { productCode: '@productCode' }),
                    performanceDetails: $resource(apiPrefix + "/portfolio/performance-details", {}),
                    performanceHistory: $resource(apiPrefix + "/portfolio/performance-history", {}),
                    performanceOverview: $resource(apiPrefix + "/portfolio/performance-overview", {}),
                    pendingOrders: $resource(apiPrefix + "/portfolio/pendingOrders", {}, {
                        "query": {
                            "isArray": false
                        }
                    }),
                    challengeCode: $resource(apiPrefix + "/order/challengCode", {}),
                    cancelOrder: $resource(apiPrefix + "/orders/cancel", {}, {
                        save: {
                            method: 'POST',
                            data: {
                                answer: true
                            },
                            isArray: false
                        }
                    }),
                    getConfigurationStaticField: $resource("bower_components/dwx-widget-portfolio-performance-azimut/mock/configurationStaticField.json"),
                    getConfigurationTableField: $resource("bower_components/dwx-widget-portfolio-performance-azimut/mock/configurationTableField.json"),
                    getPortfolioDisclaimer: function (params) {
                        var deferred = _this.$q.defer();
                        var url = "/api/cms/disclaimers?resourceId_in=" + params;
                        _this.$http.get(url).then(function (success) {
                            deferred.resolve(success.data);
                        }, function (error) {
                            deferred.reject({ type: 'function', error: error });
                        });
                        return deferred.promise;
                    },
                    downloadHoldingsFile: function (body, params) {
                        var deferred = _this.$q.defer();
                        _this.$http.post(apiPrefix + '/portfolio/holdings/' + params, body, { responseType: 'arraybuffer' }).then(function (success) {
                            deferred.resolve(success);
                        }, function (error) {
                            deferred.reject({ type: 'function', error: error });
                        });
                        return deferred.promise;
                    },
                    downloadPerformanceFile: function (body, params) {
                        var deferred = _this.$q.defer();
                        _this.$http.post(apiPrefix + '/portfolio/performance-allocations/export/' + params, body, {
                            responseType: 'blob'
                        }).then(function (success) {
                            deferred.resolve(success);
                        }, function (error) {
                            deferred.reject({ type: 'function', error: error });
                        });
                        return deferred.promise;
                    }
                };
                EventService.subscribe('profileService:logged-user-ready', function (loggedUser) {
                    return _this.initPortfolioService(portfolioList, _service, loggedUser);
                });
                if (!!this.profileService.loggedUser)
                    this.initPortfolioService(portfolioList, _service, _this.profileService.loggedUser);
                return _service;
            }
            return PortfolioCoreService;
        })();
        PortfolioCore.PortfolioCoreService = PortfolioCoreService;
    })(PortfolioCore = exports.PortfolioCore || (exports.PortfolioCore = {}));
});

