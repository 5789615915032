requirejs.config({
    waitSeconds: 0,
    paths: {
        'angular': "../bower_components/angular/angular.min",
        'angular-resource': '../bower_components/angular-resource/angular-resource.min',
        'angular-translate': '../bower_components/angular-translate/dist/angular-translate.min',
        'angular-animate': '../bower_components/angular-animate/angular-animate.min',
        'angular-route': '../bower_components/angular-route/angular-route.min',
        'angular-cookies': '../bower_components/angular-cookies/angular-cookies.min',
        'angular-dynamic-locale': '../bower_components/angular-dynamic-locale/dist/tmhDynamicLocale',
        'angular-foundation': '../bower_components/angular-foundation/mm-foundation-tpls.min',
        'angular-local-storage': '../bower_components/angular-local-storage/dist/angular-local-storage.min',
        'angular-translate-loader-partial': '../bower_components/angular-translate-loader-partial/angular-translate-loader-partial.min',
        'bootstrap': "../bower_components/bootstrap/dist/js/bootstrap.min",
        'bootstrap-datepicker': "../bower_components/bootstrap-datepicker/js/bootstrap-datepicker",
        'bootstrap-datepicker-it': '../bower_components/bootstrap-datepicker/js/locales/bootstrap-datepicker.it',
        'brightcove-experiences': '../bower_components/brightcove/index',
        'cordova_plugins': '../scripts/cordova/cordova_plugins',
        'file-saver': '../bower_components/file-saver/dist/FileSaver.min',
        'mobile-detect': '../bower_components/mobile-detect/mobile-detect',
        'eq': '../bower_components/eq.js/dist/eq.min',
        'fastclick': '../bower_components/fastclick/lib/fastclick',
        'foundation': '../bower_components/foundation-sites/js/foundation.min',
        'jquery': "../bower_components/jquery/dist/jquery.min",
        'lodash': "../bower_components/lodash/lodash.min",
        'sweetalert': '../bower_components/sweetalert/lib/sweet-alert.min',
        'cordova-loader': './cordova/cordovaLoader',
    },
    shim: {
        'angular': {
            dep: ['sweetalert'],
            exports: 'angular'
        },
        'angular-animate': ['angular'],
        'angular-resource': ['angular'],
        'angular-translate': ['angular'],
        'angular-route': ['angular'],
        'angular-cookies': ['angular'],
        'angular-dynamic-locale': ['angular'],
        'angular-foundation': ['angular'],
        'angular-local-storage': ['angular'],
        'angular-translate-loader-partial': ['angular-translate'],
        'bootstrap': ['jquery'],
        'bootstrap-datepicker': {
            deps: ['jquery', 'bootstrap',],
            exports: '$.fn.datepicker'
        },
        'bootstrap-datepicker-it': ['jquery', 'bootstrap-datepicker'],
        'brightcove-experiences': {
            exports: 'brightcove'
        },
        'fastclick': {
            exports: 'FastClick'
        },
        'foundation': ['jquery'],
        'jquery': {
            exports: '$'
        },
        'lodash': {
            exports: "_"
        },
        'mobile-detect': {
            exports: 'MobileDetect'
        }
    },
});
var baseUrl = "";
var apiPrefix = baseUrl + '/myazimut/api';
var webapp = {};
var settings;
var landingPageId;
var hideLogin;
var realEconomyUsePaidAmount = false;
var env = {
    type: 'env',
    enableCommunication: 'true',
    enableFocusLink: 'true',
    enablePir: 'true',
    includeContractWithZeroBalance: 'false',
    onboardingPageId: '101',
    reboardingPageId: '101',
};
require(["./boot"], function () {
    console.log("Config call!");
});
require(['fastclick', 'file-saver', 'foundation', 'sweetalert', 'brightcove-experiences', 'eq', 'angular-local-storage', 'angular-foundation', 'mobile-detect', 'cordova-loader', 'bootstrap-datepicker', 'bootstrap-datepicker-it'], function (FastClk, fsaver, foundation, sweetAlertDep, brightcoveDep, eqDep, angularLocalStorageDep, angularFoundation, MobileDetect, cordovaLoader, datePicker) {
    FastClick = FastClk;
    $(function () {
        FastClick.attach(document.body);
    });
    $(window.document).foundation();
    brightcove = brightcoveDep;
    eqjs = eqDep;
    eqjs.refreshNodes();
    eqjs.query(undefined, true);
    sweetAlert = sweetAlertDep;
    md = new MobileDetect(window.navigator.userAgent);
    new cordovaLoader.CordovaLoader();
});

define("config", function(){});

