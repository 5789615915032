define('../bower_components/azimut-utility/src/services/remotePaginationService',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var PaginationService = (function () {
            function PaginationService($rootScope, $location, loginService, EventService, initConfigService) {
                return function (itemsCount, itemsPerPage, currentPage, numberOfKeys) {
                    var self, updateItemsListWithoutCurrentPageReset;
                    self = this;
                    updateItemsListWithoutCurrentPageReset = function (itemsCount) {
                        self.size = itemsCount;
                        if (self.size > 0) {
                            self.pages = Math.ceil(self.size / self.itemsPerPage);
                        }
                        else {
                            self.pages = 0;
                        }
                        self.pagesArray = new Array(self.pages);
                    };
                    self.updateItems = function (itemsCount) {
                        updateItemsListWithoutCurrentPageReset(itemsCount);
                        self.changePage(1);
                    };
                    self.changePage = function (newPage) {
                        self.currentPage = newPage;
                        if (self.currentPage < self.pages) {
                            self.itemsInPage = self.itemsPerPage;
                        }
                        else if (self.currentPage === self.pages) {
                            self.itemsInPage = self.size % self.itemsPerPage || self.itemsPerPage;
                        }
                        else {
                            self.itemsInPage = 0;
                        }
                    };
                    (function (itemsCount, itemsPerPage, currentPage, numberOfKeys) {
                        self.itemsPerPage = itemsPerPage;
                        if (numberOfKeys != undefined) {
                            self.numberOfKeys = numberOfKeys;
                        }
                        else {
                            self.numberOfKeys = 7;
                        }
                        updateItemsListWithoutCurrentPageReset(itemsCount);
                        self.changePage(currentPage || 1);
                    })(itemsCount, itemsPerPage, currentPage, numberOfKeys);
                };
            }
            return PaginationService;
        })();
        Utility.PaginationService = PaginationService;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

