define('widget-user-data-advanced/widget-user-data-advanced',["require", "exports", './config', '../../bower_components/dwx-widget-user-data-advanced/src/app', '../../bower_components/dwx-widget-user-data-advanced/src/controllers/userDataAdvancedCtrl'], function (require, exports, config, WidgetUserDataAdvanced, WidgetUserDataAdvancedController) {
    var newList = WidgetUserDataAdvanced.configuration.list;
    newList['userDataAdvancedConfig'] = { callback: ['$translateProvider', config.UserdataConfig], type: "config" };
    newList['UserDataAdvancedCtrl'] = {
        callback: ["$scope", "$rootScope", "$attrs", "userDataAdvancedService", "$timeout", "$http", "$element", "initConfigService", "errorsHandlerService", "$translatePartialLoader", "EventService", "$window", "$interval", "profileService", WidgetUserDataAdvancedController.UserDataAdvancedCtrl],
        type: 'controller'
    };
    WidgetUserDataAdvanced.configuration.list = newList;
    exports.configuration = WidgetUserDataAdvanced.configuration;
});

