define('layout-base/../../bower_components/dwx-layout-base/src/directives/headerDirective',["require", "exports"], function (require, exports) {
    var Layout;
    (function (Layout) {
        var LayoutHeaderDirective = (function () {
            function LayoutHeaderDirective() {
                return {
                    restrict: "A",
                    link: function (scope, element, attrs) {
                    },
                    controller: "HeaderCtrl as HeaderCtrl",
                    templateUrl: 'bower_components/dwx-layout-base/dist/src/views/header.html',
                    replace: false
                };
            }
            return LayoutHeaderDirective;
        })();
        Layout.LayoutHeaderDirective = LayoutHeaderDirective;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

