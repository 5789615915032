define('../bower_components/dwx-widget-my-documents/src/filter/sortFieldInAttributes',["require", "exports"], function (require, exports) {
    var SortFieldInAttributes = (function () {
        function SortFieldInAttributes() {
            this._this = this;
        }
        SortFieldInAttributes.myFilter = function (items, fields, reverseOrder) {
            var filtered = [];
            angular.forEach(items, function (item) {
                filtered.push(item);
            });
            filtered.sort(function (a, b) {
                var result;
                angular.forEach(fields, function (val, key) {
                    var aObj = SortFieldInAttributes.checkValue(a['attributes'][fields[key]]);
                    var bObj = SortFieldInAttributes.checkValue(b['attributes'][fields[key]]);
                    if (aObj > bObj)
                        result = 1;
                    if (aObj < bObj)
                        result = -1;
                });
                return result;
            });
            if (reverseOrder)
                filtered.reverse();
            return filtered;
        };
        SortFieldInAttributes.Factory = function () {
            var instance = new SortFieldInAttributes();
            return SortFieldInAttributes.myFilter;
        };
        SortFieldInAttributes.checkValue = function (val) {
            if (SortFieldInAttributes.isNumeric(val)) {
                return parseInt(val);
            }
            return val;
        };
        SortFieldInAttributes.isNumeric = function (num) {
            return !isNaN(num);
        };
        return SortFieldInAttributes;
    })();
    exports.SortFieldInAttributes = SortFieldInAttributes;
});

