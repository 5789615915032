define('layout-base/../../bower_components/dwx-utility/src/directives/responsiveScrollableTableDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var ResponsiveScrollableTableDirective = (function () {
            function ResponsiveScrollableTableDirective($timeout) {
                this.restrict = 'A';
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(element, $timeout);
                };
            }
            ResponsiveScrollableTableDirective.prototype.myLink = function (element, $timeout) {
                $timeout(function () {
                    $(element).one("scroll", function (event) {
                        var actions, cell, cols, colsPosition, colsWidth, elementPosition, elementWidth, fixedColWidth, i, index, obj, row, rowHeight, rowPosition, rows, scrollPosition, stickyCol;
                        scrollPosition = $(element).scrollLeft();
                        colsWidth = [];
                        colsPosition = [];
                        fixedColWidth = 0;
                        cols = $(element).children(".custom-table").children(".ct-row.ct-row:first-child").children(".ct-head-col");
                        index = 0;
                        while (index < cols.length) {
                            obj = cols[index];
                            elementWidth = $(obj).outerWidth();
                            elementPosition = $(obj).position().left + scrollPosition;
                            colsWidth.push(elementWidth);
                            colsPosition.push(elementPosition);
                            fixedColWidth += elementWidth - 1;
                            index++;
                        }
                        rows = $(element).children(".custom-table").children(".ct-row");
                        index = 0;
                        while (index < rows.length) {
                            row = rows[index];
                            rowHeight = $(row).outerHeight();
                            rowPosition = $(row).position().top;
                            stickyCol = $(row).children(".ct-head-col");
                            i = 0;
                            while (i < stickyCol.length) {
                                cell = stickyCol[i];
                                $(cell).css("width", colsWidth[i]).css("height", rowHeight).css("left", colsPosition[i]).addClass("ct-head-sticky");
                                if (i === stickyCol.length - 1) {
                                    $(cell).after("<div class='ct-head-placeholder ct-added' style='height: " + rowHeight + "px;'><div class='ct-added' style='height: " + rowHeight + "px; width: " + fixedColWidth + "px;'></div></div><div class='ct-head-shadow ct-added' style='min-height: " + rowHeight + "px; left: " + fixedColWidth + "px;'></div>");
                                }
                                i++;
                            }
                            actions = $(row).next(".ct-actions");
                            actions.css("margin-top", -rowHeight).css("max-height", rowHeight).css("max-width", actions.children(":first").outerWidth() * actions.children().length);
                            index++;
                        }
                        $timeout(function () {
                            $(element).find(".ct-row").toggle().toggle();
                        });
                        return $(element).addClass("ct-stickied");
                    });
                });
            };
            ResponsiveScrollableTableDirective.Factory = function () {
                var directive = function ($timeout) {
                    return new ResponsiveScrollableTableDirective($timeout);
                };
                directive['$inject'] = ['$timeout'];
                return directive;
            };
            return ResponsiveScrollableTableDirective;
        })();
        Utility.ResponsiveScrollableTableDirective = ResponsiveScrollableTableDirective;
        var CustomTableActionTriggerDirective = (function () {
            function CustomTableActionTriggerDirective($timeout) {
                this.restrict = 'C';
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(element, $timeout);
                };
            }
            CustomTableActionTriggerDirective.prototype.myLink = function (element, $timeout) {
                $(element).click(function (event) {
                    var actions, row, stickied;
                    $(element).closest(".custom-table-scroller").trigger("scroll");
                    row = $(element).closest(".ct-row");
                    stickied = row.find(".ct-head-col:not(.ct-actions-trigger)");
                    actions = row.next("ul.ct-actions");
                    actions.addClass("ct-actions-active");
                    $timeout(function () {
                        var shadow;
                        shadow = row.find(".ct-head-shadow");
                        stickied.css("margin-left", actions.outerWidth() - $(element).outerWidth());
                        return shadow.css("margin-left", actions.outerWidth() - $(element).outerWidth());
                    });
                });
            };
            CustomTableActionTriggerDirective.Factory = function () {
                var directive = function ($timeout) {
                    return new CustomTableActionTriggerDirective($timeout);
                };
                directive['$inject'] = ['$timeout'];
                return directive;
            };
            return CustomTableActionTriggerDirective;
        })();
        Utility.CustomTableActionTriggerDirective = CustomTableActionTriggerDirective;
        var CustomTableActionCloseDirective = (function () {
            function CustomTableActionCloseDirective() {
                this.restrict = 'A';
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(element);
                };
            }
            CustomTableActionCloseDirective.Factory = function () {
                var directive = function () {
                    return new CustomTableActionCloseDirective();
                };
                directive['$inject'] = [];
                return directive;
            };
            CustomTableActionCloseDirective.prototype.myLink = function (element) {
                $(element).click(function (event) {
                    var row, shadow, stickied;
                    row = $(element).closest("ul").prev(".ct-row");
                    stickied = row.find(".ct-head-col:not(.ct-actions-trigger)");
                    shadow = row.find(".ct-head-shadow");
                    $(element).closest("ul.ct-actions").removeClass("ct-actions-active");
                    stickied.css("margin-left", 0);
                    shadow.css("margin-left", 0);
                });
            };
            return CustomTableActionCloseDirective;
        })();
        Utility.CustomTableActionCloseDirective = CustomTableActionCloseDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

