define('widget-portfolio-summary/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var PortfolioSummaryConfig = (function () {
        function PortfolioSummaryConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return PortfolioSummaryConfig;
    })();
    exports.PortfolioSummaryConfig = PortfolioSummaryConfig;
});

