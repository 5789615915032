define('widget-news-list/../../project_components/dwx-widget-news-list/src/controllers/newsListCtrl',["require", "exports", '../../../../scripts/owui-widget.constant'], function (require, exports, OwuiWidgetConstant) {
    var NewsListController = (function () {
        function NewsListController($scope, $routeParams, initConfigService, EventService, $location, configurationService) {
            this.$scope = $scope;
            this.$routeParams = $routeParams;
            this.EventService = EventService;
            this.$location = $location;
            this.configurationService = configurationService;
            this.owuiWidgetNewsListName = 2 /* WIDGET_NEWS_LIST */;
            var _this = this;
            $scope.events = this;
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetAdvisor';
            _this.widgetConfig = initConfigService[_this.widgetId];
            this.widgetConfig.status.mode = 'on';
            var listenerCallback = this.handler.bind(this);
            $scope.$on("$destroy", function () {
                _this.EventService.unsubscribe(_this.owuiWidgetEventHandler);
                _this.widgetElement.removeEventListener('owui-thule-widget-news-list', listenerCallback);
                $(_this.widgetElement).remove();
            });
            this.newsDetailParameter = _this.$location.search();
            this.breadcrumb = this.newsDetailParameter.back;
            if (!!this.newsDetailParameter.newsId && !!this.newsDetailParameter.hasOwnProperty('newsIndex')) {
                this.widgetProp = {
                    'news-id': this.newsDetailParameter.newsId,
                    'news-index': this.newsDetailParameter.newsIndex,
                    'dwx-mode': true
                };
            }
            else {
                this.widgetProp = {
                    'news-type': 'ALL',
                    'dwx-mode': true
                };
            }
            _this.owuiWidgetEventHandler = EventService.subscribe('owuiWidgetDirective:widgetLoaded', function () {
                $('.NewsList__header--link').on('click', function () {
                    _this.goBack();
                });
                _this.widgetElement = document.getElementsByTagName('owui-thule-widget-news-list')[0];
                _this.widgetElement.addEventListener('owui-thule-widget-news-list', listenerCallback);
            });
        }
        NewsListController.prototype.getManifestAssets = function () {
            return this.configurationService.manifestJson;
        };
        NewsListController.prototype.handler = function (event) {
            var _this = this;
            var newsDetailParams = {
                newsId: encodeURIComponent(event.detail.data.idReference),
                newsIndex: event.detail.data.newsIndex,
                back: _this.newsDetailParameter.back
            };
            if (!_this.newsDetailParameter.newsId)
                newsDetailParams.back = 'News';
            _this.breadcrumb = newsDetailParams.back;
            _this.$location.search(newsDetailParams);
            _this.widgetProp = {
                'news-id': encodeURIComponent(event.detail.data.idReference),
                'news-index': event.detail.data.newsIndex,
                'dwx-mode': true
            };
            _this.$scope.$apply();
        };
        NewsListController.prototype.goBack = function () {
            var _this = this;
            var path = _this.breadcrumb === 'News' ? '/news' : '/';
            var back = _this.breadcrumb === 'News' ? 'Home' : null;
            if (!!_this.$location.search().hasOwnProperty('newsId')) {
                _this.breadcrumb = 'Home';
                _this.widgetProp = {
                    'news-type': 'ALL',
                    'dwx-mode': true
                };
            }
            _this.$location.path(path).search({ back: back });
            _this.$scope.$apply();
        };
        return NewsListController;
    })();
    exports.NewsListController = NewsListController;
});

