define('widget-portfolio-ownership-view/../../bower_components/dwx-widget-portfolio-ownership-view/src/app',["require", "exports", './controllers/portfolioOwnershipViewCtrl', './directives/portfolioOwnershipViewDirective'], function (require, exports, controller, directive) {
    exports.configuration = {
        list: {
            "portfolioOwnershipViewCtrl": { callback: ['$scope', 'portfolioCoreService', 'EventService', 'initConfigService', '$location', 'profileService', controller.PortfolioOwnershipViewCtrl], type: "controller" },
            "dwxWidgetPortfolioOwnershipView": { callback: [directive.PortfolioOwnershipViewDirective.Factory()], type: "directive" },
        },
        ngModules: []
    };
});

