define('widget-pir-box/../../scripts/owui-widget.constant',["require", "exports"], function (require, exports) {
    (function (OWUI_WIDGET_NAME) {
        OWUI_WIDGET_NAME[OWUI_WIDGET_NAME["WIDGET_COMMUNICATION"] = 0] = "WIDGET_COMMUNICATION";
        OWUI_WIDGET_NAME[OWUI_WIDGET_NAME["WIDGET_NEWS"] = 1] = "WIDGET_NEWS";
        OWUI_WIDGET_NAME[OWUI_WIDGET_NAME["WIDGET_NEWS_LIST"] = 2] = "WIDGET_NEWS_LIST";
        OWUI_WIDGET_NAME[OWUI_WIDGET_NAME["WIDGET_PORTFOLIO_SUMMARY"] = 3] = "WIDGET_PORTFOLIO_SUMMARY";
        OWUI_WIDGET_NAME[OWUI_WIDGET_NAME["WIDGET_FOCUS"] = 4] = "WIDGET_FOCUS";
        OWUI_WIDGET_NAME[OWUI_WIDGET_NAME["WIDGET_LOGIN"] = 5] = "WIDGET_LOGIN";
        OWUI_WIDGET_NAME[OWUI_WIDGET_NAME["WIDGET_PIR_BOX"] = 6] = "WIDGET_PIR_BOX";
    })(exports.OWUI_WIDGET_NAME || (exports.OWUI_WIDGET_NAME = {}));
    var OWUI_WIDGET_NAME = exports.OWUI_WIDGET_NAME;
    exports.WIDGET_COMMUNICATION = 'widgetCommunication';
    exports.WIDGET_NEWS = 'widgetNews';
    exports.WIDGET_NEWS_LIST = 'widgetNewsList';
    var owuiWidget = {};
    owuiWidget[0 /* WIDGET_COMMUNICATION */] = {
        widgetTag: 'owui-thule-widget-communication',
        stealName: 'owui_thule_widget_communication/*'
    };
    owuiWidget[1 /* WIDGET_NEWS */] = {
        widgetTag: 'owui-thule-widget-news',
        stealName: 'owui_thule_widget_news/*'
    };
    owuiWidget[2 /* WIDGET_NEWS_LIST */] = {
        widgetTag: 'owui-thule-widget-news-list',
        stealName: 'owui_thule_widget_news_list/*'
    };
    owuiWidget[3 /* WIDGET_PORTFOLIO_SUMMARY */] = {
        widgetTag: 'owui-thule-widget-customer-portfolio-summary',
        stealName: 'owui_thule_widget_customer_portfolio_summary/*'
    };
    owuiWidget[4 /* WIDGET_FOCUS */] = {
        widgetTag: 'owui-thule-widget-real-economy-focus',
        stealName: 'owui_thule_widget_real_economy_focus/*'
    };
    owuiWidget[6 /* WIDGET_PIR_BOX */] = {
        widgetTag: 'owui-thule-widget-pir-box',
        stealName: 'owui_thule_widget_pir_box/*'
    };
    owuiWidget[5 /* WIDGET_LOGIN */] = {
        widgetTag: 'owui-thule-widget-authentication-my-azimut',
        stealName: 'owui_thule_widget_authentication_my_azimut/*'
    };
    exports.OwuiWidgetMap = owuiWidget;
});

