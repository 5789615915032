define('layout-base/../../bower_components/dwx-utility/src/directives/datePickerDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var DatePickerDirective = (function () {
            function DatePickerDirective($timeout, $filter, eventService) {
                this.$timeout = $timeout;
                this.$filter = $filter;
                this.require = '?ngModel';
                this.restrict = 'A';
                this.defaultDateFormat = "dd/MM/yyyy";
                var _this = this;
                this.eventService = eventService;
                this.link = function (scope, element, attrs, ctrl) {
                    if (ctrl) {
                        ctrl.$parsers.push(function (viewValue) {
                            if (attrs.required) {
                                var valid = false;
                                if (viewValue && viewValue != "") {
                                    valid = true;
                                }
                                ctrl.$setValidity('invalidDateField', valid);
                            }
                            var parsedDate = $('.datepicker').datepicker['DPGlobal']['parseDate'](viewValue, attrs.customDateFormat ? attrs.customDateFormat : _this.defaultDateFormat);
                            return parsedDate.getTime();
                        });
                        ctrl.$formatters.push(function (modelValue) {
                            return _this.formatDate(modelValue, attrs.customDateFormat);
                        });
                    }
                    _this.myLink(scope, element, attrs, $timeout, eventService);
                    var handleChangeLanguage = eventService.subscribe("translateChangeSuccess", function () {
                        $(element).datepicker('remove');
                        $(element).val('');
                        _this.initDatePicker($(element));
                    });
                    scope.$on("$destroy", function () {
                        eventService.unsubscribe(handleChangeLanguage);
                    });
                };
            }
            DatePickerDirective.prototype.formatDate = function (value, customDateFormat) {
                var _this = this;
                if (customDateFormat) {
                    return _this.$filter('date')(value, customDateFormat);
                }
                else {
                    return _this.$filter('date')(value, _this.defaultDateFormat);
                }
            };
            DatePickerDirective.prototype.myLink = function (scope, element, attrs, $timeout, eventService) {
                var _this = this;
                element.bind('keydown', function (e) {
                    e.preventDefault();
                });
                $(element).bind('focus', function () {
                    $(this).siblings(".datepicker-trigger").first().click();
                });
                $(element).siblings(".datepicker-trigger").bind('click', function () {
                    if (scope.$root.$$phase != '$apply' && scope.$root.$$phase != '$digest') {
                        scope.$digest();
                    }
                    _this.startDate = $(element).attr("data-start-date") ? +$(element).attr("data-start-date") : undefined;
                    _this.endDate = $(element).attr("data-end-date") ? +$(element).attr("data-end-date") : undefined;
                    $(element).datepicker('setStartDate', new Date(_this.startDate));
                    $(element).datepicker('setEndDate', new Date(_this.endDate));
                    $(element).datepicker("show");
                });
                _this.initDatePicker($(element));
                eventService.subscribe('clearInput', function (obj) {
                    if ((obj.name = $(element).attr('name'))) {
                        return $(element).val('');
                    }
                    else if ((obj.id = $(element).attr('id'))) {
                        return $(element).val('');
                    }
                });
            };
            DatePickerDirective.prototype.initDatePicker = function (el, defOptions) {
                if (defOptions === void 0) { defOptions = {}; }
                var addCssClass, localeForDatePicker, options, bindTo;
                var _this = this;
                _this.dateMinViewMode = $(el).attr("dwx-datepicker-min-view-mode");
                _this.dateFormat;
                if (!_this.dateMinViewMode) {
                    _this.dateMinViewMode = 0;
                }
                else {
                    switch (_this.dateMinViewMode) {
                        case "months":
                            _this.dateFormat = 'mm/yyyy';
                            break;
                        case 1:
                            _this.dateFormat = 'mm/yyyy';
                            break;
                        case "years":
                            _this.dateFormat = 'yyyy';
                            break;
                        case 2:
                            _this.dateFormat = 'yyyy';
                            break;
                        default:
                            _this.dateFormat = 'dd/mm/yyyy';
                            break;
                    }
                }
                switch (language) {
                    case "en-gb":
                        localeForDatePicker = "en-GB";
                        break;
                    case "it-it":
                        localeForDatePicker = "it";
                        break;
                    case "es-es":
                        localeForDatePicker = "es";
                        break;
                    case "fr-fr":
                        localeForDatePicker = "fr";
                        break;
                    case "ar-ar":
                        localeForDatePicker = "ar";
                        break;
                    case "nl-nl":
                        localeForDatePicker = "nl";
                        break;
                    case "zh-cn":
                        localeForDatePicker = "zh-CN";
                }
                addCssClass = true;
                options = {
                    autoclose: true,
                    language: localeForDatePicker,
                    minViewMode: _this.dateMinViewMode
                };
                options = $.extend(options, defOptions);
                if (_this.dateFormat) {
                    options['format'] = _this.dateFormat;
                }
                bindTo = el.attr("data-bind-to");
                el.datepicker(options).on('show', function () {
                    var picker;
                    if (bindTo) {
                        $(bindTo).append($('.datepicker'));
                    }
                    picker = $('.datepicker');
                    picker.trigger("blur");
                    if (addCssClass) {
                        picker.addClass('datepicker-animate');
                        addCssClass = false;
                        _this.$timeout(function () {
                            picker.removeClass('datepicker-animate');
                        }, 500);
                    }
                }).on('hide', function () {
                    addCssClass = true;
                }).on('changeDate', function (date) {
                    var dateParameter;
                    dateParameter = {
                        date: $(this).val(),
                        id: $(this).attr('id'),
                        utcDate: $(this).datepicker("getUTCDate"),
                        name: $(this).attr('name')
                    };
                    _this.eventService.publish('insertDate', [dateParameter]);
                    $(this).datepicker('hide');
                });
            };
            DatePickerDirective.Factory = function () {
                var directive = function ($timeout, $filter, eventService) {
                    return new DatePickerDirective($timeout, $filter, eventService);
                };
                directive['$inject'] = ['$timeout', '$filter', 'EventService'];
                return directive;
            };
            return DatePickerDirective;
        })();
        Utility.DatePickerDirective = DatePickerDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

