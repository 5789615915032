define('../bower_components/dwx-widget-unlock-user/src/app',["require", "exports", './controllers/unlockUserCtrl', './services/unlockUserService', './directives/unlockUserDirective', './config'], function (require, exports, unlockUserController, unlockUserService, unlockUserDirective, config) {
    exports.configuration = {
        list: {
            "unlockUserCtrl": { callback: ['$scope', 'unlockUserService', 'initConfigService', '$translatePartialLoader', '$translate', '$location', 'profileService', 'EventService', 'configurationService', unlockUserController.UnlockUserController], type: "controller" },
            "unlockUserService": { callback: ['$resource', unlockUserService.UnlockUserService], type: "service" },
            "dwxUnlockUser": { callback: ['unlockUserService', unlockUserDirective.UnlockUserDirective.Factory()], type: "directive" },
            "config": { callback: ['$translateProvider', config.UnlockConfig], type: "config" },
        },
        ngModules: ['pascalprecht.translate']
    };
});

