define('../bower_components/dwx-widget-portfolio-performance-azimut/src/config/configuration',["require", "exports"], function (require, exports) {
    exports.config = {
        "fields": ["productName", "productType", "contractCode", "totalInvested", "switchIn", "dividendsAndWithdrawn", "switchOut", "averageCapital", "totalValue", "plusMinus", "performance"],
        "currencyFields": [],
        "dateFields": [],
        "percentageFields": ["performance"],
        "fieldsToRound": {
            "averageCapital": 2,
            "performance": 2,
            "plusMinus": 2,
            "switchIn": 2,
            "totalInvested": 2,
            "dividendsAndWithdrawn": 2,
            "totalValue": 2,
            "switchOut": 2
        },
        "timeRangeList": ["PERF_FROM_INITIAL_YEAR", "PERF_FROM_ONE_YEAR", "PERF_INTERVAL"],
        "categories": ["GESTITO", "ADMINISTERED", "ECONOMIAREALE", "PREVIDENZA"],
        "defaultTimeRange": "PERF_FROM_INITIAL_YEAR",
        "defaultCategory": "GESTITO"
    };
});

