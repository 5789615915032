define('widget-news/../../project_components/dwx-widget-news/src/app',["require", "exports", "./controllers/newsCtrl", "./directives/newsDirective", "./config"], function (require, exports, newsController, newsDirective, newsConfig) {
    exports.configuration = {
        list: {
            "newsCtrl": {
                callback: ["$scope", "initConfigService", "EventService", "$location", "configurationService", newsController.NewsController],
                type: "controller"
            },
            "dwxWidgetNews": { callback: [newsDirective.NewsDirective], type: "directive" },
            "newsConfig": { callback: [newsConfig.NewsConfig], type: "config" }
        },
        ngModules: ['ngResource']
    };
});

