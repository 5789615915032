define('layout-base/../../bower_components/dwx-utility/src/directives/dropDownClickableDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var DropdownClickableDirective = (function () {
            function DropdownClickableDirective(eventService) {
                this.eventService = eventService;
                this.restrict = 'A';
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(scope, element, eventService);
                };
            }
            DropdownClickableDirective.prototype.getAvailableSpaceAroundElement = function (element) {
                return {
                    over: element.offset().top - window.pageYOffset,
                    under: window.innerHeight - element.offset().top - element[0].getBoundingClientRect().height + window.pageYOffset,
                    left: element.offset().left - window.pageXOffset,
                    right: window.innerWidth - element.offset().left - element[0].getBoundingClientRect().width + window.pageXOffset
                };
            };
            DropdownClickableDirective.prototype.getPositionPossibilities = function (element, trigger, spaceAround) {
                var tooltipSize = {
                    "h": element.outerHeight(),
                    "w": element.outerWidth()
                };
                return {
                    under: (spaceAround.under >= tooltipSize.h) ? {
                        "top": trigger.position().top + trigger[0].getBoundingClientRect().height,
                        "left": trigger.position().left + trigger[0].getBoundingClientRect().width / 2 - tooltipSize.w / 2
                    } : false,
                    over: (spaceAround.over >= tooltipSize.h) ? {
                        "top": trigger.position().top - tooltipSize.h,
                        "left": trigger.position().left + trigger[0].getBoundingClientRect().width / 2 - tooltipSize.w / 2
                    } : false,
                    right: (spaceAround.right >= tooltipSize.w && spaceAround.over >= tooltipSize.h / 2 && spaceAround.under >= tooltipSize.h / 2) ? {
                        "top": trigger.position().top + trigger[0].getBoundingClientRect().height / 2 - tooltipSize.h / 2,
                        "left": trigger.position().left + trigger[0].getBoundingClientRect().width
                    } : false,
                    left: (spaceAround.left >= tooltipSize.w && spaceAround.over >= tooltipSize.h / 2 && spaceAround.under >= tooltipSize.h / 2) ? {
                        "top": trigger.position().top + trigger[0].getBoundingClientRect().height / 2 - tooltipSize.h / 2,
                        "left": trigger.position().left - tooltipSize.w
                    } : false,
                    overLeft: (spaceAround.over >= tooltipSize.h && spaceAround.left >= tooltipSize.w) ? {
                        "top": trigger.position().top - tooltipSize.h,
                        "left": trigger.position().left - tooltipSize.w
                    } : false,
                    underLeft: (spaceAround.under >= tooltipSize.h && spaceAround.left >= tooltipSize.w) ? {
                        "top": trigger.position().top + trigger[0].getBoundingClientRect().height,
                        "left": trigger.position().left - tooltipSize.w
                    } : false,
                    underRight: (spaceAround.under >= tooltipSize.h && spaceAround.right >= tooltipSize.w) ? {
                        "top": trigger.position().top + trigger[0].getBoundingClientRect().height,
                        "left": trigger.position().left + trigger[0].getBoundingClientRect().width
                    } : false,
                    overRight: (spaceAround.over >= tooltipSize.h && spaceAround.right >= tooltipSize.w) ? {
                        "top": trigger.position().top - tooltipSize.h,
                        "left": trigger.position().left + trigger[0].getBoundingClientRect().width
                    } : false
                };
            };
            DropdownClickableDirective.prototype.myLink = function (scope, element, eventService) {
                var _this = this, targetId = $(element).attr('data-dropdown-box-id'), target = $(targetId), triggerId = $(element).attr('data-dropdown-button-id'), trigger = $(triggerId);
                (function ($) {
                    var closeTooltip = eventService.subscribe("close-tooltip", function () {
                        hideDropDown();
                    }, false);
                    var tmp = $('<div>', {
                        class: 'js-dropDownClosingArea'
                    }).css({
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        position: 'fixed'
                    });
                    var hideDropDown = function () {
                        target.removeClass('is_opened over right under left underRight underLeft overRight overLeft');
                        tmp.remove();
                    };
                    var showDropDown = function (event) {
                        event.stopPropagation();
                        event.preventDefault();
                        tmp.click(hideDropDown);
                        tmp.on('mousewheel DOMMouseScroll', function (event) {
                            var _thisElement = $(this);
                            _thisElement.css({
                                'pointer-events': 'none',
                                'ms-pointer-events': 'none'
                            });
                            setTimeout(function () {
                                _thisElement.css({
                                    'pointer-events': 'inherit',
                                    'ms-pointer-events': 'inherit'
                                });
                            }, 200);
                        });
                        target.before(tmp);
                        target.addClass('is_opened');
                    };
                    $(element).on('click', triggerId, function (event) {
                        $(element).css({ 'position': 'relative' });
                        var spaceAround = _this.getAvailableSpaceAroundElement(trigger);
                        var possibilities = _this.getPositionPossibilities(target, trigger, spaceAround);
                        var position = this.getAttribute('data-fn-tooltip-position') || 'under';
                        if (!possibilities[position]) {
                            var possibility = false;
                            for (var key in possibilities) {
                                if (possibilities.hasOwnProperty(key) && possibilities[key]) {
                                    position = key;
                                    possibility = true;
                                }
                            }
                            if (!possibility) {
                                position = 'under';
                            }
                        }
                        target.addClass(position).css({
                            top: possibilities[position].top ? possibilities[position].top : trigger.position().top + trigger.outerHeight(),
                            left: possibilities[position].left ? possibilities[position].left : trigger.position().left + (trigger.outerWidth() / 2) - (target.outerWidth() / 2),
                            position: "absolute",
                            opacity: 1
                        });
                        showDropDown(event);
                    });
                })(jQuery);
            };
            DropdownClickableDirective.Factory = function () {
                var directive = function (eventService) {
                    return new DropdownClickableDirective(eventService);
                };
                directive['$inject', 'EventService'] = [''];
                return directive;
            };
            return DropdownClickableDirective;
        })();
        Utility.DropdownClickableDirective = DropdownClickableDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

