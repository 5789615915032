define('layout-base/../../bower_components/dwx-utility/src/directives/dropdownDirective',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var DropdownDirective = (function () {
            function DropdownDirective() {
                this.restrict = 'A';
                var _this = this;
                this.link = function (scope, element) {
                    _this.myLink(scope, element);
                };
            }
            DropdownDirective.prototype.getAvailableSpaceAroundElement = function (element) {
                return {
                    over: $(element).offset().top - window.pageYOffset,
                    under: window.innerHeight - $(element).offset().top - element.getBoundingClientRect().height + window.pageYOffset,
                    left: $(element).offset().left - window.pageXOffset,
                    right: window.innerWidth - $(element).offset().left - element.getBoundingClientRect().width + window.pageXOffset
                };
            };
            DropdownDirective.prototype.myLink = function (scope, element) {
                var _this = this;
                (function ($) {
                    var closeCurrentTooltip = function () {
                        $('.current-active-tooltip').remove();
                        triggerElement = undefined;
                    };
                    var tooltip, triggerElement = undefined, timeout = undefined, closingDelayTime = 500;
                    $(element).on('mouseover', '[data-fn-tooltip]', function (event) {
                        event.preventDefault();
                        clearTimeout(timeout);
                        timeout = undefined;
                        if (!$(this).is(triggerElement)) {
                            closeCurrentTooltip();
                            triggerElement = $(this);
                            var spaceAround = _this.getAvailableSpaceAroundElement(this), targetID = this.getAttribute('data-fn-tooltip'), target = $(targetID, $(element));
                            tooltip = $(target).clone().addClass('current-active-tooltip').css({
                                display: "block",
                                position: "fixed",
                                opacity: 0
                            }).appendTo("body");
                            tooltip.on('mouseenter', function (e) {
                                clearTimeout(timeout);
                                timeout = undefined;
                            }).on('mouseleave', function (e) {
                                if (!timeout) {
                                    timeout = setTimeout(closeCurrentTooltip, closingDelayTime);
                                }
                            });
                            var tooltipSize = {
                                "h": tooltip.outerHeight(),
                                "w": tooltip.outerWidth()
                            };
                            var possibilities = {
                                under: (spaceAround.under >= tooltipSize.h && spaceAround.left >= (tooltipSize.w - this.getBoundingClientRect().width) / 2 && spaceAround.right >= (tooltipSize.w - this.getBoundingClientRect().width) / 2) ? {
                                    "top": $(this).offset().top + this.getBoundingClientRect().height,
                                    "left": $(this).offset().left + this.getBoundingClientRect().width / 2 - tooltipSize.w / 2
                                } : false,
                                over: (spaceAround.over >= tooltipSize.h && spaceAround.left >= (tooltipSize.w - this.getBoundingClientRect().width) / 2 && spaceAround.right >= (tooltipSize.w - this.getBoundingClientRect().width) / 2) ? {
                                    "top": $(this).offset().top - tooltipSize.h,
                                    "left": $(this).offset().left + this.getBoundingClientRect().width / 2 - tooltipSize.w / 2
                                } : false,
                                right: (spaceAround.right >= tooltipSize.w && spaceAround.over >= tooltipSize.h / 2 && spaceAround.under >= tooltipSize.h / 2) ? {
                                    "top": $(this).offset().top + this.getBoundingClientRect().height / 2 - tooltipSize.h / 2,
                                    "left": $(this).offset().left + this.getBoundingClientRect().width
                                } : false,
                                left: (spaceAround.left >= tooltipSize.w && spaceAround.over >= tooltipSize.h / 2 && spaceAround.under >= tooltipSize.h / 2) ? {
                                    "top": $(this).offset().top + this.getBoundingClientRect().height / 2 - tooltipSize.h / 2,
                                    "left": $(this).offset().left - tooltipSize.w
                                } : false,
                                overLeft: (spaceAround.over >= tooltipSize.h && spaceAround.left >= tooltipSize.w) ? {
                                    "top": $(this).offset().top - tooltipSize.h,
                                    "left": $(this).offset().left - tooltipSize.w
                                } : false,
                                underLeft: (spaceAround.under >= tooltipSize.h && spaceAround.left >= tooltipSize.w) ? {
                                    "top": $(this).offset().top + this.getBoundingClientRect().height,
                                    "left": $(this).offset().left - tooltipSize.w
                                } : false,
                                underRight: (spaceAround.under >= tooltipSize.h && spaceAround.right >= tooltipSize.w) ? {
                                    "top": $(this).offset().top + this.getBoundingClientRect().height,
                                    "left": $(this).offset().left + this.getBoundingClientRect().width
                                } : false,
                                overRight: (spaceAround.over >= tooltipSize.h && spaceAround.right >= tooltipSize.w) ? {
                                    "top": $(this).offset().top - tooltipSize.h,
                                    "left": $(this).offset().left + this.getBoundingClientRect().width
                                } : false
                            };
                            var position = this.getAttribute('data-fn-tooltip-position') || 'under';
                            if (!possibilities[position]) {
                                for (var key in possibilities) {
                                    if (possibilities.hasOwnProperty(key) && possibilities[key]) {
                                        position = key;
                                        break;
                                    }
                                }
                            }
                            tooltip.addClass(position).css({
                                top: possibilities[position].top,
                                left: possibilities[position].left,
                                position: "absolute",
                                opacity: 1
                            });
                        }
                    }).on('mouseout', '[data-fn-tooltip]', function (event) {
                        if (!timeout) {
                            timeout = setTimeout(closeCurrentTooltip, closingDelayTime);
                        }
                    });
                })(jQuery);
            };
            DropdownDirective.Factory = function () {
                var directive = function () {
                    return new DropdownDirective();
                };
                directive['$inject'] = [''];
                return directive;
            };
            return DropdownDirective;
        })();
        Utility.DropdownDirective = DropdownDirective;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

