define('../bower_components/dwx-widget-media-library/src/directives/videoProviders/brightcoveVideo',["require", "exports"], function (require, exports) {
    var BrightcoveVideoDirective = (function () {
        function BrightcoveVideoDirective($timeout) {
            this.restrict = 'A';
            this.replace = true;
            this.transclude = false;
            this.scope = {
                account: '=',
                videoId: '=?',
                player: "=?",
                playlistId: "=?",
                autostart: "=?"
            };
            this.template = '<div class="flex-video">\
              <video\
                ng-attr-id="{{videoId}}"\
                data-embed="default"\
                class="video-js" controls\
                style="width: 100%; height: 100%; position: absolute; top: 0px; bottom: 0px; right: 0px; left: 0px;">\
                </video>\
            </div>';
            this.link = function ($scope) {
                requirejs.config({
                    paths: { 'bc': ((baseUrl != undefined && baseUrl.trim() != '') ? "https:" : location.protocol) + '//players.brightcove.net/' + $scope.account + '/' + $scope.player + '_default/index.min' }
                });
                require(['bc'], function (bc) {
                    angular.isDefined($scope.player) && $scope.player.trim() != '' ? $scope.player : 'default';
                    $timeout(function () {
                        var $video = $('#' + $scope.videoId);
                        var video = $video[0];
                        $video.attr('data-account', $scope.account);
                        $video.attr('data-player', $scope.player);
                        if ($scope.videoId != undefined) {
                            $video.attr('data-video-id', $scope.videoId);
                        }
                        if ($scope.playlistId != undefined) {
                            $video.attr('data-playlist-id', $scope.playlistId);
                        }
                        $video.attr('data-setup', '{"techOrder": ["html5", "hls", "flash"]}');
                        bc(video);
                        var myPlayer = videojs(video);
                        myPlayer.ready(function () {
                            console.log("ready");
                        });
                        myPlayer.on('loadedmetadata', function () {
                            console.log("loadedmetadata");
                            console.log("autostart : ", $scope.autostart);
                            if ($scope.autostart) {
                                myPlayer.play();
                            }
                        });
                        myPlayer.on("loadstart", '', function () {
                            console.log("loadstart");
                        });
                        myPlayer.on("play", '', function () {
                            console.log("play");
                        });
                    }, 0);
                });
            };
        }
        BrightcoveVideoDirective.Factory = function () {
            var directive = function ($timeout) {
                return new BrightcoveVideoDirective($timeout);
            };
            directive['$inject'] = ['$timeout'];
            return directive;
        };
        return BrightcoveVideoDirective;
    })();
    exports.BrightcoveVideoDirective = BrightcoveVideoDirective;
});

