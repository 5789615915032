define('widget-login/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var LoginConfig = (function () {
        function LoginConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return LoginConfig;
    })();
    exports.LoginConfig = LoginConfig;
});

