define('widget-portfolio-summary/../../bower_components/dwx-widget-portfolio-summary/src/controllers/portfolioSummaryCtrl',["require", "exports", '../../../../scripts/owui-widget.constant'], function (require, exports, OwuiWidgetConstant) {
    var PortfolioSummaryCtrl = (function () {
        function PortfolioSummaryCtrl($scope, EventService, portfolioCoreService, initConfigService, configurationService, profileService, $location) {
            this.$scope = $scope;
            this.EventService = EventService;
            this.portfolioCoreService = portfolioCoreService;
            this.configurationService = configurationService;
            this.profileService = profileService;
            this.$location = $location;
            this.portfolioConfig = {};
            this.owuiWidgetNewsName = 3 /* WIDGET_PORTFOLIO_SUMMARY */;
            this.isTouch = function () {
                return (Modernizr.touch && (typeof cordova != 'undefined'));
            };
            var _this = this;
            var widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetAdvisor';
            this.widgetConfig = initConfigService[widgetId];
            this.hideDetailsButton = _this.$location.path() === '/page/9';
            if (!!_this.portfolioCoreService['dossierList'] && !!_this.profileService.loggedUser) {
                var behalfCode = _this.profileService.loggedUser.userInfos.BEHALF_USER_CODE;
                var accessoryUserCode = _this.profileService.loggedUser.userInfos.ACCESSORY_USER_CODE;
                var customerCode;
                if (!!accessoryUserCode && !!accessoryUserCode.value) {
                    customerCode = accessoryUserCode.value;
                }
                else {
                    customerCode = !!behalfCode ? behalfCode.value : _this.profileService.loggedUser.userInfos.USER_CODE.value;
                }
                var portfolioConfig = {
                    customerCode: customerCode,
                    dossierList: _this.portfolioCoreService['dossierList'].map(function (dossier) {
                        return dossier.ndg;
                    }),
                };
                _this.setPortfolioConfig(portfolioConfig);
            }
            var loggedUserHandler = EventService.subscribe('profileService:logged-user-ready', function (loggedUser) {
                var behalfCode = _this.profileService.loggedUser.userInfos.BEHALF_USER_CODE;
                var accessoryUserCode = _this.profileService.loggedUser.userInfos.ACCESSORY_USER_CODE;
                var customerCode;
                if (!!accessoryUserCode && !!accessoryUserCode.value) {
                    customerCode = accessoryUserCode.value;
                }
                else {
                    customerCode = !!behalfCode ? behalfCode.value : loggedUser.userInfos.USER_CODE.value;
                }
                var portfolioConfig = {
                    customerCode: customerCode
                };
                _this.setPortfolioConfig(portfolioConfig);
                _this.widgetConfig.status.mode = 'on';
            });
            var handleDossierSelected = EventService.subscribe('dossierSelected', function () {
                var portfolioConfig = {
                    dossierList: _this.$location.path() === '/page/9' ? _this.portfolioCoreService['dossierList'].filter(function (dossier) { return dossier.checked; }).map(function (dossier) { return dossier.ndg; }) : _this.portfolioCoreService['dossierList'].map(function (dossier) { return dossier.ndg; }),
                    showDisclaimerPirAlternative: !!_this.portfolioCoreService.portfolioLoad ? _this.portfolioCoreService.portfolioLoad.extension.flagPirAlternative : false
                };
                _this.setPortfolioConfig(portfolioConfig);
            });
            var handleRefreshPortfolioWithDossier = EventService.subscribe('refreshPortfolioWithDossier', function () {
                var portfolioConfig = {
                    dossierList: _this.portfolioCoreService['dossierList'].filter(function (dossier) { return dossier.checked; }).map(function (dossier) { return dossier.ndg; })
                };
                _this.setPortfolioConfig(portfolioConfig);
            });
            var owuiWidgetEventHandler = EventService.subscribe('owuiWidgetDirective:widgetLoaded', function (widgetName) {
                var widgetPtfSummaryTag = OwuiWidgetConstant.OwuiWidgetMap[3 /* WIDGET_PORTFOLIO_SUMMARY */].widgetTag;
                if (widgetName === widgetPtfSummaryTag) {
                    console.log('owuiWidgetDirective:widgetLoaded', widgetName);
                    document.getElementsByTagName(widgetPtfSummaryTag)[0].addEventListener(widgetPtfSummaryTag, function (event) {
                        switch (event.detail.type) {
                            case 'go-to-details':
                                _this.$location.path('/page/9');
                                _this.$scope.$apply();
                        }
                    });
                }
            });
            $scope.$on("$destroy", function () {
                _this.EventService.unsubscribe(handleDossierSelected, false);
                _this.EventService.unsubscribe(handleRefreshPortfolioWithDossier, false);
                _this.EventService.unsubscribe(loggedUserHandler, false);
                _this.EventService.unsubscribe(owuiWidgetEventHandler, false);
            });
        }
        PortfolioSummaryCtrl.prototype.showGoToDetailsMobile = function () {
            return !this.hideDetailsButton && this.isTouch();
        };
        PortfolioSummaryCtrl.prototype.goGoDetailsMobile = function () {
            this.$location.path('/page/9');
        };
        PortfolioSummaryCtrl.prototype.setPortfolioConfig = function (portfolioConfig) {
            var _this = this;
            _this.portfolioConfig = {
                firstHolder: !sessionStorage.getItem("coowner"),
                customerCode: portfolioConfig['customerCode'] || _this.portfolioConfig['customerCode'],
                dossierList: portfolioConfig['dossierList'] || _this.portfolioConfig['dossierList'],
                showDisclaimerPirAlternative: portfolioConfig.showDisclaimerPirAlternative || _this.portfolioConfig.showDisclaimerPirAlternative,
                excludeZeroBalance: true,
                useAmount: !realEconomyUsePaidAmount,
                totalFia: !!_this.portfolioCoreService.portfolioLoad ? _this.portfolioCoreService.portfolioLoad.totalFia : undefined,
                totalPtf: !!_this.portfolioCoreService.portfolioLoad ? _this.portfolioCoreService.portfolioLoad.totalPtf : undefined
            };
            if (!!_this.portfolioConfig.customerCode && !!_this.portfolioConfig.dossierList) {
                _this.widgetProp = {
                    'portfolio-config': JSON.stringify(_this.portfolioConfig),
                    'hide-details-button': this.hideDetailsButton || this.isTouch()
                };
            }
        };
        PortfolioSummaryCtrl.prototype.renderWidget = function () {
            var _this = this;
            return !!_this.getManifestAssets() && !!_this.widgetProp;
        };
        PortfolioSummaryCtrl.prototype.getManifestAssets = function () {
            return this.configurationService.manifestJson;
        };
        PortfolioSummaryCtrl.$inject = ["$scope", "$rootScope", "EventService", "portfolioCoreService", "$filter", "initConfigService"];
        PortfolioSummaryCtrl.ngID = 'dwxPortfolioSummaryCtrl';
        PortfolioSummaryCtrl.ngType = 'controller';
        return PortfolioSummaryCtrl;
    })();
    exports.PortfolioSummaryCtrl = PortfolioSummaryCtrl;
});

