define('widget-user-data/../../bower_components/dwx-widget-user-data/src/controllers/userDataCtrl',["require", "exports", 'lodash'], function (require, exports, _) {
    var UserDataCtrl = (function () {
        function UserDataCtrl($scope, $rootScope, $attrs, userDataService, $timeout, $http, $element, initConfigService, errorsHandlerService, $translatePartialLoaderProvider, profileService, EventService) {
            var _this = this;
            this.$scope = $scope;
            this.$rootScope = $rootScope;
            this.userDataService = userDataService;
            this.$http = $http;
            this.$element = $element;
            this.initConfigService = initConfigService;
            this.errorsHandlerService = errorsHandlerService;
            this.$translatePartialLoaderProvider = $translatePartialLoaderProvider;
            this.profileService = profileService;
            this.EventService = EventService;
            this.editingField = {};
            this.waiting = false;
            this.editRequestPhone = false;
            this.editRequestMail = false;
            this.keepLoading = false;
            this.closeSidebar = function () {
                _this.EventService.publish('close-sidebar');
            };
            this.startProcessCallBack = function (content) {
                _this.setStatus(content.stepCode, content.stepId);
                _this.setWaiting(false);
            };
            this.validateMail = function () {
                _this.setWaiting(true);
                switch (_this.udStatus) {
                    case 'INSERT_EMAIL':
                        if (_this.inputConfiguration.mail.fieldModel == _this.inputConfiguration.mail.repeatFieldModel) {
                            if (_this.inputConfiguration.mail.fieldModel.toLocaleLowerCase() == _this.inputConfiguration.mail.value.toLocaleLowerCase()) {
                                _this.setWaiting(false);
                                _this.udStatus = 'STATUS_CHANGED';
                                _this.selectedStatus = _this.statusConfiguration.sameFieldError;
                            }
                            else {
                                _this.inputConfiguration.mail.isInvalid = false;
                                _this.submitMail();
                            }
                        }
                        else {
                            _this.setWaiting(false);
                            _this.inputConfiguration.mail.isInvalid = true;
                        }
                        break;
                    case 'CHECK_MAIL_CODE':
                        _this.submitCheckCode();
                        break;
                    case 'CHECK_MAIL_CODE_ERROR':
                        if (_this.inputConfiguration.mail.fieldModel == _this.inputConfiguration.mail.repeatFieldModel) {
                            _this.submitCheckCode();
                        }
                        else {
                            _this.setWaiting(false);
                            _this.inputConfiguration.mail.isInvalid = true;
                        }
                        break;
                }
            };
            this.validatePhone = function () {
                _this.setWaiting(true);
                switch (_this.udStatus) {
                    case 'INSERT_MOBILE_NUMBER':
                        if (_this.inputConfiguration.telephoneNumber.fieldModel == _this.inputConfiguration.telephoneNumber.repeatFieldModel) {
                            if (_this.inputConfiguration.telephoneNumber.fieldModel.toLocaleLowerCase() == _this.inputConfiguration.telephoneNumber.value.toLocaleLowerCase()) {
                                _this.setWaiting(false);
                                _this.udStatus = 'STATUS_CHANGED';
                                _this.selectedStatus = _this.statusConfiguration.sameFieldError;
                            }
                            else {
                                _this.inputConfiguration.telephoneNumber.isInvalid = false;
                                _this.submitTelephoneNumber();
                            }
                        }
                        else {
                            _this.setWaiting(false);
                            _this.inputConfiguration.telephoneNumber.isInvalid = true;
                        }
                        break;
                    case 'CHECK_SMS_CODE':
                        _this.submitCheckCode();
                        break;
                    case 'CHECK_SMS_CODE_ERROR':
                        if (_this.inputConfiguration.telephoneNumber.fieldModel == _this.inputConfiguration.telephoneNumber.repeatFieldModel) {
                            _this.submitCheckCode();
                        }
                        else {
                            _this.setWaiting(false);
                            _this.inputConfiguration.telephoneNumber.isInvalid = true;
                        }
                        break;
                }
            };
            this.validatePassword = function () {
                _this.setWaiting(true);
                if (_this.inputConfiguration.password.oldFieldModel && _this.inputConfiguration.password.fieldModel == _this.inputConfiguration.password.repeatFieldModel) {
                    _this.submitPassword();
                }
                else {
                    _this.setWaiting(false);
                    _this.inputConfiguration.password.isInvalid = true;
                }
            };
            this.submitMail = function () {
                var body = _this.setRequestBody('userEmail_', _this.inputConfiguration.mail.fieldModel);
                _this.submitBody(body);
            };
            this.submitCheckCode = function () {
                var body = _this.setRequestBody('userCheckCode_', _this.checkCode);
                _this.submitBody(body);
            };
            this.submitTelephoneNumber = function () {
                var body = _this.setRequestBody('userMobileNumber_', _this.inputConfiguration.telephoneNumber.fieldModel);
                _this.submitBody(body);
            };
            this.submitPassword = function () {
                _this.userDataService.updatePassword.update({}, {
                    password: _this.inputConfiguration.password.oldFieldModel,
                    newPassword: _this.inputConfiguration.password.fieldModel,
                }, function (response) {
                    _this.clearAll();
                    _this.setWaiting(false);
                    if (response.outcome == 'OK') {
                        _this.udStatus = 'STATUS_CHANGED';
                        _this.selectedStatus = _this.statusConfiguration.completeSuccess;
                    }
                    else {
                        _this.statusConfiguration.genericError.statusMessage = response.message;
                        _this.udStatus = 'STATUS_CHANGED';
                        _this.selectedStatus = _this.statusConfiguration.genericError;
                    }
                });
            };
            this.abortPasswordChange = function () {
                _this.udStatus = undefined;
                _this.clearAll();
            };
            this.initStep = function () {
                _this.userDataService.getStatus.get({ processName: _this.processName }, function (response) {
                    _this.checkResponse(response, _this.initCallBack);
                    _this.inputConfiguration[_this.selectedInput].needToRetry = false;
                });
            };
            this.initCallBack = function (content) {
                if (content) {
                    _this.setStatus(content.stepCode, content.stepId);
                    _this.setUserData(content.additionalInfo);
                    _this.setCheckMessageError(content.additionalInfo._remainingRetry);
                }
                else {
                    _this.setStatus(null, null);
                }
            };
            this.abortProcess = function () {
                _this.setWaiting(true);
                _this.userDataService.abort.save({
                    processName: _this.processName
                }, function (response) {
                    _this.setWaiting(false);
                    _this.clearAll();
                    if (response.outcome == 'OK') {
                        _this.udStatus = undefined;
                    }
                    else {
                        _this.statusConfiguration.genericError.statusMessage = response.message;
                        _this.udStatus = 'STATUS_CHANGED';
                        _this.selectedStatus = _this.statusConfiguration.genericError;
                    }
                });
            };
            this.refreshPage = function () {
                window.location.reload();
            };
            this.setRequestBody = function (key, value) {
                var body = {};
                value = String(value);
                body[key] = value;
                return body;
            };
            this.submitBody = function (body) {
                var bodyToSend = {};
                if (body) {
                    bodyToSend = body;
                }
                _this.userDataService.submit.save({
                    processName: _this.processName,
                    stepCode: _this.udStatus,
                    stepId: _this.udStepIdStatus
                }, bodyToSend, function (response) {
                    _this.checkResponse(response, _this.submitCallBack);
                }, function (error) {
                    _this.statusConfiguration.genericError.statusMessage = error;
                    _this.selectedStatus = _this.statusConfiguration.genericError;
                    _this.udStatus = 'STATUS_CHANGED';
                    _this.setWaiting(false);
                });
            };
            this.submitCallBack = function (content) {
                _this.setStatus(content.stepCode, content.stepId);
                _this.setUserData(content.additionalInfo);
                _this.setCheckMessageError(content.additionalInfo._remainingRetry);
                _this.setWaiting(false);
            };
            this.complete = function () {
                _this.setWaiting(true);
                _this.userDataService.complete.save({
                    processName: _this.processName,
                    stepCode: _this.udStatus,
                    stepId: _this.udStepIdStatus
                }, function (response) {
                    _this.setWaiting(false);
                    _this.clearAll();
                    if (response.outcome == 'OK') {
                        _this.udStatus = 'STATUS_CHANGED';
                        _this.selectedStatus = _this.statusConfiguration.completeSuccess;
                    }
                    else {
                        _this.statusConfiguration.completeError.statusMessage = response.message;
                        _this.udStatus = 'STATUS_CHANGED';
                        _this.selectedStatus = _this.statusConfiguration.completeError;
                    }
                });
            };
            this.setWaiting = function (waiting) {
                _this.waiting = waiting;
            };
            this.checkResponse = function (response, callBack) {
                if (response.outcome == 'OK') {
                    callBack(response.content);
                }
                else {
                    _this.statusConfiguration.genericError.statusMessage = response.message;
                    _this.selectedStatus = _this.statusConfiguration.genericError;
                    _this.udStatus = 'STATUS_CHANGED';
                    _this.setWaiting(false);
                }
            };
            this.setCheckMessageError = function (remainingRetry) {
                _this.checkEmailMessageError = 'Attenzione il codice inserito non è valido. Hai a disposizione ancora ' + remainingRetry + ' tentativi/o, dopodichè ti verrà chiesto di reinserire l email';
                _this.checkPhoneNumberMessageError = 'Attenzione il codice inserito non è valido. Hai a disposizione ancora ' + remainingRetry + ' tentativi/o, dopodichè ti verrà chiesto di reinserire il numero di telefono';
            };
            this.setStatus = function (stepCode, stepId) {
                _this.setUDStatus(stepCode);
                _this.setUDStepIdStatus(stepId);
            };
            this.setUDStatus = function (status) {
                _this.udStatus = status;
            };
            this.setUDStepIdStatus = function (stepId) {
                _this.udStepIdStatus = stepId;
            };
            this.clearAll = function () {
                _this.editRequestMail = false;
                _this.editRequestPhone = false;
                _this.inputConfiguration.mail.fieldModel = "";
                _this.inputConfiguration.mail.repeatFieldModel = "";
                _this.inputConfiguration.password.oldFieldModel = "";
                _this.inputConfiguration.password.fieldModel = "";
                _this.inputConfiguration.password.repeatFieldModel = "";
                _this.checkCode = "";
                _this.inputConfiguration.telephoneNumber.fieldModel = "";
                _this.inputConfiguration.telephoneNumber.repeatFieldModel = "";
            };
            this.inputConfiguration = {
                "identifier": {
                    label: "Numero identificativo",
                    value: "N/A",
                    noteditable: true
                },
                "password": {
                    label: "Password",
                    value: "****************",
                    pattern: /((?=.*\d)(?=.*[a-z])((?=.*[A-Z])|(?=.*[@#$%!?_-])).{4,})/,
                    oldFieldModel: "",
                    fieldModel: "",
                    repeatFieldModel: "",
                    errorMessage: "Attenzione i campi inseriti non sono uguali",
                    isInvalid: false
                },
                "mail": {
                    label: "Email",
                    value: "N/A",
                    pattern: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|tax|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
                    fieldModel: "",
                    repeatFieldModel: "",
                    errorMessage: "Attenzione i campi inseriti non sono uguali",
                    genericError: "Il codice inserito non è corretto",
                    isInvalid: false
                },
                "telephoneNumber": {
                    label: 'Numero di cellulare',
                    value: "N/A",
                    pattern: '^(([\\+])[0-9]{1,4})[0-9]{7,}$',
                    fieldModel: "",
                    repeatFieldModel: "",
                    errorMessage: "Attenzione i campi inseriti non sono uguali",
                    genericError: "Il codice inserito non è corretto",
                    isInvalid: false,
                },
                "newMail": {
                    label: "Email",
                    value: "N/A"
                },
                "newTelephoneNumber": {
                    label: "Numero di cellulare",
                    value: "N/A"
                },
            };
            this.statusConfiguration = {
                complete: {
                    statusTitle: "Completa",
                    statusMessage: "Premi completa affinchè le modifiche siano salvate",
                    statusButtonLabel: "Completa",
                    statusButtonCallback: function () {
                        _this.complete();
                    }
                },
                sameFieldError: {
                    statusTitle: "Si è verificato un errore",
                    statusMessage: "Il campo inserito corrisponde a quello attualmente memorizzato",
                    statusButtonLabel: "Indietro",
                    statusButtonCallback: function () {
                        _this.udStatus = undefined;
                        _this.clearAll();
                    }
                },
                completeError: {
                    statusTitle: "Si è verificato un errore",
                    statusMessage: "",
                    statusButtonLabel: "Indietro",
                    statusButtonCallback: function () {
                        _this.udStatus = undefined;
                        _this.clearAll();
                    }
                },
                completeSuccess: {
                    statusTitle: "Dati modificati",
                    statusMessage: "I dati sono stati modificati correttamente",
                    statusButtonLabel: "Indietro",
                    statusButtonCallback: function () {
                        _this.refreshPage();
                    }
                },
                notEditable: {
                    statusTitle: "Non puoi modificare i tuoi dati di contatto.",
                    statusMessage: "",
                    statusButtonLabel: "Indietro",
                    statusButtonCallback: function () {
                        _this.udStatus = undefined;
                        _this.clearAll();
                    }
                },
                genericError: {
                    statusTitle: "Si è verificato un errore",
                    statusMessage: "",
                    statusButtonLabel: "Indietro",
                    statusButtonCallback: function () {
                        _this.udStatus = undefined;
                        _this.clearAll();
                    }
                }
            };
            this.init();
        }
        UserDataCtrl.prototype.init = function () {
            var _this = this;
            this.widgetId = this.$scope.identifier || (typeof this.$scope.widget != 'undefined') ? this.$scope.widget.id : 'widgetUserData';
            this.widgetConfig = this.initConfigService[this.widgetId];
            if (this.$translatePartialLoaderProvider != undefined) {
                this.$translatePartialLoaderProvider.addPart('bower_components/dwx-widget-user-data');
            }
            if (this.widgetConfig.status.mode == undefined) {
                this.widgetConfig.status = {};
                this.widgetConfig.status['mode'] = 'loading';
            }
            if (this.widgetConfig.status.mode !== 'locked') {
                if (this.widgetConfig.view == "Widget") {
                    this.keepLoading = true;
                    this.getUserInfo();
                }
                else if (this.widgetConfig.view == "HeaderView") {
                    try {
                        this.widgetPageId = this.widgetConfig.widgetInfos.USER_DATA_PAGE_ID.value;
                    }
                    catch (e) {
                        console.log(e);
                    }
                }
                this.uploadUrl = "" + apiPrefix + "/userdata/photo/upload";
                this.file = {};
                this.fileUploadResponse = {};
                this.info = this.widgetConfig;
                this.editMode = this.info.widgetInfos.EDIT_MODE ? this.info.widgetInfos.EDIT_MODE : false;
                if (!!this.profileService.loggedUser) {
                    this.nominative = this.profileService.loggedUser.userInfos.BEHALF_USER_NOMINATIVE;
                }
                else {
                    this.profileService.profile.get({}, function (response) {
                        _this.nominative = response.userInfos.BEHALF_USER_NOMINATIVE;
                    });
                }
                this.data = {};
                this.userDataService.userData.get({
                    widgetId: this.info.id
                }, function (response) {
                    if (response.outcome === 'OK') {
                        _this.data = response.content;
                        if (!_this.keepLoading) {
                            _this.widgetConfig.status.mode = 'on';
                        }
                        if (eqjs !== undefined) {
                            setTimeout(function () {
                                eqjs.query($(_this.$element));
                            }, 0);
                        }
                    }
                    else {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = "dashboard.error." + response.messageKey;
                        _this.$rootScope.$broadcast('http-response-error', response.messageKey);
                    }
                }, function (error) {
                    _this.widgetConfig.status.mode = 'error';
                    _this.widgetConfig.status.message = "dashboard.error." + error.messageKey;
                    _this.$rootScope.$broadcast('http-request-error', error);
                });
            }
        };
        UserDataCtrl.prototype.submit = function () {
            console.log(this.file);
            if (this.file) {
                this.$http({
                    method: 'POST',
                    url: this.uploadUrl,
                    data: $.param({
                        file: this.file
                    }),
                    headers: {
                        'Content-Type': void 0
                    }
                });
            }
        };
        UserDataCtrl.prototype.updateData = function (fieldName) {
            var ctrl = this;
            ctrl.widgetConfig.status.mode = 'waiting';
            ctrl.userDataService.updateData.save({}, JSON.stringify(ctrl.data), function (response) {
                if (response.outcome === 'OK') {
                    ctrl.data = response.content;
                    ctrl.widgetConfig.status.mode = 'on';
                    ctrl.editingField[fieldName] = false;
                }
                else {
                    ctrl.errorsHandlerService.handleResponseError(response, ctrl.widgetConfig);
                }
            }, function (error) {
                ctrl.errorsHandlerService.handleRequestError(error, ctrl.widgetConfig);
            });
        };
        UserDataCtrl.prototype.getUserInfo = function () {
            var _this = this;
            try {
                this.widgetPageId = this.widgetConfig.widgetInfos.USER_DATA_PAGE_ID.value;
                this.configurationData = JSON.parse(this.widgetConfig.widgetInfos.CONFIGURATION.value).configurationData;
                this.fullName = {};
                this.configurationData = this.configurationData.filter(function (obj) {
                    return obj.fieldName !== 'name';
                });
                this.userDataService.formData.get({}, function (response) {
                    if (response.outcome === 'OK') {
                        var map = response.content.map;
                        angular.forEach(response.content.map, function (value, key) {
                            value = _.omit(value, _.isNull);
                            if (key == 'expiredDocument') {
                                _this.expiredDocument = map['expiredDocument'];
                            }
                            else if (key != "name" && key != 'expiredDocument') {
                                angular.extend(_this.inputConfiguration[key], value);
                            }
                            else if (key == 'name') {
                                angular.extend(_this.fullName, value);
                            }
                        });
                        _this.widgetConfig.status.mode = 'on';
                    }
                    else {
                        _this.widgetConfig.status.mode = 'error';
                        _this.widgetConfig.status.message = response.message;
                        _this.$rootScope.$broadcast('http-response-error', response.messageKey);
                    }
                });
            }
            catch (e) {
                console.log(e);
            }
        };
        UserDataCtrl.prototype.editBaseFields = function (selectedInput) {
            this.selectedInput = selectedInput;
            if (this.inputConfiguration[selectedInput]['updatable']) {
                this.startBaseProcess(selectedInput);
            }
            else {
                this.statusConfiguration.notEditable.statusMessage = this.inputConfiguration[this.selectedInput].message;
                this.udStatus = 'STATUS_CHANGED';
                this.selectedStatus = this.statusConfiguration.notEditable;
            }
        };
        UserDataCtrl.prototype.startBaseProcess = function (selectedInput) {
            switch (selectedInput) {
                case "mail":
                    this.processName = "mailRegistration";
                    this.startProcess();
                    break;
                case "telephoneNumber":
                    this.processName = "mobilePhoneRegistration";
                    this.startProcess();
                    break;
                case "password":
                    this.udStatus = 'INSERT_PASSWORD';
                    break;
            }
        };
        UserDataCtrl.prototype.startProcess = function () {
            var _this = this;
            this.setWaiting(true);
            this.userDataService.startProcess.save({ processName: this.processName }, function (response) {
                _this.checkResponse(response, _this.startProcessCallBack);
            });
        };
        UserDataCtrl.prototype.setUserData = function (info) {
            this.userName = info._userName;
        };
        UserDataCtrl.prototype.getPgNominative = function () {
            return !!this.nominative ? this.nominative.value : null;
        };
        return UserDataCtrl;
    })();
    exports.UserDataCtrl = UserDataCtrl;
});

