define('../bower_components/azimut-utility/src/directives/documentsModalDetailsDirective',["require", "exports"], function (require, exports) {
    var AzimutUtility;
    (function (AzimutUtility) {
        AzimutUtility.basePath = 'bower_components/azimut-utility/dist/src/views/';
        var DocumentsModalDetailsDirective = (function () {
            function DocumentsModalDetailsDirective(EventService, $translatePartialLoaderProvider) {
                this.EventService = EventService;
                this.restrict = 'A';
                this.scope = {
                    documentDetails: '=',
                    documentCategory: '@',
                };
                this.replace = true;
                this.template = '<div ng-include="contentUrl"></div>';
                var _this = this;
                $translatePartialLoaderProvider.addPart('bower_components/azimut-utility');
                this.link = function (scope, element, attrs) {
                    scope.contentUrl = AzimutUtility.basePath + scope.documentCategory + '-info-modal.html';
                    var handlerSignRoom;
                    scope.fields = ['mandate', 'productCompanyDesc', 'productName', 'operationDesc', 'paymentType', 'amount'];
                    scope.detailsTabIndex = 0;
                    handlerSignRoom = EventService.subscribe('modalClosed', function () {
                        scope.detailsTabIndex = 0;
                    });
                    scope.closeDocDetails = function () {
                        $('.info-modal').foundation('reveal', 'close');
                    };
                    scope.$on("$destroy", function () {
                        return EventService.unsubscribe(handlerSignRoom);
                    });
                };
            }
            DocumentsModalDetailsDirective.Factory = function () {
                var directive = function (EventService, $translatePartialLoaderProvider) {
                    return new DocumentsModalDetailsDirective(EventService, $translatePartialLoaderProvider);
                };
                directive['$inject'] = ['EventService', '$translatePartialLoader'];
                return directive;
            };
            return DocumentsModalDetailsDirective;
        })();
        AzimutUtility.DocumentsModalDetailsDirective = DocumentsModalDetailsDirective;
        var DocumentsModalDirective = (function () {
            function DocumentsModalDirective(EventService) {
                this.restrict = 'A';
                this.scope = { modalName: '@' };
                var _this = this;
                this.link = function (scope, element) {
                    var modal;
                    $(element).bind('click', function () {
                        if (scope.modalName != undefined) {
                            modal = $('.info-modal-' + scope.modalName);
                            modal.foundation('reveal', 'open');
                        }
                        else {
                            modal = $('.info-modal');
                            modal.foundation('reveal', 'open');
                        }
                        $('main').css('overflow', 'hidden');
                    });
                    $(document).on('closed.fndtn.reveal', '[data-reveal]', function () {
                        var modal = $(this);
                        $('main').css('overflow', 'scroll');
                    });
                    $(document).foundation({
                        reveal: {
                            close: function (e) {
                                $('main').css('overflow', 'scroll');
                                if (e.namespace !== 'fndtn.reveal') {
                                    return;
                                }
                                return EventService.publish('modalClosed', [], true);
                            }
                        }
                    });
                    var handleCloseModal = EventService.subscribe('closeModal', function () {
                        modal = modal || $('.info-modal');
                        modal.foundation('reveal', 'close');
                    });
                    scope.$on("$destroy", function () {
                        return EventService.unsubscribe(handleCloseModal);
                    });
                };
            }
            DocumentsModalDirective.Factory = function () {
                var directive = function (EventService) {
                    return new DocumentsModalDirective(EventService);
                };
                directive['$inject'] = ['EventService'];
                return directive;
            };
            return DocumentsModalDirective;
        })();
        AzimutUtility.DocumentsModalDirective = DocumentsModalDirective;
        var DocumentsModalCloseDirective = (function () {
            function DocumentsModalCloseDirective() {
                this.restrict = 'C';
                var _this = this;
                this.link = function (scope, element) {
                    $('a.custom-close-reveal-modal').click(function () {
                        $('.info-modal-dossier-selector').foundation('reveal', 'close');
                    });
                };
            }
            DocumentsModalCloseDirective.Factory = function () {
                var directive = function () {
                    return new DocumentsModalCloseDirective();
                };
                directive['$inject'] = [];
                return directive;
            };
            return DocumentsModalCloseDirective;
        })();
        AzimutUtility.DocumentsModalCloseDirective = DocumentsModalCloseDirective;
    })(AzimutUtility = exports.AzimutUtility || (exports.AzimutUtility = {}));
});

