define('../bower_components/dwx-widget-my-documents/src/filter/filterDocuments',["require", "exports"], function (require, exports) {
    var FilterDocuments = (function () {
        function FilterDocuments() {
            this._this = this;
        }
        FilterDocuments.myFilter = function (items, fields) {
            var filtered = [];
            var _this = this;
            angular.forEach(items, function (item) {
                var objProperty = FilterDocuments.getObjProperty(item, fields);
                if (objProperty != undefined && objProperty) {
                    filtered.push(item);
                }
            });
            return filtered;
        };
        FilterDocuments.Factory = function () {
            var instance = new FilterDocuments();
            return FilterDocuments.myFilter;
        };
        FilterDocuments.getObjProperty = function (obj, prop) {
            var parts = prop.split('.'), last = parts.pop(), l = parts.length, i = 1, current = parts[0], tmpObj = obj;
            while ((tmpObj = obj[current]) && i < l) {
                current = parts[i];
                i++;
            }
            if (tmpObj) {
                return tmpObj[last];
            }
            else {
                return obj[last];
            }
        };
        return FilterDocuments;
    })();
    exports.FilterDocuments = FilterDocuments;
});

