define('widget-pir-box/../../project_components/dwx-widget-pir-box/src/app',["require", "exports", "./controllers/pirBoxCtrl", "./directives/pirBoxDirective", "./config"], function (require, exports, pirBoxController, pirBoxDirective, pirBoxConfig) {
    exports.configuration = {
        list: {
            "pirBoxCtrl": {
                callback: ["$scope", "initConfigService", "configurationService", "EventService", "profileService", pirBoxController.PirBoxController],
                type: "controller"
            },
            "dwxWidgetPirBox": { callback: [pirBoxDirective.PirBoxDirective], type: "directive" },
            "pirBoxConfig": { callback: [pirBoxConfig.PirBoxConfig], type: "config" }
        },
        ngModules: ['ngResource']
    };
});

