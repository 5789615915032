define('layout-base/../../bower_components/dwx-utility/src/controllers/svgCtrl',["require", "exports"], function (require, exports) {
    var SvgController = (function () {
        function SvgController() {
            this.size = 50;
            this.radius = 20;
            this.c = 3.14 * (2 * this.radius);
            this.dashArray = 2 * 3.14 * this.radius;
            if (this.value > 100 || this.value < 0)
                this.value = 100;
        }
        SvgController.prototype.initPercentage = function (value) {
            var _this = this;
            _this.value = value || 100;
            _this.percentage = ((100 - _this.value) / 100) * _this.c;
        };
        return SvgController;
    })();
    exports.SvgController = SvgController;
});

