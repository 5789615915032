define('../bower_components/dwx-widget-unlock-user/src/controllers/unlockUserCtrl',["require", "exports", '../../../../scripts/owui-widget.constant'], function (require, exports, OwuiWidgetConstant) {
    var UnlockUserController = (function () {
        function UnlockUserController($scope, unlockUserService, initConfigService, $translatePartialLoaderProvider, $translate, $location, profileService, EventService, configurationService) {
            var _this = this;
            this.$scope = $scope;
            this.unlockUserService = unlockUserService;
            this.$translatePartialLoaderProvider = $translatePartialLoaderProvider;
            this.$translate = $translate;
            this.$location = $location;
            this.configurationService = configurationService;
            this.owuiWidgetLoginName = 5 /* WIDGET_LOGIN */;
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetMyDocuments';
            this.widgetConfig = initConfigService[this.widgetId];
            if (this.widgetConfig == undefined) {
                ($scope.widget) ? '' : $scope.widget = {};
                ($scope.widget.status) ? '' : $scope.widget.status = {};
                ($scope.widget.status.mode) ? '' : $scope.widget.status.mode = 'loading';
                this.widgetConfig = {};
                this.widgetConfig.status = $scope.widget.status;
            }
            if (this.$translatePartialLoaderProvider != undefined) {
                this.$translatePartialLoaderProvider.addPart('bower_components/dwx-widget-unlock-user');
            }
            var loggedUserHandler = EventService.subscribe('profileService:logged-user-ready', function (loggedUser) {
                console.log('PROFILE SERVICE', loggedUser);
                _this.goToHome();
            });
            if (!profileService.loggedUser) {
                this.token = $location.search().access_token;
                this.widgetProp = {
                    "step": "USER_UNLOCKED",
                    "access-token": this.token
                };
            }
            else {
                this.goToHome();
            }
            this.owuiWidgetEventHandler = EventService.subscribe('owuiWidgetDirective:widgetLoaded', function (widgetName) {
                var widgetLoginTag = OwuiWidgetConstant.OwuiWidgetMap[5 /* WIDGET_LOGIN */].widgetTag;
                if (widgetName === widgetLoginTag) {
                    console.log('owuiWidgetDirective:widgetLoaded', widgetName);
                    setTimeout(function () {
                        var widgetEl = document.getElementsByTagName(widgetLoginTag)[0];
                        if (!!widgetEl) {
                            var shadowRoot = widgetEl.shadowRoot;
                            var elements = shadowRoot.querySelectorAll(UnlockUserController.WIDGET_LOGIN_FAST_CLICK_ELEMENTS);
                            elements.forEach(function (element) {
                                element.addEventListener('touchstart', function (e) {
                                    e.stopImmediatePropagation();
                                }, { passive: false });
                            });
                        }
                    }, 0);
                    document.getElementsByTagName(widgetLoginTag)[0].addEventListener(widgetLoginTag, function (event) {
                        switch (event.detail.type) {
                            case 'go-to-login':
                                console.log("go-to-login");
                                window.location.hash = '#/';
                                window.location.reload();
                                break;
                        }
                    });
                }
            });
            $scope.$on("$destroy", function () {
                EventService.unsubscribe(loggedUserHandler, false);
            });
        }
        UnlockUserController.prototype.goToHome = function () {
            window.location.hash = '#/';
            window.location.reload();
        };
        UnlockUserController.prototype.getManifestAssets = function () {
            return this.configurationService.manifestJson;
        };
        UnlockUserController.prototype.isOwuiWidgetReady = function () {
            return this.getManifestAssets() && !!this.widgetProp;
        };
        UnlockUserController.WIDGET_LOGIN_FAST_CLICK_ELEMENTS = [
            '#app',
            'input',
            'button',
            'span',
            '.Button',
            '.AuthenticationMyAzimut__registrationLink',
            '.AuthenticationMyAzimut__retrieveCredentialLink',
            '.AuthenticationMyAzimut__biometricPin',
            '.AuthenticationMyAzimut__biometricId',
            '.AuthenticationMyAzimut__bannerLink',
            '.GoBackContainer__link'
        ];
        return UnlockUserController;
    })();
    exports.UnlockUserController = UnlockUserController;
});

