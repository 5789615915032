define('../bower_components/azimut-utility/src/config',["require", "exports"], function (require, exports) {
    var AziumtUtilityConfig = (function () {
        function AziumtUtilityConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return AziumtUtilityConfig;
    })();
    exports.AziumtUtilityConfig = AziumtUtilityConfig;
});

