define('../bower_components/dwx-widget-media-library/src/controllers/mediaLibraryCtrl',["require", "exports", 'lodash'], function (require, exports, _) {
    var MediaLibraryController = (function () {
        function MediaLibraryController($scope, mediaLibraryService, initConfigService, paginationService, $timeout, $translatePartialLoaderProvider, $translate, $location) {
            var _this = this;
            this.$scope = $scope;
            this.mediaLibraryService = mediaLibraryService;
            this.paginationService = paginationService;
            this.$timeout = $timeout;
            this.$translatePartialLoaderProvider = $translatePartialLoaderProvider;
            this.$translate = $translate;
            this.$location = $location;
            this.playlistPagination = [];
            this.selectedMediaInPlaylist = [];
            this.filteredMediaList = [];
            this.startVideo = false;
            this.noVideoInPlaylist = [];
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetMediaLibrary';
            this.widgetConfig = initConfigService[this.widgetId];
            if (this.widgetConfig == undefined) {
                ($scope.widget) ? '' : $scope.widget = {};
                ($scope.widget.status) ? '' : $scope.widget.status = {};
                ($scope.widget.status.mode) ? '' : $scope.widget.status.mode = 'loading';
                this.widgetConfig = {};
                this.widgetConfig.status = $scope.widget.status;
            }
            if (this.$translatePartialLoaderProvider != undefined) {
                this.$translatePartialLoaderProvider.addPart('bower_components/dwx-widget-media-library');
            }
            if (this.widgetConfig.status.mode !== 'locked') {
                this.configuration = JSON.parse(this.widgetConfig.widgetInfos['CONFIGURATION'].value);
                this.itemsInPage = (this.configuration.itemsInPage) ? this.configuration.itemsInPage : 3;
                this.playListId = (this.configuration.playListId) ? this.configuration.playListId : '';
                this.mediaCount = this.configuration.mediaCount;
                this.pageIndex = 1;
                if (!this.configuration.hideVideo) {
                    mediaLibraryService.getPlayList.get({
                        playlistId: this.playListId,
                        mediaCount: this.mediaCount
                    }, function (response) {
                        _this.responseCode = response.outcome;
                        _this.responseMessage = response.message;
                        if (_this.responseCode == "OK") {
                            _this.responseContent = (response.content.length && Array.isArray(response.content)) ? response.content : [response.content];
                            _this.selectedMediaInPlaylist = [];
                            for (var i = 0; i < _this.responseContent['length']; i++) {
                                _this.playlistPagination[i] = new paginationService([], _this.itemsInPage, _this.pageIndex);
                                _this.selectedMediaInPlaylist[i] = {};
                                if (_this.responseContent[i].extraInfo) {
                                    _this.selectedMediaInPlaylist[i].accountId = _this.responseContent[i].extraInfo.accountId;
                                    _this.selectedMediaInPlaylist[i].playerId = _this.responseContent[i].extraInfo.playerId;
                                }
                                if (_this.responseContent[i].mediaList.length > 0) {
                                    _this.selectedMediaInPlaylist[i].media = _this.responseContent[i].mediaList[0];
                                    _this.selectedMediaInPlaylist[i].media.autostart = true;
                                    _this.filteredMediaList[i] = _this.responseContent[i].mediaList.slice(1);
                                    _this.playlistPagination[i].updateItems(_this.filteredMediaList[i]);
                                    _this.widgetConfig.status.mode = 'on';
                                }
                                else {
                                    console.log("Nessun video nella playlist");
                                    _this.noVideoInPlaylist[i] = true;
                                }
                            }
                        }
                        else {
                            _this.widgetConfig.status.message = response.message;
                            _this.widgetConfig.status.mode = 'error';
                        }
                    }, function (error) {
                        _this.widgetConfig.status.message = error;
                        _this.widgetConfig.status.mode = 'error';
                    });
                }
                else {
                    this.widgetConfig.status.mode = 'on';
                }
            }
        }
        MediaLibraryController.prototype.changePage = function (index, playlistIndex) {
            this.playlistPagination[playlistIndex].changePage(index);
        };
        MediaLibraryController.prototype.initVideo = function () {
            this.startVideo = true;
        };
        MediaLibraryController.prototype.setSelectedMedia = function (index, media) {
            var _this = this;
            this.startVideo = false;
            this.$timeout(function () {
                _this.selectedMediaInPlaylist[index].media = media;
                _this.selectedMediaInPlaylist[index].media.autostart = true;
                _this.filteredMediaList[index] = _.filter(_this.responseContent[index].mediaList, function (media) {
                    return media.id != _this.selectedMediaInPlaylist[index].media.id;
                });
                var currentPage = _this.playlistPagination[index].currentPage;
                _this.playlistPagination[index].updateItems(_this.filteredMediaList[index]);
                _this.playlistPagination[index].changePage(currentPage, index);
                _this.startVideo = true;
                _this.$scope.$evalAsync();
            }, 0);
        };
        return MediaLibraryController;
    })();
    exports.MediaLibraryController = MediaLibraryController;
});

