define('../bower_components/dwx-analytics-tracker/src/app',["require", "exports", './run', "../../dwx-engine/src/app"], function (require, exports, run) {
    exports.configuration = {
        'list': {
            "analyticsTrackerRun": {
                callback: ["$rootScope", "profileService", "EventService", run.AnalyticsTrackerRun],
                type: "run"
            }
        },
        'ngModules': []
    };
});

