define('widget-portfolio-holdings/../../bower_components/dwx-widget-portfolio-holdings/src/directives/portfolioHoldingsDirective',["require", "exports"], function (require, exports) {
    var PortfolioHoldingsDirective = (function () {
        function PortfolioHoldingsDirective() {
            this.restrict = 'A';
            this.scope = { identifier: '=' };
            this.replace = true;
            this.controller = 'dwxPortfolioHoldingsCtrl as portfolioHoldingsCtrl';
            this.templateUrl = 'bower_components/dwx-widget-portfolio-holdings/dist/src/views/partials/main.html';
            var _this = this;
        }
        PortfolioHoldingsDirective.Factory = function () {
            var directive = function () {
                return new PortfolioHoldingsDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return PortfolioHoldingsDirective;
    })();
    exports.PortfolioHoldingsDirective = PortfolioHoldingsDirective;
    var TableAccordionCustom = (function () {
        function TableAccordionCustom() {
            this.restrict = 'A';
            var _this = this;
            _this.link = function (scope, element) {
                scope.open = false;
            };
        }
        TableAccordionCustom.Factory = function () {
            var directive = function () {
                return new TableAccordionCustom();
            };
            directive['$inject'] = [];
            return directive;
        };
        return TableAccordionCustom;
    })();
    exports.TableAccordionCustom = TableAccordionCustom;
});

