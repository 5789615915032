define('layout-base/../../bower_components/dwx-layout-base/src/local-layout-configurations/login-widget-config',["require", "exports"], function (require, exports) {
    exports.config = {
        "weight": null,
        "id": 14,
        "name": "loginInPagPublicWeb",
        "status": "online",
        "keyName": null,
        "view": "Widget",
        "containerId": 15,
        "widgetTemplate": {
            "id": 17,
            "name": "login",
            "minWidth": null,
            "maxWidth": null,
            "defaultWidth": null,
            "htmlPageUrl": null,
            "tileUrl": null,
            "keyName": null,
            "widgetTemplateCatalogId": 2,
            "infos": {
                "ALLOW_OVERFLOW": {
                    "id": 87,
                    "name": "ALLOW_OVERFLOW",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 17
                },
                "TITLE": {
                    "id": 88,
                    "name": "TITLE",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 17
                }
            },
            "operations": {}
        },
        "widgetInfos": {
            "ALLOW_OVERFLOW": {
                "id": 70,
                "name": "ALLOW_OVERFLOW",
                "value": "0",
                "arrayValue": null,
                "widgetTemplateInfo": {
                    "id": 87,
                    "name": "ALLOW_OVERFLOW",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 17
                },
                "widgetId": 14
            },
            "TITLE": {
                "id": 69,
                "name": "TITLE",
                "value": "login",
                "arrayValue": null,
                "widgetTemplateInfo": {
                    "id": 88,
                    "name": "TITLE",
                    "type": "String",
                    "defaultValue": null,
                    "mandatory": false,
                    "widgetTemplateId": 17
                },
                "widgetId": 14
            }
        },
        "visible": true
    };
});

