define('widget-user-data-advanced/../../bower_components/dwx-widget-user-data-advanced/src/app',["require", "exports", './controllers/userDataAdvancedCtrl', './directives/userDataAdvancedDirective', './services/userDataAdvancedService'], function (require, exports, userDataAdvancedController, userDataAdvancedDirective, userDataAdvancedService) {
    exports.configuration = {
        list: {
            'UserDataAdvancedCtrl': { callback: ["$scope", "$rootScope", "$attrs", "userDataAdvancedService", "$timeout", "$http", "initConfigService", "errorsHandlerService", userDataAdvancedController.UserDataAdvancedCtrl], type: 'controller' },
            'dwxWidgetUserDataAdvanced': { callback: [userDataAdvancedDirective.UserDataAdvancedDirective.Factory()], type: 'directive' },
            'userDataAdvancedService': { callback: ["$resource", userDataAdvancedService.UserDataAdvancedService], type: 'service' }
        },
        ngModules: []
    };
});

