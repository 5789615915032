define('layout-base/../../bower_components/dwx-layout-base/src/directives/widgetDirective',["require", "exports"], function (require, exports) {
    var Layout;
    (function (Layout) {
        Layout.templatePath = function (widgetName, widgetView) {
            return "bower_components/dwx-widget-" + widgetName + "/dist/src/views/" + widgetName + "-" + widgetView + ".html";
        };
        var IncludeDirective = (function () {
            function IncludeDirective() {
                return {
                    require: 'ngInclude',
                    link: function (scope, el, attrs) {
                        return el.replaceWith(el.children());
                    }
                };
            }
            return IncludeDirective;
        })();
        Layout.IncludeDirective = IncludeDirective;
        var WidgetDirective = (function () {
            function WidgetDirective(initConfigService) {
                this.initConfigService = initConfigService;
                this.restrict = 'A';
                this.scope = {
                    dataWidgetIndex: '@widgetIndex',
                    widgetId: '@',
                    widget: '=',
                    dwxWidget: '=',
                    widgetType: '='
                };
                this.transclude = true;
                this.template = '<div data-ng-include="contentUrl" dwx-include="" data-ng-hide="widget.status.mode != \'on\' && widgetView != \'header\'"></div><div data-ng-transclude data-ng-hide="widget.status.mode == \'on\' && widgetView != \'header\'"></div>';
                var _this = this;
                this.link = function (scope, element, attrs) {
                    attrs.$observe('widgetIndex', function (value) {
                        scope.dataWidgetIndex = value;
                    });
                    _this.initConfigService[scope.widget.id] = scope.widget;
                    _this.initConfigService[scope.widget.id]['customError'] = {};
                    scope.$watch('dwxWidget', function () {
                        if (scope.widgetType === 'Widget') {
                            scope.widgetView = 'main';
                        }
                        else if (scope.widgetType === 'HeaderView') {
                            scope.widgetView = 'header';
                        }
                        else if (scope.widgetType === 'Tile') {
                            scope.widgetView = 'tile';
                        }
                        else if (scope.widgetType === 'LightView') {
                            scope.widgetView = 'light';
                        }
                        else if (scope.widgetType === 'SidebarView') {
                            scope.widgetView = 'sidebar';
                        }
                        else {
                            scope.widgetView = 'main';
                        }
                        scope.contentUrl = Layout.templatePath(scope.dwxWidget, scope.widgetView);
                    });
                };
            }
            WidgetDirective.Factory = function () {
                var directive = function (initConfigService) {
                    return new WidgetDirective(initConfigService);
                };
                directive['$inject'] = ['initConfigService'];
                return directive;
            };
            return WidgetDirective;
        })();
        Layout.WidgetDirective = WidgetDirective;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

