define('layout-base/../../bower_components/dwx-utility/src/controllers/dwxTableController',["require", "exports"], function (require, exports) {
    var DwxTableController = (function () {
        function DwxTableController($scope, $filter) {
            this.$scope = $scope;
            this.$filter = $filter;
            this.reverseOrder = {};
        }
        DwxTableController.prototype.getValue = function (item, field) {
            if (!item[field]) {
                return "";
            }
            if (this.$scope.tableConfiguration.fieldDate.indexOf(field) > -1) {
                return this.$filter("date")(item[field]);
            }
            else if (this.$scope.tableConfiguration.fieldCurrency.indexOf(field) > -1) {
                if (!item[field].value) {
                    return "";
                }
                return this.$filter("number")(item[field].value, 2) + " " + item[field].currency;
            }
            else if (this.$scope.tableConfiguration.fieldPercentage.indexOf(field) > -1) {
                return this.$filter("number")(item[field], 0) + "%";
            }
            else {
                return item[field];
            }
        };
        DwxTableController.prototype.changeOrder = function (field) {
            if (this.reverseOrder[field] != undefined) {
                this.reverseOrder[field] = !this.reverseOrder[field];
            }
            else {
                this.reverseOrder[field] = false;
            }
            if (this.$scope.tableConfiguration.fieldCurrency.indexOf(field) > -1) {
                this.$scope.itemList = this.$filter("orderBy")(this.$scope.itemList, field + '.value', this.reverseOrder[field]);
            }
            else {
                this.$scope.itemList = this.$filter("orderBy")(this.$scope.itemList, field, this.reverseOrder[field]);
            }
        };
        return DwxTableController;
    })();
    exports.DwxTableController = DwxTableController;
});

