define('../bower_components/dwx-widget-unlock-user/src/directives/unlockUserDirective',["require", "exports"], function (require, exports) {
    exports.basePath = 'bower_components/widget-unlock-user/dist/src/views/partials/';
    var UnlockUserDirective = (function () {
        function UnlockUserDirective(unlockUserService) {
            this.unlockUserService = unlockUserService;
            this.replace = false;
            this.scope = {
                identifier: '=',
                widgetType: '@',
            };
            this.templateUrl = exports.basePath + 'main.html';
            var _this = this;
            _this.compile = function (element, attributes) {
                return {
                    pre: function (scope, element, attributes, controller, transcludeFn) {
                        scope.widget = { status: { mode: "loading" } };
                        _this.unlockUserService.widgetType = scope.widgetType;
                    },
                };
            };
        }
        UnlockUserDirective.Factory = function () {
            var directive = function (unlockUserService) {
                return new UnlockUserDirective(unlockUserService);
            };
            directive['$inject'] = ['unlockUserService'];
            return directive;
        };
        return UnlockUserDirective;
    })();
    exports.UnlockUserDirective = UnlockUserDirective;
});

