define('../bower_components/dwx-engine/src/services/angularTranslateStaticFileLoader',["require", "exports"], function (require, exports) {
    var Engine;
    (function (Engine) {
        var AngularTranslateStaticFileLoader = (function () {
            function AngularTranslateStaticFileLoader($q, $http) {
                return function (options) {
                    var deferred;
                    if (!options || (!angular.isString(options.prefix) || !angular.isString(options.suffix))) {
                        throw new Error("Couldn't load static files, no prefix or suffix specified!");
                    }
                    deferred = $q.defer();
                    $http({
                        url: [options.prefix, options.key, options.suffix].join(""),
                        method: "GET",
                        params: ""
                    }).success(function (data) {
                        deferred.resolve(data.content);
                    }).error(function (data) {
                        deferred.reject(options.key);
                    });
                    return deferred.promise;
                };
            }
            return AngularTranslateStaticFileLoader;
        })();
        Engine.AngularTranslateStaticFileLoader = AngularTranslateStaticFileLoader;
    })(Engine = exports.Engine || (exports.Engine = {}));
});

