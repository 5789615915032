define('../bower_components/dwx-widget-my-documents/src/filter/sortObjectKey',["require", "exports"], function (require, exports) {
    var SortObjectKey = (function () {
        function SortObjectKey() {
        }
        SortObjectKey.myFilter = function (items, reverse, type) {
            var filteredTemp = [];
            var filtered = [];
            switch (type) {
                case 'documents_length':
                    angular.forEach(items, function (value, key) {
                        var item = { "customer": key, "value": value.length };
                        filteredTemp.push(item);
                    });
                    filteredTemp.sort(function (a, b) {
                        return (a['value'] > b['value'] ? 1 : -1);
                    });
                    if (reverse)
                        filteredTemp.reverse();
                    angular.forEach(filteredTemp, function (item) {
                        filtered.push(item.customer);
                    });
                    break;
                default:
                    angular.forEach(items, function (value, key) {
                        filteredTemp.push(key);
                    });
                    filteredTemp.sort(function (a, b) {
                        return (a > b ? 1 : -1);
                    });
                    if (reverse)
                        filteredTemp.reverse();
                    filtered = filteredTemp;
                    break;
            }
            return filtered;
        };
        SortObjectKey.Factory = function () {
            var instance = new SortObjectKey();
            return SortObjectKey.myFilter;
        };
        return SortObjectKey;
    })();
    exports.SortObjectKey = SortObjectKey;
});

