define('layout-base/../../bower_components/dwx-layout-base/src/app',["require", "exports", './run', './controllers/headerCtrl', './controllers/layoutCtrl', './controllers/layoutHandlerCtrl', './controllers/navigationCtrl', './directives/foundationDropdown', './directives/headerDirective', './directives/navigationDirective', './directives/widgetDirective', './directives/widgetPlaceholderDirective', './services/headerService', './services/headerApiService', './services/navigationService', './directives/owuiWidgetDirective', './directives/bannerTimeoutDirective', './directives/bannerContainerDirective', "angular-translate", "./../../dwx-utility/src/app", "./../../dwx-init-config-be\/src/app"], function (require, exports, layoutRun, headerController, layoutController, layoutHandlerController, navigationController, dropDownCD, headerDirective, navigationDirective, widgetDirective, widgetPlaceholderDirective, headerService, headerApiService, navigationService, owuiWidgetDirective, bannerTimeoutDirective, bannerContainerDirective) {
    var Layout;
    (function (Layout) {
        Layout.list = {
            "layoutRun": {
                callback: ['$timeout', 'EventService', '$rootScope', '$translate', layoutRun.Layout.LayoutRun],
                type: "run"
            },
            "HeaderCtrl": {
                callback: ["$scope", "headerService", 'EventService', "headerApiService", 'profileService', headerController.Layout.HeaderController],
                type: "controller"
            },
            "LayoutCtrl": {
                callback: ['$scope', '$routeParams', 'LayoutService', 'EventService', layoutController.Layout.LayoutController],
                type: "controller"
            },
            "LayoutHandlerCtrl": {
                callback: ['$scope', 'EventService', 'localStorageService', '$cookieStore', layoutHandlerController.LayoutHandler.LayoutHandlerController],
                type: "controller"
            },
            "NavigationCtrl": {
                callback: ["$scope", "$rootScope", "$routeParams", "navigationService", 'EventService', navigationController.Layout.NavigationController],
                type: "controller"
            },
            "dropdownContent": {
                callback: ["$compile", dropDownCD.Layout.DropDownContentDirective],
                type: "directive"
            },
            "dwxLayoutHeader": {
                callback: [headerDirective.Layout.LayoutHeaderDirective],
                type: "directive"
            },
            "dwxLayoutNavigation": {
                callback: ['EventService', '$document', navigationDirective.Layout.LayoutNavigationDirective.Factory()],
                type: "directive"
            },
            "dwxInclude": {
                callback: [widgetDirective.Layout.IncludeDirective],
                type: "directive"
            },
            "dwxWidget": {
                callback: ['initConfigService', widgetDirective.Layout.WidgetDirective.Factory()],
                type: "directive"
            },
            "dwxWidgetPlaceholder": {
                callback: [widgetPlaceholderDirective.Layout.WidgetPlaceholderDirective],
                type: "directive"
            },
            "headerService": {
                callback: ['$resource', headerService.Layout.HeaderService],
                type: "service"
            },
            "headerApiService": {
                callback: ['$resource', '$http', '$q', headerApiService.HeaderApiService],
                type: "service"
            },
            "navigationService": {
                callback: ['$resource', navigationService.Layout.NavigationService],
                type: "service"
            },
            "owuiWidgetDirective": {
                callback: ['$q', 'EventService', owuiWidgetDirective.OwuiWidgetDirective.Factory()],
                type: "directive"
            },
            "bannerTimeoutDirective": {
                callback: [bannerTimeoutDirective.BannerTimeoutDirective.Factory()],
                type: "directive"
            },
            "bannerContainerDirective": {
                callback: [bannerContainerDirective.BannerContainerDirective.Factory()],
                type: "directive"
            }
        };
        Layout.ngModules = ['pascalprecht.translate'];
    })(Layout = exports.Layout || (exports.Layout = {}));
});

