define('../bower_components/azimut-utility/src/app',["require", "exports", './config', './directives/documentsModalDetailsDirective', './directives/brightcoveVideo', './directives/compileDirective', './directives/blurElement', './directives/azimutDatepicker', './services/remotePaginationService'], function (require, exports, azimutUtilityConfig, modalDetails, videoDirective, compileDirective, blurElementDirective, datePicker, remotePaginationService) {
    exports.configuration = {
        list: {
            "azimutUtilityConfig": {
                callback: ['$translateProvider', azimutUtilityConfig.AziumtUtilityConfig],
                type: "config"
            },
            "dwxDocumentsModalDetails": {
                callback: ['EventService', '$translatePartialLoader', modalDetails.AzimutUtility.DocumentsModalDetailsDirective.Factory()],
                type: "directive"
            },
            "remotePaginationService": {
                callback: [remotePaginationService.Utility.PaginationService],
                type: "factory"
            },
            "dwxModalDirective": {
                callback: ['EventService', modalDetails.AzimutUtility.DocumentsModalDirective.Factory()],
                type: "directive"
            },
            "customCloseRevealModal": {
                callback: [modalDetails.AzimutUtility.DocumentsModalCloseDirective.Factory()],
                type: "directive"
            },
            "dwxBrightcoveVideo": {
                callback: ['$timeout', videoDirective.BrightcoveVideoDirective.Factory()],
                type: "directive"
            },
            "dwxCompile": {
                callback: ['$compile', compileDirective.CompileDirective.Factory()],
                type: "directive"
            },
            "blurElement": { callback: ['$compile', blurElementDirective.BlurElementDirective.Factory()], type: "directive" },
            "dwxDatePickerAz": { callback: ["$timeout", "$filter", 'EventService', datePicker.Utility.DatePickerDirective.Factory()], type: "directive" },
        },
        ngModules: []
    };
});

