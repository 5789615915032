define('../bower_components/dwx-widget-my-documents/src/domain/serviceData',["require", "exports"], function (require, exports) {
    var GenericDocumentsServiceParams = (function () {
        function GenericDocumentsServiceParams() {
        }
        GenericDocumentsServiceParams.prototype.getDocCategories = function () {
            return this.docCategories;
        };
        GenericDocumentsServiceParams.prototype.setDocCategories = function (docCategories) {
            this.docCategories = docCategories;
            return this;
        };
        GenericDocumentsServiceParams.prototype.getDocTags = function () {
            return this.docTags;
        };
        GenericDocumentsServiceParams.prototype.setDocTags = function (docTags) {
            this.docTags = docTags;
            return this;
        };
        GenericDocumentsServiceParams.prototype.getWidgetId = function () {
            return this.widgetId;
        };
        GenericDocumentsServiceParams.prototype.setWidgetId = function (widgetId) {
            this.widgetId = widgetId;
            return this;
        };
        GenericDocumentsServiceParams.prototype.getAdvisorSearchUserCode = function () {
            return this.userCode;
        };
        GenericDocumentsServiceParams.prototype.setAdvisorSearchUserCode = function (userCode) {
            this.userCode = !!userCode ? encodeURIComponent(userCode) : userCode;
            return this;
        };
        GenericDocumentsServiceParams.prototype.getAdvisorSearchNominative = function () {
            return this.nominative;
        };
        GenericDocumentsServiceParams.prototype.setAdvisorSearchNominative = function (nominative) {
            this.nominative = !!nominative ? encodeURIComponent(nominative) : nominative;
            return this;
        };
        GenericDocumentsServiceParams.prototype.getAdvisorSearchStartDate = function () {
            return this.startDate;
        };
        GenericDocumentsServiceParams.prototype.setAdvisorSearchStartDate = function (startDate) {
            this.startDate = !!startDate ? encodeURIComponent(startDate) : startDate;
            return this;
        };
        GenericDocumentsServiceParams.prototype.getAdvisorSearchEndDate = function () {
            return this.endDate;
        };
        GenericDocumentsServiceParams.prototype.setAdvisorSearchEndDate = function (endDate) {
            this.endDate = !!endDate ? encodeURIComponent(endDate) : endDate;
            return this;
        };
        GenericDocumentsServiceParams.prototype.getAdvisorSearchDocType = function () {
            return this.docType;
        };
        GenericDocumentsServiceParams.prototype.setAdvisorSearchDocType = function (docType) {
            this.docType = docType;
            return this;
        };
        return GenericDocumentsServiceParams;
    })();
    exports.GenericDocumentsServiceParams = GenericDocumentsServiceParams;
    var GenericDocumentsPaginatedServiceParams = (function () {
        function GenericDocumentsPaginatedServiceParams() {
        }
        GenericDocumentsPaginatedServiceParams.prototype.setTagList = function (tagList) {
            this.docTags = tagList;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getDocCategory = function () {
            return this.docCategory;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setDocCategory = function (docCategory) {
            this.docCategory = docCategory;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getPage = function () {
            return this.page;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setPage = function (page) {
            this.page = page;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getSize = function () {
            return this.size;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setSize = function (size) {
            this.size = size;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getSort = function () {
            return this.size;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setSort = function (sort) {
            this.sort = sort;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getAdvisorSearchUserCode = function () {
            return this.userCode;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setAdvisorSearchUserCode = function (userCode) {
            this.userCode = !!userCode ? encodeURIComponent(userCode) : userCode;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getAdvisorSearchNominative = function () {
            return this.nominative;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setAdvisorSearchNominative = function (nominative) {
            this.nominative = !!nominative ? encodeURIComponent(nominative) : nominative;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getAdvisorSearchStartDate = function () {
            return this.startDate;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setAdvisorSearchStartDate = function (startDate) {
            this.startDate = !!startDate ? encodeURIComponent(startDate) : startDate;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getAdvisorSearchEndDate = function () {
            return this.endDate;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setAdvisorSearchEndDate = function (endDate) {
            this.endDate = !!endDate ? encodeURIComponent(endDate) : endDate;
            return this;
        };
        GenericDocumentsPaginatedServiceParams.prototype.getAdvisorSearchDocType = function () {
            return this.docType;
        };
        GenericDocumentsPaginatedServiceParams.prototype.setAdvisorSearchDocType = function (docType) {
            this.docType = docType;
            return this;
        };
        return GenericDocumentsPaginatedServiceParams;
    })();
    exports.GenericDocumentsPaginatedServiceParams = GenericDocumentsPaginatedServiceParams;
    var GenericGroupedDocumentsServiceParams = (function () {
        function GenericGroupedDocumentsServiceParams() {
        }
        GenericGroupedDocumentsServiceParams.prototype.setDocTags = function (docTags) {
            this.docTags = docTags;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getKeyIsAttribute = function () {
            return this.keyIsAttribute;
        };
        GenericGroupedDocumentsServiceParams.prototype.setKeyIsAttribute = function (keyIsAttribute) {
            this.keyIsAttribute = keyIsAttribute;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getDocCategory = function () {
            return this.docCategory;
        };
        GenericGroupedDocumentsServiceParams.prototype.setDocCategory = function (docCategory) {
            this.docCategory = docCategory;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getPage = function () {
            return this.page;
        };
        GenericGroupedDocumentsServiceParams.prototype.setPage = function (page) {
            this.page = page;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getSize = function () {
            return this.size;
        };
        GenericGroupedDocumentsServiceParams.prototype.setSize = function (size) {
            this.size = size;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getSort = function () {
            return this.size;
        };
        GenericGroupedDocumentsServiceParams.prototype.setSort = function (sort) {
            this.sort = sort;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getGroupBy = function () {
            return this.groupBy;
        };
        GenericGroupedDocumentsServiceParams.prototype.setGroupBy = function (groupBy) {
            this.groupBy = groupBy;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getAdvisorSearchUserCode = function () {
            return this.userCode;
        };
        GenericGroupedDocumentsServiceParams.prototype.setAdvisorSearchUserCode = function (userCode) {
            this.userCode = !!userCode ? encodeURIComponent(userCode) : userCode;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getAdvisorSearchNominative = function () {
            return this.nominative;
        };
        GenericGroupedDocumentsServiceParams.prototype.setAdvisorSearchNominative = function (nominative) {
            this.nominative = !!nominative ? encodeURIComponent(nominative) : nominative;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getAdvisorSearchStartDate = function () {
            return this.startDate;
        };
        GenericGroupedDocumentsServiceParams.prototype.setAdvisorSearchStartDate = function (startDate) {
            this.startDate = !!startDate ? encodeURIComponent(startDate) : startDate;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getAdvisorSearchEndDate = function () {
            return this.endDate;
        };
        GenericGroupedDocumentsServiceParams.prototype.setAdvisorSearchEndDate = function (endDate) {
            this.endDate = !!endDate ? encodeURIComponent(endDate) : endDate;
            return this;
        };
        GenericGroupedDocumentsServiceParams.prototype.getAdvisorSearchDocType = function () {
            return this.docType;
        };
        GenericGroupedDocumentsServiceParams.prototype.setAdvisorSearchDocType = function (docType) {
            this.docType = docType;
            return this;
        };
        return GenericGroupedDocumentsServiceParams;
    })();
    exports.GenericGroupedDocumentsServiceParams = GenericGroupedDocumentsServiceParams;
});

