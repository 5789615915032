define('layout-base/../../bower_components/dwx-layout-base/src/services/navigationService',["require", "exports"], function (require, exports) {
    var Layout;
    (function (Layout) {
        var NavigationService = (function () {
            function NavigationService($resource) {
                return {
                    menu: $resource("" + apiPrefix + "/navigation")
                };
            }
            return NavigationService;
        })();
        Layout.NavigationService = NavigationService;
    })(Layout = exports.Layout || (exports.Layout = {}));
});

