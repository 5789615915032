define('../bower_components/azimut-utility/src/directives/blurElement',["require", "exports"], function (require, exports) {
    var BlurElementDirective = (function () {
        function BlurElementDirective($compile) {
            this.restrict = 'A';
            var _this = this;
            this.link = function (scope, element, attrs) {
                $(element).focus(function (e) {
                    e.preventDefault();
                    $(element).blur();
                });
            };
        }
        BlurElementDirective.Factory = function () {
            var directive = function ($compile) {
                return new BlurElementDirective($compile);
            };
            directive['$inject'] = [];
            return directive;
        };
        return BlurElementDirective;
    })();
    exports.BlurElementDirective = BlurElementDirective;
});

