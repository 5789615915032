define('../bower_components/dwx-widget-html-content/src/services/htmlContentService',["require", "exports"], function (require, exports) {
    var Services;
    (function (Services) {
        var HtmlContentService = (function () {
            function HtmlContentService($resource) {
                return $resource("" + apiPrefix + "/widget/htmlcontent/:widgetId", {
                    widgetId: '@widgetId'
                });
            }
            return HtmlContentService;
        })();
        Services.HtmlContentService = HtmlContentService;
    })(Services = exports.Services || (exports.Services = {}));
});

