define('widget-news/../../project_components/dwx-widget-news/src/controllers/newsCtrl',["require", "exports", '../../../../scripts/owui-widget.constant'], function (require, exports, OwuiWidgetConstant) {
    var NewsController = (function () {
        function NewsController($scope, initConfigService, EventService, $location, configurationService) {
            this.$scope = $scope;
            this.EventService = EventService;
            this.$location = $location;
            this.configurationService = configurationService;
            this.owuiWidgetNewsName = 1 /* WIDGET_NEWS */;
            var _this = this;
            $scope.events = this;
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetAdvisor';
            _this.widgetConfig = initConfigService[_this.widgetId];
            this.widgetConfig.status.mode = 'on';
            $scope.$on("$destroy", function () {
                _this.EventService.unsubscribe(_this.owuiWidgetEventHandler);
            });
            this.widgetProp = {
                'dwx-mode': true
            };
            this.owuiWidgetEventHandler = EventService.subscribe('owuiWidgetDirective:widgetLoaded', function (widgetName) {
                var widgetNewsTag = OwuiWidgetConstant.OwuiWidgetMap[1 /* WIDGET_NEWS */].widgetTag;
                if (widgetName === widgetNewsTag) {
                    console.log('owuiWidgetDirective:widgetLoaded', widgetName);
                    document.getElementsByTagName(widgetNewsTag)[0].addEventListener(widgetNewsTag, function (event) {
                        var newsParams;
                        switch (event.detail.type) {
                            case "show-all":
                                newsParams = {
                                    back: 'Home'
                                };
                                break;
                            case "read-one":
                                newsParams = {
                                    newsId: encodeURIComponent(event.detail.data.idReference),
                                    newsIndex: event.detail.data.newsIndex,
                                    back: 'Home'
                                };
                                break;
                        }
                        _this.$location.path("/news").search(newsParams);
                        _this.$scope.$apply();
                    });
                }
            });
        }
        NewsController.prototype.getManifestAssets = function () {
            return this.configurationService.manifestJson;
        };
        return NewsController;
    })();
    exports.NewsController = NewsController;
});

