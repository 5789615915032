define('../bower_components/dwx-engine/src/app',["require", "exports", './config', './run', './controllers/profileCtrl', './services/angularTranslateStaticFileLoader', './services/configurationService', './services/languageService', './services/layoutService', './services/profileService', "angular-route", "angular-resource", "angular-cookies", "angular-translate", "angular-dynamic-locale", "./../../dwx-utility/src/app"], function (require, exports, engineConfiguration, engineRun, profileController, angularTranslateStaticFileLoader, configurationService, languageService, layoutService, profileService) {
    var Engine;
    (function (Engine) {
        Engine.configuration = {
            'list': {
                "engineConfig": {
                    callback: ['$translateProvider', 'tmhDynamicLocaleProvider', '$compileProvider', '$httpProvider', '$routeProvider', engineConfiguration.Engine.EngineConfiguration],
                    type: "config"
                },
                "engineRun": {
                    callback: ["$rootScope", '$cookies', '$location', '$routeParams', 'languageService', 'configurationService', 'profileService', 'EventService', 'LayoutService', engineRun.Engine.EngineRun],
                    type: "run"
                },
                "ProfileCtrl": {
                    callback: ['profileService', 'EventService', '$cookieStore', '$location', '$scope', profileController.Engine.ProfileController],
                    type: "controller"
                },
                "$translateStaticFilesLoader": { callback: ["$q", "$http", angularTranslateStaticFileLoader.Engine.AngularTranslateStaticFileLoader], type: "factory" },
                "configurationService": {
                    callback: ['$resource', 'EventService', configurationService.Engine.ConfigurationService],
                    type: "factory"
                },
                "languageService": {
                    callback: ["$resource", "$translate", "tmhDynamicLocale", "$cookies", 'EventService', languageService.Engine.LanguageService],
                    type: "factory"
                },
                "LayoutService": { callback: ['$resource', '$rootScope', layoutService.Engine.LayoutService], type: "factory" },
                "profileService": { callback: ['$resource', profileService.Engine.ProfileService], type: "factory" }
            },
            'ngModules': ['ngRoute', 'ngResource', 'ngCookies', 'pascalprecht.translate', 'tmh.dynamicLocale']
        };
    })(Engine = exports.Engine || (exports.Engine = {}));
});

