define('widget-user-data/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var UserdataConfig = (function () {
        function UserdataConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return UserdataConfig;
    })();
    exports.UserdataConfig = UserdataConfig;
});

