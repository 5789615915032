define('layout-base/../../bower_components/dwx-utility/src/services/callToBackService',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var CallToBackService = (function () {
            function CallToBackService(errorsHandlerService) {
                return function (widgetConfig, widgetService) {
                    var errorsHandlerService = errorsHandlerService;
                    var countCallToBack = 0;
                    var config;
                    var service;
                    var setWidgetStatusMode;
                    var serviceStatus;
                    var callToBack;
                    var getCountCalls;
                    setWidgetStatusMode = function () {
                        countCallToBack--;
                        if (countCallToBack < 1) {
                            config.status.mode = 'on';
                        }
                    };
                    this.getCountCalls = function () {
                        return countCallToBack;
                    };
                    this.callToBack = function (successFunction, successFunctionParams, serviceName, serviceMethod, serviceParams, serviceBody) {
                        if (serviceStatus && !serviceStatus.status) {
                            if (config.status.mode != 'loading') {
                                config.status.mode = 'waiting';
                            }
                            countCallToBack++;
                            service[serviceName][serviceMethod](serviceParams, serviceBody, function (response) {
                                if (successFunctionParams == undefined) {
                                    successFunctionParams = [];
                                }
                                successFunctionParams.push(JSON.parse(angular.toJson(response)));
                                successFunction.apply(this, successFunctionParams);
                                setWidgetStatusMode();
                            }, function (response) {
                                config.status.mode = 'error';
                                serviceStatus = {
                                    error: "CallToBackService down: the call to service '" + serviceName + "' generated the error " + response,
                                    status: true
                                };
                                console.log(serviceStatus.error);
                            });
                        }
                        else {
                            console.log(serviceStatus.error);
                        }
                    };
                    (function (widgetConfig, widgetService) {
                        config = widgetConfig;
                        service = widgetService;
                        serviceStatus = {
                            error: "",
                            status: false
                        };
                    })(widgetConfig, widgetService);
                };
            }
            return CallToBackService;
        })();
        Utility.CallToBackService = CallToBackService;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

