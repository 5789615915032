define('layout-base/layout-base',["require", "exports", '../../bower_components/dwx-layout-base/src/app', '../../bower_components/dwx-layout-base/src/directives/widgetDirective', './config', '../../bower_components/dwx-layout-base/src/controllers/navigationCtrl', '../../bower_components/dwx-layout-base/src/controllers/layoutCtrl', '../../bower_components/dwx-layout-base/src/controllers/headerCtrl'], function (require, exports, layoutBase, layoutBaseDirective, layoutBaseConfig, navigationController, layoutController, headerController) {
    var layoutConfigOverride = layoutBase.Layout.list;
    layoutConfigOverride['config'] = { callback: ['$translateProvider', layoutBaseConfig.LayoutConfig], type: "config" };
    layoutConfigOverride['NavigationCtrl'].callback = ["$scope", "$rootScope", "$routeParams", "navigationService", 'EventService', '$translatePartialLoader', navigationController.Layout.NavigationController];
    layoutConfigOverride['LayoutCtrl'].callback = ['$scope', '$routeParams', 'LayoutService', 'EventService', 'localStorageService', '$cookieStore', 'profileService', 'portfolioCoreService', '$location', layoutController.Layout.LayoutController];
    var newNgModules = layoutBase.Layout.ngModules;
    newNgModules.push('LocalStorageModule');
    exports.configuration = { ngModules: newNgModules, list: layoutConfigOverride };
    navigationController.subscribeToPortolioSelectedEvent = false;
    headerController.subscribeToPortolioSelectedEvent = false;
    layoutBaseDirective.Layout.templatePath = function (widgetName, widgetView) {
        return "widgets/" + widgetName + "/" + widgetView + ".html";
    };
});

