define('widget-settings-customer/config',["require", "exports"], function (require, exports) {
    var SettingsCustomerConfig = (function () {
        function SettingsCustomerConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return SettingsCustomerConfig;
    })();
    exports.SettingsCustomerConfig = SettingsCustomerConfig;
});

