define('../bower_components/dwx-widget-unlock-user/src/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var UnlockConfig = (function () {
        function UnlockConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return UnlockConfig;
    })();
    exports.UnlockConfig = UnlockConfig;
});

