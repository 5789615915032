define('widget-news-list/../../project_components/dwx-widget-news-list/src/directives/newsListDirective',["require", "exports"], function (require, exports) {
    var NewsListDirective = (function () {
        function NewsListDirective() {
            this.scope = { identifier: '=' };
            this.replace = true;
            this.controller = 'newsListCtrl as newsList';
            this.templateUrl = 'bower_components/dwx-widget-news-list/dist/src/views/partials/main.html';
            var _this = this;
        }
        NewsListDirective.Factory = function () {
            var directive = function () {
                return new NewsListDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return NewsListDirective;
    })();
    exports.NewsListDirective = NewsListDirective;
});

