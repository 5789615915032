define('../bower_components/dwx-widget-my-documents/src/app',["require", "exports", './config', './controllers/myDocumentsCtrl', './directives/myDocumentsDirective', './services/myDocumentsService', './services/myDocumentsConfigurationService', './filter/sortFieldInAttributes', './filter/filterDocuments', './filter/sortObjectKey', './directives/myDocumentsNestedTableDirective', './controllers/myDocumentsNestedTableCtrl', './services/groupedTableService', './directives/contentTableDirective'], function (require, exports, myDocConfig, myDocController, myDocDirective, myDocService, myDocConfigService, myDocFilter, myDocApplyFilter, myDocFilterObjectKey, myDocNestedTableDirective, myDocNestedTableController, groupedTableService, contentTableDirective) {
    exports.configuration = {
        list: {
            "myDocumentsConfig": { callback: ["$translateProvider", myDocConfig.MyDocumentsConfig], type: "config" },
            "myDocumentsCtrl": {
                callback: [
                    '$scope',
                    '$filter',
                    'myDocumentsService',
                    'EventService',
                    'paginationService',
                    'initConfigService',
                    '$window',
                    '$translatePartialLoader',
                    '$translate',
                    '$timeout',
                    'remotePaginationService',
                    'myDocumentsConfigurationService',
                    'groupedTableService',
                    '$q',
                    '$rootScope',
                    '$location',
                    '$interval',
                    'configurationService',
                    myDocController.MyDocumentsController
                ],
                type: "controller"
            },
            "dwxWidgetMyDocuments": {
                callback: ['myDocumentsService', myDocDirective.MyDocumentsDirective.Factory()],
                type: "directive"
            },
            "myDocumentsService": { callback: ['$resource', '$http', '$q', myDocService.MyDocumentsService], type: "service" },
            "myDocumentsConfigurationService": { callback: [myDocConfigService.MyDocumentsConfigurationService], type: "service" },
            "groupedTableService": { callback: [groupedTableService.GroupedTableService], type: "service" },
            "dwxMyDocumentsSortFieldsInAttributes": { callback: [myDocFilter.SortFieldInAttributes.Factory], type: "filter" },
            "dwxMyDocumentsFilterDocuments": { callback: [myDocApplyFilter.FilterDocuments.Factory], type: "filter" },
            "dwxMyDocumentsSortObjectKey": { callback: [myDocFilterObjectKey.SortObjectKey.Factory], type: "filter" },
            "myDocumentsNestedTableCtrl": {
                callback: ['$scope', 'myDocumentsConfigurationService', 'groupedTableService', '$filter', myDocNestedTableController.MyDocumentsNestedTableController],
                type: "controller"
            },
            "myDocumentsNestedTableDirective": {
                callback: [myDocNestedTableDirective.MyDocumentsNestedTableDirective.Factory()],
                type: "directive"
            },
            "contentTableDirective": {
                callback: ['$filter', contentTableDirective.ContentTableDirective.Factory()],
                type: "directive"
            }
        },
        ngModules: ['mm.foundation', 'pascalprecht.translate']
    };
});

