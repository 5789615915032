define('layout-base/../../bower_components/dwx-utility/src/filters/base64Filter',["require", "exports"], function (require, exports) {
    var Utility;
    (function (Utility) {
        var Base64Filter = (function () {
            function Base64Filter() {
            }
            Base64Filter.myFilter = function (input, arg) {
                if (input === null) {
                    return "";
                }
                if (arg === 'decode') {
                    return decodeURIComponent(escape(window.atob(input)));
                }
                else if (arg === 'encode') {
                    return window.btoa(unescape(encodeURIComponent(input)));
                }
                else {
                    return input;
                }
            };
            Base64Filter.Factory = function () {
                var instance = new Base64Filter();
                return Utility.Base64Filter.myFilter;
            };
            return Base64Filter;
        })();
        Utility.Base64Filter = Base64Filter;
    })(Utility = exports.Utility || (exports.Utility = {}));
});

