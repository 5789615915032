define('layout-base/../../bower_components/dwx-utility/src/directives/paginationDirective',["require", "exports"], function (require, exports) {
    var PaginationDirective = (function () {
        function PaginationDirective() {
            this.restrict = 'E';
            this.scope = false;
            this.templateUrl = 'views/pagination.html';
            this.link = function (scope) {
                console.log(scope.paginationInstance);
            };
        }
        PaginationDirective.Factory = function () {
            var directive = function () {
                return new PaginationDirective();
            };
            directive['$inject'] = [];
            return directive;
        };
        return PaginationDirective;
    })();
    exports.PaginationDirective = PaginationDirective;
});

