define('layout-base/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var LayoutConfig = (function () {
        function LayoutConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return LayoutConfig;
    })();
    exports.LayoutConfig = LayoutConfig;
});

