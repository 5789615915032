define('../bower_components/dwx-widget-my-documents/src/domain/rto-second-step-checkbox-conf',["require", "exports"], function (require, exports) {
    exports.checkboxConf = [
        {
            title: 'Esito appropriatezza',
            customField: 'flgAppropriate',
            checkboxLabel: {
                false: 'Non appropriato, richiesta conferma',
                true: 'Appropriato'
            },
            checkboxModel: 'rtoAckAppropriateness'
        },
        {
            title: 'Complesso',
            checkboxLabel: 'complexity',
            checkboxModel: 'rtoAckComplex'
        },
        {
            title: 'Ball in',
            checkboxLabel: 'bailIn',
            checkboxModel: 'rtoAckBall'
        },
        {
            title: 'Illiquido',
            checkboxLabel: 'illiquidity',
            checkboxModel: 'rtoAckIlliquid'
        },
        {
            title: 'Operaz. in conflitto',
            customField: 'flgConflictOfInterests',
            checkboxLabel: {
                false: 'No',
                true: 'Si'
            },
            checkboxModel: 'rtoAckConflict'
        }
    ];
});

