define('widget-advisor/../../project_components/dwx-widget-advisor/src/controllers/advisorCtrl',["require", "exports"], function (require, exports) {
    exports.subscribeToPortolioSelectedEvent = true;
    var AdvisorController = (function () {
        function AdvisorController($scope, advisorService, initConfigService, EventService, $translatePartialLoaderProvider, $translate) {
            this.$scope = $scope;
            this.advisorService = advisorService;
            this.EventService = EventService;
            this.$translate = $translate;
            var _this = this;
            $scope.events = this;
            this.widgetId = (typeof $scope.identifier !== 'undefined') ? $scope.identifier : (typeof $scope.widget != 'undefined') ? $scope.widget.id : 'widgetAdvisor';
            _this.widgetConfig = initConfigService[_this.widgetId];
            this.advisorData = {};
            if ($translatePartialLoaderProvider != undefined) {
                $translatePartialLoaderProvider.addPart('bower_components/dwx-widget-advisor');
            }
            if (exports.subscribeToPortolioSelectedEvent) {
                _this.eventHandler = EventService.subscribe('portfolioSelected', function () {
                    _this.advisorData = {};
                    _this.getAdvisorData();
                });
            }
            else {
                _this.getAdvisorData();
            }
            $scope.$on("$destroy", function () {
                if (exports.subscribeToPortolioSelectedEvent) {
                    _this.EventService.unsubscribe(_this.eventHandler, false);
                }
            });
            this.tab = 'contacts';
            this.timeDisabled = false;
        }
        AdvisorController.prototype.toggleButton = function () {
            var that = this;
            if (!this.timeDisabled) {
                setTimeout(function () {
                    that.timeDisabled = true;
                    $(".advisor")[0].disabled = true;
                }, 50);
                setTimeout(function () {
                    $(".advisor")[0].disabled = false;
                    that.timeDisabled = false;
                }, 1050);
            }
        };
        AdvisorController.prototype.changeTab = function (tabName) {
            this.tab = tabName;
        };
        AdvisorController.prototype.getAdvisorData = function () {
            var _this = this;
            _this.advisorService.advisorData.get({}, function (response) {
                if (response.outcome == "OK") {
                    _this.advisorData = response.content;
                    _this.widgetConfig.status.mode = 'on';
                    _this.$scope.$evalAsync(function () {
                        if (_this.$translate != undefined) {
                            _this.$translate.refresh();
                        }
                    });
                }
                else {
                    console.log('ERROR RETRIEVING ADVISOR DATA!!!');
                }
            }, function (error) {
                console.log('ERROR RETRIEVING ADVISOR DATA!!!');
            });
        };
        return AdvisorController;
    })();
    exports.AdvisorController = AdvisorController;
});

