define('../bower_components/dwx-widget-my-documents/src/config',["require", "exports"], function (require, exports) {
    var MyDocumentsConfig = (function () {
        function MyDocumentsConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return MyDocumentsConfig;
    })();
    exports.MyDocumentsConfig = MyDocumentsConfig;
});

