define('../bower_components/dwx-widget-media-library/src/config',["require", "exports", "angular-translate-loader-partial"], function (require, exports) {
    var MediaLibraryConfig = (function () {
        function MediaLibraryConfig($translateProvider) {
            $translateProvider.useLoader('$translatePartialLoader', {
                urlTemplate: '{part}/i18n/locale-{lang}.json'
            });
            $translateProvider.preferredLanguage('it-it');
        }
        return MediaLibraryConfig;
    })();
    exports.MediaLibraryConfig = MediaLibraryConfig;
});

