define('../bower_components/dwx-portfolio-core/src/app',["require", "exports", './services/portfolioCoreService'], function (require, exports, portfolioCoreService) {
    var PortfolioCore;
    (function (PortfolioCore) {
        PortfolioCore.configuration = {
            list: {
                "portfolioCoreService": {
                    callback: ["$resource", "EventService", "$cookieStore", "$rootScope", "$q", "$http", "profileService", portfolioCoreService.PortfolioCore.PortfolioCoreService],
                    type: "factory"
                }
            },
            ngModules: ['ngResource']
        };
    })(PortfolioCore = exports.PortfolioCore || (exports.PortfolioCore = {}));
});

