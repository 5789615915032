define('../bower_components/dwx-errors-handler/src/services/errorsHandlerService',["require", "exports"], function (require, exports) {
    var ErrorsHandlerService = (function () {
        function ErrorsHandlerService(EventService) {
            this.EventService = EventService;
            this.errorList = ["KBCS.DEFAULT.ERROR", "generic_error", "generic_service_error"];
            this.sessionErrorList = ["session_expired", "user_not_authenticated"];
        }
        ErrorsHandlerService.prototype.handleRequestError = function (error, widgetConfig) {
            widgetConfig.status.message = "dashboard.error.connection_error";
            widgetConfig.mode = 'error';
        };
        ErrorsHandlerService.prototype.handleResponseError = function (response, widgetConfig) {
            if (this.sessionErrorList.indexOf(response.messageKey) >= 0) {
                this.EventService.publish('user-session-changed');
                this.EventService.publish('redirect-to-home');
            }
            else if (this.errorList.indexOf(response.messageKey) >= 0) {
                widgetConfig.status.message = response.message;
                widgetConfig.status.mode = 'error';
            }
            else {
                if (widgetConfig.customError == undefined) {
                    widgetConfig.customError = {};
                }
                widgetConfig.customError.status = true;
                widgetConfig.customError.message = "error." + response.messageKey;
                widgetConfig.status.mode = 'on';
            }
        };
        ErrorsHandlerService.Factory = function () {
            var service = function (EventService) {
                return new ErrorsHandlerService(EventService);
            };
            service['$inject'] = ['EventService'];
            return service;
        };
        return ErrorsHandlerService;
    })();
    exports.ErrorsHandlerService = ErrorsHandlerService;
});

