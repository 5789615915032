define('widget-user-data-advanced/../../bower_components/dwx-widget-user-data-advanced/src/services/userDataAdvancedService',["require", "exports"], function (require, exports) {
    var UserDataAdvancedService = (function () {
        function UserDataAdvancedService($resource) {
            return {
                retrieveBundleUrl: $resource(apiPrefix + "/widget/signRoom/getBundleUrl", { bundleCode: '@bundleCode', bundleContext: '@bundleContext' }, {
                    get: {
                        isArray: false
                    }
                }),
                getGenericDocuments: $resource("" + apiPrefix + "/documents/getDocumentListByUser", {
                    docCategory: '@docCategory'
                }),
                userData: $resource("" + apiPrefix + "/userdata/:widgetId", {
                    widgetId: '@widgetId'
                }),
                formData: $resource("" + apiPrefix + "/formdata/userdata", {}),
                updatePassword: $resource("" + apiPrefix + "/accountManagement/updateUserPassword", {}, {
                    update: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                getStatus: $resource(apiPrefix + '/firstaccess/status/:processName', { processName: '@processName' }),
                startProcess: $resource(apiPrefix + '/firstaccess/start/process/:processName', { processName: '@processName' }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                submit: $resource(apiPrefix + '/firstaccess/submit/:processName/:stepCode/:stepId', {
                    processName: '@processName',
                    stepCode: '@stepCode',
                    stepId: '@stepId'
                }, {
                    save: {
                        method: 'POST',
                        isArray: false
                    }
                }),
                complete: $resource(apiPrefix + '/firstaccess/complete/:processName/:stepCode/:stepId', {
                    processName: '@processName',
                    stepCode: '@stepCode',
                    stepId: '@stepId'
                }, {
                    save: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                abort: $resource(apiPrefix + '/firstaccess/cancel/:processName', {
                    processName: '@processName',
                }, {
                    save: {
                        method: 'PUT',
                        isArray: false
                    }
                }),
                createReport: $resource(apiPrefix + "/firstaccess/createReport", {}),
                updateData: $resource(apiPrefix + "/userdata/update", {}, {
                    save: {
                        method: 'POST'
                    }
                })
            };
        }
        return UserDataAdvancedService;
    })();
    exports.UserDataAdvancedService = UserDataAdvancedService;
});

